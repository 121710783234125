import { useEffect, useMemo, useState } from 'react';

import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OrgMemberInfo, useAuthInfo } from '@propelauth/react';
import { motion } from 'framer-motion';
import { useNavigate, useSearchParams } from 'react-router-dom';

import MatterItemComponent from './components/MatterItem';
import useGetMatters, { QueryParams } from '@/api/queries/useGetMatters';
import EmptyBoxIcon from '@/assets/icons/empty-box.svg?react';
import Button from '@/components/atoms/Button';
import EmptyState from '@/components/molecules/EmptyState';
import SearchBox from '@/components/molecules/SearchBox';
import Pagination from '@/components/organisms/Pagination';
import { useUserContext } from '@/Contexts/User';
import { Matter, MatterType } from '@/types';

const roles = {
  ADMIN: 'Admin',
};

const MattersPanel = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  // State
  const [searchText, setSearchText] = useState('');
  const [totalPages, setTotalPages] = useState(1);
  const [pageQuery, setPageQuery] = useState(parseInt(searchParams.get('page') || '0') || 0);

  const dateFromQuery = searchParams.get('dateFrom') || '';
  const dateToQuery = searchParams.get('dateTo') || '';
  const sortByQuery = searchParams.get('sortBy') || 'score_desc';

  const queryParams: QueryParams = useMemo(
    () => ({
      search: searchText,
      page: pageQuery,
      dateFrom: dateFromQuery,
      dateTo: dateToQuery,
      sortBy: sortByQuery,
    }),
    [searchText, pageQuery, dateFromQuery, dateToQuery, sortByQuery],
  );

  const { isLoading, isFetching, data } = useGetMatters(queryParams);

  // Auth stuff
  const authInfo = useAuthInfo();
  const orgs = authInfo.orgHelper?.getOrgs();
  const asignedOrg = orgs?.length && (orgs[0] as OrgMemberInfo);
  const assignedRole = asignedOrg && asignedOrg?.userAssignedRole;
  const isAdmin = assignedRole === roles['ADMIN'];
  const { user } = useUserContext();
  const isSuperAdmin = user?.metadata?.metadata?.orgSuperAdmin === 'true';

  useEffect(() => {
    setTotalPages(data?.pageCount || 1);
  }, [data]);

  useEffect(() => {
    setPageQuery(0);
    navigate(`/app/chronos/matters?page=${0}${searchText ? `&searchText=${searchText}` : ''}`);
  }, [searchText, navigate]);

  const handleClickMatterItem = (id: string) => {
    navigate(`/app/chronos/explore?matterId=${id}`);
  };

  const handleClickEditMatter = (id: string) => {
    navigate(`/app/chronos/matter-editor/${id}`);
  };

  const goToNewMatter = () => {
    navigate(`/app/chronos/matter-creator`);
  };

  const handleGoToNextPage = () => {
    navigate(`/app/chronos/matters?page=${pageQuery + 1}${searchText ? `&searchText=${searchText}` : ''}`);
    setPageQuery(pageQuery + 1);
  };

  const handleGoToPreviousPage = () => {
    navigate(`/app/chronos/matters?page=${pageQuery - 1}${searchText ? `&searchText=${searchText}` : ''}`);
    setPageQuery(pageQuery - 1);
  };

  const goToPage = (value: number) => {
    navigate(`/app/chronos/matters?page=${value}${searchText ? `&searchText=${searchText}` : ''}`);
    setPageQuery(value);
  };

  return (
    <div className="w-2/3 border-l py-4">
      <div className="mb-4 flex flex-col">
        <h2 className="mb-4 border-2 border-x-0 border-t-0 px-4 pb-1 text-lg font-semibold">Matters</h2>

        <p className="mb-4 px-4 text-sm text-gray-800">
          Matters are collections of cases. Any user who is a member of a matter can see all the cases within it.
        </p>

        <div className="flex items-center justify-between gap-4 px-4">
          <div className="flex flex-row items-center gap-6">
            <SearchBox value={searchText} onSearchCall={setSearchText} placeholder="Search matters..." />
            {(isAdmin || isSuperAdmin) && (
              <Button
                data-testid="new-matter-button"
                rounded="base"
                type="brand"
                text="New Matter"
                onClick={goToNewMatter}
                icon={<FontAwesomeIcon icon={faAdd} className="mr-2 text-white" />}
              />
            )}
          </div>

          {!isLoading && data?.matters?.length ? (
            <Pagination
              canGetNextPage={pageQuery < totalPages - 1}
              canGetPrevPage={pageQuery > 0}
              prevPage={handleGoToPreviousPage}
              nextPage={handleGoToNextPage}
              currentPage={pageQuery}
              noOfPages={totalPages}
              goToPage={goToPage}
            />
          ) : null}
        </div>
      </div>
      <div className="flex w-full flex-col gap-2 rounded px-4">
        {isLoading || isFetching ? (
          <div className="flex h-full w-full flex-col gap-4">
            <div className="flex h-full w-full flex-col gap-4">
              {Array(5)
                .fill(null)
                .map((_, index) => (
                  <div key={`skeleton-${index}`} className="h-20 w-full animate-pulse rounded-md bg-gray-100" />
                ))}
            </div>
          </div>
        ) : (
          <>
            {!data?.matters?.length ? (
              <EmptyState
                title="No matters found"
                subtitle={
                  isAdmin ? 'Create your first matter!' : 'Contact your Admin to be added to an existing matter'
                }
              />
            ) : (
              <div>
                <div className="flex w-full flex-col gap-2">
                  {data?.matters?.map((matter: Matter, i: number) => {
                    const isCreator = matter.createdById === authInfo.user?.userId;
                    const isPersonalMatter = i === 0 && pageQuery === 0 && matter.type === MatterType.Personal;

                    return (
                      <motion.div
                        key={i}
                        className="text-gray-800"
                        initial={{ opacity: 0, y: 40 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.3, delay: i * 0.1 }}
                      >
                        <MatterItemComponent
                          testid={`matter-${i}`}
                          matter={isPersonalMatter ? data?.matters[0] : matter}
                          onEdit={handleClickEditMatter}
                          onView={handleClickMatterItem}
                          isCreator={isPersonalMatter || isCreator}
                          isSuperAdmin={isSuperAdmin}
                          personalMatter={isPersonalMatter}
                        />
                        {isPersonalMatter && <div className="my-2 h-2 border-b-2 border-dashed border-gray-200"></div>}
                      </motion.div>
                    );
                  })}
                </div>
                {data?.matters &&
                  data.matters.length === 1 &&
                  data.matters[0].type === MatterType.Personal &&
                  searchText === '' && (
                    <div className="my-4 flex flex-col items-center justify-center rounded-lg border border-gray-200 bg-gray-50 p-6 text-center">
                      <div className="mb-3 flex items-center justify-center rounded-full bg-gray-100 p-3">
                        <EmptyBoxIcon className="h-10 w-10 text-gray-700" />
                      </div>
                      <p className="mb-1 text-sm font-medium text-gray-700">You don't have any shared matters yet</p>
                      <p className="text-sm text-gray-600">
                        Create a New Matter or ask your colleagues to invite you to one.
                      </p>
                    </div>
                  )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default MattersPanel;
