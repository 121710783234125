import { OrgPermission } from '../../../backend/src/organisation/organisation-permission';
import { useUserContext } from '@/Contexts/User';

export const useOrgPermissions = () => {
  const { user } = useUserContext();

  const isEnabled = (flag: OrgPermission) => {
    switch (flag) {
      case OrgPermission.AnthropicModels:
        return user?.organisation?.permissions.includes(OrgPermission.AnthropicModels) ?? false;
      case OrgPermission.Inconsistencies:
        return user?.organisation?.permissions.includes(OrgPermission.Inconsistencies) ?? false;
      default:
        return false;
    }
  };

  return { isEnabled };
};
