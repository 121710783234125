import { useQuery } from 'react-query';

import { APIBaseChronos } from '@/api/hosts';
import { QueryEntity } from '@/api/types';
import useGetFetchConfig from '@/api/useGetFetchConfig';
import { Fact } from '@/types';

export function useGetFact(caseId: string, factId: string) {
  const { fetchConfigGET } = useGetFetchConfig();

  return useQuery<Fact, Error>(
    [QueryEntity.Fact, factId],
    async () => {
      return await fetch(`${APIBaseChronos}/api/case/${caseId}/fact/${factId}`, fetchConfigGET).then((res) => {
        return res.json();
      });
    },
    { refetchOnWindowFocus: false },
  );
}
