import { faBook } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ShowSupportingTexts from './ShowSupportingText';
import SkeletonOrComponent from '@/components/molecules/SkeletonOrComponent';
import FactDropdown from '@/components/organisms/FactDropdown';
import { Chunk, ConfidenceLevelObject, Fact, ThreadMessage } from '@/types';

export interface SupportingEvidenceProps {
  loading?: boolean;
  animate: boolean;
  answerMessage?: ThreadMessage;
  confidenceLevel: ConfidenceLevelObject;
  facts: Fact[];
  showAllChunks: boolean;
  setShowAllChunks: (bool: boolean) => void;
  updateFactData: (eventId: string, data: Partial<Fact>) => void;
  setSelectedChunk: (chunk: Chunk) => void;
}

interface SupportingEvidenceContentProps {
  facts: SupportingEvidenceProps['facts'];
  answerMessage: SupportingEvidenceProps['answerMessage'];
  animate: SupportingEvidenceProps['animate'];
  updateFactData: SupportingEvidenceProps['updateFactData'];
}

const SupportingEvidenceContent = ({
  facts,
  answerMessage,
  animate,
  updateFactData,
}: SupportingEvidenceContentProps) => {
  if (!answerMessage || answerMessage.facts.length === 0) {
    return null;
  }
  return (
    <div className="mb-2">
      <div className="flex flex-col gap-2">
        {facts.map((fact, index) => {
          return (
            <div
              className={`transform rounded border-2 transition-all duration-500 ease-out ${
                animate ? 'translate-y-0 opacity-100' : 'translate-y-full opacity-0'
              }`}
              style={{ transitionDelay: `${index * 50}ms` }}
              key={fact.id}
              id={fact.id}
            >
              <FactDropdown
                fact={fact}
                compressedVersion={true}
                // reference={fact.reference}
                hideRelevanceIndicator={true}
                updateFactData={updateFactData}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

const SupportingEvidence = ({
  loading,
  confidenceLevel,
  answerMessage,
  facts,
  animate,
  setShowAllChunks,
  showAllChunks,
  updateFactData,
  setSelectedChunk,
}: SupportingEvidenceProps) => {
  const hasNoContent =
    !loading &&
    (!answerMessage || ((answerMessage?.facts?.length ?? 0) === 0 && (answerMessage?.chunks?.length ?? 0) === 0));

  if (hasNoContent) {
    return null;
  }

  return (
    <div className="mt-6">
      <div className="mb-2 flex items-center gap-2">
        <FontAwesomeIcon icon={faBook} className="text-brandSecondary pb-1" />
        <h2 className="text-sm font-semibold text-gray-800">
          {confidenceLevel.text === 'Low' ? 'Possible Information' : 'Supporting Evidence'}
        </h2>
      </div>
      <SkeletonOrComponent
        isLoading={loading}
        component={
          <SupportingEvidenceContent
            facts={facts}
            answerMessage={answerMessage}
            animate={animate}
            updateFactData={updateFactData}
          />
        }
        skeletonHeight="h-12"
      />

      {loading || !answerMessage ? null : answerMessage?.chunks &&
        Array.isArray(answerMessage.chunks) &&
        answerMessage.chunks.length > 0 ? (
        <ShowSupportingTexts
          setSelectedChunk={setSelectedChunk}
          setShowAllChunks={setShowAllChunks}
          showAllChunks={showAllChunks}
          answerMessage={answerMessage}
        />
      ) : null}
    </div>
  );
};

export default SupportingEvidence;
