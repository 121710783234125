import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { APIBaseChronos } from '@/api/hosts';
import useGetFetchConfig, { FetchMethod } from '@/api/useGetFetchConfig';
import { OrgUserOption } from '@/types';

interface CreateMatterProps {
  name: string;
  description: string;
  users: OrgUserOption[];
  code: string;
}

const useCreateMatter = (onSuccess: () => void) => {
  const { getFetchConfig } = useGetFetchConfig();
  const createMatter = async ({ name, description, users, code }: CreateMatterProps) => {
    const fetchConfig = getFetchConfig({
      method: FetchMethod.POST,
      data: {
        name,
        description,
        code,
        userIds: users.map((x) => x.id),
      },
    });

    const response = await fetch(`${APIBaseChronos}/api/matters`, fetchConfig);
    if (!response.ok) {
      throw new Error('Error creating matter');
    }
    return response.json();
  };

  return useMutation({
    onSuccess,
    onError: (error) => {
      console.error('Error creating matter:', error);
      toast.error('Error creating matter');
    },
    mutationFn: createMatter,
  });
};

export default useCreateMatter;
