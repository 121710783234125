import { ArrowRight } from 'lucide-react';
import { useQueryClient } from 'react-query';
import { toast } from 'sonner';

import { useCreateInconsistencies } from '@/api/mutations/useCreateInconsistencies';
import useGetCaseOverview from '@/api/queries/useGetCaseOverview';
import { QueryEntity } from '@/api/types';
import { Button } from '@/components/atoms/new-button';

const InconsistencyRunPopup = ({ caseId, closeModal }: { caseId: string; closeModal: () => void }) => {
  const queryClient = useQueryClient();
  const { data: responseCaseOverview } = useGetCaseOverview(caseId);
  const { mutateAsync: createInconsistencies } = useCreateInconsistencies(caseId);
  const pages = responseCaseOverview?.pages ?? 0;
  const tokens = Math.ceil(pages / 5);

  const handleCreateInconsistencies = async () => {
    await createInconsistencies();
    toast.success('Creating inconsistencies... you will receive an email when this is complete.');
    queryClient.invalidateQueries({ queryKey: [QueryEntity.Case, caseId] });
    closeModal();
  };

  return (
    <div>
      <div className="flex h-24 items-center justify-center bg-gray-100"></div>
      <div className="px-8 pb-10">
        <p className="pt-4 pb-2 text-lg font-semibold">Generate Inconsistencies</p>
        <div className="flex flex-col gap-4 text-gray-700">
          <p>This will use {tokens} pages/tokens.</p>

          <div>
            <Button variant="primary" size="sm" onClick={handleCreateInconsistencies}>
              Proceed
              <ArrowRight className="h-4 w-4" />
            </Button>
          </div>

          <p className="border border-x-0 border-b-0 pt-4 text-sm text-gray-500">
            You will receive an email when this is complete.
          </p>
        </div>
      </div>
    </div>
  );
};

export default InconsistencyRunPopup;
