import { useMutation, useQueryClient } from 'react-query';

import { APIBaseChronos } from '@/api/hosts';
import { QueryEntity } from '@/api/types';
import useGetFetchConfig, { FetchMethod } from '@/api/useGetFetchConfig';

interface DeleteCaseRequest {
  caseId: string;
  isSuperAdmin: boolean;
}

export interface DeleteCaseResponse {
  success: boolean;
  notCreator?: boolean;
}

export const useDeleteCase = ({ onError }: { onError: VoidFunction }) => {
  const { getFetchConfig } = useGetFetchConfig();
  const queryClient = useQueryClient();

  return useMutation<Response, Error, DeleteCaseRequest, unknown>({
    mutationFn: async ({ caseId, isSuperAdmin }: DeleteCaseRequest) => {
      const fetchConfig = getFetchConfig({ method: FetchMethod.DELETE, data: { isSuperAdmin } });
      const response = await fetch(`${APIBaseChronos}/api/case/${caseId}`, fetchConfig);
      return response;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [QueryEntity.Case] });
      queryClient.invalidateQueries({ queryKey: [QueryEntity.DraftCases] });
    },
    onError,
  });
};
