import { useQuery } from 'react-query';

import { QueryEntity } from '../types';
import { Organisation } from '../types/backend-dto-types';
import { APIBaseChronos } from '@/api/hosts';
import useGetFetchConfig from '@/api/useGetFetchConfig';

const useGetOrganisation = (orgId: string) => {
  const { fetchConfigGET } = useGetFetchConfig();

  return useQuery<Organisation>([QueryEntity.OrganisationById, orgId], async () => {
    try {
      const res = await fetch(`${APIBaseChronos}/api/org/${orgId}`, fetchConfigGET);
      return await res.json();
    } catch (err) {
      console.error(err);
    }
  });
};

export default useGetOrganisation;
