import React, { ReactElement, ReactNode, useEffect, useRef, useState } from 'react';

import parse from 'html-react-parser';
import { CSSTransition } from 'react-transition-group';

import { referencingLinkRegex } from '@/constants/referencing';

const ReferencesPopup = ({ references }: { references: ReactNode[] }) => {
  const [popupOpen, setPopupOpen] = useState(false);
  const [popupPosition, setPopupPosition] = useState<number>(0);
  const popupRef = useRef<HTMLDivElement>(null);
  const referenceRef = useRef<HTMLDivElement>(null);

  // Close the popup when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node) &&
        referenceRef.current &&
        !referenceRef.current.contains(event.target as Node)
      ) {
        setPopupOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [popupRef]);

  const handleTogglePopup = () => {
    if (referenceRef.current) {
      const rect = referenceRef.current.getBoundingClientRect();
      setPopupPosition(rect.x);
      setPopupOpen(!popupOpen);
    }
  };

  // Create references with commas
  const formattedReferences = references.map((ref, index) => (
    <React.Fragment key={index}>
      {index === 0 && '...'}
      {ref}
      {index < references.length - 1 && ', '}
      {index === references.length - 1 && '. '}
    </React.Fragment>
  ));

  const WIDTH = references.length > 5 ? 'w-96' : 'w-64';

  return (
    <span className="relative">
      <span ref={referenceRef} onClick={handleTogglePopup} className="text-blue-500 hover:cursor-pointer">
        [...]
      </span>
      <CSSTransition in={popupOpen} timeout={300} classNames="popup" unmountOnExit nodeRef={popupRef}>
        <div
          ref={popupRef}
          className={`absolute z-20 animate-popIn ${WIDTH} top-6 rounded-lg bg-white px-6 py-2 shadow-xl ${
            popupPosition + 400 > window.innerWidth ? 'right-0' : 'left-0'
          }`}
        >
          {formattedReferences}
        </div>
      </CSSTransition>
    </span>
  );
};

const formatReferencesText = (summary: string, linkFunction: (id: string) => void): React.ReactNode => {
  const elements: React.ReactNode[] = [];

  let match: RegExpExecArray | null;
  let lastIndex = 0;

  while ((match = referencingLinkRegex.exec(summary)) !== null) {
    if (match.index > lastIndex) {
      elements.push(parse(summary.slice(lastIndex, match.index)));
    }

    const eventID = match[2];
    elements.push(
      <span className="text-blue-500 hover:cursor-pointer" key={match.index} onClick={() => linkFunction(eventID)}>
        [{match[1]}]
      </span>,
    );

    lastIndex = referencingLinkRegex.lastIndex;
  }

  if (lastIndex < summary.length) {
    elements.push(parse(summary.slice(lastIndex)));
  }

  const isSpanElement = (element: ReactNode): element is ReactElement<any> => {
    return React.isValidElement(element) && element.type === 'span';
  };

  const sweepAndMergeSpans = (elements: React.ReactNode[]): React.ReactNode[] => {
    const result: React.ReactNode[] = [];
    let spanGroup: React.ReactNode[] = [];

    elements.forEach((element, index) => {
      if (isSpanElement(element)) {
        spanGroup.push(element);
      } else {
        if (spanGroup.length > 4) {
          const originalSpans = [...spanGroup];
          result.push(<ReferencesPopup key={index} references={originalSpans} />);
        } else {
          result.push(...spanGroup);
        }
        spanGroup = [];
        result.push(element);
      }
    });

    if (spanGroup.length > 4) {
      const originalSpans = [...spanGroup];
      result.push(<ReferencesPopup references={originalSpans} />);
    } else {
      result.push(...spanGroup);
    }

    return result;
  };

  return <>{sweepAndMergeSpans(elements)}</>;
};

export default formatReferencesText;
