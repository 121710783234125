export enum InconsistencyLevel {
  Minor = 'minor',
  Severe = 'severe',
  Critical = 'critical',
}

export enum InconsistencySource {
  MultipleDepositions = 'multipleDepositions',
  SameDeposition = 'sameDeposition',
  DepositionVsEvidence = 'depositionVsEvidence',
  EvidenceVsEvidence = 'evidenceVsEvidence',
}

export enum InconsistencyType {
  Dates = 'dates',
  Events = 'events',
  Evolving = 'evolving',
  Details = 'details',
  Observations = 'observations',
  Fabrications = 'fabrications',
}

export function getInconsistencySourceDisplayName(source: InconsistencySource): string {
  switch (source) {
    case InconsistencySource.MultipleDepositions:
      return 'Multiple Claims';
    case InconsistencySource.SameDeposition:
      return 'Same Claim';
    case InconsistencySource.DepositionVsEvidence:
      return 'Claim vs Evidence';
    case InconsistencySource.EvidenceVsEvidence:
      return 'Evidence vs Evidence';
  }
}

export function getInconsistencyTypeDisplayName(type: InconsistencyType): string {
  switch (type) {
    case InconsistencyType.Dates:
      return 'Dates';
    case InconsistencyType.Events:
      return 'Conflicting Events';
    case InconsistencyType.Evolving:
      return 'Changing Testimony';
    case InconsistencyType.Details:
      return 'Conflicting Details';
    case InconsistencyType.Observations:
      return 'Observations';
    case InconsistencyType.Fabrications:
      return 'Possible Intentional Inconsistency';
  }
}
