import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CountUp from 'react-countup';
import { Tooltip } from 'react-tooltip';

import { OrgPermission } from '../../../../../../../../backend/src/organisation/organisation-permission';
import { useOrgPermissions } from '@/hooks/orgPermissions';

interface Stats {
  factsExtracted: number;
  relevantFacts: number;
  keyFacts: number;
  documents: number;
  pages: number;
  disputedFacts: number;
}

interface ClickHandlers {
  factsExtracted: () => void;
  relevantFacts: () => void;
  keyFacts: () => void;
  documents: () => void;
  pages: () => void;
  disputedFacts: () => void;
}

interface Tooltips {
  factsExtracted: string;
  relevantFacts: string;
  keyFacts: string;
  documents: string;
  pages: string;
  disputedFacts: string;
}

const Stat = ({
  title,
  value,
  index,
  tooltip,
  onClick,
  last,
}: {
  title: string;
  value: number;
  index: number;
  tooltip: string;
  onClick: () => void;
  last?: boolean;
}) => {
  return (
    <div
      className={`${
        !last ? 'border border-x-0 border-t-0' : ''
      } flex items-center justify-between px-2 py-1 transition-all duration-200 hover:cursor-pointer hover:bg-gray-200 ${
        index % 2 === 0 ? 'bg-gray-100' : ''
      }`}
      onClick={onClick}
    >
      <p>
        {title}:{' '}
        <b className="text-blue-500">
          <CountUp end={value} duration={2} />{' '}
        </b>
      </p>
      <FontAwesomeIcon
        data-tooltip-id={title}
        data-tooltip-content={tooltip}
        icon={faInfoCircle}
        className="ml-2 h-3 w-3 cursor-pointer text-gray-400"
      />
      <Tooltip
        id={title}
        opacity={1}
        style={{
          width: '200px',
          textAlign: 'center',
          padding: '4px',
          borderRadius: '8px',
          backgroundColor: 'white',
          color: 'black',
          fontSize: '12px',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: '20px',
          letterSpacing: '-0.3px',
          zIndex: 1,
        }}
      />
    </div>
  );
};

const StatsTable = ({
  stats,
  onClickHandlers,
  tooltips,
}: {
  stats: Stats;
  onClickHandlers: ClickHandlers;
  tooltips: Tooltips;
}) => {
  const { isEnabled } = useOrgPermissions();

  return (
    <div className="flex flex-col font-medium">
      <Stat
        title="Facts Extracted"
        value={stats.factsExtracted}
        index={0}
        tooltip={tooltips.factsExtracted}
        onClick={onClickHandlers.factsExtracted}
      />
      <Stat
        title="Relevant Facts"
        value={stats.relevantFacts}
        index={1}
        tooltip={tooltips.relevantFacts}
        onClick={onClickHandlers.relevantFacts}
      />
      <Stat
        title="Key Facts"
        value={stats.keyFacts}
        index={2}
        tooltip={tooltips.keyFacts}
        onClick={onClickHandlers.keyFacts}
      />
      {isEnabled(OrgPermission.Inconsistencies) ? (
        <Stat
          title="Disputed Facts"
          value={stats.disputedFacts}
          index={3}
          tooltip={tooltips.disputedFacts}
          onClick={onClickHandlers.disputedFacts}
        />
      ) : (
        <Stat title="Pages" value={stats.pages} index={3} tooltip={tooltips.pages} onClick={onClickHandlers.pages} />
      )}
      <Stat
        title="Documents"
        value={stats.documents}
        index={4}
        tooltip={tooltips.documents}
        onClick={onClickHandlers.documents}
      />
    </div>
  );
};

export default StatsTable;
