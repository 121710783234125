import { useState } from 'react';

import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  Table,
  useReactTable,
} from '@tanstack/react-table';
import { twMerge } from 'tailwind-merge';

import NoAnswer from './NoAnswer';
import TextWithReferences from './TextWithReferences';
import { Chunk, Fact, Reference } from '../../../../../../../types';
import Button from '@/components/atoms/Button';
import SearchBox from '@/components/molecules/SearchBox';

interface ExtractionContentProps {
  data: Record<string, string>[];
  references?: Reference;
  facts: Fact[];
  chunks: Chunk[];
  setSelectedChunk: (chunk: Chunk) => void;
  setSelectedFact: (fact: Fact) => void;
}

const ColumneResizer = ({ header }: { header: any }) => (
  <div
    onDoubleClick={() => header.column.resetSize()}
    onMouseDown={header.getResizeHandler()}
    onTouchStart={header.getResizeHandler()}
    className={`bg-brandPrimary hover:bg-brandSecondary absolute top-0 right-0 h-full w-px cursor-col-resize touch-none opacity-40 transition-colors select-none group-hover:w-0.5 ${
      header.column.getIsResizing() ? 'bg-brandSecondary' : ''
    }`}
  />
);

const TableData = ({
  tableInstance,
  isEmpty,
  onClearFilter,
}: {
  tableInstance: Table<Record<string, string>>;
  isEmpty: boolean;
  onClearFilter: VoidFunction;
}) => {
  if (isEmpty) {
    return (
      <tr className="mt-3 flex flex-col justify-center">
        <p className="mb-2">No data</p>
        <Button text="Clear filter" onClick={onClearFilter} type="brand" rounded="lg" />
      </tr>
    );
  }

  return (
    <tbody className="text-sm">
      {tableInstance.getRowModel().rows.map((row) => (
        <tr key={row.id}>
          {row.getVisibleCells().map((cell) => (
            <td
              key={cell.id}
              style={{
                width: cell.column.getSize(),
              }}
              className="p-4 align-text-top"
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
};

const ExtractionContent = ({
  facts,
  chunks,
  data,
  references,
  setSelectedChunk,
  setSelectedFact,
}: ExtractionContentProps) => {
  const [tableData] = useState(data);
  const [globalFilter, setGlobalFilter] = useState('');
  const columnNames = data.length > 0 ? Object.keys(data?.[0]) : [];
  const columnHelper = createColumnHelper<Record<string, string>>();

  const columns = columnNames.map((column, columnIndex) => {
    return columnHelper.accessor(
      (row) => {
        return row[column];
      },
      {
        id: column,
        cell: (cell) => {
          const value = cell.getValue();
          const rowIndex = cell.row.index;

          if (!value) {
            return '';
          }

          return (
            <TextWithReferences
              facts={facts}
              chunks={chunks}
              text={value}
              references={references}
              setSelectedChunk={setSelectedChunk}
              setSelectedFact={setSelectedFact}
              uniqueId={`${rowIndex}-${columnIndex}`}
            />
          );
        },
      },
    );
  });

  const tableInstance = useReactTable({
    columns,
    data: tableData,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    enableMultiSort: false,
    columnResizeMode: 'onChange',
    columnResizeDirection: 'ltr',
    globalFilterFn: 'includesString',
    state: {
      globalFilter,
    },
  });

  const numberOfRows = tableInstance.getPrePaginationRowModel().rows.length;
  if (tableData.length === 0) {
    return <NoAnswer />;
  }

  return (
    <div className="flex flex-1 flex-col">
      <div className="mb-3 flex items-center justify-between">
        <p>{numberOfRows} rows</p>
        <SearchBox value={globalFilter} onChange={setGlobalFilter} placeholder="Search table" />
      </div>
      <div className="overflow-y-auto">
        <table className="my-0">
          <thead>
            {tableInstance.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="overflow-visible">
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className="group relative overflow-x-visible px-2 py-1"
                    style={{
                      width: header.getSize(),
                    }}
                  >
                    <div
                      className={twMerge(
                        'flex items-center justify-between truncate',
                        header.column.getCanSort() ? 'cursor-pointer select-none' : '',
                      )}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {header.column.getIsSorted() && (
                        <FontAwesomeIcon
                          className="mr-2"
                          icon={header.column.getIsSorted() === 'asc' ? faSortUp : faSortDown}
                        />
                      )}
                    </div>
                    <ColumneResizer header={header} />
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <TableData
            tableInstance={tableInstance}
            isEmpty={numberOfRows === 0}
            onClearFilter={() => setGlobalFilter('')}
          />
        </table>
      </div>
    </div>
  );
};

export default ExtractionContent;
