export const CountsChip = ({ count, label }: { count: React.ReactNode; label: string }) => {
  return (
    <div className="flex items-center space-x-1.5">
      <div className="flex items-center rounded border border-blue-200 bg-blue-50 px-1.5 text-xs">
        <span className="font-medium text-blue-700">{count}</span>
        <span className="ml-0.5 text-blue-600">{label}</span>
      </div>
    </div>
  );
};
