import { useState, useEffect } from 'react';

import { APIBaseChronos } from '../hosts';
import useGetFetchConfig from '../useGetFetchConfig';

export interface UserListEntry {
  id: string;
  email: string;
}

export const useUserList = () => {
  const [userList, setUserList] = useState<UserListEntry[] | null>(null);

  const { fetchConfigGET } = useGetFetchConfig();

  useEffect(() => {
    if (!userList) {
      fetch(`${APIBaseChronos}/api/org/users`, fetchConfigGET)
        .then((response) => response.json())
        .then((data) => setUserList(data))
        .catch((error) => console.error('Error fetching user list:', error));
    }
  }, []);

  return userList;
};
