import { useMutation } from 'react-query';

import { APIBaseChronos } from '../hosts';
import { InconsistencyReportItem } from '../types/backend-dto-types';
import useGetFetchConfig, { FetchMethod } from '../useGetFetchConfig';

interface UseUpdateInconsistencyReportItem {
  reportItemId: string;
  reportId: string;
  caseId: string;
  body: Partial<InconsistencyReportItem>;
}

export const useUpdateInconsistencyReportItem = () => {
  const { getFetchConfig } = useGetFetchConfig();

  return useMutation(async ({ reportId, reportItemId, caseId, body }: UseUpdateInconsistencyReportItem) => {
    const fetchConfig = getFetchConfig({ method: FetchMethod.PATCH, data: body });

    await fetch(
      `${APIBaseChronos}/api/case/${caseId}/inconsistency/report/${reportId}/item/${reportItemId}`,
      fetchConfig,
    );
  });
};
