import { useCallback, useState } from 'react';

import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircleHelp } from 'lucide-react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import useToggleIncluded from '../../../../../../api/mutations/useToggleIncluded';
import IncludeCheckbox from '../../components/IncludeCheckbox';
import EmptyState from '@/components/molecules/EmptyState';
import FactDropdown from '@/components/organisms/FactDropdown';
import { cn } from '@/helpers/shadCnUtils';
import { Fact, Relevance } from '@/types';

interface FactesEditorTableProps {
  facts: Fact[];
  keyFactIdSet: Set<string>;
  caseId: string;
  setIsDateDirty: (v: boolean) => void;
  setTotalNumberOfIncludedFacts: (v: number | ((prev: number) => number)) => void;
}

const FactsEditorTable = ({
  facts,
  keyFactIdSet,
  caseId,
  setIsDateDirty,
  setTotalNumberOfIncludedFacts,
}: FactesEditorTableProps) => {
  const [searchParams] = useSearchParams();
  const factId = searchParams.get('factId');

  const { mutate: mutateUpdateIncluded } = useToggleIncluded();

  const [toggledFactIds, setToggledFactIds] = useState<string[]>(
    facts.filter((fact) => fact.included).map((fact) => fact.id),
  );

  const setChecked = (toggleFactId: string, toggleValue: boolean) => {
    setToggledFactIds(() => {
      if (toggleValue) {
        return [...toggledFactIds, toggleFactId];
      }

      return toggledFactIds.filter((id) => id !== toggleFactId);
    });
  };

  const handleFactToggle = (event: React.ChangeEvent<HTMLInputElement>, eventId: string) => {
    const toggleValue = event.target.checked;
    setChecked(eventId, toggleValue);
    setTotalNumberOfIncludedFacts((prev: number) => prev + (toggleValue ? 1 : -1));
    mutateUpdateIncluded(
      { caseId, toggleValue, eventId },
      {
        onError: () => {
          toast.error('Failed to select item');
          setChecked(eventId, !toggleValue);
        },
      },
    );
  };

  const getOpacityForRelevance = useCallback((relevance: Relevance) => {
    return relevance === Relevance.NO ? '80' : '50';
  }, []);

  return (
    <div className={cn('flex h-full flex-col gap-2 pt-1 pr-2 pb-6')}>
      {facts.length === 0 && (
        <EmptyState
          title="No facts found"
          subtitle="Sorry, we couldn't find any facts matching these criteria"
          icon={<CircleHelp className="h-12 w-12 text-yellow-500" />}
        />
      )}

      {facts.map((fact) => (
        <div key={fact.id} className="relative flex gap-3">
          <IncludeCheckbox
            included={toggledFactIds.includes(fact.id)}
            onClick={(event) => handleFactToggle(event, fact.id)}
            eventId={fact.id}
          />
          <div
            className={`w-full rounded-lg border ${
              factId === fact.id
                ? `green-glow-card border-brandSecondary/${getOpacityForRelevance(fact.relevance)}`
                : keyFactIdSet && keyFactIdSet.has(fact.id)
                  ? `gold-glow-card border-yellow-500/${getOpacityForRelevance(fact.relevance)}`
                  : ''
            } ${fact.relevance === Relevance.NO ? 'border-dashed' : ''}`}
          >
            <FactDropdown fact={fact} setIsDateDirty={setIsDateDirty} compressedVersion />
            {keyFactIdSet && keyFactIdSet.has(fact.id) ? (
              <FontAwesomeIcon icon={faStar} className="absolute top-2 right-5 h-3 w-3 text-yellow-500" />
            ) : null}
          </div>
        </div>
      ))}
    </div>
  );
};

export default FactsEditorTable;
