import { useMutation } from 'react-query';

import { APIBaseChronos } from '@/api/hosts';
import useGetFetchConfig, { FetchMethod } from '@/api/useGetFetchConfig';

interface CreateChronologyParameters {
  caseId: string | null;
  chronologyId: string | null;
  type: string | null;
}

interface HookProps {
  onSuccess: (data: CreateChronologyResponse) => void;
  onError: (data: ApiError) => void;
}

export interface CreateChronologyResponse {
  caseId: string;
  chronologyId: string;
  chronologyTitle: string;
  isNew: string;
  isProcessed: string;
}

export interface ApiError {
  statusCode: number;
  message: string;
}

export const useCreateChronology = ({ onSuccess, onError }: HookProps) => {
  const { getFetchConfig } = useGetFetchConfig();

  const createChronology = useMutation(
    async ({ caseId, chronologyId, type }: CreateChronologyParameters) => {
      const fetchConfig = getFetchConfig({
        method: FetchMethod.POST,
        data: {
          caseId,
          type,
          addToExisting: chronologyId !== null,
          existingChronologyId: chronologyId,
        },
      });
      const res = await fetch(`${APIBaseChronos}/api/case/${caseId}/chronology`, fetchConfig);
      if (!res.ok) {
        throw new Error('Network response was not ok');
      }
      return res.json(); // Return the response for use in onSuccess
    },
    {
      onSuccess: (data: CreateChronologyResponse) => {
        onSuccess(data);
      },
      onError: (error: ApiError) => {
        onError(error);
      },
    },
  );

  return {
    createChronology,
  };
};
