import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { APIBaseChronos } from '@/api/hosts';
import { QueryEntity } from '@/api/types';
import useGetFetchConfig, { FetchMethod } from '@/api/useGetFetchConfig';

export const useDeleteInconsistencyReport = ({ reportId, caseId }: { reportId: string; caseId: string }) => {
  const { getFetchConfig } = useGetFetchConfig();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      const fetchConfig = getFetchConfig({
        method: FetchMethod.DELETE,
      });
      const response = await fetch(
        `${APIBaseChronos}/api/case/${caseId}/inconsistency/report/${reportId}`,
        fetchConfig,
      );

      if (!response.ok) {
        toast.error(
          response.status === 403 ? 'You are not authorised to delete this report' : 'Failed to delete report',
        );
        return;
      }

      toast.success('Report deleted successfully');
    },
    onError: () => {
      toast.error('Failed to delete report');
    },
    onSuccess: () => {
      queryClient.invalidateQueries([QueryEntity.InconsistencyReports, caseId]);
    },
  });
};
