import { OrgUser } from '../../../../../types';

interface UsersWithAccessProps {
  users: OrgUser[];
}

const UsersWithAccess = ({ users }: UsersWithAccessProps) => {
  return (
    <div>
      <div className="mb-1 mt-4 text-sm">Users with access to this matter:</div>
      <div className="flex max-h-40 flex-col gap-2 overflow-y-auto rounded-md border bg-gray-50 p-2">
        <div className="grid grid-cols-2 gap-2">
          {users?.map((user) => (
            <div className="w-fit rounded bg-gray-200 px-2 text-xs" key={user.id}>
              {user.email}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default UsersWithAccess;
