import { useState } from 'react';

import { AnimatePresence, motion } from 'framer-motion';
import { ArrowLeft, ArrowRight } from 'lucide-react';

import { InconsistenciesFact } from './InconsistencyRow';
import FactDocViewer from '../../../components/DocViewer/FactDocViewer';
import { InconsistencyFact } from '@/types';

interface DocComparerProps {
  caseId: string;
  docGroups: { [key: string]: InconsistencyFact[] };
}

interface DocInfo {
  docId: string;
  factId: string;
  docName: string;
  factIndex: number;
  locationIndex: number;
  direction?: 'left' | 'right';
}

interface DocNavigationProps {
  doc: DocInfo;
  setDoc: (doc: DocInfo) => void;
  group: InconsistencyFact[];
  groupName: string;
}

const DocNavigation = ({ doc, setDoc, group, groupName }: DocNavigationProps) => {
  const cycleDoc = (direction: 'left' | 'right') => {
    const currentFact = group[doc.factIndex];
    const totalLocations = currentFact.fact.locations.length;

    let newFactIndex = doc.factIndex;
    let newLocationIndex = doc.locationIndex;

    if (direction === 'right') {
      if (newLocationIndex < totalLocations - 1) {
        // Move to next location of current fact
        newLocationIndex++;
      } else {
        // Move to first location of next fact
        newFactIndex++;
        newLocationIndex = 0;
      }
    } else {
      if (newLocationIndex > 0) {
        // Move to previous location of current fact
        newLocationIndex--;
      } else {
        // Move to last location of previous fact
        newFactIndex--;
        if (newFactIndex >= 0) {
          newLocationIndex = group[newFactIndex].fact.locations.length - 1;
        }
      }
    }

    // Check if we have a valid fact
    const newFact = group[newFactIndex];
    if (!newFact) return;

    const location = newFact.fact.locations[newLocationIndex];
    if (!location) return;

    setDoc({
      docId: location.docId,
      factId: newFact.factId,
      docName: location.doc?.file.name ?? '',
      factIndex: newFactIndex,
      locationIndex: newLocationIndex,
      direction,
    });
  };

  return (
    <div className="flex-1">
      <div className="flex items-center gap-4">
        <h3 className="text-lg font-semibold">{groupName.length > 55 ? groupName.slice(0, 55) + '...' : groupName}</h3>
        <span className="text-sm text-gray-500">
          {doc.factIndex + 1}/{group.length} facts
        </span>
      </div>
      <div className="flex items-center justify-between gap-4">
        <div className="flex items-center gap-2">
          <AnimatePresence mode="wait">
            <motion.p
              key={doc.docName}
              initial={{ opacity: 0, x: doc.direction === 'left' ? 50 : -50 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: doc.direction === 'left' ? -50 : 50 }}
              transition={{ duration: 0.2 }}
              className="w-fit rounded border border-dashed bg-gray-50 px-1 text-xs"
            >
              {doc.docName.length > 80 ? doc.docName.slice(0, 80) + '...' : doc.docName}
            </motion.p>
          </AnimatePresence>
        </div>
        {(group.length > 1 || group[doc.factIndex].fact.locations.length > 1) && (
          <div className="flex items-center gap-2 rounded-lg border p-1 px-2 shadow-sm">
            <div className="w-7">
              {(doc.factIndex > 0 || doc.locationIndex > 0) && (
                <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
                  <ArrowLeft
                    onClick={() => cycleDoc('left')}
                    className="h-7 w-7 cursor-pointer rounded-full border p-1 text-gray-600 hover:bg-gray-100"
                  />
                </motion.div>
              )}
            </div>
            <div className="w-7">
              {(doc.factIndex < group.length - 1 ||
                doc.locationIndex < group[doc.factIndex].fact.locations.length - 1) && (
                <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
                  <ArrowRight
                    onClick={() => cycleDoc('right')}
                    className="h-7 w-7 cursor-pointer rounded-full border p-1 text-gray-600 hover:bg-gray-100"
                  />
                </motion.div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const DocViewer = ({
  caseId,
  docId,
  factId,
  fact,
  direction,
  previousDocId,
}: {
  caseId: string;
  docId: string;
  factId: string;
  fact: any;
  direction?: 'left' | 'right';
  previousDocId?: string;
}) => {
  const isSameDocument = previousDocId === docId;

  return (
    <motion.div
      initial={{
        opacity: 0,
        x: isSameDocument ? 0 : direction === 'left' ? 300 : -300,
      }}
      animate={{
        opacity: 1,
        x: 0,
      }}
      exit={{
        opacity: 0,
        x: isSameDocument ? 0 : direction === 'left' ? -300 : 300,
      }}
      transition={isSameDocument ? { duration: 0.2 } : { type: 'spring', stiffness: 300, damping: 30 }}
      className="flex h-full flex-col gap-2"
    >
      <div className="h-[calc(90vh-9rem)] flex-1 overflow-hidden rounded-lg border bg-white shadow-sm">
        <FactDocViewer caseId={caseId} docId={docId} factId={factId} hideCloseButton={true} />
      </div>
      <InconsistenciesFact fact={fact} compressedVersion={true} />
    </motion.div>
  );
};

const DocComparer = ({ caseId, docGroups }: DocComparerProps) => {
  const groupNames = Object.keys(docGroups ?? {});

  // Only supporting 2 groups for now
  if (groupNames.length < 2) {
    return <></>;
  }
  const factGroup1 = docGroups[groupNames[0]];
  const factGroup2 = docGroups[groupNames[1]];

  const [selectedLeftDoc, setSelectedLeftDoc] = useState<DocInfo>({
    docId: factGroup1[0].fact.locations[0].docId,
    factId: factGroup1[0].factId,
    docName: factGroup1[0].fact.locations[0].doc?.file.name ?? '',
    factIndex: 0,
    locationIndex: 0,
  });

  const [selectedRightDoc, setSelectedRightDoc] = useState<DocInfo>({
    docId: factGroup2[0].fact.locations[0].docId,
    factId: factGroup2[0].factId,
    docName: factGroup2[0].fact.locations[0].doc?.file.name ?? '',
    factIndex: 0,
    locationIndex: 0,
  });

  // Keep track of previous doc IDs for transition effects
  const [previousLeftDocId, setPreviousLeftDocId] = useState(selectedLeftDoc.docId);
  const [previousRightDocId, setPreviousRightDocId] = useState(selectedRightDoc.docId);

  // Wrap the setDoc functions to track previous doc IDs
  const setLeftDoc = (doc: DocInfo) => {
    setPreviousLeftDocId(selectedLeftDoc.docId);
    setSelectedLeftDoc(doc);
  };

  const setRightDoc = (doc: DocInfo) => {
    setPreviousRightDocId(selectedRightDoc.docId);
    setSelectedRightDoc(doc);
  };

  return (
    <div className="flex max-h-[90vh] min-h-[90vh] w-full flex-col gap-4 p-6">
      <div className="flex w-full justify-between gap-20">
        <DocNavigation doc={selectedLeftDoc} setDoc={setLeftDoc} group={factGroup1} groupName={groupNames[0]} />
        <DocNavigation doc={selectedRightDoc} setDoc={setRightDoc} group={factGroup2} groupName={groupNames[1]} />
      </div>

      <div className="flex h-[calc(90vh-8rem)] w-full gap-8 overflow-scroll">
        <div className="flex w-1/2 flex-col gap-2 overflow-hidden text-xs">
          <AnimatePresence mode="wait">
            <DocViewer
              key={`left-${selectedLeftDoc.docId}-${selectedLeftDoc.factId}-${selectedLeftDoc.locationIndex}`}
              caseId={caseId}
              docId={selectedLeftDoc.docId}
              factId={selectedLeftDoc.factId}
              fact={factGroup1[selectedLeftDoc.factIndex].fact}
              direction={selectedLeftDoc.direction}
              previousDocId={previousLeftDocId}
            />
          </AnimatePresence>
        </div>
        <div className="mx-2 border-l-2 border-dashed border-gray-300" />
        <div className="flex w-1/2 flex-col gap-2 overflow-hidden text-xs">
          <AnimatePresence mode="wait">
            <DocViewer
              key={`right-${selectedRightDoc.docId}-${selectedRightDoc.factId}-${selectedRightDoc.locationIndex}`}
              caseId={caseId}
              docId={selectedRightDoc.docId}
              factId={selectedRightDoc.factId}
              fact={factGroup2[selectedRightDoc.factIndex].fact}
              direction={selectedRightDoc.direction}
              previousDocId={previousRightDocId}
            />
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

export default DocComparer;
