import React from 'react';

import { faMagnifyingGlass, faQuestion, faTable } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// import { PartyPopper } from 'lucide-react';
import { OrgPermission } from '../../../../../../../../../backend/src/organisation/organisation-permission';
import { useOrgPermissions } from '@/hooks/orgPermissions';
import { KimMode } from '@/types';

interface ModeSelectorProps {
  mode: KimMode;
  setMode: (mode: KimMode) => void;
  setPlaceholder: (placeholder: string) => void;
}

const ModeSelector: React.FC<ModeSelectorProps> = ({ mode, setMode, setPlaceholder }) => {
  const { isEnabled } = useOrgPermissions();
  const isAnthropicModelsEnabled = isEnabled(OrgPermission.AnthropicModels);

  return (
    <div className="mt-2 flex w-full justify-between rounded-lg border bg-white text-sm">
      <div className="w-1/3">
        <ModeOption
          mode={KimMode.QuestionFlow}
          currentMode={mode}
          icon={faQuestion}
          title="Question"
          description="Ask a question and get an answer."
          onClick={() => {
            setMode(KimMode.QuestionFlow);
            setPlaceholder('Tell me about X...');
          }}
        />
      </div>
      <div className="w-1/3 border-x">
        <ModeOption
          mode={KimMode.ExtractionFlow}
          currentMode={mode}
          icon={faTable}
          title="Extract"
          description="Extract information/entities into a report."
          onClick={() => {
            setMode(KimMode.ExtractionFlow);
            setPlaceholder('Extract all mentions of Y into a report...');
          }}
        />
      </div>
      <div className="w-1/3">
        <ModeOption
          mode={KimMode.DraftFlow}
          currentMode={mode}
          icon={faMagnifyingGlass}
          title={`Draft${isAnthropicModelsEnabled ? '' : ' (coming soon)'}`}
          disabled={!isAnthropicModelsEnabled}
          description="Create a draft document backed by facts."
          onClick={() => {
            setMode(KimMode.DraftFlow);
            setPlaceholder('Prepare me a draft letter of [X] about [Y]...');
          }}
        />
      </div>
    </div>
  );
};

interface ModeOptionProps {
  mode: string;
  currentMode: KimMode;
  icon: any;
  title: string;
  description: string;
  onClick?: () => void;
  disabled?: boolean;
  tag?: React.ReactNode;
}

const ModeOption: React.FC<ModeOptionProps> = ({
  mode,
  currentMode,
  icon,
  title,
  description,
  onClick,
  disabled = false,
  tag = null,
}) => {
  const baseClasses = 'transition-all duration-300 h-[90%] block text-left px-4 py-2 m-1 rounded-lg';
  const activeClasses = mode === currentMode ? `bg-gray-100 border` : '';
  const hoverClasses = disabled ? '' : 'hover:bg-gray-100 cursor-pointer';
  const disabledClasses = disabled ? 'cursor-not-allowed text-gray-400' : '';

  return (
    <div
      className={`${baseClasses} ${activeClasses} ${hoverClasses} ${disabledClasses}`}
      onClick={disabled ? undefined : onClick}
    >
      <div className="flex items-center justify-between">
        <p>
          <FontAwesomeIcon icon={icon} className="h-3 w-3 pr-1" />
          {title}
        </p>
        {tag}
      </div>
      <p className="text-xs text-gray-500">{description}</p>
    </div>
  );
};

export default ModeSelector;
