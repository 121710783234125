import { faDownload, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { CountsChip } from '../../components/CountsChip';
import DataViewToolbar from '../../components/TableToolbar';
import Button from '@/components/atoms/Button';
import TooltipIcon from '@/components/atoms/TooltipIcon';
import SearchBox from '@/components/molecules/SearchBox';
import Pagination from '@/components/organisms/Pagination';
import isPlural from '@/helpers/isPlural';

interface DocumentEditorToolbarProps {
  processedCount: number;
  totalDocs: number;
  openSettingsModal: () => void;
  openDownloadModal: () => void;
  globalFilter: string;
  setGlobalFilter: (value: string) => void;
  onSearchCall: () => void;
  clearSearch: () => void;
  currentPage: number;
  totalPages: number;
  prevPage: () => void;
  nextPage: () => void;
  goToPage: (page: number) => void;
}

const DocumentEditorToolbar = ({
  processedCount,
  totalDocs,
  openSettingsModal,
  openDownloadModal,
  globalFilter,
  setGlobalFilter,
  onSearchCall,
  clearSearch,
  currentPage,
  totalPages,
  prevPage,
  nextPage,
  goToPage,
}: DocumentEditorToolbarProps) => {
  return (
    <DataViewToolbar
      titleSection={
        <>
          <h1 className="text-lg font-semibold">Documents</h1>
          <div className="flex items-center gap-2">
            <CountsChip count={`${processedCount}/${totalDocs}`} label="processed" />
            {processedCount !== totalDocs && (
              <TooltipIcon tooltipContent="Add facts manually from unprocessed documents" />
            )}
          </div>
        </>
      }
      actionButtons={
        <>
          <Button
            onClick={openSettingsModal}
            className="bg-buttonSecondary hover:bg-buttonSecondary-hover rounded border px-2 py-2 text-xs shadow"
            text="Add Documents"
            icon={<FontAwesomeIcon icon={faPlusCircle} className="pr-2" />}
          />

          <Button
            icon={<FontAwesomeIcon icon={faDownload} className="pr-2" />}
            className="bg-buttonSecondary hover:bg-buttonSecondary-hover rounded border px-2 py-2 text-xs shadow"
            onClick={openDownloadModal}
            text="Download"
          />
        </>
      }
      searchBar={
        <SearchBox
          value={globalFilter}
          placeholder={`Search ${totalDocs} ${isPlural({ one: 'document', more: 'documents', number: totalDocs })}`}
          onChange={setGlobalFilter}
          onSearchCall={onSearchCall}
          clearSearch={clearSearch}
        />
      }
      pagination={
        <Pagination
          canGetPrevPage={currentPage > 0}
          canGetNextPage={currentPage < totalPages - 1}
          prevPage={prevPage}
          nextPage={nextPage}
          currentPage={currentPage}
          noOfPages={totalPages}
          goToPage={goToPage}
        />
      }
    />
  );
};

export default DocumentEditorToolbar;
