import React from 'react';

import { ArrowRight } from 'lucide-react';

import EmptyBoxIcon from '@/assets/icons/empty-box.svg?react';
import { Button } from '@/components/atoms/new-button';

interface EmptyStateCreationProps {
  title: string;
  onClick: () => void;
  subtitle?: string;
  icon?: React.ReactNode;
}

const EmptyStateCreation = ({
  title,
  subtitle,
  onClick,
  icon = <EmptyBoxIcon className="h-16 w-16 text-gray-700" />,
}: EmptyStateCreationProps) => {
  return (
    <div className="flex h-80 w-full items-center justify-center" data-testid="empty-state">
      <div className="flex flex-col items-center gap-4">
        <div className="flex flex-col items-center gap-1">
          {icon}
          <p className="text-xl text-gray-600">{title}</p>
          {subtitle && <p className="text-gray-400">{subtitle}</p>}
          <div className="mt-4">
            <Button variant="primary" size="sm" onClick={onClick}>
              Start
              <ArrowRight className="h-4 w-4" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmptyStateCreation;
