import { useQuery } from 'react-query';

import { APIBaseChronos } from '@/api/hosts';
import { QueryEntity } from '@/api/types';
import useGetFetchConfig from '@/api/useGetFetchConfig';
import { Case } from '@/types';

export const useGetCase = (caseId: string | undefined) => {
  const { fetchConfigGET } = useGetFetchConfig();

  return useQuery<Case>([QueryEntity.Case, caseId], async () => {
    try {
      const res = await fetch(`${APIBaseChronos}/api/case/${caseId}`, fetchConfigGET);
      return await res.json();
    } catch (err) {
      console.error(err);
    }
  });
};
