import React from 'react';

import { AnimatePresence, motion } from 'framer-motion';

interface TaggedDocumentsListProps {
  taggedDocuments: { doc_id: string; file_name: string }[];
}

const TaggedDocumentsList: React.FC<TaggedDocumentsListProps> = ({ taggedDocuments }) => {
  return (
    <AnimatePresence>
      {taggedDocuments.length > 0 && (
        <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.9 }}
          transition={{ duration: 0.1 }}
        >
          <div className="max-h-32 overflow-y-auto">
            <ul>
              {taggedDocuments.map((doc, index) => (
                <li className="text-xs italic text-gray-500" key={index}>
                  {doc.file_name.length > 40 ? `${doc.file_name.substring(0, 40)}...` : doc.file_name}
                </li>
              ))}
            </ul>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default TaggedDocumentsList;
