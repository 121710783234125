import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import GetStarted from './components/GetStarted';
import RecentCases from './components/RecentCases';
import { Guides } from './components/UserGuides';
import useGetUser from '@/api/queries/useGetUser';

const ActivityPanel = () => {
  const { data: userData, isLoading, isFetching } = useGetUser();

  return (
    <div className="w-1/3 py-4">
      {isLoading || isFetching || !userData ? (
        <div className="flex h-full items-center justify-center">
          <FontAwesomeIcon icon={faCircleNotch} className="animate-spin" />
        </div>
      ) : userData?.hasBeenActive ? (
        <>
          <RecentCases />
          <Guides user={userData} />
        </>
      ) : (
        <GetStarted user={userData} />
      )}
    </div>
  );
};

export default ActivityPanel;
