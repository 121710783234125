import { useEffect, useState } from 'react';

import { faArrowLeft, faArrowRight, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UseFormReturn } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import Select from 'react-select';
import { toast as sonnerToast } from 'sonner';

import { FullCaseForm } from '../schemas';
import { StepIndicator } from './FormStepIndicator';
import { StartProcessingCase } from './StartProcessingCase';
import { useGetCaseIsPendingUpload } from '@/api/queries/useGetCaseIsPendingUpload';
import useGetFiles from '@/api/queries/useGetFiles';
import { CaseCreationRequestDetails } from '@/api/types/mutations';
import Button from '@/components/atoms/Button';
import { Input } from '@/components/atoms/input';
import { Textarea } from '@/components/atoms/textarea';
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/molecules/form';
import { DISPUTE_STATUS_OPTIONS } from '@/constants/disputeStatusOptions';
import { reactSelectStylesCaseCreator } from '@/constants/styles';
import { UploadMode } from '@/types';

const RequiredInfo = ({ form, mode }: { form: UseFormReturn<FullCaseForm>; mode: UploadMode }) => {
  return (
    <div className="flex flex-col gap-4">
      <FormField
        control={form.control}
        name="name"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Case Name</FormLabel>
            <FormControl>
              <Input placeholder="Enter the name of the case" data-testid="case-name-input" {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="legalIssues"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Case Issues</FormLabel>
            <FormControl>
              <Textarea
                className="h-24 max-h-24"
                data-testid="case-issues-input"
                placeholder="Outline the key issues you want to explore, either in list format or prose."
                {...field}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <div className="mt-1 border-b border-gray-300"></div>

      {mode === UploadMode.Full && (
        <>
          <FormField
            control={form.control}
            name="type"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Case Type</FormLabel>
                <FormControl>
                  <Input
                    data-testid="case-type-input"
                    placeholder="Arbitration, Employment Dispute, Public Inquiry, Commercial Litigation, etc..."
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="parties"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Case Parties</FormLabel>
                <FormControl>
                  <Input
                    data-testid="case-parties-input"
                    placeholder="Key actors or entities in the case."
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </>
      )}
    </div>
  );
};

const OptionalInfo = ({ form }: { form: UseFormReturn<FullCaseForm> }) => {
  return (
    <div className="flex flex-col gap-4">
      <FormField
        control={form.control}
        name="keyContext"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Additional Case Context (Optional)</FormLabel>
            <FormControl>
              <Textarea
                placeholder='Any other contextual information Wexler should be aware of. E.g. "There is a parallel litigation happening in a different country with similar issues" or "The claimant is a public figure".'
                {...field}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="keyTimePeriod"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Case Time Period (Optional)</FormLabel>
            <FormControl>
              <Textarea
                data-testid="case-time-period-input"
                placeholder={
                  'The estimated time period of the documents, e.g. "1960 to 1990" or "Early 2000s" (don\'t worry about the formatting of the dates).'
                }
                {...field}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="disputeStatus"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Dispute Status (Optional)</FormLabel>
            <FormControl>
              <Select
                options={Object.values(DISPUTE_STATUS_OPTIONS)}
                className={`w-full rounded-md text-sm outline-none`}
                styles={reactSelectStylesCaseCreator}
                onChange={(newValue) => field.onChange(newValue?.value)}
                value={DISPUTE_STATUS_OPTIONS[field.value as keyof typeof DISPUTE_STATUS_OPTIONS]}
                placeholder={'Pre-Action, Claim, Trial, Post-Trial'}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  );
};

interface CaseDetailsProps {
  form: UseFormReturn<FullCaseForm>;
  mode: UploadMode;
  onSubmit: (data: Omit<CaseCreationRequestDetails, 'caseId'>) => void;
  uploadDisabled: boolean;
  activeUploadJobIds: string[];
}

const CaseDetails = ({ form, mode, activeUploadJobIds, onSubmit, uploadDisabled }: CaseDetailsProps) => {
  const [step, setStep] = useState(0);
  const [searchParams] = useSearchParams();
  const caseId = searchParams.get('caseId') ?? '';

  // This function checks whether there is an ongoing upload from the user.
  const { data: isPendingUploadData, refetch: isPendingUploadRefetch } = useGetCaseIsPendingUpload(caseId);
  const isPendingUpload = async (): Promise<boolean> => {
    const { data } = await isPendingUploadRefetch();
    return data === true;
  };

  // Processes cases only where an ongoing upload from the user is not present.
  const processCaseWrapper = async () => {
    const isUploadOngoing = await isPendingUpload();
    if (isUploadOngoing) {
      sonnerToast.info('An upload is still in progress. Please wait and try again later.', {
        duration: 3000,
        position: 'top-right',
        richColors: true,
      });
    } else {
      form.handleSubmit(onSubmit)();
    }
  };

  // Messy, but a quick fix until Redis is implemented.
  const { refetch: refetchFiles } = useGetFiles(caseId);
  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;
    if (isPendingUploadData === true) {
      interval = setInterval(() => {
        refetchFiles();
        isPendingUploadRefetch();
      }, 10_000);
    } else if (isPendingUploadData !== true) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isPendingUploadData, refetchFiles, isPendingUploadRefetch]);

  const handleNextStep = async () => {
    const isValid = await form.trigger();
    if (isValid) {
      setStep(1);
    }
  };

  return (
    <>
      <div className="mb-4 flex items-center justify-between gap-2 border-b border-gray-100 pb-4">
        <div className="flex items-center">
          <FontAwesomeIcon icon={faPenToSquare} className="mr-3 h-4 w-4 text-gray-600" />
          <h2 className="text-lg font-semibold text-gray-900">Details</h2>
        </div>
        <StepIndicator step={step} />
      </div>
      <div className="flex flex-col gap-8">
        {step === 0 && <RequiredInfo form={form} mode={mode} />}
        {step === 1 && <OptionalInfo form={form} />}
        <div className="flex justify-between">
          {step === 1 && (
            <Button
              type="secondary"
              onClick={() => setStep(0)}
              text="Back"
              rounded="md"
              icon={<FontAwesomeIcon icon={faArrowLeft} className="mr-2 h-4 w-4" />}
            />
          )}
          {/*If multi step then show next button, else show start processing button*/}
          {step === 1 || mode === UploadMode.Light ? (
            <StartProcessingCase
              activeUploadJobIds={activeUploadJobIds}
              uploadDisabled={uploadDisabled}
              handleUpdateCase={async () => await processCaseWrapper()}
            />
          ) : (
            <Button
              testid="next-step-btn"
              onClick={handleNextStep}
              text="Next"
              type="primary"
              rounded="md"
              icon={<FontAwesomeIcon icon={faArrowRight} className="ml-2 h-4 w-4" />}
              iconRight
            />
          )}
        </div>
      </div>
    </>
  );
};

export default CaseDetails;
