import { useState } from 'react';

import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'react-tooltip';

import { useOpenInconsistencyViewer } from '../../../../hooks/useOpenInconsistencyViewer';
import { APIBaseChronos } from '@/api/hosts';
import useUpdateFact from '@/api/mutations/useUpdateFact';
import ReactInfoYellow from '@/assets/icons/info-yellow.svg?react';
import Chip from '@/components/atoms/chip';
import TooltipIcon from '@/components/atoms/TooltipIcon';
import CalendarEditPopup from '@/components/molecules/CalendarPopup';
import CommentPopup from '@/components/molecules/Comments';
import EditableField from '@/components/molecules/EditableField';
import DropdownInput from '@/components/organisms/FactDropdown/DropdownInput';
import FactDocumentDetails from '@/components/organisms/FactDropdown/FactDocumentDetails';
import { RelevanceOptions } from '@/constants/filterOptions';
import { TooltipStyles } from '@/constants/styles';
import { Fact, Relevance } from '@/types';

const DocViewerFullFact = ({
  fact,
  showDocumentDetails = false,
  showComments = true,
  gotoDocReference = () => {},
}: {
  fact: Fact;
  showDocumentDetails?: boolean;
  showComments?: boolean;
  gotoDocReference?: (docId: string, factId: string, pageNumber: number) => void;
}) => {
  const [dateText, setDateText] = useState(fact.factDateText);
  const [relevanceText, setRelevanceText] = useState<Relevance>(fact.relevance);

  const { mutate: updateFactDescription, isLoading: isUpdatingDescription } = useUpdateFact({
    analyticsLocation: 'Fact',
  });
  const { mutate: updateFactSignificance, isLoading: isUpdatingSignificance } = useUpdateFact({
    analyticsLocation: 'Fact',
  });

  const { openInconsistencyViewer } = useOpenInconsistencyViewer();

  const handleUpdateFactDescription = async (value: string) => {
    updateFactDescription({
      caseId: fact.caseId,
      field: 'description',
      value,
      factId: fact.id,
    });
  };

  const handleUpdateFactSignificance = (value: string) => {
    updateFactSignificance({
      caseId: fact.caseId,
      field: 'significance',
      value,
      factId: fact.id,
    });
  };

  return (
    <div className="text-xs">
      <div className="flex w-full items-center justify-between font-normal">
        <div className={`w-full p-[6px] px-2 pl-2`}>
          <div className="flex w-full justify-between pr-6">
            <div className={`flex items-center gap-2 text-xs font-semibold`}>
              <CalendarEditPopup
                dateText={dateText}
                entryFieldKey="factDateText"
                updateDate={setDateText}
                compressedVersion={true}
                endpointToUpdate={`${APIBaseChronos}/api/case/${fact.caseId}/fact/${fact.id}`}
                location="Fact"
              />

              {dateText ? dateText : '(Date Unknown)'}

              {fact.dateIsAmbiguous && (
                <>
                  <ReactInfoYellow
                    data-tooltip-id={`fact-date-text-tooltip-${fact.id}-ambiguous`}
                    data-tooltip-content={fact.dateIsAmbiguousText || 'Date format is ambiguous, please check'}
                    className="cursor-pointer text-sm text-gray-700"
                    style={{ color: '#E3B336' }}
                  />
                  <Tooltip
                    opacity={1}
                    id={`fact-date-text-tooltip-${fact.id}-ambiguous`}
                    style={TooltipStyles}
                    place={'top-start'}
                  />
                </>
              )}
            </div>
            {fact.inconsistencies && fact.inconsistencies.length ? (
              <Chip
                className="cursor-pointer bg-red-100 hover:bg-red-200"
                onClick={() =>
                  fact.inconsistencies &&
                  openInconsistencyViewer(fact.inconsistencies.map((inconsistency) => inconsistency.inconsistencyId))
                }
              >
                <FontAwesomeIcon icon={faExclamationTriangle} className="text-red-500" />
                <span className="text-gray-700">Disputed</span>
              </Chip>
            ) : (
              <></>
            )}
          </div>

          <div className="flex justify-between gap-1">
            <div className={`w-full pt-1 pl-2 text-xs text-gray-900`}>
              <EditableField
                value={fact.description}
                onSave={handleUpdateFactDescription}
                isLoading={isUpdatingDescription}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-2 border border-x-0 border-t-0 border-dashed border-blue-200"></div>

      <div className="flex flex-col gap-2 pt-2 pr-4 pl-2">
        <div className="w-full">
          <div className="mb-1 flex items-center gap-1 px-2 font-semibold text-gray-800">
            Significance
            <TooltipIcon tooltipContent="A short analysis of the significance of the fact as it pertains to the key case issues and the context of the document it is extracted from." />
          </div>
          <div className="px-2 text-gray-800">
            <EditableField
              value={fact.significance}
              onSave={handleUpdateFactSignificance}
              isLoading={isUpdatingSignificance}
            />
          </div>
        </div>

        <div className="mt-2 border border-x-0 border-t-0 border-dashed border-blue-200"></div>

        <div className="flex justify-between px-2">
          <div className="w-1/2">
            <div className="mb-1 flex items-center gap-1 font-semibold text-gray-800">
              Relevant
              <TooltipIcon tooltipContent="Is this fact relevant to the initial case synopsis." />
            </div>
            <div
              className={`${
                {
                  [Relevance.YES]: 'text-green-700',
                  [Relevance.NO]: 'text-red-500',
                  [Relevance.MAYBE]: 'text-orange-400',
                }[relevanceText] || ''
              }`}
            >
              <DropdownInput
                entryFieldKey={'relevance'}
                entryFieldValue={relevanceText}
                endpointToUpdate={`${APIBaseChronos}/api/case/${fact.caseId}/fact/${fact.id}`}
                options={RelevanceOptions}
                updateRelevance={setRelevanceText}
              />
            </div>
          </div>

          <div className="w-1/2">
            <div className="mb-1 flex items-center gap-1 font-semibold text-gray-800">
              Classification
              <TooltipIcon tooltipContent="Fact classification. One of Primary, Secondary, Future or Litigation." />
            </div>
            <div>
              {fact.classification ? fact.classification.charAt(0).toUpperCase() + fact.classification.slice(1) : ''}
            </div>
          </div>
        </div>

        {showDocumentDetails && (
          <div>
            <div className="my-2 border border-x-0 border-t-0 border-dashed border-blue-200"></div>

            <FactDocumentDetails
              fact={fact}
              gotoDocReference={gotoDocReference}
              showSourceText={false}
              fullDocRow={false}
            />
          </div>
        )}
      </div>

      {showComments && (
        <>
          <div className="mt-2 border-2 border-x-0 border-t-0 border-blue-200"></div>
          <div className="flex justify-between gap-10 rounded-b-lg bg-slate-100 px-4">
            <div>
              <div className="pt-2 pb-1 font-semibold">Comments</div>
              <div></div>
            </div>
            <div className="flex items-center gap-2 py-3">
              <CommentPopup
                caseId={fact.caseId}
                factId={fact.id}
                commentsCount={fact.comments.length}
                comments={fact.comments}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default DocViewerFullFact;
