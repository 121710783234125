import { useMemo } from 'react';

import { useGetDocPresignedURL } from '../../../../../../api/queries/useGetDocPresignedURL';
import { useDocumentNavigation } from '../../../hooks/useOpenDocViewer';
import PDFViewer from '@/components/organisms/PDFViewer';
import { BoundingBox } from '@/types';


const ChunkDocViewer = ({
  caseId,
  docId,
  chunkId,
  boundingBoxes,
}: {
  caseId: string;
  docId: string;
  chunkId: string;
  boundingBoxes: BoundingBox[];
}) => {
  const { closeDocViewer } = useDocumentNavigation();

  // Data fetching
  const { data: docUrl } = useGetDocPresignedURL(caseId, docId);

  const coordinates = useMemo(() => {
    return boundingBoxes.map((boundingBox) => ({
      ...boundingBox,
      id: chunkId,
    }));
  }, [boundingBoxes, chunkId]);

  return (
    <div className="flex h-full flex-col">
      <div className="flex-grow overflow-hidden">
        <div className="h-full">
          {docUrl && (
            <PDFViewer
              fileUrl={docUrl}
              highlights={coordinates}
              closeViewer={closeDocViewer}
              highlightedFactId={chunkId}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ChunkDocViewer;
