import { useMemo, useState } from 'react';

import { ArrowLeftRight } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';

import UsersWithAccess from './UsersWithAccess';
import { trackCustomEvent } from '@/analytics/Mixpanel';
import { frontendHost } from '@/api/frontendHosts';
import useTransferCase from '@/api/mutations/useTransferCase';
import useGetMatters from '@/api/queries/useGetMatters';
import useGetUsersWithAccessToMatter from '@/api/queries/useGetUsersWithAccessToMatter';
import Button from '@/components/atoms/Button';
import { reactSelectStylesCaseCreator } from '@/constants/styles';
import { MyOptionType } from '@/types';

interface TransferCaseProps {
  caseId: string;
  setMatterShareModalOpen: (open: boolean) => void;
}

const TransferCase = ({ caseId, setMatterShareModalOpen }: TransferCaseProps) => {
  const [selectedMatter, setSelectedMatter] = useState<MyOptionType | null>(null);

  const { data: matters } = useGetMatters({ paginated: 'false' });
  const { data: users, isFetching: usersLoading } = useGetUsersWithAccessToMatter(selectedMatter?.value);
  const { mutate: transferCase } = useTransferCase();

  const navigate = useNavigate();

  const handleTransferCase = () => {
    if (selectedMatter?.value) {
      trackCustomEvent('Transferred case from personal matter', {
        caseId,
        matterId: selectedMatter.value,
      });
      transferCase(
        { caseId, matterId: selectedMatter.value },
        {
          onSuccess: () => {
            toast.success(
              <span>
                Case transferred to <b className="font-semibold">{selectedMatter?.label}</b> view it{' '}
                <a
                  className="font-medium text-blue-500 hover:text-blue-600 hover:underline"
                  href={`${frontendHost}/app/chronos/case-editor/summary?caseId=${caseId}`}
                >
                  here
                </a>
              </span>,
            );
            setMatterShareModalOpen(false);
            navigate('/app/chronos/matters?page=0');
          },
        },
      );
    }
  };

  const matterOptions = matters?.matters.map((matter) => ({
    value: matter.id,
    label: matter.name,
  }));

  return (
    <div className="px-6 py-4">
      <div>
        <div className="">Select which matter to move this case to.</div>
        <div className="text-sm text-gray-500">You can only move this case to a matter you have access to.</div>
      </div>
      {usersLoading ? (
        <div className="flex h-10 justify-center"></div>
      ) : (
        <>
          {users && (
            <>
              <UsersWithAccess users={users} />

              <div className="mt-4 text-sm text-gray-500">
                Make sure you are authorised to share this case with these users.
              </div>
            </>
          )}
        </>
      )}

      <div className="my-2 flex flex-col gap-2">
        <div className="my-4 flex gap-8">
          <Select
            options={matterOptions}
            className={`w-full rounded-md text-sm outline-none`}
            styles={reactSelectStylesCaseCreator}
            onChange={(option) => {
              setSelectedMatter(option);
            }}
            value={selectedMatter}
            placeholder={'Select a matter'}
          />
          <Button
            disabled={!selectedMatter}
            icon={<ArrowLeftRight className="mr-2" size={16} />}
            className="border"
            text="Transfer"
            type="primary"
            rounded="md"
            onClick={handleTransferCase}
          />
        </div>
      </div>
    </div>
  );
};

export default TransferCase;
