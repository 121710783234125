import React, { useState } from 'react';

import { faCircleNotch, faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';

import useAddFact from '@/api/mutations/useCreateFact';
import Button from '@/components/atoms/Button';
import { ClassificationOptions, RelevanceOptions } from '@/constants/filterOptions';
import { reactSelectStylesFormFact } from '@/constants/styles';
import { BoundingBox, Doc, MyOptionType } from '@/types';
import 'react-calendar/dist/Calendar.css';

interface FactAdderProps {
  docs: Doc[];
  handleNewFactCreated: () => void;
  caseId: string;
  docId?: string;
  sourceText?: string;
  highlightAreas?: BoundingBox[];
}

const FactAdder = ({ handleNewFactCreated, docs, docId, caseId, highlightAreas, sourceText = '' }: FactAdderProps) => {
  const [selectedDoc, setSelectedDoc] = useState<MyOptionType>(null);
  const [selectedRelevance, setSelectedRelevance] = useState<MyOptionType>(null);
  const [selectedClassification, setSelectedClassification] = useState<MyOptionType>(null);
  const [significance, setSignificance] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [factDateText, setFactDateText] = useState<string>('');

  const { isLoading: isLoadingAddNewFact, mutate } = useAddFact({
    caseId: caseId || null,
    docId: docId || selectedDoc?.value || null,
    onSuccess: handleNewFactCreated,
  });

  const handleChangeDescription = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(e.target.value);
  };

  const handleAddFact = () => {
    if (selectedClassification && selectedRelevance) {
      mutate({
        factDateText,
        classification: selectedClassification.value,
        relevance: selectedRelevance.value,
        sourceText,
        description,
        significance,
        highlightAreas,
      });
    }
  };

  const handleChangeFactDateText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFactDateText(e.target.value);
  };

  const handleChangeSignificance = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSignificance(e.target.value);
  };

  const docsOptions = docs.map((doc) => {
    return { value: doc.id, label: doc.file.name };
  });

  const disabled = (!selectedDoc && !docId) || !selectedClassification || !selectedRelevance;
  return (
    <>
      {isLoadingAddNewFact ? (
        <div className="flex h-96 flex-col items-center justify-center gap-8">
          <div className="text-xl">Adding Fact...</div>
          <FontAwesomeIcon icon={faCircleNotch} className="fa-spin text-brandSecondary text-4xl" />
        </div>
      ) : (
        <div className="flex flex-col px-6 py-4">
          {sourceText && (
            <textarea
              className="mt-2 h-36 w-full rounded-lg bg-white pl-3 text-base font-normal text-black not-italic placeholder:text-gray-400 focus:outline-none disabled:opacity-50"
              value={sourceText}
              placeholder="Source Text"
              style={{ border: '1px solid #ECEFFF' }}
              disabled
            />
          )}
          <input
            onChange={handleChangeDescription}
            className="mt-2 h-12 w-full rounded-lg bg-white pl-3 text-base font-normal text-black not-italic placeholder:text-gray-400 focus:outline-none"
            value={description}
            placeholder="Fact"
            style={{ border: '1px solid #ECEFFF' }}
          />
          {!docId && (
            <Select
              options={docsOptions}
              className="mt-3 w-full rounded-md bg-white outline-none"
              styles={reactSelectStylesFormFact}
              onChange={setSelectedDoc}
              value={selectedDoc}
              placeholder={'Select a document'}
            />
          )}
          <input
            onChange={handleChangeFactDateText}
            className="mt-3 h-12 w-full rounded-lg bg-white pl-3 text-base font-normal text-black not-italic placeholder:text-gray-400 focus:outline-none"
            value={factDateText}
            placeholder="Date of fact"
            style={{ border: '1px solid #ECEFFF' }}
          />
          <input
            onChange={handleChangeSignificance}
            className="mt-3 h-12 w-full rounded-lg bg-white pl-3 text-base font-normal text-black not-italic placeholder:text-gray-400 focus:outline-none"
            value={significance}
            placeholder="Significance"
            style={{ border: '1px solid #ECEFFF' }}
          />
          <div className="mb-4 flex gap-2">
            <Select
              options={Object.values(RelevanceOptions)}
              className="mt-3 w-full rounded-md bg-white outline-none"
              styles={reactSelectStylesFormFact}
              onChange={setSelectedRelevance}
              value={selectedRelevance}
              placeholder={'Relevant'}
            />
            <Select
              options={Object.values(ClassificationOptions)}
              className="mt-3 w-full rounded-md bg-white outline-none"
              styles={reactSelectStylesFormFact}
              onChange={setSelectedClassification}
              value={selectedClassification}
              placeholder={'Classification'}
            />
          </div>
          <Button
            onClick={handleAddFact}
            disabled={disabled}
            text="Add"
            type="primary"
            rounded="md"
            icon={<FontAwesomeIcon icon={faCirclePlus} className="mr-2" />}
          />
        </div>
      )}
    </>
  );
};

export default FactAdder;
