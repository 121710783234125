import { RedirectToLogin, RequiredAuthProvider } from '@propelauth/react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { StageSpinner } from 'react-spinners-kit';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import { APIBase } from './api/auth';
import Screens from './screens';
import { Toaster } from '@/components/atoms/Toaster';
import Sidebar from '@/components/organisms/Sidebar';
import { SidebarProvider } from '@/Contexts/Sidebar';
import { UserProvider } from '@/Contexts/User';

import 'intro.js/introjs.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-tooltip/dist/react-tooltip.css';

const queryClient = new QueryClient();

const BrowserPrompt = () => {
  return (
    <div className="flex h-screen items-center justify-center md:hidden">
      <p>Wexler is optimised for desktop screens</p>
    </div>
  );
};

function App() {
  return (
    <RequiredAuthProvider
      authUrl={APIBase}
      displayWhileLoading={
        <div className="flex h-screen w-screen items-center justify-center">
          <StageSpinner className="m-auto" color={'#081D57'} />
        </div>
      }
      displayIfLoggedOut={<RedirectToLogin />}
    >
      <QueryClientProvider client={queryClient}>
        <UserProvider>
          <SidebarProvider>
            <BrowserRouter>
              <BrowserPrompt />
              <div className="font-lota flex h-screen flex-row">
                <ToastContainer
                  position="top-right"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  pauseOnHover
                />
                <Toaster />
                <Sidebar />
                <div className="h-screen w-full pl-16 transition-all duration-700">
                  <Routes>
                    <Route path="app/*" element={<Screens />} />
                    <Route path="*" element={<Navigate to="/app" />} />
                  </Routes>
                </div>
              </div>
            </BrowserRouter>
          </SidebarProvider>
        </UserProvider>
      </QueryClientProvider>
    </RequiredAuthProvider>
  );
}

export default App;
