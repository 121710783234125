import { ReactNode } from 'react';

import { BreadcrumbHeading } from '@/components/molecules/Breadcrumb';

export interface BreadcrumbPage {
  label: ReactNode;
  href?: string;
  testid?: string;
}

interface HeaderBarProps {
  breadcrumbPages: BreadcrumbPage[];
  rightContent?: ReactNode;
}

const HeaderBar = ({ breadcrumbPages, rightContent }: HeaderBarProps) => {
  return (
    <div className="flex w-full items-center justify-between">
      <div className="flex items-center gap-2">
        <BreadcrumbHeading pages={breadcrumbPages} />
      </div>
      {rightContent && <div className="ml-auto">{rightContent}</div>}
    </div>
  );
};

export default HeaderBar;
