import { useQuery } from 'react-query';

import { TableUsageData } from '../../../../../backend/src/types/types';
import { APIBaseChronos } from '@/api/hosts';
import { QueryEntity } from '@/api/types';
import useGetFetchConfig, { FetchMethod } from '@/api/useGetFetchConfig';

const useGetUsageDataByUser = (
  enabled: boolean,
  startDate: string,
  endDate: string,
  matterId?: string,
  userId?: string,
  orgId?: string,
) => {
  const { fetchConfigGET } = useGetFetchConfig();

  return useQuery<TableUsageData>(
    [QueryEntity.OrgUsageData, 'user', startDate, endDate, userId, matterId, orgId],
    () => {
      const url = new URL(`${APIBaseChronos}/api/org/usage-data/user`);
      url.searchParams.append('start', startDate);
      url.searchParams.append('end', endDate);
      if (matterId) url.searchParams.append('matterId', matterId);
      if (userId) url.searchParams.append('userId', userId);
      if (orgId) url.searchParams.append('orgId', orgId);

      return fetch(url.toString(), {
        ...fetchConfigGET,
        method: FetchMethod.GET,
      })
        .then((res) => res.json())
        .catch((err) => {
          console.error(err);
        });
    },
    {
      enabled,
      refetchOnWindowFocus: false,
    },
  );
};

export default useGetUsageDataByUser;
