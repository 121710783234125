import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Calendar, FileText } from 'lucide-react';

import { Button } from '@/components/atoms/new-button';
import { PIPELINE_PROCESSING_STATUS } from '@/constants/pipelineStatus';
import { useUserContext } from '@/Contexts/User';
import formatDate from '@/helpers/formatDate';
import { cn } from '@/helpers/shadCnUtils';
import { Case } from '@/types';

interface CaseCardProps {
  caseObject: Case;
  onClick: (itemId: string, status?: string) => void;
  onDelete: (itemId: string) => void;
  isDeleting: boolean;
}

const CaseCard = ({ caseObject, onClick, onDelete, isDeleting }: CaseCardProps) => {
  const { user } = useUserContext();
  const isSuperAdmin = user?.metadata?.metadata?.orgSuperAdmin === 'true';
  const isOwner = user?.id === caseObject.createdById;
  const canDelete = isSuperAdmin || isOwner;

  const isCaseProcessing = caseObject.runs.at(0) && PIPELINE_PROCESSING_STATUS.includes(caseObject.runs[0].status);

  return (
    <div
      className={cn(
        'group relative flex h-full flex-col justify-between rounded-lg border bg-white p-6',
        'shadow-sm hover:shadow-lg',
        'transition-all duration-300 ease-in-out',
        'hover:border-gray-300',
        'cursor-pointer',
      )}
      onClick={() => onClick(caseObject.id, caseObject.runs[0]?.status)}
    >
      {/* Processing Badge */}
      {isCaseProcessing && (
        <div className="absolute -top-2 -right-2 rounded-full border-2 border-blue-200 bg-gradient-to-r from-blue-50 to-blue-100 px-3 py-1 text-xs font-medium text-blue-800 shadow-sm">
          <div className="flex items-center gap-1.5">
            <span>Processing</span>
            <FontAwesomeIcon icon={faSpinner} spin className="h-3 w-3" />
          </div>
        </div>
      )}

      <div className="space-y-4">
        {/* Case Name */}
        <div className="-mx-6 -mt-6 rounded-t-lg border-b border-gray-200/80 bg-gray-100 px-6 py-4 group-hover:border-gray-200">
          <h3 className="group-hover:text-brand-600 text-base font-semibold text-gray-900 transition-colors">
            {caseObject.name}
          </h3>
        </div>

        {/* Legal Issues */}
        <div className="flex items-center gap-2 text-gray-700 group-hover:text-gray-800">
          <FileText className="group-hover:text-brand-500 h-5 w-5 flex-shrink-0 transition-colors" />
          <div className="text-sm">
            {caseObject.legalIssues?.length > 100
              ? `${caseObject.legalIssues.slice(0, 100)}...`
              : caseObject.legalIssues}
          </div>
        </div>

        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2 text-sm text-green-700 group-hover:text-green-800">
            <Calendar className="group-hover:text-brand-400 h-4 w-4 transition-colors" />
            <span>Created {formatDate(caseObject.createdDate)}</span>
          </div>
          {canDelete && (
            <Button
              data-testid="delete-btn"
              variant="destructive"
              size="sm"
              onClick={(e) => {
                e.stopPropagation();
                onDelete(caseObject.id);
              }}
              disabled={isDeleting}
            >
              Delete
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CaseCard;
