import { useMutation } from 'react-query';

import { APIBaseChronos } from '@/api/hosts';
import useGetFetchConfig, { FetchMethod } from '@/api/useGetFetchConfig';

interface Props {
  reportId: string;
  caseId: string | null;
}

export const useDownloadInconsistencyReport = ({ reportId, caseId }: Props) => {
  const { getFetchConfig } = useGetFetchConfig();

  const downloadReport = async ({ format, title, columns }: { format: string; title: string; columns: string[] }) => {
    const fetchConfig = getFetchConfig({
      method: FetchMethod.POST,
      data: {
        format,
        caseId,
        title,
        columns,
      },
    });

    const blob = await (
      await fetch(`${APIBaseChronos}/api/case/${caseId}/inconsistency/report/${reportId}/download`, fetchConfig)
    ).blob();

    const link = Object.assign(document.createElement('a'), {
      href: URL.createObjectURL(blob),
      download: `${title}.${format}`,
    });

    link.click();

    URL.revokeObjectURL(link.href);
  };

  const { mutate: fetchDownload, isLoading: isLoadingDownload } = useMutation(downloadReport);

  return { fetchDownload, isLoadingDownload };
};
