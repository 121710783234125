import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { APIBaseChronos } from '../hosts';
import { QueryEntity } from '../types';
import useGetFetchConfig, { FetchMethod } from '../useGetFetchConfig';
import { BoundingBox } from '@/types';

interface UseAddFact {
  factDateText: string;
  description: string;
  relevance: string;
  classification: string;
  topic?: string;
  significance?: string;
  sourceText?: string;
  highlightAreas?: BoundingBox[];
}

const formatCoordinatesForApi = (coords: UseAddFact['highlightAreas']): BoundingBox[] => {
  if (!coords) return [];
  // this is so that the format of these coordinates is the same as those created by the ML pipeline
  return coords.map((coord) => ({
    height: coord.height,
    left: coord.left,
    top: coord.top,
    width: coord.width,
    pageNumber: coord.pageNumber,
  }));
};

const useAddFact = ({
  caseId,
  docId,
  onSuccess,
}: {
  caseId: string | null;
  docId: string | null;
  onSuccess: VoidFunction;
}) => {
  const { getFetchConfig } = useGetFetchConfig();
  const queryClient = useQueryClient();

  return useMutation({
    onSuccess,
    mutationFn: async ({
      factDateText,
      description,
      relevance,
      classification,
      topic,
      significance,
      sourceText,
      highlightAreas,
    }: UseAddFact) => {
      const fetchConfig = getFetchConfig({
        method: FetchMethod.POST,
        data: {
          description,
          factDateText,
          sourceText,
          relevance,
          classification,
          topic,
          significance,
          boundingBoxes: formatCoordinatesForApi(highlightAreas),
        },
      });

      return fetch(`${APIBaseChronos}/api/case/${caseId}/docs/${docId}/fact`, fetchConfig)
        .then((res) => {
          if (!res.ok) {
            throw new Error('Failed to create fact');
          }
          toast.success('New fact added');

          queryClient.invalidateQueries({ queryKey: [QueryEntity.CaseFacts, { docId }] });
        })
        .catch((err) => {
          console.error('Fetch Error: ', err);
          toast.error('There was an error while adding new fact. Please try again later.');
        });
    },
  });
};

export default useAddFact;
