import { useSearchParams, Route, Routes } from 'react-router-dom';

import { InconsistencyReport } from './InconsistencyReport';
import { InconsistencyReportsList } from './InconsistencyReportsList';

export const InconsistencyReportView = () => {
  const [searchParams] = useSearchParams();

  const caseId = searchParams.get('caseId');
  return (
    <Routes>
      <Route path="/" element={<InconsistencyReportsList caseId={caseId} />} />
      <Route path="view" element={<InconsistencyReport />} />
    </Routes>
  );
};
