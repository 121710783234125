import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { APIBaseChronos } from '../hosts';
import { QueryEntity } from '../types';
import useGetFetchConfig, { FetchMethod } from '../useGetFetchConfig';
import { trackCustomEvent } from '@/analytics/Mixpanel';

interface UseUpdateDoc {
  field: string;
  value: string;
  docId: string;
  caseId: string;
}

const useUpdateDoc = ({ analyticsLocation }: { analyticsLocation: string }) => {
  const { getFetchConfig } = useGetFetchConfig();
  const queryClient = useQueryClient();

  return useMutation(async ({ field, value, docId, caseId }: UseUpdateDoc) => {
    const fetchConfig = getFetchConfig({ method: FetchMethod.PATCH, data: { [field]: value } });
    return fetch(`${APIBaseChronos}/api/case/${caseId}/docs/${docId}`, fetchConfig)
      .then((res) => {
        trackCustomEvent(`Field updated ${field} - ${analyticsLocation}`, { caseId, docId });
        queryClient.invalidateQueries({ queryKey: [QueryEntity.CaseDoc, { caseId }] });

        return res.json();
      })
      .catch((err) => {
        toast.error('Failed to update field');
      });
  });
};

export default useUpdateDoc;
