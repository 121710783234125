// src/types.ts
import type { ConnectionState, IntegrationState } from '@apideck/unify/models/components/index.js';
import type { LoginMethod, OrgMemberInfo } from '@propelauth/node';
import type { Request } from 'express';

export interface User {
  userId: string;
  orgIdToOrgMemberInfo: {
    [key: string]: OrgMemberInfo;
  };
}

export interface CustomRequest extends Request {
  user?: User & { email: string; loginMethod: LoginMethod; orgId: string; userId: string };
}

export type PaginatedResponse<T> = {
  data: T[];
  pageCount: number;
  totalCount: number;
};

export type PlanStage = {
  name: string;
  description: string;
  parameters: PlanParameters[];
};

export type PlanParameters =
  | {
      name: string;
      type: 'str';
      value: string | null;
      available_values: string[];
      updated?: boolean;
    }
  | {
      name: string;
      type: 'boolean';
      value: boolean | null;
      available_values: string[];
      updated?: boolean;
    }
  | {
      name: string;
      type: 'List[single-select,fixed-list]';
      value: Option[] | null;
      available_values: Option[];
      updated?: boolean;
    }
  | {
      name: string;
      type: 'List[multi-select,fixed-list]';
      value: Option[] | null;
      available_values: Option[];
      all_selection?: boolean;
      updated?: boolean;
    }
  | {
      name: string;
      type: 'List[multi-select,any-value]';
      value: Option[] | null;
      available_values: Option[];
      updated?: boolean;
    };

export interface Option {
  label: string;
  value: string;
}

export enum DraftType {
  Claim = 'claim',
  Rebuttal = 'rebuttal',
}

export type Environment = 'production' | 'development' | 'testing' | 'local';

export enum MessageType {
  User = 'user',
  Internal = 'internal',
  Assistant = 'assistant',
}

export enum FlowType {
  DraftFlow = 'draft_flow',
  ExecutionFlow = 'execution_flow',
  QuestionFlow = 'question_flow',
}

export interface Artifact {
  file_name: string;
  extension: string;
  s3_key: string;
  s3_bucket: string;
}

export interface MessageConfig {
  trafficLight?: TrafficLight;
  flowType?: FlowType;
  flowName?: string;
  artifacts?: Artifact[];
  followUpQuestions?: string[];
  plan?: Record<string, unknown>[];
  docIds?: string[];
  draftType?: DraftType | null;
}

export enum TrafficLight {
  Green = 'green',
  Amber = 'amber',
  Red = 'red',
}

export enum ThreadStatus {
  NotStarted = 'notStarted',
  PlanLoading = 'planLoading',
  PlanLoaded = 'planLoaded',
  PlanExecuting = 'planExecuting',
  PlanComplete = 'planComplete',
  Failed = 'failed',
}

export enum Relevance {
  Relevant = 'relevant',
  NotRelevant = 'notRelevant',
  MaybeRelevant = 'maybeRelevant',
}

export enum Classification {
  Primary = 'primary',
  Secondary = 'secondary',
  Future = 'future',
  Litigation = 'litigation',
}

export interface Comment {
  id: string;
  caseId: string;
  comment: string;
  commentBy: string;
  commentDate: Date;
}

export interface DateInfo {
  dateIsAmbiguous: boolean;
  dateIsAmbiguousText: string | null;
  isFutureDate: string | null;
}

export enum DownloadFormat {
  DOCX = 'docx',
  EXCEL = 'csv',
}

export enum ChronologyType {
  KeyFacts = 'keyFacts',
  RelevantFacts = 'relevantFacts',
}

export interface KimPollingMessage {
  message: string;
  type: string;
  stage?: string;
}

export enum MatterType {
  Personal = 'personal',
  General = 'general',
}

export interface ApideckConnection {
  id: string;
  service_id: string;
  name: string;
  unified_api: string;
  state: ConnectionState;
  integration_state: IntegrationState;
  enabled: boolean;
  icon: string;
  logo: string;
  authorize_url: string;
}

export enum EmailTemplateOptions {
  ProcessingComplete = 'processing-complete',
  CaseOutOfQueue = 'case-out-of-queue',
  UserAddedToMatter = 'user-added-to-matter',
  Invite = 'invite',
  TaggedInComment = 'tagged-in-comment',
}

export interface UsageDataObject {
  chronologies: number;
  runs: number;
  pages: number;
  facts: number;
  threads: number;
}

export interface UsageDataObjectWithTokens extends UsageDataObject {
  tokens: number;
}

export interface TableUsageData {
  [id: string]: UsageDataObjectWithTokens & {
    matterName?: string;
    matterCode?: string;
    userEmail?: string;
    userDisabled?: boolean;
    matterType?: MatterType;
    matterCreatedBy?: string;
  };
}

export interface UsageData {
  id: string;
  organisationId: string;
  userId: string;
  matterId: string;
  date: Date;
  data: UsageDataObject;
}

export enum ChecklistStep {
  CreateMatter = 'create-matter',
  UploadDocuments = 'upload-documents',
  CreateCase = 'create-case',
}
