import { X } from 'lucide-react';

import { useGetInconsistencies } from '@/api/queries/useGetInconsistencies';
import InconsistencyRow from '@/screens/Chronos/CaseEditor/DataView/InconsistenciesEditor/components/InconsistencyRow';
import { useOpenInconsistencyViewer } from '@/screens/Chronos/CaseEditor/hooks/useOpenInconsistencyViewer';

const InconsistencyViewer = ({
  handleOpen,
  caseId,
  inconsistencyIds,
}: {
  handleOpen: (open: boolean) => void;
  caseId: string;
  inconsistencyIds: string[];
}) => {
  const { data, isLoading } = useGetInconsistencies({
    caseId,
    page: 1,
    pageSize: 50,
    filters: { inconsistencyIds },
  });
  const { closeInconsistencyViewer } = useOpenInconsistencyViewer();

  if (isLoading) return <></>;

  return (
    <div className="relative flex max-h-full flex-col gap-4 overflow-y-auto rounded-lg p-4">
      <div className="fixed top-1 right-1 z-10 cursor-pointer rounded-full border bg-gray-100 p-1 hover:bg-gray-200">
        <X
          className="h-5 w-5 cursor-pointer"
          onClick={() => {
            handleOpen(false);
            closeInconsistencyViewer();
          }}
        />
      </div>
      <div className="border-b border-gray-200 pb-3">
        <h3 className="text-lg font-semibold text-gray-800">Inconsistencies</h3>
        <p className="mt-1 text-sm text-gray-600">The following inconsistencies were identified for this fact:</p>
      </div>
      <div className="flex flex-col gap-2">
        {data &&
          data.inconsistencies &&
          data.inconsistencies.length > 0 &&
          data.inconsistencies.map((inconsistency) => (
            <div className="w-full rounded-lg border shadow-sm">
              <InconsistencyRow inconsistency={inconsistency} caseId={caseId} compressedVersion={true} />
            </div>
          ))}
      </div>
    </div>
  );
};

export default InconsistencyViewer;
