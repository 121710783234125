import { KEY_VALUE_DESCRIPTION } from '@/types';

export const PIPELINE_STATUS: KEY_VALUE_DESCRIPTION = {
  document_encryption: {
    value: 'document_encryption',
    label: 'Reading Documents',
    description: 'Converting documents into text and reading them.',
  },
  text_extraction: {
    value: 'text_extraction',
    label: 'Extracting Facts',
    description: 'Identifying and extracting facts from documents.',
  },
  anonymisation: {
    value: 'anonymisation',
    label: 'Grouping Facts',
    description: 'Grouping similar facts to avoid duplication.',
  },
  facts_per_document: {
    value: 'facts_per_document',
    label: 'Generating Summary',
    description: 'Generating case summary and identifying case themes.',
  },
  success_facts_per_document: {
    value: 'success_facts_per_document',
    label: 'Documents processed successfully',
    description: 'Documents processed successfully',
  },
  building_chronology: {
    value: 'building_chronology',
    label: 'Finishing',
    description: 'Final steps to prepare the case for review.',
  },
  success: {
    value: 'success',
    label: 'Success',
    description: 'Case processed successfully',
  },
  failed: {
    value: 'failed',
    label: 'Failed',
    description: 'Case processing failed',
  },
  complete: {
    value: 'complete',
    label: 'Completed',
    description: 'Case completed',
  },
  draft: {
    value: 'draft',
    label: 'Draft',
    description: 'Case is in draft mode',
  },
};

export const PIPELINE_PROCESSING_STATUS = [
  PIPELINE_STATUS.document_encryption.value,
  PIPELINE_STATUS.text_extraction.value,
  PIPELINE_STATUS.anonymisation.value,
  PIPELINE_STATUS.facts_per_document.value,
  PIPELINE_STATUS.building_chronology.value,
];

export const PIPELINE_FREEZE_LOAD_STATUS = [
  PIPELINE_STATUS.success_facts_per_document,
  PIPELINE_STATUS.success.value,
  PIPELINE_STATUS.failed.value,
  PIPELINE_STATUS.complete.value,
];
