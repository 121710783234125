import { useEffect, useState } from 'react';

import { Calendar, FileText, PencilRuler } from 'lucide-react';

import { useGetDraftCases } from '@/api/queries/useGetDraftCases';
import { Button } from '@/components/atoms/new-button';
import EmptyState from '@/components/molecules/EmptyState';
import { useUserContext } from '@/Contexts/User';
import formatDate from '@/helpers/formatDate';
import { cn } from '@/helpers/shadCnUtils';
import { Case } from '@/types';

interface DraftsModalContentProps {
  matterId: string;
  onSelectDraft: (caseId: string) => void;
  onDeleteDraft?: (caseId: string) => void;
}

const DraftCard = ({
  draft,
  onClick,
  onDelete,
  canDelete,
}: {
  draft: Case;
  onClick: () => void;
  onDelete?: () => void;
  canDelete?: boolean;
}) => {
  return (
    <div
      onClick={onClick}
      className={cn(
        'group relative flex flex-col justify-between rounded-lg border border-dashed p-4',
        'shadow-sm hover:shadow-md',
        'transition-all duration-200 ease-in-out',
        'cursor-pointer',
      )}
    >
      <div className="space-y-4">
        {/* Draft Name */}
        <div className="-mx-4 -mt-4 rounded-t-lg border-b border-dashed border-gray-200/80 bg-gray-100/80 px-4 pt-4 pb-3">
          <h3 className="text-lg font-semibold text-gray-900 transition-colors">
            {draft.name ? draft.name : <span className="text-gray-600 italic">Untitled</span>}
          </h3>
        </div>

        {/* Legal Issues Preview */}
        {draft.legalIssues && (
          <div className="flex items-start gap-2 text-gray-600">
            <FileText className="mt-0.5 h-5 w-5 flex-shrink-0 transition-colors" />
            <p className="line-clamp-2 text-sm">{draft.legalIssues}</p>
          </div>
        )}

        {/* Creation Date and Delete Button */}
        <div className="flex items-center justify-between border-t border-gray-100 pt-3">
          <div className="flex items-center gap-2 text-sm text-green-700">
            <Calendar className="h-4 w-4 transition-colors" />
            <span>Created {formatDate(draft.createdDate)}</span>
          </div>
          {canDelete && (
            <Button
              variant="destructive"
              size="sm"
              onClick={(e) => {
                e.stopPropagation();
                onDelete?.();
              }}
            >
              Delete
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

const DraftsModalContent = ({ matterId, onSelectDraft, onDeleteDraft }: DraftsModalContentProps) => {
  const [drafts, setDrafts] = useState<Case[]>([]);

  const { data: draftsData, isLoading } = useGetDraftCases(matterId);
  const { user } = useUserContext();
  const isSuperAdmin = user?.metadata?.metadata?.orgSuperAdmin === 'true';

  const handleDeleteDraft = (id: string) => {
    onDeleteDraft?.(id);
    setDrafts(drafts.filter((draft) => draft.id !== id));
  };

  useEffect(() => {
    if (draftsData && draftsData.length > 0) {
      setDrafts(draftsData);
    }
  }, [draftsData]);

  return (
    <div className="p-6" data-testid="drafts-modal">
      <h2 className="mb-4 flex items-center gap-2 border-b-2 pb-2 text-xl font-semibold text-gray-900">
        <PencilRuler className="h-6 w-6" /> Draft Cases
      </h2>
      <div className="max-h-[60vh] overflow-y-auto">
        {isLoading ? (
          <></>
        ) : !draftsData || draftsData?.length === 0 ? (
          <EmptyState title="No drafts found" subtitle="You don't have any draft cases at the moment." />
        ) : (
          <div className="grid gap-4 py-4">
            {drafts.map((draft, index) => (
              <div data-testid={`draft-case-${index}`} data-case-id={draft.id} key={draft.id}>
                <DraftCard
                  draft={draft}
                  onClick={() => onSelectDraft(draft.id)}
                  onDelete={() => handleDeleteDraft(draft.id)}
                  canDelete={isSuperAdmin || user?.id === draft.createdById}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default DraftsModalContent;
