import { useCallback, useMemo, useRef, useState } from 'react';

import { motion } from 'framer-motion';
import { ArrowLeftIcon } from 'lucide-react';
import { FixedSizeList as List } from 'react-window';

import { useGetDocPresignedURL } from '../../../../../../api/queries/useGetDocPresignedURL';
import useFetchDocumentFacts from '../../../DataView/DocumentsEditor/hooks/useFetchDocumentFacts';
import { useDocumentNavigation } from '../../../hooks/useOpenDocViewer';
import FactAdderModal from '../../Modals/FactAdderModal';
import DocViewerFactSidebar from '../components/DocViewerFactSidebar';
import DocViewerFullFact from '../components/DocViewerFullFact';
import { processFactHighlights, sortFactsByAppearance } from '../components/utils';
import { trackCustomEvent, trackPageView } from '@/analytics/Mixpanel';
import Button from '@/components/atoms/Button';
import ToastDrawer from '@/components/molecules/ToastDrawer';
import PDFViewer from '@/components/organisms/PDFViewer';
import { Fact } from '@/types';

const DocViewer = ({
  caseId,
  docId,
  factTotal,
  title,
}: {
  caseId: string;
  docId: string;
  pageNumber: number;
  factTotal: string | null;
  title?: string;
}) => {
  // State
  const listRef = useRef<List>(null);

  const [highlightedFactId, setHighlightedFactId] = useState<string | null>(null);
  const [selectedFact, setSelectedFact] = useState<Fact | null>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [factAdderModalIsOpen, setFactAdderModalIsOpen] = useState(false);

  const { closeDocViewer } = useDocumentNavigation();

  // Data fetching -------------
  const { data: docUrl } = useGetDocPresignedURL(caseId, docId);
  const { data: responseFacts, isLoading, isFetching } = useFetchDocumentFacts({ docId, caseId });

  // Coordinate and fact processing
  const { sortedFacts, coordinates, highlightFactMap } = useMemo(() => {
    if (!responseFacts) return { sortedFacts: [], coordinates: [] };
    const sortedFacts = sortFactsByAppearance(responseFacts, docId);
    const { coordinates, highlightFactMap } = processFactHighlights(sortedFacts, docId);

    return { sortedFacts, coordinates, highlightFactMap };
  }, [responseFacts, docId]);

  const handleNewFactCreated = useCallback(() => {
    setFactAdderModalIsOpen(false);
    trackCustomEvent('New Fact Created From Doc Viewer', { caseId, docId });
  }, []);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
    setSelectedFact(null);
  };

  const selectFact = (fact: Fact) => {
    setSelectedFact(fact);
    setIsDrawerOpen(true);
  };

  const scrollToFact = (id: string) => {
    const index = highlightFactMap?.get(id);
    if (index !== undefined && listRef.current) {
      setHighlightedFactId(id);
      listRef.current.scrollToItem(index, 'center');
    }
  };

  const scrollToHighlight = (id: string) => {
    setHighlightedFactId(id);
  };

  trackPageView('Doc viewer');

  return (
    <div className="flex h-full">
      <div className="w-7/12 overflow-hidden">
        <div className="flex items-center gap-2 border-b pt-3 pr-2 pb-2">
          <Button
            className="flex items-center rounded-full border bg-gray-100 px-2 py-0.5 hover:bg-gray-200"
            onClick={closeDocViewer}
            text="Back"
            icon={<ArrowLeftIcon className="h-5 w-5 pr-1" />}
          />

          <p className="flex items-center">{title && title.length > 70 ? title.slice(0, 70) + '...' : title}</p>
        </div>
        <div className="h-[calc(100%-4rem)]">
          {docUrl && (
            <PDFViewer
              fileUrl={docUrl}
              highlightedFactId={highlightedFactId}
              setHighlightedFactId={setHighlightedFactId}
              highlights={coordinates}
              onHighlightClick={scrollToFact}
            />
          )}
        </div>
      </div>

      <DocViewerFactSidebar
        docId={docId}
        isLoading={isLoading || isFetching}
        factTotal={factTotal}
        jumpToHighlightArea={scrollToHighlight}
        highlightedFactId={highlightedFactId}
        selectFact={selectFact}
        facts={sortedFacts}
        listRef={listRef}
        setFactAdderModalIsOpen={setFactAdderModalIsOpen}
      />

      <ToastDrawer isOpen={isDrawerOpen} toggleDrawer={toggleDrawer} position="right">
        {selectedFact && (
          <motion.div key={selectedFact.id}>
            <DocViewerFullFact fact={selectedFact} />
          </motion.div>
        )}
      </ToastDrawer>

      <FactAdderModal
        caseId={caseId}
        docId={docId}
        onSuccess={handleNewFactCreated}
        onClose={() => setFactAdderModalIsOpen(false)}
        isOpen={factAdderModalIsOpen}
      />
    </div>
  );
};

export default DocViewer;
