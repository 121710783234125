import { useState } from 'react';

import { trackCustomEvent } from '@/analytics/Mixpanel';
import Button from '@/components/atoms/Button';
import MultiSelect from '@/components/atoms/MultiSelect';
import { transformFilterName } from '@/helpers/filterNames';
import { Classification, DocIds, FactFilters, MyOptionType, Relevance } from '@/types';

const FactsFilterPopup = ({
  activeFilters,
  allDocuments,
  setFilters,
  handleClose,
}: {
  activeFilters: FactFilters;
  allDocuments: DocIds[];
  setFilters: (v: FactFilters) => void;
  handleClose: () => void;
}) => {
  const [documentFilters, setDocumentFilters] = useState<MyOptionType[]>(activeFilters['docIds'] || []);
  const [relevanceFilters, setRelevanceFilters] = useState<MyOptionType[]>(activeFilters['relevance'] || []);
  const [classificationFilters, setClassificationFilters] = useState<MyOptionType[]>(
    activeFilters['classification'] || [],
  );

  const handleSubmit = () => {
    const newFilters = { ...activeFilters };
    newFilters['docIds'] = documentFilters;
    newFilters['relevance'] = relevanceFilters;
    newFilters['classification'] = classificationFilters;

    trackCustomEvent('Filters applied');
    setFilters(newFilters);
    handleClose();
  };
  const handleClear = () => {
    setFilters({
      classification: [],
      docIds: [],
      relevance: [],
      keyFacts: Boolean(activeFilters['keyFacts']),
      disputedFacts: Boolean(activeFilters['disputedFacts']),
    });
    handleClose();
  };

  return (
    <div className="flex flex-col gap-2 px-8 py-8 text-sm">
      <div className="flex items-center justify-center gap-2">
        <p className="mr-2 w-24 border-r">Documents: </p>
        <MultiSelect
          placeholder="Select Documents"
          options={allDocuments.map((doc) => ({
            label: doc.file.name,
            value: doc.id,
          }))}
          selectedOptions={documentFilters}
          onChange={(newValue) => {
            setDocumentFilters([...newValue]);
          }}
          className="w-64 text-xs"
        />
      </div>
      <div className="flex items-center justify-center gap-2">
        <p className="mr-2 w-24 border-r">Relevance:</p>
        <MultiSelect
          placeholder="Select Relevance"
          options={Object.values(Relevance).map((relevance) => ({
            label: transformFilterName(relevance, 'factRelevance'),
            value: relevance,
          }))}
          selectedOptions={relevanceFilters}
          onChange={(newValue) => {
            setRelevanceFilters([...newValue]);
          }}
          className="w-64 text-xs"
        />
      </div>
      <div className="flex items-center justify-center gap-2">
        <p className="mr-2 w-24 border-r">Classification:</p>
        <MultiSelect
          placeholder="Select Classification"
          options={Object.values(Classification).map((classification) => ({
            label: transformFilterName(classification),
            value: classification,
          }))}
          selectedOptions={classificationFilters}
          onChange={(newValue) => {
            setClassificationFilters([...newValue]);
          }}
          className="w-64 text-xs"
        />
      </div>
      <div className="mt-6 mb-4 flex justify-between px-4">
        <Button type="delete" rounded="md" text="Clear Filters" onClick={handleClear} />
        <Button type="primary" rounded="md" text="Apply Filters" onClick={handleSubmit} />
      </div>
    </div>
  );
};

export default FactsFilterPopup;
