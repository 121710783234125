import { useQuery } from 'react-query';

import { InconsistencyReport } from '../types/backend-dto-types';
import { APIBaseChronos } from '@/api/hosts';
import { QueryEntity } from '@/api/types';
import { PaginatedResponse } from '@/api/types/queries';
import useGetFetchConfig from '@/api/useGetFetchConfig';

export const useGetInconsistencyReports = ({
  caseId,
  searchQuery,
  page,
  pageSize,
  paginated = true,
}: {
  caseId: string;
  searchQuery?: string;
  page?: number;
  pageSize?: number;
  paginated?: boolean;
}) => {
  const { fetchConfigGET } = useGetFetchConfig();

  return useQuery<PaginatedResponse<InconsistencyReport>>(
    [QueryEntity.InconsistencyReports, { caseId }, { searchQuery, page, pageSize, paginated }],
    async () => {
      const cleanSearchText = searchQuery ? searchQuery?.replace(/\s+/g, ' ').trim() : '';

      const res = await fetch(
        `${APIBaseChronos}/api/case/${caseId}/inconsistency/report/?search=${cleanSearchText}&page=${page}&pageSize=${pageSize}&paginated=${paginated}`,
        fetchConfigGET,
      );

      return await res.json();
    },
  );
};
