import { useEffect, useState } from 'react';

import { faArrowUpRightFromSquare, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { StageSpinner } from 'react-spinners-kit';

import ChronologyModal from './components/ChronologyModal';
import ExploreSection from './components/ExploreSection';
import KeyFactsTimeline from './components/KeyFactsTimeline';
import OverviewSection from './components/OverviewSection';
import ThemeContent from './components/ThemeContent';
import { SummaryTooltip, ThemeTooltip } from './components/TooltipModals';
import formatReferencesText from './helpers/formatHTML';
import { trackCustomEvent, trackPageView } from '@/analytics/Mixpanel';
import useGetCaseOverview from '@/api/queries/useGetCaseOverview';
import useGetSummary from '@/api/queries/useGetSummary';
import Copy from '@/components/atoms/Copy';
import Accordion from '@/components/molecules/Accordion';
import { default as CustomModal, default as Modal } from '@/components/molecules/Modals/CustomModal';
import { cleanReferencesAndCopyToClipboard } from '@/constants/referencing';
import { ShadowPulseColors } from '@/constants/styles';
import { Fact, Summary } from '@/types';

const CaseSummary = () => {
  const [searchParams] = useSearchParams();
  const caseId = searchParams.get('caseId');

  // State
  const [keyFacts, setKeyFacts] = useState<Fact[]>([]);
  const [updatedFactsData, setUpdatedFactsData] = useState<{ [key: string]: Fact }>({});
  const updateFactData = (eventId: string, data: Partial<Fact>) => {
    setUpdatedFactsData((prev) => ({
      ...prev,
      [eventId]: { ...prev[eventId], ...data },
    }));
  };

  const [chronologyExplorerOpen, setChronologyExplorerOpen] = useState(false);
  const [themes, setThemes] = useState<Summary[]>([]);
  const [summaryText, setSummaryText] = useState<string>('');
  const [tooltipModalOpen, setTooltipModalOpen] = useState(false);
  const [tooltipModalContent, setTooltipModalContent] = useState(<></>);

  // Hooks
  const { data: summaries, isLoading: summaryIsLoading } = useGetSummary(caseId);
  const { data: overview, isLoading: overviewIsLoading } = useGetCaseOverview(caseId);

  const location = useLocation();
  const navigate = useNavigate();

  // Effects
  useEffect(() => {
    trackPageView('Case Summary');
  }, []);

  useEffect(() => {
    if (!summaries || !summaries.content || !summaries.content.length) return;

    // Set the summary text to the first summary content
    setSummaryText(summaries.content[0].content);

    // Set the themes to the rest of the summaries
    setThemes(summaries.content.slice(1));
  }, [summaries]);

  useEffect(() => {
    if (summaries && summaries.keyFacts) {
      const modifiedFacts = summaries.keyFacts.map((fact: Fact) => ({
        ...fact,
        ...updatedFactsData[fact.id], // Merge with any updated data
      }));
      setKeyFacts(modifiedFacts);
    }
  }, [summaries, updatedFactsData]);

  // Handlers
  const scrollToRow = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      // Apply classes for visual effect
      element.style.setProperty('--pulse-color', ShadowPulseColors.brandSecondary);
      element.classList.add('relative', 'z-30', 'animate-shadow-pulse');
      setTimeout(() => {
        element.classList.remove('animate-shadow-pulse', 'z-30', 'relative');
      }, 2000); // How long we pulse for
    }
  };

  const handleViewKeyFacts = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('page', '0');
    searchParams.set('viewKeyFacts', 'true');

    navigate('/app/chronos/case-editor/data/facts?' + searchParams.toString());
  };

  const handleDisplayTooltipModal = (content: string) => {
    if (content === 'Summary') {
      setTooltipModalContent(<SummaryTooltip />);
    } else if (content === 'Themes') {
      setTooltipModalContent(<ThemeTooltip />);
    }
    setTooltipModalOpen(true);
  };

  return (
    <div className="flex flex-col pb-4 leading-relaxed">
      {summaryIsLoading ? (
        <div className="flex h-full items-center justify-center">
          <StageSpinner className="m-auto" size={40} color={'#081D57'} />
        </div>
      ) : (
        <>
          {summaryText && (
            <div className="mb-8 flex">
              <div className="w-full rounded-md border-2 border-gray-200/60 bg-white p-4">
                <div className="mb-2 flex items-center font-semibold">
                  Summary
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    className="ml-2 h-3 w-3 cursor-pointer text-gray-400 hover:text-blue-500"
                    onClick={() => handleDisplayTooltipModal('Summary')}
                  />
                </div>
                <div className="text-sm leading-relaxed">{formatReferencesText(summaryText, scrollToRow)}</div>
                <div className="mt-4 flex gap-2 text-gray-800">
                  <Copy
                    copyHandler={() => {
                      trackCustomEvent('Summary Copied', { caseId });
                      cleanReferencesAndCopyToClipboard(summaryText);
                    }}
                  />
                </div>
              </div>
            </div>
          )}

          <div className="mb-10 flex w-full justify-between gap-8">
            <ExploreSection setChronologyExplorerOpen={setChronologyExplorerOpen} />

            <OverviewSection
              isLoading={overviewIsLoading}
              overview={overview}
              handleViewKeyFacts={handleViewKeyFacts}
            />
          </div>

          {themes.length ? (
            <div className="mb-8 rounded-lg border-2 border-gray-200/60 bg-white p-4">
              <div id="themes" className="mb-2 flex items-center font-semibold">
                Themes
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  className="ml-2 h-3 w-3 cursor-pointer text-gray-400 hover:text-blue-500"
                  onClick={() => handleDisplayTooltipModal('Themes')}
                />
              </div>
              <div className="flex flex-col rounded-md border-2 border-gray-200/60">
                {themes.map((theme, index) => (
                  <Accordion
                    id={`theme-${index}`}
                    key={index}
                    title={<p className="pr-12 font-medium text-blue-500">{theme.name}</p>}
                    content={
                      <ThemeContent
                        key={index}
                        id={`theme-${index}`}
                        subquestions={theme.subquestions}
                        content={theme.content}
                        scrollToRow={scrollToRow}
                      />
                    }
                    className="border-b bg-white p-4"
                  />
                ))}
              </div>
            </div>
          ) : null}

          {caseId && summaries && summaries.keyFacts && summaries.keyFacts.length > 0 && (
            <>
              <div className="mb-4 rounded-lg border-2 border-gray-200/60 bg-white p-4">
                <div className="mb-3 flex w-full items-center gap-1">
                  <div className="flex items-center font-semibold">Key Facts</div>

                  <FontAwesomeIcon
                    icon={faArrowUpRightFromSquare}
                    className="h-4 w-4 cursor-pointer rounded-full p-2 text-blue-500 hover:bg-gray-100"
                    onClick={handleViewKeyFacts}
                  />
                </div>
                <KeyFactsTimeline caseId={caseId} factProps={keyFacts} updateFactData={updateFactData} />
              </div>
            </>
          )}
          {/*Chronology Modal*/}
          <Modal
            handleClose={() => {
              setChronologyExplorerOpen(false);
            }}
            isOpen={chronologyExplorerOpen}
            content={
              <ChronologyModal
                totalKeyFacts={overview?.keyFacts ?? 0}
                totalRelevantFacts={overview?.relevantFacts ?? 0}
                caseId={caseId}
              />
            }
          />
          {/*Tooltip Modal*/}
          <CustomModal
            handleClose={() => {
              setTooltipModalOpen(false);
            }}
            isOpen={tooltipModalOpen}
            content={tooltipModalContent}
          />
        </>
      )}
    </div>
  );
};

export default CaseSummary;
