import React, { useCallback, useEffect, useState } from 'react';

import parse, { HTMLReactParserOptions } from 'html-react-parser';
import { marked } from 'marked';

import ExtractionContent from './ExtractionContent';
import NoAnswer from './NoAnswer';
import { referencingLinkRegex } from '@/constants/referencing';
import { Chunk, Fact, KimMode, Reference, ThreadMessage } from '@/types';

interface AnswerContentProps {
  facts: Fact[];
  scrollToRow: (id: string, type: string) => void;
  setSelectedChunk: (chunk: Chunk) => void;
  setSelectedFact: (fact: Fact) => void;
  answerMessage: ThreadMessage | undefined;
  flowType: KimMode | undefined;
}

const formatAnswer = async (answer: string, references: Reference, scrollToRow: AnswerContentProps['scrollToRow']) => {
  if (!answer) return;

  // Parse the answer as markdown into HTML string
  let parsed: string = await marked.parse(answer);

  // Apply any custom formatting to the HTML string
  const olRegex = /<ol>/g;
  if (olRegex.test(parsed)) {
    parsed = parsed.replace(/<ol>/g, '<ol className="list-decimal ml-6">');
  }

  // Replace references with clickable links
  const options: HTMLReactParserOptions = {
    replace: (domNode: any) => {
      // Find any text nodes
      if (domNode.type === 'text') {
        const text = domNode.data;

        // Find any references in the text
        const referenceMatch = text.match(referencingLinkRegex);
        if (referenceMatch) {
          const parts = [];
          let lastIndex = 0;

          for (const match of text.matchAll(referencingLinkRegex)) {
            // Add text before the reference
            if (match.index! > lastIndex) {
              parts.push(text.slice(lastIndex, match.index));
            }

            // Parse reference numbers
            const nums = match[1].split(',').map((num: string) => parseInt(num.trim(), 10));

            // Add the reference span
            parts.push(
              <span key={match.index} className="inline-flex flex-wrap items-center gap-1">
                {nums.map((num: number, i: number) => {
                  const ref = references[num];
                  if (ref === null || ref === undefined) return <span>{num}</span>;
                  const id = ref.id;
                  return (
                    <span
                      key={`${num}-${i}`}
                      className={`cursor-pointer ${ref.type === 'chunk' ? 'text-orange-500' : 'text-blue-500'}`}
                      onClick={() => scrollToRow(id, ref.type || '')}
                    >
                      {i === 0 ? '[' : ''}
                      {num}
                      {i === nums.length - 1 ? ']' : ','}
                    </span>
                  );
                })}
              </span>,
            );

            lastIndex = match.index! + match[0].length;
          }

          // Add any remaining text
          if (lastIndex < text.length) {
            parts.push(text.slice(lastIndex));
          }

          // Display it all inline
          return <span className="inline">{parts}</span>;
        }
        return domNode;
      }
    },
  };

  const html = parse(parsed, options);
  return html;
};

const AnswerContent = ({
  facts,
  answerMessage,
  scrollToRow,
  flowType,
  setSelectedChunk,
  setSelectedFact,
}: AnswerContentProps) => {
  const [content, setContent] = useState<React.ReactNode>(null);

  const getContentFromFlowType = useCallback(
    async (flowType: KimMode | undefined) => {
      if (flowType === KimMode.ExtractionFlow) {
        try {
          const maybeArray = JSON.parse(answerMessage?.content || '');

          if (Array.isArray(maybeArray)) {
            return (
              <ExtractionContent
                facts={facts}
                chunks={answerMessage?.chunks ?? []}
                data={maybeArray}
                references={answerMessage?.references ?? undefined}
                setSelectedChunk={setSelectedChunk}
                setSelectedFact={setSelectedFact}
              />
            );
          }
        } catch {}
      }

      if (flowType === KimMode.DraftFlow) {
        return (
          <div className="my-4 flex w-full flex-col items-center">
            <div className="relative min-h-[800px] w-full max-w-[800px] overflow-x-auto rounded-lg border-2 bg-white px-12 py-10 shadow-lg">
              <div className="markdown-content relative text-justify text-sm text-gray-800">
                {await formatAnswer(answerMessage?.content || '', answerMessage?.references || {}, scrollToRow)}
              </div>
            </div>
          </div>
        );
      }

      return (
        <div className="markdown-content text-sm text-gray-800">
          {await formatAnswer(answerMessage?.content || '', answerMessage?.references || {}, scrollToRow)}
        </div>
      );
    },
    [answerMessage, scrollToRow],
  );

  useEffect(() => {
    getContentFromFlowType(flowType).then(setContent);
  }, [flowType, answerMessage, getContentFromFlowType]);

  const hasNoContent = !answerMessage || !answerMessage.content;

  return <div className="rounded border-2 px-4 py-3">{hasNoContent ? <NoAnswer /> : content}</div>;
};

export default AnswerContent;
