import { AddUserInput } from './AddUserInput';
import { EmailTemplateOptions } from '../../../../backend/src/types/types';
import { useSendEmail } from '../../api/mutations/useSendEmail';
import { useUserContext } from '../../Contexts/User';
import { Option } from '../../types';
import { trackCustomEvent } from '@/analytics/Mixpanel';

interface UserOptionListProps {
  orgUsersOptions: Option[];
  handleAddUser: (user: Option) => void;
}
const UserOptionList = ({ orgUsersOptions, handleAddUser }: UserOptionListProps) => {
  const { user } = useUserContext();
  const { mutate: sendEmail } = useSendEmail();

  const handleCreateOption = (inputValue: string) => {
    trackCustomEvent('Invited User With No Account', {
      userId: user?.id,
      orgId: user?.organisationId,
    });
    sendEmail({
      templateType: EmailTemplateOptions.Invite,
      to: inputValue,
      from: user?.email ?? '',
    });
  };

  const options = orgUsersOptions.map((option) => ({
    label: option.label,
    value: option.label,
    id: option.value,
  }));

  return (
    <AddUserInput
      options={options}
      onCreate={handleCreateOption}
      onAdd={handleAddUser}
    />
  );
};

export default UserOptionList;
