import { useLocation } from 'react-router-dom';

import PipelineMessage from './PipelineMessage';
import ProcessingPipeline from './ProcessingPipeline';
import { DocumentUploadCompleteOnboardingModal } from '@/components/molecules/Modals/Onboarding/DocumentUploadOnboardingModal';
import { PIPELINE_STATUS } from '@/constants/pipelineStatus';
interface StatusDisplayProps {
  casePipelineStatus: string;
  message: string;
}

const StatusDisplay = ({ casePipelineStatus, message }: StatusDisplayProps) => {
  const { state } = useLocation();
  const fromChecklistDocumentUpload = state?.fromChecklistDocumentUpload;

  const isFailed = casePipelineStatus === PIPELINE_STATUS.failed.value;

  return (
    <div className="border-opacity-40 my-4 rounded-lg border-2 border-gray-200 bg-white p-8 pt-4">
      <h2 className="mb-2 flex w-full items-center gap-2 border-b pb-1 text-xl font-semibold">Processing Case</h2>
      <div className="py-2">
        <PipelineMessage isFailed={isFailed} timingMessage={message} />
        {!isFailed && <ProcessingPipeline casePipelineStatus={casePipelineStatus} />}
        {fromChecklistDocumentUpload && <DocumentUploadCompleteOnboardingModal />}
      </div>
    </div>
  );
};

export default StatusDisplay;
