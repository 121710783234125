import React, { useEffect, useState } from 'react';

import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useSearchParams } from 'react-router-dom';

import ThreadsBarItem from './ThreadsBarItem';
import useGetThreads from '../hooks/useGetThreads';
import NewThreadIcon from '@/assets/icons/new-thread.svg?react';
import { ThreadObject } from '@/types';

interface ThreadsBarProps {
  createNewThread: () => void;
  goToThread: (thread_id: string) => void;
}

const PageSize = 25;

const ThreadsBar: React.FC<ThreadsBarProps> = ({ createNewThread, goToThread }) => {
  const [searchParams] = useSearchParams();
  const caseId = searchParams.get('caseId');

  const { isFetching, threads } = useGetThreads(caseId || '');
  const [displayedItems, setDisplayedItems] = useState(threads.slice(0, PageSize));
  const [hasMore, setHasMore] = useState(false);
  const activeThreadId = searchParams.get('threadId');

  const fetchMoreData = async () => {
    // 0.8s for animation spinner
    await new Promise((resolve) => setTimeout(resolve, 800));

    if (displayedItems.length >= threads.length) {
      setHasMore(false);
      return;
    }
    setDisplayedItems((prevItems) => [...prevItems, ...threads.slice(prevItems.length, prevItems.length + PageSize)]);
  };

  const [sentryRef] = useInfiniteScroll({
    loading: false,
    hasNextPage: hasMore,
    onLoadMore: fetchMoreData,
  });

  useEffect(() => {
    if (threads.length) {
      setDisplayedItems(threads.slice(0, PageSize));
      setHasMore(threads.length > PageSize);
    }
  }, [threads]);

  return (
    <>
      <div className="mb-1 h-full">
        <div className="mb-1 flex items-center gap-2 border-b px-4 pb-1 pt-2 text-sm font-semibold">
          Previous
          {activeThreadId && (
            <NewThreadIcon
              onClick={createNewThread}
              className="h-6 w-6 rounded-full p-1 hover:cursor-pointer hover:bg-gray-200"
            />
          )}
        </div>
        <div
          className="flex flex-col gap-1 overflow-x-clip overflow-y-scroll px-2 pr-3"
          style={{ height: 'calc(100% - 3rem)' }}
        >
          {isFetching ? (
            <div className="flex w-52 flex-col justify-center overflow-hidden">
              {[1, 2, 3, 4, 5, 6, 7].map((i) => (
                <div key={i} className="my-1 h-8 w-52 animate-pulse rounded bg-gray-100 px-2"></div>
              ))}
            </div>
          ) : (
            <>
              {displayedItems.map((threadObject: ThreadObject) => {
                return (
                  <ThreadsBarItem
                    key={threadObject.id}
                    threadText={threadObject.name}
                    threadId={threadObject.id}
                    goToThread={goToThread}
                    threadStatus={threadObject.status}
                    isActive={threadObject.id === activeThreadId}
                    createdAt={threadObject.createdDate.toString()}
                  />
                );
              })}
              {hasMore && (
                <div ref={sentryRef} className="mb-4 flex items-center justify-center">
                  <FontAwesomeIcon icon={faCircleNotch} className="fa-spin text-brandSecondary" />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default React.memo(ThreadsBar);
