import React, { useRef } from 'react';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faBookOpen,
  faBuildingUser,
  faChartColumn,
  faDoorOpen,
  faEnvelope,
  faGear,
  faHome,
  faPhone,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { faBooks, faShieldKeyhole } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLogoutFunction } from '@propelauth/react';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';

import { settingsHost } from '@/api/authHosts';
import { useSidebar } from '@/Contexts/Sidebar';
import { useUserContext } from '@/Contexts/User';
import { cn } from '@/helpers/shadCnUtils';

interface SidebarItemProps {
  title: string;
  icon: IconProp;
  expanded: boolean;
  onClick?: () => void;
  href?: string;
  to?: string;
}

const SidebarItem: React.FC<SidebarItemProps> = ({ title, icon, expanded, onClick, href, to }) => {
  const baseClassName = cn(
    'group relative flex h-10 cursor-pointer flex-row items-center pl-5 text-sm text-white not-italic',
    expanded ? 'w-40' : 'w-10',
  );

  const content = (
    <div className="flex items-center gap-2">
      <div
        className={cn(
          'absolute inset-0 ml-2 w-full origin-center scale-95 rounded bg-gray-100/10 opacity-0 transition-all duration-300 ease-in-out group-hover:scale-100 group-hover:opacity-100',
        )}
      />
      <FontAwesomeIcon icon={icon} className="relative w-4 text-white" />
      {
        <span className={cn('relative w-40 transition-opacity duration-300', expanded ? 'opacity-100' : 'opacity-0')}>
          {title}
        </span>
      }
    </div>
  );

  if (to) {
    return (
      <NavLink to={to} className={baseClassName}>
        {content}
      </NavLink>
    );
  }

  if (href) {
    return (
      <a href={href} className={baseClassName}>
        {content}
      </a>
    );
  }

  return (
    <div className={baseClassName} onClick={onClick}>
      {content}
    </div>
  );
};

const SectionHeader = ({ title, expanded }: { title: string; expanded: boolean }) => {
  return (
    <div
      className={cn(
        'px-5 text-sm font-medium whitespace-nowrap text-gray-300 transition-opacity duration-200',
        expanded ? 'opacity-100' : 'opacity-0',
      )}
    >
      {title}
    </div>
  );
};

const SideMenu: React.FC = () => {
  const { isExpanded, setIsExpanded } = useSidebar();
  const logoutFn = useLogoutFunction();
  const { user } = useUserContext();
  const sidebarRef = useRef<HTMLDivElement>(null);

  const handleClickLogout = (): void => {
    logoutFn(true);
    toast.info('See you again soon.');
  };

  const handleClickSettings = (): void => {
    window.open(settingsHost, '_self');
  };

  const isAnalyticsAdmin = user?.metadata?.metadata?.analyticsAdmin === 'true';
  const isOrgSuperAdmin = user?.metadata?.metadata?.orgSuperAdmin === 'true';

  return (
    <div
      data-testid="sidebar"
      ref={sidebarRef}
      className={cn(
        'absolute top-0 left-0 z-50 flex h-screen items-start justify-start overflow-hidden text-white transition-all duration-700',
        'bg-gradient-to-b from-[#013e45] via-[#013e45] via-40% to-[#31c4ac]',
        isExpanded ? 'w-[180px] shadow-lg' : 'w-16 shadow-none',
      )}
    >
      <div
        onMouseEnter={() => setIsExpanded(true)}
        onMouseLeave={() => setIsExpanded(false)}
        className="flex h-full w-full flex-col items-start justify-start py-2 pb-4"
      >
        <div className="mt-2 mb-8 cursor-pointer px-4 text-3xl font-semibold text-white not-italic">
          {isExpanded ? 'wexler.ai' : 'w'}
        </div>

        <div className="mb-4">
          <SidebarItem title="Home" expanded={isExpanded} to="app/chronos/matters" icon={faHome as IconProp} />
        </div>

        <div className="mb-4">
          <SectionHeader title="Resources" expanded={isExpanded} />
          <SidebarItem title="User Guide" expanded={isExpanded} to="app/chronos/guide" icon={faBookOpen as IconProp} />
          <SidebarItem
            title="Client Materials"
            expanded={isExpanded}
            to="app/chronos/client-materials"
            icon={faBooks as IconProp}
          />
        </div>

        {(isAnalyticsAdmin || isOrgSuperAdmin) && (
          <div className="mb-4">
            <SectionHeader title="Team" expanded={isExpanded} />
            {isAnalyticsAdmin && (
              <SidebarItem
                title="Usage"
                expanded={isExpanded}
                to="app/chronos/usage-dashboard"
                icon={faChartColumn as IconProp}
              />
            )}
            {isOrgSuperAdmin && (
              <SidebarItem title="Users" expanded={isExpanded} to="app/chronos/users" icon={faUsers as IconProp} />
            )}
          </div>
        )}

        {user?.organisation?.isGlobalAdmin && (
          <div className="mb-4">
            <SectionHeader title="Global Admin" expanded={isExpanded} />
            <SidebarItem
              title="Organisations"
              expanded={isExpanded}
              to="app/chronos/organisations"
              icon={faBuildingUser as IconProp}
            />
          </div>
        )}

        <div className="mt-auto">
          <SectionHeader title="Support" expanded={isExpanded} />
          <SidebarItem
            title="support@wexler.ai"
            expanded={isExpanded}
            href="mailto:support@wexler.ai"
            icon={faEnvelope as IconProp}
          />
          <SidebarItem
            title="+44 20804 09060"
            expanded={isExpanded}
            href="tel:+442080409060"
            icon={faPhone as IconProp}
          />
          <SidebarItem title="Privacy" expanded={isExpanded} to="app/privacy" icon={faShieldKeyhole as IconProp} />
          <SidebarItem title="Settings" expanded={isExpanded} onClick={handleClickSettings} icon={faGear as IconProp} />
          <SidebarItem title="Logout" expanded={isExpanded} onClick={handleClickLogout} icon={faDoorOpen as IconProp} />
        </div>
      </div>
    </div>
  );
};

export default React.memo(SideMenu);
