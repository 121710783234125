const Privacy = () => {
  return (
    <div className="flex h-screen w-full flex-col items-start justify-start overflow-auto pb-10">
      <div className="flex w-full items-center border-b-2 bg-gray-100">
        <h1 className="px-8 pt-4 pb-2 text-3xl font-bold text-gray-800 not-italic">
          Report a Security or Privacy Concern
        </h1>
      </div>

      <div className="px-8 py-4">
        <p className="mb-6">
          If you've encountered a security, confidentiality, integrity, availability, or privacy-related issue, report
          it using one of the following methods:
        </p>

        <h2 className="mb-4 text-xl font-semibold">Submit a Report</h2>
        <ul className="mb-6 list-disc pl-6">
          <li className="mb-2">
            <a href="https://forms.gle/wTZMfbicv46U8QnX7" className="font-medium text-blue-600 hover:underline">
              Use the Incident Report Form
            </a>{' '}
            to provide details, including supporting evidence like screenshots or logs.
          </li>
          <li>
            Email us at{' '}
            <a href="mailto:privacy@wexler.ai" className="text-blue-600 hover:underline">
              privacy@wexler.ai
            </a>{' '}
            with a description of the issue, affected system/service, date and time of occurrence, and any supporting
            evidence.
          </li>
        </ul>

        <h2 className="mb-4 text-xl font-semibold">What Happens Next</h2>
        <ul className="mb-6 list-disc pl-6">
          <li>Our team reviews your report and assesses the impact.</li>
          <li>We may request additional information if needed.</li>
          <li>We take appropriate action and provide updates if necessary.</li>
        </ul>

        <p>Your report helps us maintain security and compliance.</p>
      </div>
    </div>
  );
};

export default Privacy;
