import React, { useCallback, useEffect, useState } from 'react';

import { faCircleNotch, faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'react-calendar/dist/Calendar.css';
import Select, { ActionMeta, MultiValue, SingleValue } from 'react-select';
import makeAnimated from 'react-select/animated';

import Button from '@/components/atoms/Button';
import { reactSelectStylesDownloadColumns, reactSelectStylesDownloadFormat } from '@/constants/styles';
import { MyOptionType } from '@/types';

const FORMAT_OPTIONS = [
  { label: 'Excel', value: 'csv' },
  { label: 'Docx', value: 'docx' },
];

interface Props {
  columnOptions: { label: string; value: string }[];
  downloadTitle: string;
  loadingDownload: boolean;
  handleDownload: ({ title, columns, format }: { title: string; columns: string[]; format: string }) => void;
}

export const DownloadCSVModal = ({ columnOptions, downloadTitle, loadingDownload, handleDownload }: Props) => {
  const [title, setTitle] = useState(downloadTitle);
  const [columns, setColumns] = useState<MyOptionType[]>(columnOptions);
  const [format, setFormat] = useState<MyOptionType[]>([FORMAT_OPTIONS[0]]);

  useEffect(() => {
    setTitle(downloadTitle);
  }, [downloadTitle]);

  const handleChangeTitle = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  }, []);

  const handleChangeSelectedColumns = useCallback(
    (newValue: MultiValue<MyOptionType> | SingleValue<MyOptionType>, actionMeta: ActionMeta<MyOptionType>) => {
      setColumns(Array.isArray(newValue) ? newValue : newValue ? [newValue] : []);
    },
    [],
  );

  const handleChangeSelectedFormat = useCallback(
    (newValue: MultiValue<MyOptionType> | SingleValue<MyOptionType>, actionMeta: ActionMeta<MyOptionType>) => {
      setFormat(Array.isArray(newValue) ? newValue : newValue ? [newValue] : []);
    },
    [],
  );

  const handleDownloadClick = () => {
    const columnValues = columns.map((column) => column?.value).filter((value) => value !== undefined) as string[];
    const formatValue = format[0]?.value as string;
    handleDownload({ title, columns: columnValues, format: formatValue });
  };

  const animatedComponents = makeAnimated();

  return (
    <>
      {loadingDownload ? (
        <div className="flex h-72 flex-col items-center justify-center gap-8">
          <div className="text-xl">Downloading...</div>
          <FontAwesomeIcon icon={faCircleNotch} className="fa-spin text-brandSecondary text-4xl" />
        </div>
      ) : (
        <div className="px-6 py-4">
          <div className="mb-4 flex flex-col gap-1">
            <label className="text-sm font-medium text-gray-600">Title</label>
            <input
              onChange={handleChangeTitle}
              className="h-10 w-full rounded border px-1 text-sm font-normal text-black not-italic placeholder:text-gray-400 focus:outline-none"
              value={title}
              placeholder="Title"
            ></input>
          </div>
          <div className="mb-4 flex flex-col gap-1">
            <label className="text-sm font-medium text-gray-600">Columns</label>
            <Select
              options={columnOptions}
              components={animatedComponents}
              className="w-full rounded-md bg-white outline-none"
              styles={reactSelectStylesDownloadColumns}
              isMulti
              onChange={handleChangeSelectedColumns}
              value={columns}
              placeholder={'Select columns'}
              maxMenuHeight={200}
            />
          </div>

          <div className="flex flex-col gap-1">
            <label className="text-sm font-medium text-gray-600">Format</label>
            <Select
              options={FORMAT_OPTIONS}
              className="w-full rounded-md bg-white outline-none"
              styles={reactSelectStylesDownloadFormat}
              onChange={handleChangeSelectedFormat}
              value={format}
              placeholder={'Select format'}
              maxMenuHeight={200}
            />
          </div>

          <div className="my-4 border-t border-gray-200"></div>

          <Button
            onClick={handleDownloadClick}
            text="Download"
            type="primary"
            rounded="md"
            icon={<FontAwesomeIcon icon={faDownload} className="mr-2" />}
          />
        </div>
      )}
    </>
  );
};
