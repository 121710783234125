import { useQuery } from 'react-query';

import { APIBaseChronos } from '@/api/hosts';
import { QueryEntity } from '@/api/types';
import useGetFetchConfig from '@/api/useGetFetchConfig';
import { OrgUser } from '@/types';

const useOrgUsers = () => {
  const { fetchConfigGET } = useGetFetchConfig();
  const fetchOrgUsers = () => {
    return fetch(`${APIBaseChronos}/api/org/users`, fetchConfigGET)
      .then((res) => res.json())
      .catch((error) => console.error('Error fetching organization users:', error));
  };

  return useQuery<OrgUser[]>([QueryEntity.Users], fetchOrgUsers, {});
};

export default useOrgUsers;
