import React from 'react';

import { faCircleArrowLeft, faLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';

import ThreadsBar from '../../../ThreadsBar';
import NewThreadIcon from '@/assets/icons/new-thread.svg?react';
import { ThreadMessage } from '@/types';

interface AnswerSidebarProps {
  userMessage: ThreadMessage | undefined;
  createNewThread: () => void;
  goToThread: (threadId: string) => void;
  handleBack: () => void;
}

const ChatBubble = ({ text }: { text: string }) => {
  return (
    <div className="max-h-52 w-fit max-w-[90%] overflow-y-scroll rounded-xl border bg-gray-50 px-3 py-1 text-xs whitespace-pre-wrap">
      {text}
    </div>
  );
};

const AnswerSidebar: React.FC<AnswerSidebarProps> = ({ userMessage, createNewThread, goToThread, handleBack }) => {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(window.location.href);
    toast.info('Link copied to clipboard', { autoClose: 2000 });
  };

  return (
    <div className="flex w-56 min-w-[14rem] flex-col rounded-tl-lg border-r bg-white">
      <div className="flex h-1/2 flex-col justify-between border-b border-dashed pt-2 pb-2">
        <div className="flex items-center gap-1 border-b px-4 pb-1 text-sm font-semibold text-gray-800">
          <FontAwesomeIcon
            icon={faCircleArrowLeft}
            className="mr-3 h-5 w-5 cursor-pointer text-lg text-gray-400 hover:text-gray-600"
            onClick={handleBack}
          />
          <p>Question</p>
          <NewThreadIcon
            onClick={createNewThread}
            className="h-6 w-6 rounded-full p-1 hover:cursor-pointer hover:bg-gray-200"
          />
          <FontAwesomeIcon
            icon={faLink}
            className="rounded-full p-1 hover:cursor-pointer hover:bg-gray-200"
            onClick={copyToClipboard}
          />
        </div>

        <div className="flex h-full flex-col justify-between overflow-y-scroll px-2 pt-4">
          <div className="flex flex-col items-start gap-2">
            <ChatBubble text={userMessage ? userMessage.content : '...'} />
          </div>
        </div>
      </div>

      <div className="h-1/2 bg-gray-50/50">
        <ThreadsBar createNewThread={createNewThread} goToThread={goToThread} />
      </div>
    </div>
  );
};

export default AnswerSidebar;
