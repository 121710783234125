import { useState } from 'react';

import {
  InconsistencyLevel,
  InconsistencySource,
  InconsistencyType,
} from '../../../../../../../../backend/src/case/inconsistency/inconsistency-display';
import { trackCustomEvent } from '@/analytics/Mixpanel';
import Button from '@/components/atoms/Button';
import MultiSelect from '@/components/atoms/MultiSelect';
import { transformFilterName } from '@/helpers/filterNames';
import { DocIds, InconsistencyFilters, MyOptionType } from '@/types';

const InconsistenciesFilterPopup = ({
  activeFilters,
  allDocuments,
  setFilters,
  handleClose,
}: {
  activeFilters: InconsistencyFilters;
  allDocuments: DocIds[];
  setFilters: (v: InconsistencyFilters) => void;
  handleClose: () => void;
}) => {
  const [documentFilters, setDocumentFilters] = useState<MyOptionType[]>(activeFilters['docIds'] || []);
  const [levelFilters, setLevelFilters] = useState<MyOptionType[]>(activeFilters['level'] || []);
  const [typeFilters, setTypeFilters] = useState<MyOptionType[]>(activeFilters['type'] || []);
  const [sourceFilters, setSourceFilters] = useState<MyOptionType[]>(activeFilters['source'] || []);

  const handleSubmit = () => {
    const newFilters = { ...activeFilters };
    newFilters['docIds'] = documentFilters;
    newFilters['level'] = levelFilters;
    newFilters['type'] = typeFilters;
    newFilters['source'] = sourceFilters;

    trackCustomEvent('Inconsistency filters applied');
    setFilters(newFilters);
    handleClose();
  };
  const handleClear = () => {
    setFilters({ docIds: [], level: [], type: [] });
    handleClose();
  };

  return (
    <div className="flex flex-col gap-2 px-8 py-8 text-sm">
      <div className="flex items-center justify-center gap-2">
        <p className="mr-2 w-24 border-r">Documents: </p>
        <MultiSelect
          options={allDocuments.map((doc) => ({
            label: doc.file.name,
            value: doc.id,
          }))}
          selectedOptions={documentFilters}
          onChange={(newValue) => {
            setDocumentFilters([...newValue]);
          }}
          placeholder="Select documents"
          className="w-64 text-xs"
        />
      </div>
      <div className="flex items-center justify-center gap-2">
        <p className="mr-2 w-24 border-r">Severity:</p>
        <MultiSelect
          options={Object.values(InconsistencyLevel).map((level) => ({
            label: transformFilterName(level),
            value: level,
          }))}
          selectedOptions={levelFilters}
          onChange={(newValue) => {
            setLevelFilters([...newValue]);
          }}
          placeholder="Select severity"
          className="w-64 text-xs"
        />
      </div>
      <div className="flex items-center justify-center gap-2">
        <p className="mr-2 w-24 border-r">Source:</p>
        <MultiSelect
          options={Object.values(InconsistencySource).map((source) => ({
            label: transformFilterName(source, 'inconsistencySource'),
            value: source,
          }))}
          selectedOptions={sourceFilters}
          onChange={(newValue) => {
            setSourceFilters([...newValue]);
          }}
          placeholder="Select type"
          className="w-64 text-xs"
        />
      </div>
      <div className="flex items-center justify-center gap-2">
        <p className="mr-2 w-24 border-r">Type:</p>
        <MultiSelect
          options={Object.values(InconsistencyType).map((type) => ({
            label: transformFilterName(type),
            value: type,
          }))}
          selectedOptions={typeFilters}
          onChange={(newValue) => {
            setTypeFilters([...newValue]);
          }}
          placeholder="Select type"
          className="w-64 text-xs"
        />
      </div>
      <div className="mt-6 mb-4 flex justify-between px-4">
        <Button type="delete" rounded="md" text="Clear Filters" onClick={handleClear} />
        <Button type="primary" rounded="md" text="Apply Filters" onClick={handleSubmit} />
      </div>
    </div>
  );
};

export default InconsistenciesFilterPopup;
