import BookAnimation from '@/assets/animations/Book.json';
import DocAnimation from '@/assets/animations/Scan.json';
import LottieAnimation from '@/components/atoms/Lottie';

export const SummaryTooltip = () => {
  return (
    <div>
      <div className="flex h-24 items-center justify-center bg-gray-100">
        <LottieAnimation autoplay={true} loop={true} animationData={BookAnimation} className="h-48 w-48" />
      </div>
      <div className="px-8 pb-10">
        <p className="pt-4 pb-2 text-lg font-semibold">Summaries</p>
        <div className="flex flex-col gap-4 text-gray-700">
          <p>
            {' '}
            Wexler uses intelligent AI to analyse the extracted facts and create an executive overview of the case,
            focusing on the key facts how how they relate to the original case details provided by the user.
          </p>

          <p className="border border-x-0 border-b-0 pt-4 text-sm text-gray-500">
            This summary is directional and should not be considered comprehensive or exhaustive.
          </p>
        </div>
      </div>
    </div>
  );
};

export const ThemeTooltip = () => {
  return (
    <div>
      <div className="flex h-24 items-center justify-center bg-gray-100">
        <LottieAnimation autoplay={true} loop={true} animationData={DocAnimation} className="h-36 w-36" />
      </div>
      <div className="px-8 pb-10">
        <p className="pt-4 pb-2 text-lg font-semibold">Themes</p>
        <div className="flex flex-col gap-4 text-gray-700">
          <p>
            {' '}
            Themes relate to the overarching issues highlighted in a case. Wexler's AI uses both the case details
            provided by the user and the extracted facts to pull out topics and potential legal issues across the whole
            document set.
          </p>
          <p>Each theme contains a theme overview as well as further subquestion questions related to it.</p>

          <p className="border border-x-0 border-b-0 pt-4 text-sm text-gray-500">
            The extracted themes are directional and should not be considered comprehensive or exhaustive.
          </p>
        </div>
      </div>
    </div>
  );
};
