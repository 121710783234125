import { faCalendarAlt, faDownload } from '@fortawesome/free-solid-svg-icons';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SingleValue } from 'react-select';

import { UsageView } from '..';
import ModeSelector from './ModeSelector';
import DatePickerInput from '@/components/atoms/DatePickerInput';
import { Button } from '@/components/atoms/new-button';
import Select from '@/components/atoms/Select';
import { Matter, MatterType, MyOptionType, Org, OrgUser } from '@/types';

interface UsageHeaderProps {
  isFetching: boolean;
  isWexlerAdmin: boolean;
  view: UsageView;
  mattersData: Matter[];
  usersData: OrgUser[];
  orgData?: Org[];
  selectedOrganisation: SingleValue<MyOptionType>;
  selectedMatter: SingleValue<MyOptionType>;
  selectedUser: SingleValue<MyOptionType>;
  setView: (view: UsageView) => void;
  setStartDate: (date: Date) => void;
  setEndDate: (date: Date) => void;
  setSelectedMatter: (value: SingleValue<MyOptionType>) => void;
  setSelectedUser: (value: SingleValue<MyOptionType>) => void;
  setSelectedOrganisation: (value: SingleValue<MyOptionType>) => void;
  handleDownloadClick: VoidFunction;
  startDate: Date | null;
  endDate: Date | null;
}

const UsageHeader = ({
  mattersData,
  orgData,
  usersData,
  view,
  startDate,
  endDate,
  selectedOrganisation,
  selectedMatter,
  selectedUser,
  setStartDate,
  setEndDate,
  setView,
  setSelectedOrganisation,
  setSelectedMatter,
  setSelectedUser,
  handleDownloadClick,
  isWexlerAdmin,
  isFetching,
}: UsageHeaderProps) => {
  const userOptions =
    (usersData &&
      usersData.length &&
      usersData.map((user: OrgUser) => ({
        label: user.email,
        value: user.id,
      }))) ||
    [];

  const matterOptions =
    (mattersData &&
      mattersData.length &&
      mattersData.map((matter: Matter) => {
        let label;
        if (selectedOrganisation) {
          label = matter.id;
        } else {
          if (matter.type === MatterType.Personal) {
            label = `${matter.name} - ${matter.createdBy.email}`;
          } else {
            label = matter.name;
          }
        }
        return {
          label,
          value: matter.id,
        };
      })) ||
    [];

  const organisationOptions =
    (orgData &&
      orgData.length &&
      orgData.map((org) => ({
        label: org.name,
        value: org.id,
      }))) ||
    [];

  return (
    <div className="flex flex-col space-y-4">
      <div className="flex flex-wrap items-center justify-between gap-4">
        <div className="flex flex-col">
          <h1 className="text-xl font-semibold text-gray-800">Usage Dashboard</h1>
          <p className="text-sm text-gray-600">An overview of your organisation's usage</p>
        </div>

        <div className="flex items-center rounded-md border border-gray-200 bg-gray-50 p-1">
          <div className="mr-2 flex items-center px-2 text-gray-500">
            <FontAwesomeIcon icon={faCalendarAlt} className="mr-2" />
            <span className="text-sm font-medium">Date Range:</span>
          </div>
          <div className="items-cente flex text-sm">
            <DatePickerInput
              selectedDate={startDate}
              onChange={(date: Date | null) => {
                if (date) {
                  setStartDate(date);
                }
              }}
              placeholderText="Start Date"
              className="focus:ring-brand-500 h-9 w-30 cursor-pointer rounded-md border-0 bg-transparent transition-colors duration-200 hover:bg-gray-200"
            />
            <div className="mx-1 flex items-center text-xl text-gray-400">-</div>
            <DatePickerInput
              selectedDate={endDate}
              onChange={(date: Date | null) => {
                if (date) {
                  setEndDate(date);
                }
              }}
              placeholderText="End Date"
              className="focus:ring-brand-500 h-9 w-30 cursor-pointer rounded-md border-0 bg-transparent transition-colors duration-200 hover:bg-gray-200"
            />
          </div>
        </div>
      </div>

      <div className="flex flex-wrap items-center justify-between gap-4">
        <ModeSelector view={view} setView={setView} />

        <div className="flex flex-wrap items-center gap-3 rounded-md border border-gray-200 bg-gray-50 px-4 py-2 text-sm">
          <div className="mr-1 flex items-center text-gray-600">
            <FontAwesomeIcon icon={faFilter} className="mr-2" />
            <span className="font-medium">Filters:</span>
          </div>
          <Select
            className="w-48"
            options={userOptions}
            selectedOption={selectedUser}
            onChange={(newValue: SingleValue<MyOptionType>) => setSelectedUser(newValue)}
            placeholder="User"
          />
          <Select
            className="w-48"
            options={matterOptions}
            selectedOption={selectedMatter}
            onChange={(newValue: SingleValue<MyOptionType>) => setSelectedMatter(newValue)}
            placeholder="Matter"
          />
          {isWexlerAdmin && (
            <Select
              className="w-48"
              options={organisationOptions}
              selectedOption={selectedOrganisation}
              onChange={(newValue: SingleValue<MyOptionType>) => setSelectedOrganisation(newValue)}
              placeholder="Organisation"
            />
          )}

          <div className="mx-2 h-8 w-px bg-gray-200" />

          <Button
            variant="brand"
            disabled={isFetching}
            onClick={handleDownloadClick}
            data-tooltip-id="download-tooltip"
          >
            <FontAwesomeIcon icon={faDownload} />
            <span>Download</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UsageHeader;
