import { useEffect, useState } from 'react';

import { SingleValue } from 'react-select';
import { StageSpinner } from 'react-spinners-kit';

import DateRangeIndicator from './components/DateRangeIndicator';
import StatisticsGrid from './components/StatisticGrid';
import StatisticsTable from './components/StatisticTable';
import UsageHeader from './components/UsagePanelHeader';
import { formatChartData, formatTableData } from '../../../../../backend/src/utils/usageDataFormatting';
import downloadUsage from '@/api/queries/analyticsQueries/downloadUsage';
import useFetchOrganisations from '@/api/queries/analyticsQueries/useFetchOrganisations';
import useFetchOrgMatters from '@/api/queries/analyticsQueries/useFetchOrgMatters';
import useFetchOrgUsageData from '@/api/queries/analyticsQueries/useFetchOrgUsageData';
import useFetchOrgUsers from '@/api/queries/analyticsQueries/useFetchOrgUsers';
import useGetUsageDataByMatter from '@/api/queries/analyticsQueries/useGetUsageDataByMatter';
import useGetUsageDataByUser from '@/api/queries/analyticsQueries/useGetUsageDataByUser';
import useGetFetchConfig from '@/api/useGetFetchConfig';
import ChartCard from '@/components/molecules/ChartCard';
import AreaChart from '@/components/molecules/Charts/AreaChart';
import PageWrapper from '@/components/molecules/PageWrappers/PageWrapper';
import SearchBox from '@/components/molecules/SearchBox';
import { useUserContext } from '@/Contexts/User';
import { MyOptionType } from '@/types';

export enum UsageView {
  Table = 'table',
  Charts = 'charts',
}

const UsageDashboard = () => {
  const { user } = useUserContext();
  const isWexlerAdmin = user?.metadata?.metadata?.wexlerAnalyticsAdmin === 'true';

  // Calculate default start date (1 month ago) and end date (now)
  const defaultStartDate = new Date();
  defaultStartDate.setMonth(defaultStartDate.getMonth() - 1);
  const defaultEndDate = new Date();

  // Page view
  const [view, setView] = useState<UsageView>(UsageView.Charts);

  // Filters with default start and end dates
  const [startDate, setStartDate] = useState<Date>(defaultStartDate);
  const [endDate, setEndDate] = useState<Date>(defaultEndDate);
  const [selectedUser, setSelectedUser] = useState<SingleValue<MyOptionType>>(null);
  const [selectedMatter, setSelectedMatter] = useState<SingleValue<MyOptionType>>(null);
  const [selectedOrganisation, setSelectedOrganisation] = useState<SingleValue<MyOptionType>>(null);

  // Table search
  const [searchText, setSearchText] = useState<string>('');
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [tableGrouping, setTableGrouping] = useState<'matter' | 'user'>('matter');

  // Fetch users and matters in the organization
  const {
    data: usersData,
    isFetching: isFetchingUsers,
    refetch: refetchUsers,
  } = useFetchOrgUsers(selectedOrganisation ? [selectedOrganisation.value] : []);
  const {
    data: mattersData,
    isFetching: isFetchingMatters,
    refetch: refetchMatters,
  } = useFetchOrgMatters(selectedOrganisation ? [selectedOrganisation.value] : []);
  // Fetch organisations for Wexler admins
  const { data: orgData, isFetching: isFetchingOrgData } = useFetchOrganisations(isWexlerAdmin);

  const { fetchConfigGET } = useGetFetchConfig();

  useEffect(() => {
    refetchUsers();
  }, [selectedOrganisation, refetchUsers]);
  useEffect(() => {
    refetchMatters();
  }, [selectedOrganisation, refetchMatters]);

  // Fetch all the usage data
  const { isLoading, data: responseData } = useFetchOrgUsageData(
    !isFetchingUsers && !isFetchingMatters && !isFetchingOrgData,
    startDate.toISOString(),
    endDate.toISOString(),
    selectedMatter ? selectedMatter.value : undefined,
    selectedUser ? selectedUser.value : undefined,
    selectedOrganisation ? selectedOrganisation.value : undefined,
  );
  const { data: usageDataByMatter, isLoading: isLoadingUsageDataByMatter } = useGetUsageDataByMatter(
    !isFetchingUsers && !isFetchingMatters && !isFetchingOrgData,
    startDate.toISOString(),
    endDate.toISOString(),
    selectedMatter ? selectedMatter.value : undefined,
    selectedUser ? selectedUser.value : undefined,
    selectedOrganisation ? selectedOrganisation.value : undefined,
  );
  const { data: usageDataByUser, isLoading: isLoadingUsageDataByUser } = useGetUsageDataByUser(
    !isFetchingUsers && !isFetchingMatters && !isFetchingOrgData,
    startDate.toISOString(),
    endDate.toISOString(),
    selectedMatter ? selectedMatter.value : undefined,
    selectedUser ? selectedUser.value : undefined,
    selectedOrganisation ? selectedOrganisation.value : undefined,
  );

  const onSearchCall = () => {
    setSearchFilter(searchText);
  };

  const toggleTableGrouping = (value: 'matter' | 'user') => {
    setTableGrouping(value);
  };

  const handleDownloadClick = async () => {
    await downloadUsage(startDate, endDate, fetchConfigGET);
  };

  const rows = tableGrouping === 'matter' ? formatTableData(usageDataByMatter) : formatTableData(usageDataByUser);

  return (
    <PageWrapper className="bg-gray-50">
      <div className="flex w-full flex-col items-start justify-start px-4 md:px-6 lg:px-8">
        <div className="mb-8 flex w-full flex-col gap-6">
          <div className="w-full rounded-xl bg-white p-6 shadow-sm">
            <UsageHeader
              orgData={orgData}
              usersData={usersData ?? []}
              mattersData={mattersData ?? []}
              handleDownloadClick={handleDownloadClick}
              view={view}
              startDate={startDate}
              endDate={endDate}
              selectedOrganisation={selectedOrganisation}
              selectedMatter={selectedMatter}
              selectedUser={selectedUser}
              setView={setView}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              setSelectedMatter={setSelectedMatter}
              setSelectedOrganisation={setSelectedOrganisation}
              setSelectedUser={setSelectedUser}
              isFetching={isLoading}
              isWexlerAdmin={isWexlerAdmin}
            />
          </div>

          {view === UsageView.Table ? (
            <div className="flex w-full flex-col justify-center gap-4 rounded-xl bg-white p-6 shadow-sm">
              <div className="flex w-full flex-row items-center justify-between gap-2">
                <div className="flex items-center gap-4">
                  <h2 className="text-lg font-semibold text-gray-800">Usage by Matter/User</h2>
                  <DateRangeIndicator startDate={startDate} endDate={endDate} />
                </div>

                <SearchBox
                  value={searchText}
                  onChange={setSearchText}
                  onSearchCall={onSearchCall}
                  placeholder="Search"
                />
              </div>

              <div className="w-full overflow-x-auto rounded-xl border border-gray-200 text-sm">
                {isLoadingUsageDataByMatter || isLoadingUsageDataByUser ? (
                  <div className="flex h-60 items-center justify-center p-8">
                    <StageSpinner color="#31c4ac" />
                  </div>
                ) : (
                  <StatisticsTable
                    searchFilter={searchFilter}
                    columns={[
                      tableGrouping === 'matter'
                        ? { header: 'Matter', accessorKey: selectedOrganisation !== null ? 'id' : 'matterName' }
                        : { header: 'User', accessorKey: 'userEmail' },
                      { header: 'Number of pages', accessorKey: 'pages' },
                      { header: 'Number of runs', accessorKey: 'runs' },
                      { header: 'Number of facts', accessorKey: 'facts' },
                      { header: 'Number of chronologies', accessorKey: 'chronologies' },
                      { header: 'Number of Kim threads', accessorKey: 'threads' },
                      { header: 'Tokens used', accessorKey: 'tokens' },
                    ]}
                    rows={rows}
                    toggleGroupBy={toggleTableGrouping}
                  />
                )}
              </div>
            </div>
          ) : (
            <div className="flex flex-col gap-6">
              <div className="rounded-xl bg-white p-6 shadow-sm">
                <div className="mb-4 flex items-center gap-4">
                  <h2 className="text-lg font-semibold text-gray-800">Total Usage</h2>
                  <DateRangeIndicator startDate={startDate} endDate={endDate} />
                </div>
                <StatisticsGrid isLoading={isLoading} data={responseData} />
              </div>

              <div className="grid grid-cols-1 gap-6 lg:grid-cols-2">
                <ChartCard title="Pages processed" className="transition-shadow duration-200 hover:shadow-md">
                  {responseData?.data ? (
                    <AreaChart
                      className="h-60"
                      data={formatChartData(responseData.data || [], 'pages', startDate, endDate)}
                      index="week"
                      categories={['pages']}
                      valueFormatter={(value) => (Number.isInteger(value) ? value.toString() : '')}
                    />
                  ) : (
                    <div className="flex h-60 items-center justify-center p-8">
                      <StageSpinner color="#31c4ac" />
                    </div>
                  )}
                </ChartCard>
                <ChartCard title="Number of runs" className="transition-shadow duration-200 hover:shadow-md">
                  {responseData?.data ? (
                    <AreaChart
                      className="h-60"
                      data={formatChartData(responseData.data || [], 'runs', startDate, endDate)}
                      index="week"
                      categories={['runs']}
                      valueFormatter={(value) => (Number.isInteger(value) ? value.toString() : '')}
                    />
                  ) : (
                    <div className="flex h-60 items-center justify-center p-8">
                      <StageSpinner color="#31c4ac" />
                    </div>
                  )}
                </ChartCard>
                <ChartCard title="Number of chronologies" className="transition-shadow duration-200 hover:shadow-md">
                  {responseData?.data ? (
                    <AreaChart
                      className="h-60"
                      data={formatChartData(responseData.data || [], 'chronologies', startDate, endDate)}
                      index="week"
                      categories={['chronologies']}
                      valueFormatter={(value) => (Number.isInteger(value) ? value.toString() : '')}
                    />
                  ) : (
                    <div className="flex h-60 items-center justify-center p-8">
                      <StageSpinner color="#31c4ac" />
                    </div>
                  )}
                </ChartCard>
                <ChartCard
                  title="Number of Kim questions asked"
                  className="transition-shadow duration-200 hover:shadow-md"
                >
                  {responseData?.data ? (
                    <AreaChart
                      className="h-60"
                      data={formatChartData(responseData.data || [], 'threads', startDate, endDate)}
                      index="week"
                      categories={['threads']}
                      valueFormatter={(value) => (Number.isInteger(value) ? value.toString() : '')}
                    />
                  ) : (
                    <div className="flex h-60 items-center justify-center p-8">
                      <StageSpinner color="#31c4ac" />
                    </div>
                  )}
                </ChartCard>
              </div>
            </div>
          )}
        </div>
      </div>
    </PageWrapper>
  );
};

export default UsageDashboard;
