import { useMemo, useState } from 'react';

import { useAuthInfo } from '@propelauth/react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useQueryClient } from 'react-query';
import { Tooltip } from 'react-tooltip';
import { SSE } from 'sse.js';

import { APIBaseChronos } from '../../../../api/hosts';
import { TooltipStyles } from '../../../../constants/styles';

interface Props {
  jobId: string;
  complete: () => void;
}

export const UploadJobProgress = ({ jobId, complete }: Props) => {
  const queryClient = useQueryClient();
  const authInfo = useAuthInfo();

  const accessToken = authInfo.accessToken;

  const [completedCount, setCompletedCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const tooltipContent = `Extracting zip: ${completedCount} of ${totalCount} files extracted`;
  const progress = totalCount !== 0 ? 100 * (completedCount / totalCount) : 0;

  function setupSSEListener(): void {
    const sseResponse = new SSE(`${APIBaseChronos}/api/storage/unzip-events/${jobId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    sseResponse.onmessage = (msg) => {
      // NOTE: move to zod w/ monorepo
      const data = JSON.parse(msg.data);

      if (data.type === 'zip-extract-progress') {
        setCompletedCount(data.completedCount);
        setTotalCount(data.totalCount);
      } else if (data.type === 'zip-extract-complete') {
        complete();
        queryClient.invalidateQueries({ queryKey: [QueryEntity.Files, caseId] });
      }
    };
  }

  useMemo(() => setupSSEListener(), [jobId]);

  return (
    <>
      <div className="h-8 w-8" data-tooltip-id={`job-${jobId}-tooltip`} data-tooltip-content={tooltipContent}>
        <CircularProgressbar value={progress} strokeWidth={16} />
      </div>

      <Tooltip id={`job-${jobId}-tooltip`} style={TooltipStyles} />
    </>
  );
};
