import { useQuery } from 'react-query';

import { Organisation } from '../types/backend-dto-types';
import { APIBaseChronos } from '@/api/hosts';
import useGetFetchConfig from '@/api/useGetFetchConfig';

export const useGetAllOrganisations = () => {
  const { fetchConfigGET } = useGetFetchConfig();

  return useQuery<Organisation[]>('all-orgs', async () => {
    const url = new URL(`${APIBaseChronos}/api/org/all`);

    try {
      const res = await fetch(url.toString(), fetchConfigGET);
      return await res.json();
    } catch (err) {}
  });
};
