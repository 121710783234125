import { useEffect, useRef } from 'react';

import {
  faCircle,
  faCircleArrowLeft,
  faCircleNotch,
  faList,
  faRocket,
  faWandMagicSparkles,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence, motion } from 'framer-motion';

import PlanStep from './components/PlanStep';
import KimLoadingAnimation from '@/assets/animations/KimLoading.json';
import LottieAnimation from '@/components/atoms/Lottie';
import { ParameterValue, PlanStage } from '@/types';

interface PlanDrawerProps {
  planOpen: boolean;
  loadingPlan: boolean;
  executePlan: (plan: PlanStage[]) => void;
  updatePlan: (plan: PlanStage[], step: number, parameterName: string, newValue: ParameterValue) => void;
  planStage: Record<string, string>;
  plan: PlanStage[];
  executing: boolean;
  editable: boolean;
  cancelPlan: () => void;
  handleBack: () => void;
}

const PlanDrawer = ({
  planOpen,
  loadingPlan,
  updatePlan,
  executePlan,
  planStage,
  plan,
  executing,
  editable,
  cancelPlan,
  handleBack,
}: PlanDrawerProps) => {
  const container = {
    hidden: { opacity: 1 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!containerRef.current) return;

    // Find the index of the step that's currently in-progress
    const inProgressIndex = plan.findIndex((step) => planStage[step.name] === 'in-progress');
    if (inProgressIndex !== -1) {
      const stepElements = containerRef.current?.getElementsByClassName('plan-step');
      if (stepElements?.[inProgressIndex]) {
        const container = containerRef.current;
        const element = stepElements[inProgressIndex];

        // Calculate the scroll position to center the element
        const elementTop = (element as HTMLElement).offsetTop;
        const elementHeight = element.clientHeight;
        const containerHeight = container.clientHeight;
        const scrollTo = elementTop - containerHeight / 2 + elementHeight / 2;

        container.scrollTo({
          top: scrollTo,
          behavior: 'smooth',
        });
      }
    }
  }, [planStage, plan]);

  return (
    <AnimatePresence>
      {planOpen && (
        <motion.div
          className="h-full w-2/5 max-w-[400px] rounded-tl-md rounded-br-sm border-r shadow-sm"
          initial={{ x: '-100%' }}
          animate={{ x: 0 }}
          exit={{ x: '-100%' }}
          transition={{ duration: 0.3 }}
        >
          <div className="h-full">
            <div className="flex h-10 w-full items-center border-b text-lg font-medium">
              <p className="flex items-center gap-2 px-4">
                <FontAwesomeIcon
                  icon={faCircleArrowLeft}
                  className="mr-3 h-5 w-5 cursor-pointer text-gray-400 hover:text-gray-600"
                  onClick={handleBack}
                />
                <FontAwesomeIcon icon={faList} className="h-3 w-3" />
                Plan
              </p>
            </div>
            <div className="flex h-[calc(100%-2.5rem)] flex-col justify-between">
              {loadingPlan ? (
                <div className="mx-4 mt-10 flex h-[400px] flex-col items-center justify-center gap-4 rounded-lg border-2">
                  <p className="text-sm text-gray-500">Building plan...</p>
                  <FontAwesomeIcon icon={faCircleNotch} className="fa-spin h-3 w-3" />
                </div>
              ) : (
                <div className="h-[calc(100%-7rem)]">
                  <p className="px-4 pt-3 pb-1 text-sm text-gray-800">Kim will execute the following plan:</p>
                  <div
                    ref={containerRef}
                    className={`mx-4 my-1 max-h-[calc(100%-2.5rem)] overflow-y-auto rounded-lg border-2 p-4 text-sm text-gray-700 ${
                      executing ? 'pointer-events-auto select-none [&_*]:pointer-events-none' : ''
                    }`}
                  >
                    <motion.div
                      className="relative flex flex-col"
                      variants={container}
                      initial="hidden"
                      animate="visible"
                    >
                      {/* Continuous vertical line */}
                      <div className="absolute top-4 bottom-2 left-[0.7rem] z-0 w-0 border-l-2 border-gray-300"></div>
                      {plan.map((step, index) => (
                        <PlanStep
                          key={index}
                          step={step}
                          index={index}
                          executing={executing}
                          planStage={planStage}
                          updatePlan={(parameterName: string, newValue: ParameterValue) =>
                            updatePlan(plan, index, parameterName, newValue)
                          }
                        />
                      ))}
                    </motion.div>
                  </div>
                </div>
              )}
              {editable ? (
                <div className="h-28 px-4 pt-1 pb-4">
                  <div className="py-4 text-xs text-gray-500">
                    You can alter any highlighted{' '}
                    <span className="rounded bg-blue-100 px-1 text-gray-600">options</span> of a plan.
                  </div>
                  <div className="flex flex-row justify-between gap-2">
                    <button
                      onClick={() => cancelPlan()}
                      className="rounded border border-red-300 bg-red-50 px-2 py-1 text-gray-700 disabled:cursor-not-allowed disabled:opacity-50"
                      disabled={executing || loadingPlan}
                    >
                      Cancel
                      <FontAwesomeIcon icon={faXmark} className="pl-2" />
                    </button>
                    <button
                      onClick={() => executePlan(plan)}
                      className="rounded border border-green-300 bg-green-50 px-2 py-1 text-gray-700 disabled:cursor-not-allowed disabled:opacity-50"
                      disabled={executing || loadingPlan}
                    >
                      Execute Plan
                      <FontAwesomeIcon icon={faWandMagicSparkles} className="pl-2" />
                    </button>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

interface PlanPageProps {
  questionValue: string;
  loading: boolean;
  messages: string[];
}

const PlanPage = ({ questionValue, loading, messages }: PlanPageProps) => {
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const processMessages = (messages: string[]): React.ReactNode => {
    if (!messages || messages.length === 0) return '';

    return messages.map((message, messageIndex) => {
      const paragraphs = message.split('<br>');

      return (
        <motion.div
          key={messageIndex}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="flex items-baseline justify-start border-b border-dashed border-gray-200 pb-1"
        >
          <FontAwesomeIcon icon={faCircle} className="text-brandSecondary mr-2 h-2 w-2 animate-pulse" />
          <div className="flex flex-col">
            {paragraphs.map((paragraph, index) => {
              // Trim leading/trailing commas and spaces
              const trimmedParagraph = paragraph.trim().replace(/^,+|,+$/g, '');
              // Replace numbers with bold numbers using JSX
              const parts = trimmedParagraph.split(/(\d+)/);
              return (
                <p key={index} className="mb-2 last:mb-0">
                  {parts.map((part, partIndex) => (isNaN(Number(part)) ? part : <b key={partIndex}>{part}</b>))}
                </p>
              );
            })}
          </div>
        </motion.div>
      );
    });
  };

  return (
    <div className="mt-4 mr-6 ml-7 min-w-0 flex-1 pl-1">
      <h1
        className={`relative mb-2 max-h-[150px] w-full overflow-y-auto rounded-tl-xl rounded-r-3xl border bg-gray-50 px-4 py-2 text-sm ${
          questionValue.length > 250 && 'text-xs'
        }`}
      >
        <pre className="font-lota break-words whitespace-pre-line">
          {questionValue.charAt(0).toUpperCase() + questionValue.slice(1)}
        </pre>
      </h1>
      {loading ? (
        <div>
          <div className="flex h-28 items-center justify-center">
            <LottieAnimation animationData={KimLoadingAnimation} loop={true} autoplay={true} className="h-28" />
          </div>
          <div className="mt-2">
            <div className="mb-2 font-medium text-gray-800">
              <FontAwesomeIcon icon={faRocket} className="mr-2 h-4 w-4" />
              Executing Plan:
            </div>
            <AnimatePresence>
              <motion.div className="flex max-h-[250px] flex-col gap-1 overflow-y-auto rounded-md border p-2 text-sm text-gray-800">
                {processMessages(messages)}
                <div ref={messagesEndRef} />
              </motion.div>
            </AnimatePresence>
          </div>
        </div>
      ) : (
        <div className="mt-6 flex flex-col gap-2">
          <div className="h-4 w-full animate-pulse rounded-full bg-gray-100"></div>
          <div className="h-4 w-full animate-pulse rounded-full bg-gray-100"></div>
          <div className="h-4 w-full animate-pulse rounded-full bg-gray-100"></div>
          <div className="h-4 w-full animate-pulse rounded-full bg-gray-100"></div>
          <div className="h-4 w-full animate-pulse rounded-full bg-gray-100"></div>
          <div className="h-4 w-3/4 animate-pulse rounded-full bg-gray-100"></div>
        </div>
      )}
    </div>
  );
};

export { PlanDrawer, PlanPage };
