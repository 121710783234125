import { useEffect, useRef, useState } from 'react';

import { cn } from '../../helpers/shadCnUtils';

export interface TabItem {
  label: string;
  id: string;
  onClick: VoidFunction;
}

interface NavigationTabsProps {
  items: TabItem[];
  activeItem: string;
}

const NavigationTabs = ({ items, activeItem }: NavigationTabsProps) => {
  const [indicatorStyle, setIndicatorStyle] = useState({});
  const tabsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (tabsRef.current) {
      // TODO: Remove queryselector - fragile
      const currentTab = tabsRef.current.querySelector(`[data-tab="${activeItem}"]`) as HTMLElement;
      if (currentTab) {
        setIndicatorStyle({
          left: currentTab.offsetLeft,
          width: currentTab.clientWidth,
        });
      }
    }
  }, [activeItem]);

  return (
    <div className="flex w-full items-center justify-between">
      <div
        className="relative mb-3 flex w-fit flex-row gap-3 border border-x-0 border-t-0 border-b-1 border-gray-300"
        ref={tabsRef}
      >
        {items.map((item) => {
          return (
            <div
              key={item.id}
              onClick={item.onClick}
              className={cn('relative inline-flex cursor-pointer items-center justify-center px-2 py-1')}
              data-tab={item.id}
              data-testid={item.id}
            >
              {item.label}
            </div>
          );
        })}

        <div className="bg-brandSecondary absolute bottom-0 h-0.5 transition-all duration-300" style={indicatorStyle} />
      </div>
    </div>
  );
};

export default NavigationTabs;
