import { useCallback, useEffect, useState } from 'react';

import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuthInfo } from '@propelauth/react';
import ProgressBar from '@ramonak/react-progress-bar';
import AwsS3 from '@uppy/aws-s3';
import Uppy from '@uppy/core';
import useUppyState from '@uppy/react/lib/useUppyState';
import { useQueryClient } from 'react-query';
import { CircleSpinner } from 'react-spinners-kit';
import { SSE } from 'sse.js';

import { APIBaseChronos } from '../../../../../api/hosts';
import { usePresignedPost } from '../../../../../api/queries/usePresignedPost';
import { QueryEntity } from '../../../../../api/types';
import useGetFetchConfig, { FetchMethod } from '../../../../../api/useGetFetchConfig';
import FileUploadInput from '../../../../../components/organisms/FileUploadInput';

enum ZipUploadState {
  Ready,
  Uploading,
  Extracting,
  Complete,
}

export const ZipUploadModalContent = ({ caseId }: { caseId: string }) => {
  const queryClient = useQueryClient();
  const authInfo = useAuthInfo();
  const accessToken = authInfo.accessToken;

  const { getFetchConfig } = useGetFetchConfig();

  const { data: presignedPostData } = usePresignedPost(caseId, 'zip');

  const [uploadState, setUploadState] = useState(ZipUploadState.Ready);
  const [unzipProgressText, setUnzipProgressText] = useState('');

  const beginUnzip = useCallback(async (filename: string) => {
    const fetchConfig = getFetchConfig({
      method: FetchMethod.POST,
      data: {
        caseId,
        filename,
      },
    });

    const beginUnzipResponse = await fetch(`${APIBaseChronos}/api/storage/unzip/`, fetchConfig);
    const jobId = (await beginUnzipResponse.json()).jobId;

    const sseResponse = new SSE(`${APIBaseChronos}/api/storage/unzip-events/${jobId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    // TODO: Use same Zod types as backend after monorepo
    sseResponse.onmessage = (msg) => {
      const data = JSON.parse(msg.data);

      if (data.type === 'zip-extract-progress') {
        setUnzipProgressText(`Extracting ${data.completedCount} of ${data.totalCount} ...`);
      } else if (data.type === 'zip-extract-complete') {
        setUploadState(ZipUploadState.Complete);
        queryClient.invalidateQueries({ queryKey: [QueryEntity.Files, caseId] });
      }
    };
  }, []);

  const [uppy] = useState(() =>
    new Uppy({
      restrictions: {
        maxNumberOfFiles: 1,
        allowedFileTypes: ['.zip'],
      },
    }).use(AwsS3),
  );

  const totalProgress = useUppyState(uppy, (state) => state.totalProgress);

  const onUploadUppy = useCallback(
    (files: File[]) => {
      uppy.addFiles(files.map((file) => ({ source: 'local', data: file, name: file.name })));
      uppy.upload();

      setUploadState(ZipUploadState.Uploading);
    },
    [uppy],
  );

  const handleUppyUploadComplete = useCallback(async () => {
    const file = uppy.getFiles().pop();

    setUploadState(ZipUploadState.Extracting);
    setUnzipProgressText('Preparing archive for extraction...');
    beginUnzip(file?.name ?? '');
  }, [uppy]);

  useEffect(() => {
    if (presignedPostData) {
      uppy.getPlugin('AwsS3Multipart')?.setOptions({
        // NOTE: We currently turn off multipart uploads for zip files
        // as we haven't implemented Uppy Companion yet on the worker to
        // streamline multipart uploads
        shouldUseMultipart: false,
        getUploadParameters: () => {
          return {
            method: 'POST',
            url: presignedPostData.url,
            fields: presignedPostData.fields,
          };
        },
      });

      uppy.off('complete', handleUppyUploadComplete);

      uppy.on('complete', handleUppyUploadComplete);
    }
  }, [presignedPostData, handleUppyUploadComplete, uppy]);

  const renderState = (state: ZipUploadState) => {
    switch (state) {
      case ZipUploadState.Ready:
        return (
          <div className="w-80">
            <FileUploadInput
              isUploading={false}
              onDrop={onUploadUppy}
              type="zip"
              maxFiles={1}
              acceptedTypes={{ 'application/zip': ['.zip'] }}
            />
          </div>
        );

      case ZipUploadState.Uploading:
        return (
          <div className="flex w-full flex-col items-center gap-4">
            <div className="w-60 pt-12">
              <ProgressBar
                completed={totalProgress}
                customLabel={''}
                bgColor={'#4161FF'}
                height={'6px'}
                isLabelVisible={false}
              />
            </div>

            <span className="text-gray-400 italic">Please do not navigate away from this page</span>
          </div>
        );

      case ZipUploadState.Extracting:
        return (
          <div className="flex flex-col items-center gap-4 pt-8">
            <CircleSpinner className="m-auto" color={'#081D57'} />

            {unzipProgressText}

            <span className="text-gray-400 italic">You can now leave this page</span>
          </div>
        );

      case ZipUploadState.Complete:
        return (
          <div className="flex flex-col items-center gap-4 pt-12">
            <FontAwesomeIcon icon={faCheckCircle} />
            <p className="text-gray-600">Upload complete</p>
          </div>
        );
    }
  };

  return (
    <div className="flex h-72 flex-col items-center gap-2 p-4">
      <p className="font-semibold">Zip Upload</p>

      <p className="flex flex-col items-center text-sm text-gray-600">
        <span>Zip upload supports any number of documents.</span>
        <span>Valid files inside the zip will be extracted and added to the case.</span>
      </p>

      {renderState(uploadState)}
    </div>
  );
};
