import { useMutation } from 'react-query';
import { toast } from 'sonner';

import { MailSendRequest } from '../../../../backend/src/mail/dto/mail-send.request.dto';
import { APIBaseChronos } from '@/api/hosts';
import useGetFetchConfig, { FetchMethod } from '@/api/useGetFetchConfig';

export const useSendEmail = () => {
  const { getFetchConfig } = useGetFetchConfig();

  const sendEmail = async (data: MailSendRequest) => {
    const fetchConfig = getFetchConfig({
      method: FetchMethod.POST,
      data,
    });
    await fetch(`${APIBaseChronos}/api/mail/send`, fetchConfig);
  };

  return useMutation({
    mutationFn: sendEmail,
    onSuccess: () => {
      toast.success(`Email sent`);
    },
    onError: () => {
      toast.error(`Failed to send email`);
    },
  });
};
