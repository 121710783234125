import { useQuery } from 'react-query';

import { APIBaseChronos } from '../hosts';
import { QueryEntity } from '../types';
import useGetFetchConfig from '../useGetFetchConfig';

export const useGetUploadPagesEstimate = (caseId: string) => {
  const { fetchConfigGET } = useGetFetchConfig();

  return useQuery<{ count: number }>([QueryEntity.UploadPagesEstimate, caseId], async () => {
    try {
      const res = await fetch(`${APIBaseChronos}/api/files/${caseId}/upload-estimate`, fetchConfigGET);
      return await res.json();
    } catch (err) {
      console.error(err);
    }
  });
};
