import { useState } from 'react';

import Drawer from '../../../../../../components/molecules/Drawer';
import DocViewer from '../../../components/DocViewer/FactDocViewer';
import FactDropdown from '@/components/organisms/FactDropdown';
import { Fact } from '@/types';

interface Props {
  caseId: string;
  factProps: Fact[];
  updateFactData: (eventId: string, data: Partial<Fact>) => void;
}

const KeyFactsTimeline = ({ caseId, factProps, updateFactData }: Props) => {
  const [isDocViewerOpen, setIsDocViewerOpen] = useState(false);

  const [viewedReference, setViewedReference] = useState<{ docId: string; factId: string; pageNumber: number } | null>(
    null,
  );

  const onViewDocument = (docId: string, factId: string, pageNumber: number) => {
    setViewedReference({ docId, factId, pageNumber });
    setIsDocViewerOpen(true);
  };

  return (
    <>
      {factProps && (
        <>
          <div className="relative flex flex-col gap-4">
            {/* Vertical line */}
            <div className="absolute top-0 left-4 h-full border-l-2 border-gray-300"></div>

            {factProps.map((fact: Fact, index: number) => (
              <div key={index} className="">
                <div className="flex items-center">
                  {/* Marker */}
                  <div className="border-brandSecondary absolute left-[7px] h-5 w-5 rounded-full border-2 bg-white"></div>
                  {/* Fact content */}
                  <div
                    key={fact.id}
                    id={fact.id}
                    className="ml-10 w-full rounded border-2 border-gray-200/40 bg-gray-50"
                  >
                    <FactDropdown fact={fact} updateFactData={updateFactData} onViewDocument={onViewDocument} />
                  </div>
                </div>
              </div>
            ))}
          </div>

          <Drawer
            children={
              viewedReference && (
                <DocViewer
                  docId={viewedReference.docId}
                  caseId={caseId}
                  factId={viewedReference.factId}
                  onClose={() => {
                    setViewedReference(null);
                    setIsDocViewerOpen(false);
                  }}
                />
              )
            }
            hideCloseButton={true}
            isOpen={isDocViewerOpen}
          />
        </>
      )}
    </>
  );
};

export default KeyFactsTimeline;
