import { faCircleCheck, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';

import { PIPELINE_PROCESSING_STATUS, PIPELINE_STATUS } from '@/constants/pipelineStatus';

const getPipelineStatusIcon = (pipelineStatus: string, index: number) => {
  switch (true) {
    case PIPELINE_PROCESSING_STATUS.indexOf(pipelineStatus) > index:
      return (
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{
            type: 'spring',
            stiffness: 260,
            damping: 20,
          }}
          className="flex items-center justify-center"
        >
          <FontAwesomeIcon icon={faCircleCheck} className="text-brandSecondary h-6 w-6" />
        </motion.div>
      );
    case PIPELINE_PROCESSING_STATUS.indexOf(pipelineStatus) === index:
      return <FontAwesomeIcon icon={faCircleNotch} className="fa-spin h-4 w-4 text-yellow-500" />;
    case PIPELINE_PROCESSING_STATUS.indexOf(pipelineStatus) < index:
      return <div className="h-2 w-2 rounded-full bg-gray-300" />;
    default:
      return <div className="h-2 w-2 rounded-full bg-gray-300" />;
  }
};

const ProcessingPipeline = ({ casePipelineStatus }: { casePipelineStatus: string }) => {
  return (
    <div className="relative mt-8">
      <div className="absolute top-0 left-3 h-full w-[2px] bg-gray-200" />

      <div className="flex flex-col space-y-8">
        {PIPELINE_PROCESSING_STATUS.map((status: string, index: number) => {
          return (
            <div key={status} className="flex items-center">
              <div className="relative z-10 mr-4 flex h-6 w-6 items-center justify-center rounded-full border-2 border-gray-300 bg-white">
                {getPipelineStatusIcon(casePipelineStatus, index)}
              </div>
              <div className="w-80 rounded border">
                <div className="flex justify-center rounded-t-sm border-b bg-gray-200 py-0.5 text-sm">
                  {PIPELINE_STATUS[status]?.label}
                </div>
                <div className="px-4 py-1.5 text-xs text-gray-500">{PIPELINE_STATUS[status]?.description}</div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ProcessingPipeline;
