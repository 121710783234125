import { useEffect, useState } from 'react';

import { faCircleCheck, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';

import {
  BooleanParameter,
  MultiSelectListCreatableParameter,
  MultiSelectListParameter,
  SingleSelectListParameter,
  StringParameter,
} from '../Parameters';
import { Option, ParameterValue, PlanParameters, PlanStage } from '@/types';

const PlanParameterSelector = ({
  parameter,
  updatePlan,
}: {
  parameter: PlanParameters;
  updatePlan: (parameterName: string, newValue: ParameterValue) => void;
}) => {
  const [parameterValue, setParameterValue] = useState<ParameterValue>(parameter.value);

  useEffect(() => {
    setParameterValue(parameter.value);
  }, [parameter.value]);

  switch (parameter.type) {
    case 'str':
      return <StringParameter parameterName={parameter.name} parameterValue={parameterValue} updatePlan={updatePlan} />;
    case 'boolean':
      return (
        <BooleanParameter parameterName={parameter.name} parameterValue={parameterValue} updatePlan={updatePlan} />
      );

    case 'List[single-select,fixed-list]':
      return (
        <SingleSelectListParameter
          parameterName={parameter.name}
          parameter={parameter}
          parameterValue={Array.isArray(parameterValue) ? parameterValue : []}
          updatePlan={updatePlan}
        />
      );

    case 'List[multi-select,fixed-list]':
      return (
        <MultiSelectListParameter
          parameterName={parameter.name}
          parameter={
            parameter as PlanParameters & { all_selection: boolean; value: Option[]; available_values: Option[] }
          }
          parameterValue={parameterValue}
          updatePlan={updatePlan}
        />
      );

    case 'List[multi-select,any-value]':
      return (
        <MultiSelectListCreatableParameter
          parameterName={parameter.name}
          parameterValue={parameterValue}
          updatePlan={updatePlan}
        />
      );
  }
};

interface PlanStepProps {
  step: PlanStage;
  index: number;
  executing: boolean;
  planStage: Record<string, string>;
  updatePlan: (parameterName: string, newValue: ParameterValue) => void;
}

const getDescriptionWithParams = ({ step, updatePlan }: Pick<PlanStepProps, 'step' | 'updatePlan'>) => {
  return (
    <>
      <p className="mb-1">{step.description}</p>
      {step.parameters.map((param) => (
        <PlanParameterSelector key={param.name} parameter={param} updatePlan={updatePlan} />
      ))}
    </>
  );
};

const PlanStepContent = ({ planStage, step, updatePlan }: Omit<PlanStepProps, 'executing'>) => {
  let bgColor = 'bg-gray-100';
  let borderColor = 'border-gray-300';

  if (planStage[step.name] === 'in-progress') {
    bgColor = 'bg-yellow-50';
    borderColor = 'border-yellow-500';
  } else if (planStage[step.name] === 'complete') {
    bgColor = 'bg-green-50';
    borderColor = 'border-green-300';
  }

  const description = step.parameters.length > 0 ? getDescriptionWithParams({ step, updatePlan }) : step.description;

  return (
    <div className="flex w-full flex-col">
      <div
        className={`w-full flex-grow rounded-md border px-2 py-2 text-xs font-medium text-gray-800 ${borderColor} ${bgColor} transition-colors duration-500`}
      >
        {description}
      </div>
    </div>
  );
};

const PlanStepIcon = ({ index, planStage, step }: Pick<PlanStepProps, 'index' | 'planStage' | 'step'>) => {
  if (planStage[step.name] === 'in-progress') {
    return <FontAwesomeIcon icon={faCircleNotch} className="fa-spin h-4 w-4 text-yellow-500" />;
  }
  if (planStage[step.name] === 'complete') {
    return (
      <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{
          type: 'spring',
          stiffness: 260,
          damping: 20,
        }}
        className="flex items-center justify-center"
      >
        <FontAwesomeIcon icon={faCircleCheck} className="text-brandSecondary h-6 w-6" />
      </motion.div>
    );
  }
  return <span>{index + 1}</span>;
};

const PlanStep = ({ step, index, planStage, updatePlan }: PlanStepProps) => {
  return (
    <div key={index} className="plan-step mb-4 flex items-center last:mb-0">
      <div className="border-brandSecondary z-10 mr-3 flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-full border-2 bg-white">
        <PlanStepIcon planStage={planStage} index={index} step={step} />
      </div>
      <PlanStepContent index={index} step={step} updatePlan={updatePlan} planStage={planStage} />
    </div>
  );
};

export default PlanStep;
