import React from 'react';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

interface DatePickerInputProps {
  selectedDate: Date | null;
  onChange: (date: Date | null) => void;
  placeholderText: string;
  className?: string;
}

const DatePickerInput: React.FC<DatePickerInputProps> = ({
  selectedDate,
  onChange,
  placeholderText,
  className = 'h-10 w-36 text-sm',
}) => {
  return (
    <div className={className}>
      <DatePicker
        selected={selectedDate}
        onChange={onChange}
        placeholderText={placeholderText}
        className="h-10 w-full cursor-pointer rounded-md outline-none"
        dateFormat="dd/MM/yyyy"
      />
    </div>
  );
};

export default DatePickerInput;
