import { ReactNode } from 'react';

interface MainPageWrapperProps {
  headerContent: ReactNode;
  children: ReactNode;
}

export const MainPageWrapper = ({ headerContent, children }: MainPageWrapperProps) => {
  return (
    <div className="flex h-screen w-full flex-col">
      {/* Header */}
      <div className="w-full px-6 pt-4">
        <div className="flex w-full flex-col gap-2 text-gray-800">
          <div className="mt-1 flex w-full gap-2 text-2xl font-semibold text-gray-900 not-italic">{headerContent}</div>
        </div>
      </div>

      {/* Main Content */}
      <div className="h-full w-full overflow-y-auto">{children}</div>
    </div>
  );
};
