import * as Sentry from '@sentry/react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ReactDOM from 'react-dom/client';
import '../index.css';

import App from './App';
import reportWebVitals from './reportWebVitals';

if (import.meta.env.MODE === 'production') {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    enabled: import.meta.env.VITE_WEXLER_ENV !== 'local',
    environment: import.meta.env.VITE_WEXLER_ENV,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', /^https:\/\/chronos-api.wexler\.ai\/api/],
      }),
      new Sentry.Replay(),
      new Sentry.Integrations.Breadcrumbs({ console: false }),
    ],
    // Performance Monitoring
    sampleRate: 0.5,
    tracesSampleRate: 0.5, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.5, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 0.5, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  // <React.StrictMode> // Need to disable for Chronology Drag & Drop rows https://github.com/react-dnd/react-dnd/issues/3452
  <DndProvider backend={HTML5Backend}>
    <App />
  </DndProvider>,
  // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
