import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faQuestion,
  faQuestionSquare,
  faSquareQuestion,
  faMessagesQuestion,
  faCloudQuestion,
  faClipboardQuestion,
  faFileCircleQuestion,
  faShredder,
  faPersonCircleQuestion,
  faFile,
  faFiles,
  faFileExport,
  faFileCircleInfo,
  faFileSpreadsheet,
  faFileMagnifyingGlass,
  faFileSignature,
  faFileLines,
  faEnvelope,
  faFileExclamation,
  faFileCheck,
  faFileSlash,
  faFileShield,
  faFilePen,
  faFileLock,
  faFilesMedical,
  faFileContract,
  faFileInvoiceDollar,
  faFileUser,
  faFileCertificate,
  faLaptopFile,
} from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const getFontAwesomeIcon = (icon: string): IconProp => {
  return ({
    question: faQuestion,
    questionBlock: faQuestionSquare,
    squareQuestion: faSquareQuestion,
    messagesQuestion: faMessagesQuestion,
    cloudQuestion: faCloudQuestion,
    clipboardQuestion: faClipboardQuestion,
    fileCircleQuestion: faFileCircleQuestion,
    shredder: faShredder,
    personCircleQuestion: faPersonCircleQuestion,
    file: faFile,
    files: faFiles,
    fileExport: faFileExport,
    fileCircleInfo: faFileCircleInfo,
    fileSpreadsheet: faFileSpreadsheet,
    fileMagnifyingGlass: faFileMagnifyingGlass,
    fileSignature: faFileSignature,
    fileLines: faFileLines,
    envelope: faEnvelope,
    fileExclamation: faFileExclamation,
    fileCheck: faFileCheck,
    fileSlash: faFileSlash,
    fileShield: faFileShield,
    filePen: faFilePen,
    fileLock: faFileLock,
    filesMedical: faFilesMedical,
    filesContract: faFileContract,
    fileInvoiceDollar: faFileInvoiceDollar,
    fileUser: faFileUser,
    fileCertificate: faFileCertificate,
    laptopFile: faLaptopFile,
  }[icon] ?? faQuestion) as IconProp;
};

interface Props {
  questions: { prompt: string; icon: string }[];
  setQuestionValue: (v: string) => void;
}

export const ExampleQuestions = ({ questions, setQuestionValue }: Props) => {
  return (
    <div className="flex w-full flex-col items-start gap-2 pt-4">
      <span className="text-xs font-semibold text-gray-500">Suggested</span>
      <div className="grid w-full grid-cols-[auto_1fr] gap-x-2 gap-y-1">
        {questions.map((q) => (
          <>
            <FontAwesomeIcon icon={getFontAwesomeIcon(q.icon)} className="place-self-center text-lg text-gray-700" />
            <span
              className="cursor-pointer rounded px-2 py-1 text-sm text-gray-700 transition-colors duration-200 hover:bg-gray-100 hover:text-black"
              onClick={() => setQuestionValue(q.prompt)}
            >
              {q.prompt}
            </span>
          </>
        ))}
      </div>
    </div>
  );
};
