import { useQuery } from 'react-query';

import { APIBaseChronos } from '@/api/hosts';
import { QueryEntity } from '@/api/types';
import { DocTotalsRes } from '@/api/types/queries';
import useGetFetchConfig from '@/api/useGetFetchConfig';

export const useGetDocTotals = (caseId: string | null) => {
  const { fetchConfigGET } = useGetFetchConfig();

  return useQuery<DocTotalsRes>(
    [QueryEntity.DocTotals, { caseId }],
    () =>
      fetch(`${APIBaseChronos}/api/case/${caseId}/docs/totals`, fetchConfigGET).then((res) => {
        if (!res.ok) {
          throw new Error('Network response was not ok');
        }
        return res.json();
      }),
    { refetchOnWindowFocus: false },
  );
};
