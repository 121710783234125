import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { X } from 'lucide-react';
import workerUrl from 'pdfjs-dist/build/pdf.worker.mjs?url';
import { Document, pdfjs } from 'react-pdf';

import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import { DEFAULT_PAGE_HEIGHT, DEFAULT_PAGE_WIDTH } from './constants';
import { PDFPage } from './PDFPage';
import { useVisiblePages } from './useVisiblePages';
import { BoundingBoxWithId } from '@/types';

pdfjs.GlobalWorkerOptions.workerSrc = workerUrl;

interface PDFViewerProps {
  fileUrl: string | null | undefined;
  highlights: BoundingBoxWithId[];
  closeViewer?: () => void;
  setHighlightedFactId?: (id: string) => void;
  highlightedFactId?: string | null;
  onHighlightClick?: (id: string) => void;
}

const PDFViewer = ({
  fileUrl,
  highlights,
  closeViewer,
  highlightedFactId,
  setHighlightedFactId,
  onHighlightClick,
}: PDFViewerProps) => {
  // Scrollable container and highlight refs
  const containerRef = useRef<HTMLDivElement>(null);
  const canvasRefs = useRef<(HTMLCanvasElement | null)[]>([]);
  const highlightCanvasRefs = useRef<(HTMLCanvasElement | null)[]>([]);

  const [numPages, setNumPages] = useState(0);
  const [documentReady, setDocumentReady] = useState(false);
  const [pageHeight, setPageHeight] = useState<number | undefined>();
  const [pageWidth, setPageWidth] = useState<number | undefined>();

  const visiblePages = useVisiblePages(containerRef, numPages, {
    height: pageHeight ?? DEFAULT_PAGE_HEIGHT,
    width: pageWidth ?? DEFAULT_PAGE_WIDTH,
  });

  // Calculate the total height of the document (added 10px margin between pages)
  const totalHeight = useMemo(() => {
    let height = 0;
    for (let pageNumber = 1; pageNumber <= numPages; pageNumber++) {
      height += (pageHeight ?? DEFAULT_PAGE_HEIGHT) + 10;
    }
    return height;
  }, [numPages, pageHeight]);

  // Get vertical positions for pages
  const getPagePosition = useCallback(
    (pageNumber: number) => {
      return ((pageHeight ?? DEFAULT_PAGE_HEIGHT) + 10) * (pageNumber - 1);
    },
    [pageHeight],
  );

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
    canvasRefs.current = Array(numPages).fill(null);
    highlightCanvasRefs.current = Array(numPages).fill(null);
    setDocumentReady(true);
  };

  const handlePageLoad = (pageNumber: number, { height, width }: { height: number; width: number }) => {
    if (!pageHeight) {
      setPageHeight(height);
    }
    if (!pageWidth) {
      setPageWidth(width);
    }
  };

  const scrollToHighlight = useCallback(
    (id: string) => {
      if (!containerRef.current) return;

      const highlight = highlights.find((h) => h.id === id);
      if (!highlight) return;

      const { top, pageNumber } = highlight;
      const container = containerRef.current;

      // Calculate the scroll position using the top and the page number
      const scrollY = top * container.clientHeight + getPagePosition(pageNumber);

      if (setHighlightedFactId) {
        setHighlightedFactId(id);
      }

      container.scrollTo({ top: scrollY, behavior: 'smooth' });
    },
    [getPagePosition, setHighlightedFactId, highlights],
  );

  // Handle scrolling to a highlight when the scrollToHighlightId changes
  useEffect(() => {
    if (highlightedFactId && documentReady && pageHeight) {
      scrollToHighlight(highlightedFactId);
    }
    // Disabling next line as the page dimensions are constantly changing, these are a dependency of
    // scrollToHighlight, so it will trigger this useEffect on every page change
     
  }, [highlightedFactId, documentReady, highlights, pageHeight]);

  // Cleanup the component when the component unmounts
  useEffect(() => {
    return () => {
      canvasRefs.current = [];
      highlightCanvasRefs.current = [];
      setPageHeight(undefined);
      setPageWidth(undefined);
      setNumPages(0);
      setDocumentReady(false);
    };
  }, []);

  return (
    <div ref={containerRef} className="relative flex h-full justify-center overflow-auto">
      {closeViewer && (
        <div
          className="fixed top-1 right-1 z-30 cursor-pointer rounded-full border bg-gray-100 p-1 hover:bg-gray-200"
          onClick={closeViewer}
        >
          <X className="h-5 w-5" />
        </div>
      )}
      {/* Needs a set height for virtual scroll to work and a set width for doc to be centered */}
      <div className="relative" style={{ height: totalHeight, width: pageWidth ?? DEFAULT_PAGE_WIDTH }}>
        <Document
          key={fileUrl}
          loading={
            <div className="my-20 flex h-full items-center justify-center">
              <FontAwesomeIcon icon={faSpinner} className="animate-spin" />
            </div>
          }
          file={fileUrl}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          {documentReady &&
            visiblePages.map((pageNumber) => {
              const top = getPagePosition(pageNumber);
              const dimensions = { height: pageHeight ?? DEFAULT_PAGE_HEIGHT, width: pageWidth ?? DEFAULT_PAGE_WIDTH };

              return (
                <PDFPage
                  key={`page-${pageNumber}`}
                  pageNumber={pageNumber}
                  top={top}
                  dimensions={dimensions}
                  onLoadSuccess={handlePageLoad}
                  canvasRefs={canvasRefs}
                  highlightCanvasRefs={highlightCanvasRefs}
                  highlights={highlights}
                  highlightedFactId={highlightedFactId}
                  onHighlightClick={onHighlightClick}
                />
              );
            })}
        </Document>
      </div>
    </div>
  );
};

export default PDFViewer;
