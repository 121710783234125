import { createContext, FC, ReactNode, useContext } from 'react';

import { trackUser } from '@/analytics/Mixpanel';
import useGetUser from '@/api/queries/useGetUser';
import { OrgUserWithMetadata } from '@/types';

interface UserContextType {
  user: OrgUserWithMetadata | undefined;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

const useUserContext = (): UserContextType => {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error('useUserContext must be used within a UserProvider');
  }
  return context;
};

interface UserProviderProps {
  children: ReactNode;
}

const UserProvider: FC<UserProviderProps> = ({ children }) => {
  const { data: user } = useGetUser();
  if (user) {
    trackUser(user);
  }
  return <UserContext.Provider value={{ user }}>{children}</UserContext.Provider>;
};

export { UserContext, UserProvider, useUserContext };
