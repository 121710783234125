import { MutableRefObject } from 'react';

import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Page } from 'react-pdf';

import { DEFAULT_PAGE_HEIGHT, DEFAULT_PAGE_WIDTH } from './constants';
import { HighlightCanvas } from './HighlightCanvas';
import { BoundingBoxWithId } from '@/types';

interface PDFPageProps {
  pageNumber: number;
  top: number;
  dimensions: { width: number; height: number };
  onLoadSuccess: (pageNumber: number, page: { width: number; height: number }) => void;
  canvasRefs: MutableRefObject<(HTMLCanvasElement | null)[]>;
  highlightCanvasRefs: MutableRefObject<(HTMLCanvasElement | null)[]>;
  highlights: BoundingBoxWithId[];
  onHighlightClick?: (id: string) => void;
  highlightedFactId?: string | null;
}

export const PDFPage = ({
  pageNumber,
  top,
  dimensions,
  onLoadSuccess,
  canvasRefs,
  highlightCanvasRefs,
  highlights,
  highlightedFactId,
  onHighlightClick,
}: PDFPageProps) => {
  return (
    <div
      className="absolute shadow-lg"
      style={{
        top,
        width: dimensions?.width ?? DEFAULT_PAGE_WIDTH,
      }}
    >
      <HighlightCanvas
        canvas={canvasRefs.current[pageNumber - 1]}
        highlightCanvas={highlightCanvasRefs.current[pageNumber - 1]}
        highlights={highlights}
        pageNumber={pageNumber}
        width={dimensions?.width ?? DEFAULT_PAGE_WIDTH}
        height={dimensions?.height ?? DEFAULT_PAGE_HEIGHT}
        highlightedFactId={highlightedFactId}
        onHighlightClick={onHighlightClick}
      />
      <Page
        pageNumber={pageNumber}
        canvasRef={(ref) => (canvasRefs.current[pageNumber - 1] = ref)}
        width={dimensions?.width ?? DEFAULT_PAGE_WIDTH}
        height={dimensions?.height ?? DEFAULT_PAGE_HEIGHT}
        onLoadSuccess={(page) => onLoadSuccess(page.pageNumber, page)}
        renderTextLayer={false}
        renderAnnotationLayer={false}
        loading={
          <div className="flex h-full items-center justify-center">
            <FontAwesomeIcon icon={faSpinner} className="animate-spin" />
          </div>
        }
      />
    </div>
  );
};
