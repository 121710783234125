import { useState } from 'react';

import { useQuery } from 'react-query';

import { APIBaseChronos } from '../hosts';
import { QueryEntity } from '../types';
import useGetFetchConfig from '../useGetFetchConfig';

interface PresignedPostResponse {
  url: string;
  fields: Record<string, string>;
  expiresIn: number;
}

interface CachedData {
  data: PresignedPostResponse;
  expiry: number;
}

const ONE_MIN_MS = 60 * 1000;

export const usePresignedPost = (caseId: string, type = 'files') => {
  const { fetchConfigGET } = useGetFetchConfig();

  const [cachedData] = useState<Map<string, CachedData>>(new Map());

  const fetchPresignedPost = async (): Promise<PresignedPostResponse> => {
    const cacheKey = `${caseId}-${type}`;
    const cached = cachedData.get(cacheKey);

    // Use cached details if we're >1 minute away from expiry
    if (cached && cached.expiry - Date.now() > ONE_MIN_MS) {
      return cached.data;
    }

    const response = await fetch(
      `${APIBaseChronos}/api/storage/presigned-post?caseId=${caseId}&type=${type}`,
      fetchConfigGET,
    );
    if (!response.ok) {
      throw new Error('Fetching presigned post failed');
    }

    const res = await response.json();

    cachedData.set(cacheKey, {
      data: res,
      expiry: Date.now() + 10000,
    });

    return res;
  };

  return useQuery([QueryEntity.PresignedPost, caseId, type], fetchPresignedPost, {
    // Refetch the S3 presigned URL every 59 minutes, as they expire after 1 hour
    // This should ensure we always have a valid presigned URL
    refetchInterval: 59 * ONE_MIN_MS,
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: false,
  });
};
