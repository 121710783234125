import { useMutation } from 'react-query';
import { useNavigate, NavigateOptions } from 'react-router-dom';

import { APIBaseChronos } from '@/api/hosts';
import useGetFetchConfig, { FetchMethod } from '@/api/useGetFetchConfig';
import { Case, MatterType } from '@/types';

export const useCreateCase = (matterType?: MatterType, navigationOptions: NavigateOptions = {}) => {
  const navigate = useNavigate();
  const { getFetchConfig } = useGetFetchConfig();

  return useMutation<Case, Error, string | null>(
    async (matterId) => {
      const fetchConfig = getFetchConfig({
        method: FetchMethod.POST,
        data: {
          matterId: matterId,
        },
      });
      const res = await fetch(`${APIBaseChronos}/api/case`, fetchConfig);
      if (!res.ok) {
        throw new Error('Failed to create case');
      }
      return res.json();
    },
    {
      onSuccess: (data) => {
        if (matterType === MatterType.Personal) {
          navigate(`/app/chronos/case-creator?caseId=${data.id}&mode=light`, navigationOptions);
        } else {
          navigate(`/app/chronos/case-creator?caseId=${data.id}&mode=full`, navigationOptions);
        }
      },
    },
  );
};
