import { useEffect, useMemo, useState } from 'react';

import { faCog, faHome, faSpinner, faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'moment-timezone';
import { Navigate, Route, Routes, useNavigate, useSearchParams } from 'react-router-dom';
import { StageSpinner } from 'react-spinners-kit';
import { toast } from 'react-toastify';

import CaseAssistant from './CaseAssistant';
import CaseSummary from './CaseSummary';
import StatusDisplay from './components/CaseProcessing';
import SettingsModalContent from './components/Modals/SettingsModal';
import NavigationTabs from './components/NavigationTabs';
import Share, { ShareOptions } from './components/Share';
import DataView from './DataView';
import computeCaseMessage from './helpers/computeCaseMessage';
import { useOpenInconsistencyViewer } from './hooks/useOpenInconsistencyViewer';
import Drawer from '../../../components/molecules/Drawer';
import InconsistencyViewer from '../../../components/molecules/InconsistencyViewer';
import { useGetCase } from '@/api/queries/useGetCase';
import HeaderBar from '@/components/molecules/HeaderBar';
import Modal from '@/components/molecules/Modals/SettingsModal';
import PageMissing from '@/components/molecules/PageMissing';
import { MainPageWrapper } from '@/components/molecules/PageWrappers/MainPageWrapper';
import { PIPELINE_FREEZE_LOAD_STATUS, PIPELINE_PROCESSING_STATUS, PIPELINE_STATUS } from '@/constants/pipelineStatus';
import { Case, MatterType } from '@/types';

const CaseEditor = () => {
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const caseId = searchParams.get('caseId');

  const [caseState, setCaseState] = useState<Case | null>();

  const navigate = useNavigate();

  const { isLoading: isLoadingCase, data: caseData, refetch: refetchCase } = useGetCase(caseId || '');

  const isCaseReprocessing =
    caseData?.runs?.at(0) && caseData.runs.length > 1 && PIPELINE_PROCESSING_STATUS.includes(caseData.runs[0].status);

  useEffect(() => {
    if (caseData) {
      if (caseData.type === 'unauthorized') {
        toast.warning('You are not authorised to view this case...');
        navigate(`/app/chronos/matters`);
      } else if (caseData?.id) {
        setCaseState(caseData);
      }
    }
  }, [caseData, navigate]);

  // Use effect which polls the case object for updates
  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (!PIPELINE_FREEZE_LOAD_STATUS.includes(caseState?.runs?.[0]?.status || '')) {
      intervalId = setInterval(() => {
        refetchCase();
      }, 3000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [caseState, refetchCase]);

  const message = useMemo(() => {
    if (!caseState || !caseState.runs || caseState.runs.length === 0) return '';

    const run = caseState.runs[0];
    return computeCaseMessage(run.inQueue, run.queuePosition, run.completedAtEstimate, run.queueEstimateDatetime);
  }, [caseState]);

  const inconsistencyIds = searchParams.get('inconsistencyIds')?.split(',') || [];
  const [isInconsistencyViewerOpen, setIsInconsistencyViewerOpen] = useState(false);
  const { closeInconsistencyViewer } = useOpenInconsistencyViewer();

  useEffect(() => {
    if (inconsistencyIds && inconsistencyIds.length > 0) {
      setIsInconsistencyViewerOpen(true);
    } else {
      setIsInconsistencyViewerOpen(false);
    }
  }, [inconsistencyIds]);

  const renderContent = () => {
    if (isLoadingCase) return null;

    const successfulRuns = caseState?.runs?.filter((run) => run.status === PIPELINE_STATUS.complete.value);
    if (successfulRuns && successfulRuns.length > 0) {
      return (
        <>
          {caseId && <NavigationTabs caseId={caseId} />}
          <Routes>
            <Route path="summary" element={<CaseSummary />} />
            <Route path="data/*" element={<DataView openSettingsModal={() => setSettingsModalOpen(true)} />} />
            <Route path="assistant" element={<CaseAssistant />} />
            <Route path="*" element={<Navigate to={`/app/chronos/case-editor/summary`} />} />
          </Routes>
        </>
      );
    }
    const mostRecentRunStatus = caseState?.runs?.[0]?.status || '';

    if (
      PIPELINE_PROCESSING_STATUS.includes(mostRecentRunStatus) ||
      mostRecentRunStatus === PIPELINE_STATUS.failed.value
    ) {
      return <StatusDisplay casePipelineStatus={mostRecentRunStatus} message={message} />;
    }

    return (
      <PageMissing
        title={'Case Loading Failed'}
        description={
          <div className="mt-4 text-sm">
            Sorry! We encountered a problem loading this case. Please{' '}
            <a className="text-blue-500 hover:cursor-pointer" href="mailto:support@wexler.ai">
              contact
            </a>{' '}
            Wexler for support.
          </div>
        }
      />
    );
  };

  const headerContent = (
    <>
      {!isLoadingCase && caseState && (
        <HeaderBar
          breadcrumbPages={[
            {
              label: <FontAwesomeIcon icon={faHome} className="h-4 w-4 text-gray-700" />,
              href: '/app/chronos/matters',
            },
            {
              label: (
                <div>
                  {caseState?.matter?.name?.length > 30
                    ? `${caseState?.matter?.name?.substring(0, 30)}...`
                    : caseState?.matter?.name}
                </div>
              ),
              href: `/app/chronos/explore?matterId=${caseState?.matterId}`,
            },
            {
              label: (
                <div className="flex items-center">
                  {caseState.name.length > 40 ? `${caseState.name.substring(0, 40)}...` : caseState.name}

                  {!PIPELINE_PROCESSING_STATUS.includes(caseState?.runs?.[0]?.status) && (
                    <FontAwesomeIcon
                      icon={faCog}
                      className="text-brandPrimary hover:text-brandPrimary-hover h-4 w-4 rounded-full p-2 hover:cursor-pointer"
                      onClick={() => setSettingsModalOpen(true)}
                    />
                  )}
                </div>
              ),
            },
          ]}
          rightContent={
            <div className="flex gap-4">
              {isCaseReprocessing && (
                <div className="flex items-center gap-2 rounded-full border border-yellow-300 bg-yellow-50 px-3 py-1.5 text-sm text-yellow-700">
                  <div className="flex items-center gap-2">
                    <span>Case is reprocessing</span>
                    <div className="flex items-center gap-1">
                      <FontAwesomeIcon icon={faSpinner} spin className="text-yellow-500" />
                    </div>
                  </div>
                </div>
              )}

              {caseId && caseState && (
                <Share
                  allowedOptions={
                    caseState.matter.type === MatterType.Personal
                      ? [ShareOptions.MoveToDifferentMatter]
                      : [ShareOptions.AddUsers]
                  }
                  caseId={caseId}
                  matterId={caseState.matterId}
                />
              )}
            </div>
          }
        />
      )}
    </>
  );

  return (
    <MainPageWrapper headerContent={headerContent}>
      <div className="h-full w-full bg-center px-6 pb-2">{renderContent()}</div>
      <Modal
        title={
          <div className="flex items-center gap-4">
            <FontAwesomeIcon icon={faCog} className="text-gray-700" />
            Case Settings
          </div>
        }
        isOpen={settingsModalOpen}
        handleClose={() => setSettingsModalOpen(false)}
        content={<SettingsModalContent caseId={caseId || ''} handleClose={() => setSettingsModalOpen(false)} />}
      />
      <Drawer
        children={
          inconsistencyIds &&
          caseId && (
            <InconsistencyViewer
              handleOpen={setIsInconsistencyViewerOpen}
              caseId={caseId}
              inconsistencyIds={inconsistencyIds}
            />
          )
        }
        hideCloseButton={true}
        isOpen={isInconsistencyViewerOpen}
        onClose={() => {
          closeInconsistencyViewer();
        }}
      />
    </MainPageWrapper>
  );
};

export default CaseEditor;
