export interface ApiResponse {
  msg: string;
  type: string;
}

export interface Pagination {
  currentPage: number;
  filteredPages: number | null;
  pageSize: number;
  processedCount: number;
  totalCount: number;
  totalPages: number;
}

export enum QueryEntity {
  ApideckAllConnections,
  ApideckSession,
  Case,
  CaseDoc,
  CaseFacts,
  CaseInconsistencies,
  CaseIsPendingUpload,
  Chronologies,
  Chronology,
  DocIds,
  DocTotals,
  DocUnzip,
  DraftCases,
  Fact,
  Files,
  IncludedFactsCount,
  InconsistencyReport,
  InconsistencyReports,
  KeyFacts,
  Matters,
  MatterUsers,
  Messages,
  OrganisationById,
  OrgUsageData,
  PersonalMatter,
  PresignedGetDocument,
  PresignedPost,
  RecentCases,
  Summary,
  Threads,
  UploadPagesEstimate,
  Users,
}
