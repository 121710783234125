import { CircleHelp, FileText, Plus } from 'lucide-react';

import { Button } from '@/components/atoms/new-button';
import { MatterType, OrgUser, OrgUserWithMetadata } from '@/types';

interface UserMattersProps {
  selectedUser: OrgUserWithMetadata | OrgUser | null;
  onAddToMatters: () => void;
  removeUserFromMatters: ({ userId, matterIds }: { userId: string; matterIds: string[] }) => void;
}

const UserMatters = ({ selectedUser, onAddToMatters, removeUserFromMatters }: UserMattersProps) => {
  if (!selectedUser) return null;

  return (
    <div className="p-6">
      <h3 className="mb-4 border-b pb-2 text-lg font-medium text-gray-900">
        Matters for <span className="text-blue-600">{selectedUser.email}</span>
      </h3>

      <div className="mb-4 max-h-60 overflow-y-auto pr-1">
        {selectedUser.matters && selectedUser.matters.length > 1 ? (
          <div className="space-y-3">
            {selectedUser.matters
              .filter((matter) => matter.type !== MatterType.Personal)
              .map((matter) => (
                <div
                  key={matter.id}
                  className="group rounded-lg border border-gray-200 bg-gray-50 px-4 py-3 transition-all hover:border-blue-300 hover:bg-blue-50"
                >
                  <div className="flex items-center gap-4">
                    <div className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-blue-100">
                      <FileText className="h-5 w-5 text-blue-600" />
                    </div>
                    <div className="flex-1">
                      <h4 className="text-sm font-medium text-gray-900">{matter.name}</h4>
                      <div className="mt-1 flex flex-col gap-2">
                        {matter.description && (
                          <p className="line-clamp-1 text-xs text-gray-600">{matter.description}</p>
                        )}
                        {matter.code && (
                          <span className="inline-flex w-fit items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-blue-700/10 ring-inset">
                            Code: {matter.code}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="flex w-32 items-center justify-center">
                      {matter.createdById === selectedUser.id ? (
                        <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-green-600/20">
                          Matter Owner
                        </span>
                      ) : (
                        <Button
                          variant="destructive"
                          size="sm"
                          className="ml-1 text-xs"
                          onClick={(e) => {
                            e.stopPropagation();
                            removeUserFromMatters({ userId: selectedUser.id, matterIds: [matter.id] });
                          }}
                        >
                          Remove Access
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center p-8 text-center text-gray-500">
            <div className="mb-2 text-3xl">
              <CircleHelp className="h-6 w-6" />
            </div>
            <p>This user is not part of any matters</p>
          </div>
        )}
      </div>

      <div className="flex justify-end border-t pt-4">
        <Button variant="primary" onClick={onAddToMatters}>
          Add to other Matters <Plus className="h-3.5 w-3.5" />
        </Button>
      </div>
    </div>
  );
};

export default UserMatters;
