import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { useUserContext } from '../Contexts/User';

export function useGlobalAdminCheck(redirectPath = '/app/chronos') {
  const { user } = useUserContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.organisation !== undefined && !user.organisation.isGlobalAdmin) {
      navigate(redirectPath);
    }
  }, [user, navigate, redirectPath]);
}
