import { useMutation } from 'react-query';

import { APIBaseChronos } from '@/api/hosts';
import useGetFetchConfig, { FetchMethod } from '@/api/useGetFetchConfig';

export const useDeleteChronologyFact = (caseId: string) => {
  const { getFetchConfig } = useGetFetchConfig();

  return useMutation(async (factId: string) => {
    const fetchConfig = getFetchConfig({ method: FetchMethod.DELETE });
    return fetch(`${APIBaseChronos}/api/case/${caseId}/chronology/fact/${factId}`, fetchConfig);
  });
};
