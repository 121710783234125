import { Chunk } from '@/types';

const DocumentChunk = ({ chunk, handleSelectChunk }: { chunk: Chunk; handleSelectChunk: (chunk: Chunk) => void }) => {
  return (
    <div className="rounded border-2 p-2" key={chunk.id}>
      <div className="flex justify-between">
        <div className="mt-1 mb-1 font-semibold">Source Text:</div>

        <div>
          <div className="flex gap-2">
            <div
              className="cursor-pointer overflow-hidden text-ellipsis text-blue-500 hover:text-blue-700"
              onClick={() => handleSelectChunk(chunk)}
            >
              {chunk.doc.file.name.length > 90 ? chunk.doc.file.name.slice(0, 90) + '...' : chunk.doc.file.name}
            </div>
            <span className="bg-brandTertiary/30 rounded-sm px-2 text-gray-600">{chunk.doc.type}</span>
          </div>
        </div>
      </div>

      <p>{chunk.content}</p>
    </div>
  );
};

export default DocumentChunk;
