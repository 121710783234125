import { useEffect, useState } from 'react';

import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { UploadJobProgress } from './UploadJobProgress';
import Button from '../../../../components/atoms/Button';

interface Props {
  activeUploadJobIds: string[];
  uploadDisabled: boolean;
  handleUpdateCase: () => void;
}

export const StartProcessingCase = ({ activeUploadJobIds, uploadDisabled, handleUpdateCase }: Props) => {
  const [activeJobIds, setActiveJobIds] = useState<string[]>([]);

  useEffect(() => {
    setActiveJobIds(activeUploadJobIds);
  }, [activeUploadJobIds]);

  const onCompleteJob = (jobId: string) => {
    setActiveJobIds((prev) => prev.filter((id) => id !== jobId));
  };

  return (
    <div className="ml-auto flex items-center gap-4">
      {activeJobIds.map((jobId) => (
        <UploadJobProgress key={jobId} jobId={jobId} complete={() => onCompleteJob(jobId)} />
      ))}

      <Button
        testid="start-processing-btn"
        onClick={handleUpdateCase}
        disabled={uploadDisabled || activeJobIds.length > 0}
        className="bg-brandSecondary hover:bg-brandSecondary-hover flex items-center justify-center rounded-md px-4 py-2 text-white transition-colors disabled:cursor-not-allowed disabled:opacity-50"
        text="Start Processing"
        iconRight
        icon={<FontAwesomeIcon icon={faArrowRight} className="ml-2 h-4 w-4" />}
      />
    </div>
  );
};
