import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSearchParams } from 'react-router-dom';

import Button from '@/components/atoms/Button';

const NoAnswer = () => {
  const [_, setSearchParams] = useSearchParams();

  const onNewSearchClick = () => {
    setSearchParams((prev) => {
      prev.delete('threadId');
      return prev;
    });
  };

  return (
    <div>
      <p className="mb-3 text-sm">Unable to process answer, press the button below to start a new search</p>
      <Button
        text="New search"
        type="brand"
        rounded="lg"
        onClick={onNewSearchClick}
        icon={<FontAwesomeIcon icon={faSearch} className="mr-2" />}
      />
    </div>
  );
};

export default NoAnswer;
