import { useMutation, useQueryClient } from 'react-query';

import { OrgPermission } from '../../../../backend/src/organisation/organisation-permission';
import { APIBaseChronos } from '../hosts';
import { QueryEntity } from '../types';
import useGetFetchConfig, { FetchMethod } from '../useGetFetchConfig';

interface Props {
  permissions: OrgPermission[];
}

export const useUpdateOrganisation = (orgId: string) => {
  const { getFetchConfig } = useGetFetchConfig();
  const queryClient = useQueryClient();

  const updateOrganisation = async (data: Props) => {
    const fetchConfig = getFetchConfig({
      method: FetchMethod.PATCH,
      data,
    });

    await fetch(`${APIBaseChronos}/api/org/${orgId}`, fetchConfig);
  };

  return useMutation({
    mutationFn: updateOrganisation,
    onSuccess: () => {
      queryClient.invalidateQueries([QueryEntity.OrganisationById, orgId]);
    },
  });
};
