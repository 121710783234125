import { useMutation } from 'react-query';

import { APIBaseChronos } from '@/api/hosts';
import useGetFetchConfig, { FetchMethod } from '@/api/useGetFetchConfig';
import { ApideckFileData } from '@/types';

interface UploadFilesParams {
  caseId: string;
  fileArray: Array<ApideckFileData>;
}

interface UseApideckUploadFilesParams {
  onSuccess: () => Promise<void>;
  onError: () => void;
}

export const useApideckUploadFiles = ({ onSuccess, onError }: UseApideckUploadFilesParams) => {
  const { getFetchConfig } = useGetFetchConfig();

  return useMutation<void, Error, UploadFilesParams>(
    async ({ caseId, fileArray }) => {
      const fetchConfig = getFetchConfig({
        method: FetchMethod.POST,
        data: {
          caseId: caseId,
          files: fileArray,
        },
      });
      const res = await fetch(`${APIBaseChronos}/api/apideck/upload-files`, fetchConfig);
      if (!res.ok) {
        throw new Error('Failed to upload files');
      }
    },
    {
      onSuccess: async () => {
        await onSuccess();
      },
      onError: () => {
        onError();
      },
    },
  );
};
