import React from 'react';

import TooltipIcon from '@/components/atoms/TooltipIcon';

interface StatisticItemProps {
  title: string;
  value: string | number | null;
  isFetching: boolean;
  tooltipText?: string;
}

const formatValue = (value: string | number | null) => {
  if (value === null || value === undefined) {
    return <span className="text-4xl font-bold">0</span>;
  }
  const stringValue = typeof value === 'number' ? value.toLocaleString() : value.toString();

  // Determine the text size based on the length of the value
  let textSizeClass = 'text-4xl font-bold';
  if (stringValue.length > 12) {
    textSizeClass = 'text-2xl font-bold';
  } else if (stringValue.length > 8) {
    textSizeClass = 'text-3xl font-bold';
  }

  return <span className={textSizeClass}>{stringValue}</span>;
};

const StatisticItem: React.FC<StatisticItemProps> = ({ title, value, isFetching, tooltipText }) => (
  <div className="flex flex-1 flex-col items-stretch justify-between rounded-lg border border-gray-100 p-4 shadow-sm transition-all duration-300 hover:shadow-md">
    <div className="mb-3 flex flex-row items-center gap-1 text-sm font-medium text-gray-500">
      {title}
      {tooltipText && <TooltipIcon tooltipContent={tooltipText} />}
    </div>
    <div className="font-semibold text-black">
      {isFetching || value === null ? (
        <div className="h-10 w-20 animate-pulse rounded-2xl bg-gray-100" />
      ) : (
        formatValue(value)
      )}
    </div>
  </div>
);

export default StatisticItem;
