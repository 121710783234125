import { useState, ReactNode } from 'react';

import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

import { OnboardingModalBase, StepItem, TipItem } from './OnboardingModalBase';
import { useCreateCase } from '@/api/mutations/useCreateCase';
import { Button } from '@/components/atoms/new-button';
import { Matter, MatterType } from '@/types';

export const CaseCreationMatterSelectionModal = ({
  matters,
  isOpen,
  setIsOpen,
}: {
  matters: Matter[];
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}) => {
  const navigate = useNavigate();
  const { mutate: createCase } = useCreateCase(MatterType.General, { state: { fromChecklistCaseCreation: true } });

  const mattersListContent = matters && matters.length > 0 && (
    <>
      <p className="mb-1 text-sm text-gray-700">
        It looks like you are already a member of some matters! Choose one to create a case in:
      </p>
      <div className="mb-4 max-h-60 overflow-y-auto rounded border p-4">
        {matters.map((matter) => (
          <div
            key={matter.id}
            data-testid={`onboarding-case-creation-matter-card-${matter.id}`}
            onClick={() => createCase(matter.id)}
            className="mb-2 cursor-pointer rounded-lg border border-gray-200 bg-gray-50 px-4 py-2 transition-all hover:border-purple-300 hover:bg-purple-50 hover:shadow-md"
          >
            <div className="flex items-center gap-3">
              <div className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-purple-100">
                <Box />
              </div>
              <div className="flex-1">
                <h4 className="text-sm font-medium text-gray-900">{matter.name}</h4>
                <p className="line-clamp-1 text-xs text-gray-600">{matter.description}</p>
              </div>
              <FontAwesomeIcon
                icon={faArrowRight}
                className="h-4 w-4 text-purple-500 opacity-0 transition-opacity group-hover:opacity-100"
              />
            </div>
          </div>
        ))}
      </div>
    </>
  );

  const separatorOrEmptyMatterMessage = (
    <>
      {matters && matters.length > 0 ? (
        <div className="relative my-2">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-gray-300"></div>
          </div>
          <div className="relative flex justify-center">
            <span className="bg-white px-2 text-sm text-gray-500">or</span>
          </div>
        </div>
      ) : (
        <div className="relative my-4">
          <p className="text-sm text-gray-700">
            It looks like you aren't a part of any matters yet. Create a matter first to organise your cases.
          </p>
        </div>
      )}

      <Button variant="primary" size="lg" className="w-full" onClick={() => navigate('/app/chronos/matter-creator')}>
        Create new matter
      </Button>
    </>
  );

  return (
    <OnboardingModalBase
      testId="onboarding-case-creation-matter-selection-modal"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title="Create your first case!"
      icon={faPenToSquare}
      introduction={<p className="mb-4 text-sm text-gray-700">Cases help you organise documents within matters.</p>}
    >
      {mattersListContent}
      <div className="mt-4 flex flex-col gap-2">{separatorOrEmptyMatterMessage}</div>
    </OnboardingModalBase>
  );
};

export const CaseCreationOnboardingModal = () => {
  const [checkListModalOpen, setCheckListModalOpen] = useState(true);

  const steps: StepItem[] = [
    {
      icon: faPenToSquare,
      title: 'Enter case details',
      description:
        'Add a case name and fill out the rest of the case details, e.g. case issues, case type, case parties, etc.',
    },
    {
      icon: faArrowRight,
      title: 'Upload your files and start processing!',
      description: 'Upload files from your file system, or using our integrations, and then start processing!',
    },
  ];

  const introduction: ReactNode = (
    <>
      <p className="mb-2 text-sm text-gray-700">
        You may have already seen this screen if you created a case in your Personal Matter. But this time let's try
        uploading a case inside a shared matter!
      </p>
      <p className="mb-4 text-sm text-gray-700">
        The upload process is exactly the same as in the Personal Matter, except we default to using a{' '}
        <span className="text-brand-secondary font-semibold">Full Upload</span> which allows you to enter more detailed
        information to help Wexler's AI better understand your case.
      </p>
    </>
  );

  const tip: TipItem = {
    text: 'The more information you provide about your case, the better our AI can analyse your documents.',
    color: 'blue',
  };

  return (
    <OnboardingModalBase
      testId="onboarding-case-creation-modal"
      isOpen={checkListModalOpen}
      setIsOpen={setCheckListModalOpen}
      title="Case Creation!"
      icon={faPenToSquare}
      maxWidth="50rem"
      introduction={introduction}
      steps={steps}
      tip={tip}
    />
  );
};
