import { faChartSimple, faTableList } from '@fortawesome/free-solid-svg-icons';
import { faChartSine, faTableTree } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { UsageView } from '../index';
import { cn } from '@/helpers/shadCnUtils';

interface ModeSelectorProps {
  view: UsageView;
  setView: (view: UsageView) => void;
}

const ModeSelector = ({ view, setView }: ModeSelectorProps) => {
  return (
    <div className="relative flex h-10 items-center rounded-full bg-gray-100 p-1 text-sm">
      <div
        className={cn('absolute h-8 w-[50%] rounded-full bg-white shadow-sm transition-all duration-300 ease-in-out', {
          'left-1': view === UsageView.Charts,
          'left-[calc(50%_-_4px)]': view === UsageView.Table,
        })}
      />
      <button
        onClick={() => setView(UsageView.Charts)}
        className={cn(
          'relative z-10 flex w-[80px] items-center justify-center gap-2 rounded-full px-4 py-1.5 font-medium transition-all duration-200',
          view === UsageView.Charts ? 'text-brandSecondary' : 'text-gray-600',
        )}
      >
        <FontAwesomeIcon icon={faChartSine} />
        Charts
      </button>
      <button
        onClick={() => setView(UsageView.Table)}
        className={cn(
          'relative z-10 flex w-[80px] items-center justify-center gap-2 rounded-full px-4 py-1.5 font-medium transition-all duration-200',
          view === UsageView.Table ? 'text-brandSecondary' : 'text-gray-600',
        )}
      >
        <FontAwesomeIcon icon={faTableTree} />
        Table
      </button>
    </div>
  );
};

export default ModeSelector;
