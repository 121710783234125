import { useState } from 'react';

import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';

import { MatterType, UsageDataObjectWithTokens } from '../../../../../../backend/src/types/types';
import TableFilterPopup from '@/screens/Chronos/UsageDashboard/components/TableFilterPopup';

interface Column {
  header: string;
  accessorKey: string;
}

interface Row extends UsageDataObjectWithTokens {
  matterName?: string;
  matterCode?: string;
  userEmail?: string;
  userDisabled?: boolean;
  matterType?: MatterType;
  matterCreatedBy?: string;
}
interface StatsTableProps {
  columns: Column[];
  rows: Row[];
  searchFilter: string;
  toggleGroupBy: (value: 'matter' | 'user') => void;
}

const StatisticsTable = ({ columns, rows, searchFilter, toggleGroupBy }: StatsTableProps) => {
  const [sorting, setSorting] = useState([
    { id: 'pages', desc: true },
    { id: 'runs', desc: true },
    { id: 'facts', desc: true },
    { id: 'chronologies', desc: true },
    { id: 'threads', desc: true },
  ]);

  const table = useReactTable<Row>({
    columns: columns.map((column) => {
      if (column.accessorKey === 'matterName') {
        return {
          ...column,
          cell: ({ row }) => (
            <div>
              <div>
                {row.original.matterType === MatterType.Personal ? (
                  <span>
                    {row.original.matterName}
                    <span className="text-xs text-gray-500"> ({row.original.matterCreatedBy})</span>
                  </span>
                ) : (
                  <span>{row.original.matterName}</span>
                )}
              </div>
              <div className="text-xs text-gray-500">{row.original.matterCode}</div>
            </div>
          ),
        };
      }
      if (column.accessorKey === 'userEmail') {
        return {
          ...column,
          cell: ({ row }) => (
            <div>
              <div>{row.original.userEmail}</div>
              {row.original.userDisabled && <div className="text-xs text-gray-500">Disabled</div>}
            </div>
          ),
        };
      }
      return column;
    }),
    data: rows,
    state: {
      globalFilter: searchFilter,
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <div className="w-full overflow-x-auto">
      <table className="w-full min-w-[800px] text-gray-700">
        <thead className="sticky h-12 bg-gray-100">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className={`py-4 font-semibold ${
                    header.index === 0 ? 'pl-8 text-start' : 'text-end'
                  } ${header.index === columns.length - 1 ? 'pr-8' : ''}`}
                >
                  {header.column.id === 'matterName' ||
                  header.column.id === 'userEmail' ||
                  header.column.id === 'id' ? (
                    <div className="flex items-center gap-1">
                      <TableFilterPopup
                        options={[
                          { label: 'Matter', value: 'matter' },
                          { label: 'User', value: 'user' },
                        ]}
                        onChange={(value) => toggleGroupBy(value as 'matter' | 'user')}
                      />
                      {flexRender(header.column.columnDef.header, header.getContext())}
                    </div>
                  ) : (
                    flexRender(header.column.columnDef.header, header.getContext())
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.length > 0 ? (
            table.getRowModel().rows.map((row) => (
              <tr
                key={row.id}
                className={`h-16 border border-x-0 border-gray-200 transition-colors duration-150 hover:bg-gray-50 ${
                  row.index % 2 === 0 ? 'white' : 'bg-gray-100'
                }`}
              >
                {row.getVisibleCells().map((cell, index) => (
                  <td
                    key={cell.id}
                    className={`py-4 ${index === 0 ? 'pl-8 text-start' : 'text-end'} ${
                      index === columns.length - 1 ? 'pr-8' : ''
                    }`}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={columns.length} className="flex justify-center py-8 text-center text-gray-500">
                No data available
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default StatisticsTable;
