import { useMemo, useState } from 'react';

import { faArrowRight, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion, AnimatePresence, m } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

import { ChecklistStep } from '../../../../../../../../backend/src/types/types';
import { trackCustomEvent } from '@/analytics/Mixpanel';
import { useCreateCase } from '@/api/mutations/useCreateCase';
import useGetMatters from '@/api/queries/useGetMatters';
import { useGetPersonalMatter } from '@/api/queries/useGetPersonalMatter';
import FileIcon from '@/assets/guide/homepage-guides/file-icon.svg?react';
import HammerAndWrenchIcon from '@/assets/guide/homepage-guides/hammer-and-wrench-icon.svg?react';
import { CaseCreationMatterSelectionModal } from '@/components/molecules/Modals/Onboarding/CaseCreationOnboardingModal';
import { MatterType, OrgUserWithMetadata } from '@/types';

interface ChecklistItem {
  id: ChecklistStep;
  title: string;
  description: string;
  icon: React.ReactNode;
  action: () => void;
  actionText: string;
  hidden?: boolean;
}

const ChecklistStepRow = ({ item, index }: { item: ChecklistItem; index: number }) => {
  return (
    <motion.div
      key={item.id}
      onClick={() => item.action()}
      className="group relative cursor-pointer overflow-hidden rounded-lg border border-l-4 border-gray-100 bg-white shadow-sm"
      data-testid={`checklist-item-${item.id}`}
      initial={{ opacity: 0, x: -50 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0 }}
      whileHover={{
        y: -5,
        boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
        transition: { duration: 0.2 },
      }}
      transition={{
        duration: 0.4,
        delay: index * 0.15,
        type: 'spring',
        stiffness: 100,
      }}
    >
      <div className="group-hover:bg-opacity-80 absolute top-0 right-0 flex h-full w-12 items-center justify-center bg-purple-100">
        <FontAwesomeIcon icon={faArrowRight} className="h-4 w-4 text-purple-600 transition-transform duration-200" />
      </div>

      <div className="flex items-start p-4 pr-16">
        <div className="relative mr-3 flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full bg-purple-100 transition-shadow duration-200 group-hover:shadow-sm">
          <span className="absolute -top-1 -left-1 flex h-4 w-4 items-center justify-center rounded-full bg-gray-700 text-[10px] font-bold text-white">
            {index + 1}
          </span>
          {item.icon}
        </div>

        <div className="flex-grow">
          <div className="flex items-center justify-between">
            <h3 className="font-medium">{item.title}</h3>
          </div>
          <p className="mt-1 text-xs">{item.description}</p>
        </div>
      </div>
    </motion.div>
  );
};

export const Checklist = ({ user }: { user: OrgUserWithMetadata }) => {
  const navigate = useNavigate();
  const { mutate: createCase } = useCreateCase(MatterType.Personal, { state: { fromChecklistDocumentUpload: true } });
  const { data: personalMatter } = useGetPersonalMatter();
  const { data: matters } = useGetMatters({ paginated: 'false' });
  const [isCaseCreationModalOpen, setIsCaseCreationModalOpen] = useState(false);

  const allItems: ChecklistItem[] = useMemo(() => {
    return [
      {
        id: ChecklistStep.UploadDocuments,
        title: 'Upload your first documents',
        description: 'Analyse a document in your Personal Matter',
        icon: <FileIcon className="h-5 w-5" />,
        action: () => {
          if (personalMatter) {
            createCase(personalMatter.id);
            trackCustomEvent('Clicked Upload Documents Checklist Step', {
              userId: user.id,
            });
          }
        },
        actionText: 'Upload documents',
      },
      {
        id: ChecklistStep.CreateMatter,
        title: 'Create your first matter',
        description: 'Create a matter to collaborate with your colleagues',
        icon: <HammerAndWrenchIcon className="h-5 w-5" />,
        action: () => {
          trackCustomEvent('Clicked Create Matter Checklist Step', {
            userId: user.id,
          });
          navigate('/app/chronos/matter-creator', { state: { fromChecklistMatterCreation: true } });
        },
        actionText: 'View matter',
        hidden: Boolean(matters != undefined && matters.matters.length),
      },
      {
        id: ChecklistStep.CreateCase,
        title: 'Submit your first collaborative case',
        description: 'Submit a case in a shared matter',
        icon: <HammerAndWrenchIcon className="h-5 w-5" />,
        action: () => {
          trackCustomEvent('Clicked Create Case Checklist Step', {
            userId: user.id,
          });
          setIsCaseCreationModalOpen(true);
        },
        actionText: 'Create case',
      },
    ];
  }, [matters, personalMatter]);

  // Only show items on the user onboardingChecklistItems
  const items = allItems.filter((item) => {
    if (!user.onboardingChecklistItems) return true;
    return user.onboardingChecklistItems.includes(item.id);
  });

  if (items.length === 0) {
    return null;
  }

  const progressPercentage =
    user.onboardingChecklistItems === undefined
      ? 0
      : ((allItems.length - user.onboardingChecklistItems.length) / allItems.length) * 100;

  return (
    <div className="flex w-full flex-col gap-2">
      <div className="mb-2 border border-x-0 border-b-0 px-4 pt-2">
        <div className="flex items-center justify-between">
          <h2 className="text-base font-semibold">To-do</h2>
          <div className="flex items-center text-sm text-gray-600">
            <span className="mr-2">{Math.round(progressPercentage)}% complete</span>
            {progressPercentage === 100 && <FontAwesomeIcon icon={faCheckCircle} className="text-green-500" />}
          </div>
        </div>

        {/* Progress bar */}
        <div className="my-2 h-2 w-full overflow-hidden rounded-full bg-gray-200">
          <motion.div
            className="h-full bg-purple-500"
            initial={{ width: 0 }}
            animate={{ width: `${progressPercentage}%` }}
            transition={{ duration: 0.5, ease: 'easeOut' }}
          />
        </div>
      </div>

      <div className="flex w-full flex-col gap-4 px-4 pb-4" data-testid="onboarding-checklist">
        <AnimatePresence mode="popLayout">
          {items.map(
            (item, index) => item.hidden != true && <ChecklistStepRow key={item.id} item={item} index={index} />,
          )}
        </AnimatePresence>
      </div>
      <CaseCreationMatterSelectionModal
        matters={matters?.matters ?? []}
        isOpen={isCaseCreationModalOpen}
        setIsOpen={setIsCaseCreationModalOpen}
      />
    </div>
  );
};

export default Checklist;
