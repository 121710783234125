import { BoundingBoxWithId, Fact, Location } from '@/types';

export const processFactHighlights = (facts: Fact[], docId: string) => {
  const coordinates: BoundingBoxWithId[] = [];
  const highlightFactMap = new Map<string, number>();

  facts.forEach((fact, index) => {
    highlightFactMap.set(fact.id, index);
    fact.locations.forEach((location) => {
      // Only process the fact highlights for the current doc we are viewing
      if (location.docId === docId) {
        // Add the fact id to the coordinates
        coordinates.push(
          ...location.boundingBoxes.map((boundingBox) => ({
            ...boundingBox,
            id: fact.id,
          })),
        );
      }
    });
  });

  return { coordinates, highlightFactMap };
};

export const sortFactsByAppearance = (facts: Fact[], docId: string) => {
  facts.sort((a: Fact, b: Fact) => {
    const aLocation = a.locations.find((location: Location) => location.docId === docId);
    const bLocation = b.locations.find((location: Location) => location.docId === docId);
    if (!aLocation || !bLocation) return 0;

    if (!aLocation.boundingBoxes?.length) return 1;
    if (!bLocation.boundingBoxes?.length) return -1;

    const pageCompare = aLocation.boundingBoxes[0].pageNumber - bLocation.boundingBoxes[0].pageNumber;
    if (pageCompare !== 0) return pageCompare;

    return aLocation.boundingBoxes[0].top - bLocation.boundingBoxes[0].top;
  });

  return facts;
};
