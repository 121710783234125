import Chip from '@/components/atoms/chip';
import { PlainTextContent } from '@/helpers/textRendering';
import { Fact, Location } from '@/types';

interface Props {
  fact: Fact;
  showSourceText?: boolean;
  fullDocRow?: boolean;
  gotoDocReference: (docId: string, factId: string, pageNumber: number) => void;
}

const FactDocumentDetails = ({ fact, showSourceText = true, fullDocRow = true, gotoDocReference }: Props) => {
  const uploadDate = new Date(fact.createdDate);

  return (
    <>
      <div className="mb-1 px-2 font-semibold text-gray-800">Documents</div>
      <div>
        {fact.locations.map((factLocation: Location, index: number) => {
          const pageNumber = factLocation?.boundingBoxes?.length > 0 ? factLocation?.boundingBoxes?.[0]?.pageNumber : 1;
          const file = factLocation.doc?.file?.name;
          const documentType = factLocation.doc?.type;
          const documentDateText = factLocation.doc?.dateText;

          return (
            <div
              key={`${factLocation.docId}-${index}`}
              className={`flex items-baseline justify-between gap-4 px-2 pb-1 ${showSourceText ? 'border border-x-0 border-t-0 border-dashed' : ''}`}
            >
              <div className="flex w-full items-baseline gap-4">
                <div
                  key={factLocation.docId}
                  className="cursor-pointer overflow-hidden text-ellipsis text-blue-500 hover:text-blue-600 hover:underline"
                  onClick={() => gotoDocReference(factLocation.docId, fact.id, pageNumber)}
                >
                  {file}
                </div>

                <Chip className={`bg-brandTertiary/30 ${!fullDocRow ? 'ml-auto' : ''}`}>{documentType}</Chip>

                {fullDocRow && documentDateText ? (
                  <span className="text-xs text-gray-600">({documentDateText})</span>
                ) : null}
              </div>

              {fullDocRow && (
                <div className="text-xs whitespace-nowrap text-gray-600 italic">
                  (uploaded: {`${uploadDate.getDate()}/${uploadDate.getMonth() + 1}/${uploadDate.getFullYear()}`})
                </div>
              )}
            </div>
          );
        })}
      </div>

      {showSourceText && (
        <div>
          <div className="mt-3 mb-1 px-2 font-semibold text-gray-800">Source Text</div>
          <div className="px-2 text-gray-500">
            <PlainTextContent html={fact.sourceText} maxLength={600} />
          </div>
        </div>
      )}
    </>
  );
};

export default FactDocumentDetails;
