import { FileText, Clock, Bot, Shield } from 'lucide-react';

const downloadableDocs = [
  {
    title: 'Service Overview',
    url: 'overview.docx',
  },
  {
    title: 'Frequently Asked Questions',
    url: 'faqs.docx',
  },
];

const videos = [
  {
    title: 'Fact Extraction',
    url: 'https://www.loom.com/share/0af22b03df6544f7a439b7981e723a98?sid=98168b1d-a9ff-48e3-a434-11b0cbd85bf2',
    description: 'Extracting Facts from Documents',
    icon: <FileText className="text-brandSecondary h-5 w-5" />,
  },
  {
    title: 'Chronologies',
    url: 'https://www.loom.com/share/299eabca82464476ab9be63ff4fcd506?sid=f2c38130-d7b8-48a6-86e4-29cabd5aae85',
    description: 'Building Chronologies',
    icon: <Clock className="text-brandSecondary h-5 w-5" />,
  },
  {
    title: 'Kim',
    url: 'https://www.loom.com/share/9e5e9396831743c18b756cb6ebde62f8?sid=ae61ac56-35d3-4d71-b3aa-f2f2972a3860',
    description: 'Question Answering',
    icon: <Bot className="text-brandSecondary h-5 w-5" />,
  },
  {
    title: 'Inconsistency Detection',
    url: 'https://www.loom.com/share/5bacc21864154f1cafdc08755cb69faa?sid=966a0001-a8aa-4a2d-b99d-f4c31e4b22e4',
    description: 'Detecting Inconsistencies',
    icon: <Shield className="text-brandSecondary h-5 w-5" />,
  },
];

const formatEmailDownloadContent = (userEmail: string, overviewBase64: string, faqsBase64: string) => `
From: ${userEmail}
To: 
Subject: Wexler: New Litigation AI Tool
MIME-Version: 1.0
Content-Type: multipart/mixed; boundary="boundary123"

--boundary123
Content-Type: text/plain; charset=UTF-8

Dear [Client],

We have onboarded a platform called wexler.ai (https://wexler.ai) to assist with our litigation workflows. It reads through documents and extracts the key facts, creates chronologies and allows you to ask and answer questions. The security details are found at https://trust.wexler.ai, and I have attached two documents explaining the product in more detail.

Videos of the platform:
${videos.map((video) => `- ${video.title}: ${video.url}`).join('\n')}

Let me know if you have any further questions.

Kind regards,

--boundary123
Content-Type: application/vnd.openxmlformats-officedocument.wordprocessingml.document
Content-Disposition: attachment; filename="wexler_overview.docx"
Content-Transfer-Encoding: base64

${overviewBase64}

--boundary123
Content-Type: application/vnd.openxmlformats-officedocument.wordprocessingml.document
Content-Disposition: attachment; filename="wexler_faqs.docx"
Content-Transfer-Encoding: base64

${faqsBase64}

--boundary123--
`;

const emailClipboardContent = `
Dear [Client],
We have onboarded a platform called wexler.ai (https://wexler.ai) to assist with our litigation workflows. It reads through documents and extracts the key facts, creates chronologies and allows you to ask and answer questions. The security details are found at https://trust.wexler.ai, and I have attached two documents explaining the product in more detail.

Videos of the platform:
${videos.map((video) => `- ${video.title}: ${video.url}`).join('\n')}

Let me know if you have any further questions.

Kind regards,
`;

export { formatEmailDownloadContent, emailClipboardContent, downloadableDocs, videos };
