import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCheckCircle, faCircleExclamation, faEye, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation, useNavigate } from 'react-router-dom';

import { useGetCase } from '../../../../../../../api/queries/useGetCase';
import { Button } from '../../../../../../../components/atoms/new-button';
import { PIPELINE_PROCESSING_STATUS } from '../../../../../../../constants/pipelineStatus';
import useUpdateDoc from '@/api/mutations/useUpdateDoc';
import EditableField from '@/components/molecules/EditableField';
import formatDate from '@/helpers/formatDate';
import { Doc } from '@/types';

const DocInfoChip = ({ label, value, loading }: { label: string; value: string | number | null; loading: boolean }) => {
  if (loading) {
    return <div className="h-6 w-36 animate-pulse rounded-xl bg-gray-300"></div>;
  }
  if (value === null) {
    return <></>;
  }
  return (
    <p className="rounded border px-2 text-gray-500 shadow-sm">
      <b>{label}:</b> {value || 0}
    </p>
  );
};

const DocumentRow = ({ doc, caseId, setTitle }: { doc: Doc; caseId: string; setTitle: (title: string) => void }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { mutate: updateDocFileName, isLoading: isUpdatingFileName } = useUpdateDoc({ analyticsLocation: 'CaseDoc' });
  const { mutate: updateDocAuthor, isLoading: isUpdatingAuthor } = useUpdateDoc({ analyticsLocation: 'CaseDoc' });
  const { mutate: updateDocDate, isLoading: isUpdatingDate } = useUpdateDoc({ analyticsLocation: 'CaseDoc' });

  const { data: caseData } = useGetCase(caseId ?? '');

  const isCaseReprocessing =
    caseData?.runs?.at(0) && caseData.runs.length > 1 && PIPELINE_PROCESSING_STATUS.includes(caseData.runs[0].status);

  const handleViewDoc = (docId: string, factTotal: number | null) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('docId', docId);
    searchParams.set('page', '0');
    searchParams.set('factTotal', factTotal?.toString() || '0');
    navigate(location.pathname + '?' + searchParams.toString());
    setTitle(doc.file.name);
  };

  const handleUpdateDocFileName = (value: string) => {
    updateDocFileName({ caseId, docId: doc.id, field: 'filename', value });
  };

  const handleUpdateDocAuthor = (value: string) => {
    updateDocAuthor({ caseId, docId: doc.id, field: 'author', value });
  };

  const handleUpdateDocDate = (value: string) => {
    updateDocDate({ caseId, docId: doc.id, field: 'dateText', value });
  };

  const processState: { icon: IconProp; text: string; iconColor: string } = doc.isProcessed
    ? { icon: faCheckCircle, text: 'Processed', iconColor: 'text-green-500' }
    : isCaseReprocessing
      ? { icon: faSpinner, text: 'Processing...', iconColor: 'text-gray-500' }
      : { icon: faCircleExclamation, text: 'Not Processed', iconColor: 'text-red-500' };

  const formatAuthorString = (author: string | undefined | null) => {
    if (author === undefined || author === null) {
      return '';
    }

    if (author.length < 50) {
      return author;
    }

    const authors = author.split(', ');
    let trimmedAuthorString = '';

    for (const authorItem of authors) {
      trimmedAuthorString += authorItem;

      if (trimmedAuthorString.length >= 50) {
        return `${trimmedAuthorString} ...`;
      }

      trimmedAuthorString += ', ';
    }

    return `${author.slice(0, 50)} ...`;
  };

  return (
    <div key={doc.id} className="flex flex-col rounded-md border px-4 py-2 shadow">
      <div className="flex items-center justify-between">
        <div className="mb-2 w-2/3 text-sm font-semibold">
          <EditableField value={doc.file.name} onSave={handleUpdateDocFileName} isLoading={isUpdatingFileName} />
        </div>

        <div className="flex items-center justify-center gap-2">
          <span className="text-sm text-gray-500">{doc.file.extension.toUpperCase()}</span>
          <span className="bg-brandTertiary/20 mx-1 rounded-sm px-1 text-sm text-gray-600">{doc.type}</span>
        </div>
      </div>
      <div className="text-sm">
        <div className="flex items-center justify-between">
          <div className="flex w-10/12 flex-col leading-tight text-gray-700">
            <div className="mb-2 flex items-center gap-8">
              <div className="flex items-center gap-2">
                <span className="underline">Author:</span>
                <EditableField
                  value={formatAuthorString(doc.author) ?? ''}
                  onSave={handleUpdateDocAuthor}
                  isLoading={isUpdatingAuthor}
                />
              </div>

              <div className="flex items-center gap-2">
                <span className="underline">Date:</span>
                <EditableField
                  value={formatDate(doc.date ?? '')}
                  onSave={handleUpdateDocDate}
                  isLoading={isUpdatingDate}
                />
              </div>
            </div>

            <div className="mb-2 flex items-center gap-2">
              {/* <EditableField value={doc.summary} onSave={handleUpdateDocDate} isLoading={isUpdatingDate} /> */}
              {doc.summary}
            </div>
          </div>

          <div className="mt-2 flex items-center gap-2">
            <DocInfoChip label="Facts" value={doc.factsCount} loading={false} />
          </div>
        </div>

        <div className="flex justify-between pt-1 pb-1">
          <div className="flex items-center">
            <Button variant="primary" onClick={() => handleViewDoc(doc.id, doc.factsCount || 0)}>
              View Document
            </Button>
          </div>

          <div className="flex flex-col gap-1">
            <div className="text-xs text-gray-700">Uploaded: {formatDate(doc.createdDate)}</div>
            <div className="flex justify-end text-xs text-gray-700">
              <div className="flex items-center gap-1">
                <FontAwesomeIcon
                  icon={processState.icon}
                  className={`${processState.iconColor} ${processState.icon === faSpinner ? 'animate-spin' : ''}`}
                />
                {processState.text}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentRow;
