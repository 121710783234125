import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const StepIndicator = ({ step }: { step: number }) => (
  <div className="flex w-full items-center justify-end space-x-4 text-sm">
    <div className={`flex items-center ${step === 0 ? 'text-brandSecondary' : 'text-gray-400'}`}>
      <div
        className={`flex h-6 w-6 items-center justify-center rounded-full border-2 border-brandSecondary bg-brandSecondary text-white`}
      >
        {step === 0 ? '1' : <FontAwesomeIcon icon={faCheck} className="h-3 w-3" />}
      </div>
      <span className="ml-2 font-medium">Required Info</span>
    </div>
    <div className="h-0.5 w-full min-w-1 max-w-16 bg-gray-300" />
    <div className={`flex items-center ${step === 1 ? 'text-brandSecondary' : 'text-gray-400'}`}>
      <div
        className={`h-6 w-6 rounded-full border-2 ${step === 1 ? 'border-brandSecondary bg-brandSecondary text-white' : 'border-gray-300'} flex items-center justify-center`}
      >
        2
      </div>
      <span className="ml-2 font-medium">Optional Info</span>
    </div>
  </div>
);
