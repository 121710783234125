import { useState } from 'react';

import { faArrowRight, faPenToSquare } from '@fortawesome/free-solid-svg-icons';

import { OnboardingModalBase, StepItem } from './OnboardingModalBase';

export const MatterCreationOnboardingModal = () => {
  const [isOpen, setIsOpen] = useState(true);

  const steps: StepItem[] = [
    {
      icon: faPenToSquare,
      title: 'Fill out matter details',
      description: 'Add a matter name and description to describe the content of the matter.',
    },
    {
      icon: faArrowRight,
      title: 'Add colleagues',
      description:
        'Add any colleagues that you want to share this matter with. They will be able to see all cases within this matter.',
    },
  ];

  return (
    <OnboardingModalBase
      testId="matter-creation-onboarding-modal"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title="Welcome to matter creation!"
      icon={faPenToSquare}
      introduction={
        <p className="mb-4 text-sm text-gray-700">
          Matters are the top level container in Wexler, they are used to group together cases (collections of
          documents). For example you could create the matter <b>Smith v. Jones</b>, and then anything relating to Smith
          v. Jones should live inside this matter.
        </p>
      }
      steps={steps}
      tip={{
        text: 'Download the client email template if you want to send information about Wexler to your clients.',
        color: 'blue',
      }}
    />
  );
};
