import { useState } from 'react';

import NewMatters from './components/NewMatters';
import UserMatters from './components/UserMatters';
import UsersTable from './components/UsersTable';
import { useAddUserToMatters } from '@/api/mutations/useAddUserToMatters';
import { useRemoveUserFromMatters } from '@/api/mutations/useRemoveUserFromMatters';
import useFetchOrgUsers from '@/api/queries/analyticsQueries/useFetchOrgUsers';
import useGetMatters from '@/api/queries/useGetMatters';
import Modal from '@/components/molecules/Modals/CustomModal';
import PageWrapper from '@/components/molecules/PageWrappers/PageWrapper';
import { Matter, OrgUser, OrgUserWithMetadata } from '@/types';

const Users = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<OrgUserWithMetadata | OrgUser | null>(null);

  const [addMatterModalOpen, setAddMatterModalOpen] = useState(false);
  const [matterSearchQuery, setMatterSearchQuery] = useState('');
  const [selectedMatters, setSelectedMatters] = useState<Set<string>>(new Set());

  const { data: mattersData, isLoading: isLoadingMatters } = useGetMatters({
    paginated: 'false',
  });
  const { data: usersData, isFetching: isFetchingUsers } = useFetchOrgUsers();
  const { mutate: addUserToMatters } = useAddUserToMatters();
  const { mutate: removeUserFromMatters } = useRemoveUserFromMatters();

  const getAvailableMatters = (): Matter[] => {
    if (!selectedUser || !mattersData?.matters) return [];
    // Get IDs of matters user already has access to and filter them out
    const userMatterIds = new Set(selectedUser.matters?.map((matter) => matter.id) || []);
    return mattersData.matters.filter((matter: Matter) => !userMatterIds.has(matter.id));
  };

  const filteredMatters = getAvailableMatters().filter(
    (matter: Matter) =>
      matter.name.toLowerCase().includes(matterSearchQuery.toLowerCase()) ||
      (matter.code && matter.code.toLowerCase().includes(matterSearchQuery.toLowerCase())) ||
      (matter.description && matter.description.toLowerCase().includes(matterSearchQuery.toLowerCase())),
  );

  // Handle matter selection
  const toggleMatterSelection = (matterId: string) => {
    const newSelectedMatters = new Set(selectedMatters);
    if (newSelectedMatters.has(matterId)) {
      newSelectedMatters.delete(matterId);
    } else {
      newSelectedMatters.add(matterId);
    }
    setSelectedMatters(newSelectedMatters);
  };

  // Handle adding selected matters to user
  const handleAddUserToMatters = () => {
    if (!selectedUser) return;

    addUserToMatters({ userId: selectedUser.id, matterIds: Array.from(selectedMatters) });

    // Close the modal and reset selection
    setAddMatterModalOpen(false);
    setSelectedMatters(new Set());
    setMatterSearchQuery('');
  };

  const handleRemoveUserFromMatter = ({ userId, matterIds }: { userId: string; matterIds: string[] }) => {
    if (!selectedUser) return;

    removeUserFromMatters({ userId, matterIds });
    // Remove the matter from the selected user
    const updatedMatters = selectedUser.matters?.filter((matter) => !matterIds.includes(matter.id));

    setSelectedUser({ ...selectedUser, matters: updatedMatters });
  };

  // Handler for opening the user matters modal
  const handleOpenUserMattersModal = (user: OrgUserWithMetadata | OrgUser) => {
    setSelectedUser(user);
    setModalOpen(true);
  };

  return (
    <PageWrapper className="bg-gray-50">
      <div className="flex w-full flex-col items-start justify-start px-4 md:px-6 lg:px-8">
        <div className="mb-8 flex w-full flex-col gap-6">
          <div className="w-full rounded-xl bg-white p-6 shadow-sm">
            <div className="flex flex-col gap-6">
              <div className="flex flex-col">
                <h1 className="text-xl font-semibold text-gray-800">Users</h1>
                <p className="text-sm text-gray-600">See all your users and manage their access.</p>
              </div>
            </div>
          </div>

          <UsersTable
            users={usersData || []}
            isLoading={isFetchingUsers}
            onViewUserMatters={handleOpenUserMattersModal}
          />

          {/* Current User Matters Modal */}
          <Modal
            isOpen={modalOpen}
            handleClose={() => setModalOpen(false)}
            content={
              <UserMatters
                selectedUser={selectedUser}
                onAddToMatters={() => setAddMatterModalOpen(true)}
                removeUserFromMatters={handleRemoveUserFromMatter}
              />
            }
          />

          {/* Add To Matters Modal */}
          <Modal
            isOpen={addMatterModalOpen}
            handleClose={() => {
              setAddMatterModalOpen(false);
              setSelectedMatters(new Set());
              setMatterSearchQuery('');
            }}
            content={
              <NewMatters
                selectedUser={selectedUser}
                filteredMatters={filteredMatters}
                isLoadingMatters={isLoadingMatters}
                selectedMatters={selectedMatters}
                onToggleMatterSelection={toggleMatterSelection}
                onAddMattersToUser={handleAddUserToMatters}
                onCancel={() => {
                  setAddMatterModalOpen(false);
                  setSelectedMatters(new Set());
                  setMatterSearchQuery('');
                }}
                matterSearchQuery={matterSearchQuery}
                onSearchQueryChange={(query) => setMatterSearchQuery(query)}
              />
            }
          />
        </div>
      </div>
    </PageWrapper>
  );
};

export default Users;
