import { useMutation } from 'react-query';

import { APIBaseChronos } from '@/api/hosts'; // Ensure this path is correct
import useGetFetchConfig, { FetchMethod } from '@/api/useGetFetchConfig'; // Ensure this path is correct

interface UserUpdateMatterProps {
  name?: string;
  description?: string;
  code?: string;
  userIds?: string[];
}

const useUpdateMatter = (id: string) => {
  const { getFetchConfig } = useGetFetchConfig();

  const updateMatter = async (data: UserUpdateMatterProps) => {
    const fetchConfig = getFetchConfig({
      method: FetchMethod.PATCH,
      data,
    });

    const response = await fetch(`${APIBaseChronos}/api/matters/${id}`, fetchConfig);
    if (!response.ok) {
      throw new Error('Error updating matter');
    }
    return await response.json();
  };

  return useMutation({
    mutationFn: updateMatter,
  });
};

export default useUpdateMatter;
