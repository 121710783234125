import { useMutation, useQueryClient } from 'react-query';

import { Inconsistency } from '../../types';
import { APIBaseChronos } from '../hosts';
import { QueryEntity } from '../types';
import useGetFetchConfig, { FetchMethod } from '../useGetFetchConfig';

interface UseUpdateInconsistency {
  inconsistencyId: string;
  caseId: string;
  body: Partial<Inconsistency>;
}

export const useUpdateInconsistency = () => {
  const { getFetchConfig } = useGetFetchConfig();
  const queryClient = useQueryClient();

  return useMutation(async ({ inconsistencyId, caseId, body }: UseUpdateInconsistency) => {
    const fetchConfig = getFetchConfig({ method: FetchMethod.PATCH, data: body });
    return fetch(`${APIBaseChronos}/api/case/${caseId}/inconsistency/${inconsistencyId}`, fetchConfig).then(() => {
      queryClient.invalidateQueries({ queryKey: [QueryEntity.CaseInconsistencies, { caseId }] });
    });
  });
};
