import { useQuery } from 'react-query';

import { APIBaseChronos } from '@/api/hosts';
import { QueryEntity } from '@/api/types';
import useGetFetchConfig from '@/api/useGetFetchConfig';
import { RecentCase } from '@/types';

const useGetRecentCases = () => {
  const { fetchConfigGET } = useGetFetchConfig();

  return useQuery<RecentCase[]>(
    [QueryEntity.RecentCases],
    async () => {
      const url = new URL(`${APIBaseChronos}/api/user/recent-cases`);

      try {
        const res = await fetch(url.toString(), {
          ...fetchConfigGET,
          method: 'GET',
        });
        return await res.json();
      } catch (err) {
        console.error(err);
      }
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

export default useGetRecentCases;
