import { useQuery } from 'react-query';

import { Inconsistency } from '../../types';
import { APIBaseChronos } from '@/api/hosts';
import { QueryEntity } from '@/api/types';
import { PaginatedResponse } from '@/api/types/queries';
import useGetFetchConfig from '@/api/useGetFetchConfig';

export const useGetInconsistencyReport = ({
  caseId,
  reportId,
  page,
  pageSize,
}: {
  caseId: string;
  reportId: string;
  page: number;
  pageSize: number;
}) => {
  const { fetchConfigGET } = useGetFetchConfig();

  return useQuery<PaginatedResponse<Inconsistency>>(
    [QueryEntity.InconsistencyReport, { reportId }, { page, pageSize }],
    async () => {
      const res = await fetch(
        `${APIBaseChronos}/api/case/${caseId}/inconsistency/report/${reportId}?page=${page}&pageSize=${pageSize}`,
        fetchConfigGET,
      );

      return res.json();
    },
    { refetchOnWindowFocus: false },
  );
};
