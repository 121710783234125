import { useEffect, useState } from 'react';

import { faFileShield, faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';

import InconsistencyFilterPopup from './FilterPopup';
import { trackCustomEvent } from '../../../../../../analytics/Mixpanel';
import { useCreateInconsistencyReport } from '../../../../../../api/mutations/useCreateInconsistencyReport';
import { ShownSelectedCountsHeader } from '../../components/ShownSelectedCountsHeader';
import DataViewToolbar from '../../components/TableToolbar';
import Button from '@/components/atoms/Button';
import ExpandableButton from '@/components/molecules/ExpandableButton';
import CustomModal from '@/components/molecules/Modals/SettingsModal';
import SearchBox from '@/components/molecules/SearchBox';
import Pagination from '@/components/organisms/Pagination';
import { DocIds, InconsistencyFilters } from '@/types';

interface InconsistenciesEditorToolbarProps {
  caseId: string;
  selectedInconsistencyIds: string[];
  allInconsistencyIds: string[];
  globalFilter: string;
  setGlobalFilter: (value: string) => void;
  onSearchCall: () => void;
  clearSearch: () => void;
  currentPage: number;
  totalPages: number;
  prevPage: () => void;
  nextPage: () => void;
  goToPage: (page: number) => void;
  allDocuments: DocIds[];
  activeFilters?: InconsistencyFilters;
  setFilters?: (v: InconsistencyFilters) => void;
  handleToggleAll: (checked: boolean) => void;
}

const InconsistenciesEditorToolbar = ({
  caseId,
  allInconsistencyIds,
  selectedInconsistencyIds,
  globalFilter,
  setGlobalFilter,
  onSearchCall,
  clearSearch,
  currentPage,
  totalPages,
  prevPage,
  nextPage,
  goToPage,
  allDocuments,
  activeFilters,
  setFilters,
  handleToggleAll,
}: InconsistenciesEditorToolbarProps) => {
  const [activeFilterCount, setActiveFilterCount] = useState(0);
  const [filtersModalOpen, setFiltersModalOpen] = useState(false);

  const { mutateAsync: createInconsistencyReport } = useCreateInconsistencyReport(caseId);

  const navigate = useNavigate();

  useEffect(() => {
    if (activeFilters) {
      let count = 0;
      Object.keys(activeFilters).forEach((key) => {
        const filterValues = activeFilters[key as keyof InconsistencyFilters];
        if (filterValues && Array.isArray(filterValues) && filterValues.length > 0) {
          count += 1;
        }
      });
      setActiveFilterCount(count);
    }
  }, [activeFilters]);

  return (
    <>
      <DataViewToolbar
        longTitleSection
        titleSection={
          <ShownSelectedCountsHeader
            header="Inconsistencies"
            resultsLength={allInconsistencyIds.length}
            resultsIncludedLength={selectedInconsistencyIds.length}
            handleToggleAll={handleToggleAll}
          />
        }
        pagination={
          <Pagination
            canGetPrevPage={currentPage > 0}
            canGetNextPage={currentPage < totalPages - 1}
            prevPage={prevPage}
            nextPage={nextPage}
            currentPage={currentPage}
            noOfPages={totalPages}
            goToPage={goToPage}
          />
        }
        searchBar={
          <SearchBox
            value={globalFilter}
            placeholder={`Search...`}
            onChange={setGlobalFilter}
            onSearchCall={onSearchCall}
            clearSearch={clearSearch}
          />
        }
        actionButtons={
          <Button
            icon={<FontAwesomeIcon icon={faFileShield} className="pr-2" />}
            disabled={selectedInconsistencyIds.length === 0}
            onClick={async () => {
              const report = await createInconsistencyReport(selectedInconsistencyIds);
              trackCustomEvent('Inconsistency report created', { caseId });
              navigate(
                `/app/chronos/case-editor/data/inconsistency-report/view?caseId=${caseId}&reportId=${report.id}&page=1&title=${report.title}`,
              );
            }}
            type="secondary"
            size="xs"
            rounded="base"
            className="px-2 py-2 shadow"
            text="Create Report"
          />
        }
        filterButtons={
          <>
            <div className="relative">
              <ExpandableButton icon={faFilter} onClick={() => setFiltersModalOpen(true)}>
                <p className="text-sm">Filters</p>
              </ExpandableButton>
              {activeFilterCount > 0 ? (
                <div className="bg-brandTertiary/20 absolute top-[-8px] right-[-8px] rounded-full border px-[4px] text-xs">
                  {activeFilterCount}
                </div>
              ) : null}
            </div>
          </>
        }
      />

      {activeFilters && setFilters && (
        <CustomModal
          title={
            <div className="flex items-center gap-2">
              <FontAwesomeIcon icon={faFilter} className="text-gray-600" />
              Filters
            </div>
          }
          content={
            <InconsistencyFilterPopup
              activeFilters={activeFilters}
              allDocuments={allDocuments}
              setFilters={setFilters}
              handleClose={() => setFiltersModalOpen(false)}
            />
          }
          isOpen={filtersModalOpen}
          size="small"
          handleClose={() => setFiltersModalOpen(false)}
        />
      )}
    </>
  );
};

export default InconsistenciesEditorToolbar;
