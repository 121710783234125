import { useMutation } from 'react-query';

import { APIBaseChronos } from '@/api/hosts';
import { CaseCreationRequestDetails } from '@/api/types/mutations';
import useGetFetchConfig, { FetchMethod } from '@/api/useGetFetchConfig';

export const useProcessCase = () => {
  const { getFetchConfig } = useGetFetchConfig();

  return useMutation(
    async ({
      caseId,
      name,
      type,
      legalIssues,
      parties,
      disputeStatus,
      keyTimePeriod,
      keyContext,
    }: CaseCreationRequestDetails) => {
      const fetchConfig = getFetchConfig({
        method: FetchMethod.POST,
        data: {
          name,
          type,
          legalIssues,
          parties,
          disputeStatus,
          keyTimePeriod,
          keyContext,
        },
      });

      const response = await fetch(`${APIBaseChronos}/api/case/${caseId}/submit`, fetchConfig);
      if (!response.ok) {
        throw new Error('Failed to submit case');
      }
    },
  );
};
