import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AccordionContext = React.createContext({
  requestHeightUpdate: () => {},
});

const Accordion = ({
  title,
  content,
  icon,
  id,
  className,
  outerOpen,
}: {
  title: string | React.ReactNode;
  content: string | React.ReactNode;
  icon?: React.ReactNode;
  id?: string;
  className?: string;
  outerOpen?: (open: boolean) => void;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [maxHeight, setMaxHeight] = useState<string | undefined>('0px');
  const contentRef = useRef<HTMLDivElement>(null);
  const { requestHeightUpdate } = useContext(AccordionContext);

  const updateMaxHeight = useCallback(() => {
    if (contentRef.current) {
      const height = isOpen ? `${contentRef.current.scrollHeight}px` : '0px';
      requestHeightUpdate();
      setMaxHeight(height);
    }
  }, [isOpen, requestHeightUpdate]);

  useEffect(() => {
    const contentElement = contentRef.current;
    if (!contentElement) return;

    const resizeObserver = new ResizeObserver(updateMaxHeight);
    resizeObserver.observe(contentElement);

    return () => {
      resizeObserver.unobserve(contentElement);
    };
  }, [updateMaxHeight]);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
    if (!outerOpen) return;
    outerOpen(!isOpen);
  };

  return (
    <AccordionContext.Provider value={{ requestHeightUpdate: updateMaxHeight }}>
      <div className={`${className ? className : 'border-b bg-gray-50 p-4'}`}>
        <div
          data-open={isOpen}
          id={id}
          className="flex items-center justify-between hover:cursor-pointer"
          onClick={toggleAccordion}
        >
          {<h3 className="w-full pr-2 font-semibold text-gray-800">{title}</h3>}
          {icon ? (
            icon
          ) : (
            <FontAwesomeIcon
              icon={faChevronDown}
              className="mr-4 text-gray-500 transition-transform duration-300"
              style={{ transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)' }}
            />
          )}
        </div>
        <div
          ref={contentRef}
          className="transition-max-height overflow-hidden duration-500 ease-in-out"
          style={{ maxHeight }}
        >
          {content}
        </div>
      </div>
    </AccordionContext.Provider>
  );
};

export default Accordion;
