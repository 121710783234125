import { useQuery } from 'react-query';

import { APIBaseChronos } from '@/api/hosts';
import { QueryEntity } from '@/api/types';
import useGetFetchConfig from '@/api/useGetFetchConfig';
import { Case } from '@/types';

export const useGetCaseIsPendingUpload = (caseId: string | undefined) => {
  const { fetchConfigGET } = useGetFetchConfig();

  return useQuery<Boolean>([QueryEntity.CaseIsPendingUpload, caseId], async () => {
    try {
      const response = await fetch(`${APIBaseChronos}/api/case/${caseId}`, fetchConfigGET);
      const data: Case = await response.json();
      return data.isPendingUpload;
    } catch {
      return false;
    }
  });
};
