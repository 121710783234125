import { useRef, useState } from 'react';

import { ILottie } from '@lottielab/lottie-player';

import LottieAnimation from './Lottie';
import CopyAnimation from '@/assets/animations/Copy.json';

interface CopyProps {
  copyHandler: () => void;
  variant?: 'icon' | 'chip';
}

const Copy = ({ copyHandler, variant = 'chip' }: CopyProps) => {
  const [copyClicked, setCopyClicked] = useState(false);
  const animationRef = useRef<ILottie>(null);
  const handleCopyClick = () => {
    setCopyClicked(true);
    copyHandler();
    setTimeout(() => {
      setCopyClicked(false);
      animationRef.current?.stop();
    }, 2000);
  };

  if (variant === 'icon') {
    return (
      <div onClick={handleCopyClick} className="h-4 w-4 hover:cursor-pointer">
        <LottieAnimation
          lottieRef={animationRef}
          playing={copyClicked}
          autoplay={copyClicked}
          animationData={CopyAnimation}
          loop={false}
          className="h-4 w-4"
        />
      </div>
    );
  }

  return (
    <div
      onClick={handleCopyClick}
      className="flex items-center gap-2 rounded-full border px-3 py-2 hover:cursor-pointer hover:bg-gray-200"
    >
      <LottieAnimation
        lottieRef={animationRef}
        playing={copyClicked}
        autoplay={copyClicked}
        animationData={CopyAnimation}
        loop={false}
        className="h-4 w-4"
      />

      <span className={`text-xs transition-all duration-100 ease-in-out ${copyClicked ? 'w-10' : 'w-7'}`}>
        {copyClicked ? 'Copied!' : 'Copy'}
      </span>
    </div>
  );
};

export default Copy;
