import { useQuery } from 'react-query';

import { APIBaseChronos } from '@/api/hosts';
import { QueryEntity } from '@/api/types';
import { FetchCaseDocsRes } from '@/api/types/queries';
import useGetFetchConfig from '@/api/useGetFetchConfig';

export const useGetDocs = (caseId: string | null, page: number, pageSize: number, searchQuery: string) => {
  const { fetchConfigGET } = useGetFetchConfig();

  return useQuery<FetchCaseDocsRes>(
    [QueryEntity.CaseDoc, { caseId }, { page, pageSize, searchQuery }],
    () =>
      fetch(
        `${APIBaseChronos}/api/case/${caseId}/docs?page=${page}&pageSize=${pageSize}&searchQuery=${searchQuery}`,
        fetchConfigGET,
      ).then((res) => {
        if (!res.ok) {
          throw new Error('Network response was not ok');
        }
        return res.json();
      }),
    { refetchOnWindowFocus: false },
  );
};
