import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'sonner';

import { QueryEntity } from '../types';
import { APIBaseChronos } from '@/api/hosts';
import useGetFetchConfig, { FetchMethod } from '@/api/useGetFetchConfig';

export const useAddUserToMatters = () => {
  const { getFetchConfig } = useGetFetchConfig();
  const queryClient = useQueryClient();

  return useMutation(
    async ({ userId, matterIds }: { userId: string; matterIds: string[] }) => {
      const fetchConfig = getFetchConfig({
        method: FetchMethod.PATCH,
        data: {
          userId: userId,
          matterIds: matterIds,
        },
      });
      await fetch(`${APIBaseChronos}/api/matters/add-user`, fetchConfig);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryEntity.Users, []]);
        toast.success('User added to matters');
      },
    },
  );
};
