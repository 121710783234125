export const referencingLinkRegex = /\[(\d+)\]\{([a-f0-9-]{36})\}/g;

export const cleanReferencesAndCopyToClipboard = (content: string | undefined) => {
  if (!content) return '';
  const cleanedText = content
    .replace(referencingLinkRegex, '')
    .replace(/ +/g, ' ')
    .replace(/<b>/g, '')
    .replace(/<\/b>/g, '')
    .replace(/<br>/g, '\n');

  navigator.clipboard.writeText(cleanedText);
};
