import { FetchConfig } from '../../useGetFetchConfig';
import { APIBaseChronos } from '@/api/hosts';

const downloadUsage = (startDate: Date | null, endDate: Date | null, fetchConfigGET: FetchConfig) => {
  const url = new URL(`${APIBaseChronos}/api/org/usage-data/download`);
  if (startDate) url.searchParams.append('start', startDate.toISOString());
  if (endDate) url.searchParams.append('end', endDate.toISOString());

  return fetch(url.toString(), {
    ...fetchConfigGET,
    method: 'GET',
  })
    .then(async (res) => {
      const blob = await res.blob();

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;

      a.download = 'wexler_usage_data.xlsx';
      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    })
    .catch((err) => {
      console.error(err);
    });
};

export default downloadUsage;
