import { useCallback } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

export const useOpenInconsistencyViewer = () => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const openInconsistencyViewer = useCallback(
    (inconsistencyIds: string[]) => {
      const searchParams = new URLSearchParams(search);
      searchParams.set('inconsistencyIds', inconsistencyIds.join(','));
      navigate(`${pathname}?${searchParams}`, { replace: true });
    },
    [navigate, pathname, search],
  );

  const closeInconsistencyViewer = () => {
    const searchParams = new URLSearchParams(search);
    searchParams.delete('inconsistencyIds');
    navigate(`${pathname}?${searchParams}`, { replace: true });
  };

  return { openInconsistencyViewer, closeInconsistencyViewer };
};
