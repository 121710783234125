import { useQuery } from 'react-query';

import { APIBaseChronos } from '@/api/hosts';
import { QueryEntity } from '@/api/types';
import useGetFetchConfig from '@/api/useGetFetchConfig';
import { Case } from '@/types';

export const useGetDraftCases = (matterId: string | null) => {
  const { fetchConfigGET } = useGetFetchConfig();

  return useQuery<Case[]>(
    [QueryEntity.DraftCases, matterId],
    async () => {
      const response = await fetch(`${APIBaseChronos}/api/matters/${matterId}/drafts`, fetchConfigGET);
      return response.json();
    },
    {
      enabled: !!matterId,
      refetchOnWindowFocus: false,
    },
  );
};
