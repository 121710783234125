import { useState } from 'react';

import { Popover, PopoverTrigger, PopoverContent } from '@radix-ui/react-popover';
import {
  Archive,
  ArrowRightLeft,
  ArrowUpDown,
  Drama,
  Eye,
  FileText,
  MapPin,
  RefreshCw,
  ShieldAlert,
  ShieldCheck,
  ShieldQuestion,
} from 'lucide-react';

import {
  getInconsistencySourceDisplayName,
  getInconsistencyTypeDisplayName,
  InconsistencyLevel,
  InconsistencySource,
  InconsistencyType,
} from '../../../../../../../../backend/src/case/inconsistency/inconsistency-display';
import { useUpdateInconsistency } from '../../../../../../api/mutations/useUpdateInconsistency';
import { useUpdateInconsistencyReportItem } from '../../../../../../api/mutations/useUpdateInconsistencyReportItem';
import EditableField from '../../../../../../components/molecules/EditableField';
import formatReferencesText from '../../../CaseSummary/helpers/formatHTML';
import { useDocumentNavigation } from '../../../hooks/useOpenDocViewer';
import Calendar from '@/assets/icons/calendar.svg?react';
import Chip from '@/components/atoms/chip';
import { Button } from '@/components/atoms/new-button';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/atoms/Tooltip';
import Accordion from '@/components/molecules/Accordion';
import { cn } from '@/helpers/shadCnUtils';
import { Fact, Inconsistency, InconsistencyFact } from '@/types';

const InconsistencyLevelIndicator = ({
  inconsistencyId,
  caseId,
  level,
}: {
  inconsistencyId: string;
  caseId: string;
  level: InconsistencyLevel;
}) => {
  const { mutate: updateInconsistency } = useUpdateInconsistency();

  const updateInconsistencyLevel = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    newLevel: InconsistencyLevel,
  ) => {
    event.stopPropagation();
    updateInconsistency({
      inconsistencyId,
      caseId,
      body: {
        level: newLevel,
      },
    });
  };

  const config = {
    [InconsistencyLevel.Minor]: {
      Icon: ShieldQuestion,
      label: 'Minor',
      className: 'bg-yellow-100 hover:bg-yellow-200/80',
    },
    [InconsistencyLevel.Severe]: {
      Icon: ShieldCheck,
      label: 'Severe',
      className: 'bg-orange-200 hover:bg-orange-300/80',
    },
    [InconsistencyLevel.Critical]: {
      Icon: ShieldAlert,
      label: 'Critical',
      className: 'bg-red-300 hover:bg-red-400/80',
    },
  };

  const { Icon, label, className } = config[level];

  return (
    <TooltipProvider delayDuration={0} skipDelayDuration={0}>
      <Popover>
        <Tooltip>
          <PopoverTrigger asChild>
            <TooltipTrigger asChild>
              <div
                className={`flex w-fit items-center gap-1 rounded-full px-2 py-0.5 transition-colors ${className}`}
                role="status"
                aria-label={`Inconsistencies level: ${label}`}
                onClick={(e) => e.stopPropagation()}
              >
                <Icon className="h-4 w-4" />
                <div className="font-semibold">{label}</div>
              </div>
            </TooltipTrigger>
          </PopoverTrigger>

          <TooltipContent>
            <p>Edit severity</p>
          </TooltipContent>
        </Tooltip>

        <PopoverContent className="z-50 mt-1 flex flex-col gap-1 rounded-md border-[1px] border-gray-200 bg-white p-1 outline-none">
          <div
            className="flex items-center gap-2 hover:text-yellow-500"
            onClick={(e) => updateInconsistencyLevel(e, InconsistencyLevel.Minor)}
          >
            <ShieldQuestion className="h-4 w-4 text-yellow-500" />
            <div>Minor</div>
          </div>
          <div
            className="flex items-center gap-2 hover:text-orange-500"
            onClick={(e) => updateInconsistencyLevel(e, InconsistencyLevel.Severe)}
          >
            <ShieldCheck className="h-4 w-4 text-orange-500" />
            <div>Severe</div>
          </div>
          <div
            className="flex items-center gap-2 hover:text-red-600"
            onClick={(e) => updateInconsistencyLevel(e, InconsistencyLevel.Critical)}
          >
            <ShieldAlert className="h-4 w-4 text-red-600" />
            <div>Critical</div>
          </div>
        </PopoverContent>
      </Popover>
    </TooltipProvider>
  );
};

const EvidenceCountIndicator = ({ count }: { count: number }) => {
  return (
    <div className="group rounded-md border border-gray-200 bg-gray-50 px-3 py-1">
      <div className="flex items-center gap-2">
        <Archive className="h-4 w-4 text-gray-500" />
        <span>
          <b className="text-gray-700">{count}</b>
          <span className="ml-1 text-gray-600">pieces of evidence</span>
        </span>
      </div>
    </div>
  );
};

const InconsistencyModeIndicator = ({ mode }: { mode: InconsistencySource }) => {
  const color = {
    [InconsistencySource.MultipleDepositions]: 'bg-purple-100',
    [InconsistencySource.SameDeposition]: 'bg-blue-100',
    [InconsistencySource.DepositionVsEvidence]: 'bg-orange-100',
    [InconsistencySource.EvidenceVsEvidence]: 'bg-pink-100',
  }[mode];

  return (
    <div className={`rounded-md border px-2 py-0.5 ${color}`}>
      <b>{getInconsistencySourceDisplayName(mode)}</b>
    </div>
  );
};

const InconsistencyTypeIndicator = ({ type }: { type: InconsistencyType }) => {
  const config = {
    [InconsistencyType.Dates]: {
      Icon: Calendar,
      label: getInconsistencyTypeDisplayName(InconsistencyType.Dates),
      className: 'bg-purple-100 hover:bg-purple-200/80 text-purple-900',
    },
    [InconsistencyType.Events]: {
      Icon: MapPin,
      label: getInconsistencyTypeDisplayName(InconsistencyType.Events),
      className: 'bg-blue-100 hover:bg-blue-200/80 text-blue-900',
    },
    [InconsistencyType.Evolving]: {
      Icon: RefreshCw,
      label: getInconsistencyTypeDisplayName(InconsistencyType.Evolving),
      className: 'bg-green-100 hover:bg-green-200/80 text-green-900',
    },
    [InconsistencyType.Details]: {
      Icon: FileText,
      label: getInconsistencyTypeDisplayName(InconsistencyType.Details),
      className: 'bg-yellow-100 hover:bg-yellow-200/80 text-yellow-900',
    },
    [InconsistencyType.Observations]: {
      Icon: Eye,
      label: getInconsistencyTypeDisplayName(InconsistencyType.Observations),
      className: 'bg-pink-100 hover:bg-pink-200/80 text-pink-900',
    },
    [InconsistencyType.Fabrications]: {
      Icon: Drama,
      label: getInconsistencyTypeDisplayName(InconsistencyType.Fabrications),
      className: 'bg-red-100 hover:bg-red-200/80 text-red-900',
    },
  };

  const { Icon, label, className } = config[type];
  return (
    <div
      className={`flex items-center gap-1.5 rounded-md px-2.5 py-1 transition-colors ${className}`}
      role="status"
      aria-label={`Inconsistencies type: ${label}`}
    >
      <Icon className="h-4 w-4" />
      <b>{label}</b>
    </div>
  );
};

export const InconsistenciesFact = ({
  fact,
  highlightedFactId,
  openDocViewer,
  compressedVersion = false,
  reference,
}: {
  fact: Fact;
  highlightedFactId?: string | null;
  openDocViewer?: (docId: string, factId: string, pageNumber: number) => void;
  compressedVersion?: boolean;
  reference?: string;
}) => {
  return (
    <div
      key={fact.id}
      style={{ '--color': 'rgba(49, 196, 172, 0.3)' } as React.CSSProperties}
      className={cn('rounded-md border border-gray-200 bg-white p-2', highlightedFactId === fact.id && 'shadow-border')}
    >
      <div className="flex w-full justify-between">
        <div className="flex items-center gap-2 text-xs text-blue-600">
          <div>{reference && <span className="rounded-full border bg-gray-50 px-1 py-0.5">[{reference}]</span>}</div>

          {/* for now, only show the first doc */}
          {fact.locations && fact.locations.length > 0 && (
            <>
              {fact.locations.map((location) => {
                return (
                  <div key={location.doc?.id}>
                    <span
                      data-tooltip-id={`fact-document-tooltip-${fact.id}-${location.doc?.id}`}
                      data-tooltip-content={location.doc?.file?.name}
                      className={!compressedVersion ? 'cursor-pointer' : ''}
                      onClick={(e) => {
                        if (compressedVersion) {
                          return;
                        }
                        e.stopPropagation();
                        if (openDocViewer) {
                          openDocViewer(location.doc?.id ?? '', fact.id, location.boundingBoxes[0].pageNumber ?? 0);
                        }
                      }}
                    >
                      {location.doc?.file?.name
                        ? location.doc?.file?.name.slice(0, fact.locations.length > 1 ? 12 : 20).trim() + '...'
                        : ''}
                    </span>
                    {location.doc?.type && <Chip className="bg-brandTertiary/20">{location.doc?.type}</Chip>}
                  </div>
                );
              })}
            </>
          )}
        </div>
        <div className="ml-2 flex justify-end gap-2 text-xs">
          {fact.factDateText && (
            <div className={`flex items-center gap-2`}>
              <Calendar className="h-4 w-4 text-gray-800" />

              <p>{fact.factDateText}</p>
            </div>
          )}
        </div>
      </div>
      <div className="p-1">{fact.description}</div>
    </div>
  );
};

const InconsistencyRow = ({
  inconsistency,
  caseId,
  openDocComparer,
  compressedVersion,
  isReportInconsistency = false,
}: {
  inconsistency: Inconsistency;
  caseId: string;
  openDocComparer?: (docGroups: { [key: string]: InconsistencyFact[] }) => void;
  compressedVersion?: boolean;
  isReportInconsistency?: boolean;
}) => {
  const [highlightedFactId, setHighlightedFactId] = useState<string | null>(null);
  const { openDocViewer } = useDocumentNavigation();

  const colors = [
    {
      bg: 'bg-blue-50/50',
      line: 'bg-blue-200',
    },
    {
      bg: 'bg-red-50/50',
      line: 'bg-red-200',
    },
    {
      bg: 'bg-purple-50/50',
      line: 'bg-purple-200',
    },
    {
      bg: 'bg-orange-50/50',
      line: 'bg-orange-200',
    },
  ];

  const docGroups: { [key: string]: InconsistencyFact[] } = inconsistency.facts ?? {};
  const groupNames = Object.keys(docGroups);
  const groupCount = groupNames.length;
  const totalFacts = Object.values(docGroups).reduce((acc, group) => acc + group.length, 0);

  const [inconsistencyDescription, setInconsistencyDescription] = useState(inconsistency.description);

  const { mutate: updateInconsistencyReportItem } = useUpdateInconsistencyReportItem();

  function handleUpdateInconsistencyDescription(value: string) {
    if (inconsistency.reportId === undefined) {
      return;
    }

    updateInconsistencyReportItem({
      caseId,
      reportId: inconsistency.reportId,
      reportItemId: inconsistency.id,
      body: {
        description: value,
      },
    });
    setInconsistencyDescription(value);
  }

  return (
    <Accordion
      title={
        <div className={cn('w-full p-2 text-sm font-normal', compressedVersion && 'text-xs')}>
          <div className="flex w-full justify-between gap-2">
            <InconsistencyLevelIndicator
              level={inconsistency.level}
              inconsistencyId={inconsistency.id}
              caseId={caseId}
            />
            <div className="flex items-center divide-x divide-gray-200">
              <div className="pr-2">
                <InconsistencyModeIndicator mode={inconsistency.source} />
              </div>
              {!compressedVersion && (
                <>
                  <div className="px-2">
                    <EvidenceCountIndicator count={totalFacts} />
                  </div>
                </>
              )}
              <div className="pl-2">
                <InconsistencyTypeIndicator type={inconsistency.type} />
              </div>
            </div>
          </div>
          <div className="w-full cursor-default p-1" onClick={(e) => e.stopPropagation()}>
            {isReportInconsistency ? (
              <EditableField
                value={inconsistencyDescription}
                onSave={handleUpdateInconsistencyDescription}
                isLoading={false}
              />
            ) : (
              <div>
                {formatReferencesText(inconsistency.description, (id) => {
                  setHighlightedFactId(id);
                })}
              </div>
            )}
          </div>
        </div>
      }
      content={
        <div className={cn('p-4 text-sm', compressedVersion && 'text-xs')}>
          <div className="mb-2 flex w-full items-center justify-between">
            <p className="mb-1 font-semibold">Contradictory Evidence:</p>
            {openDocComparer && !compressedVersion && (
              <Button
                variant="outline"
                size="sm"
                className="text-semibold flex items-center gap-2 shadow"
                onClick={() => openDocComparer(docGroups)}
              >
                Compare Docs
                <ArrowRightLeft className="mr-2 h-4 w-4" />
              </Button>
            )}
          </div>

          {groupNames.map((groupName: string, index: number) => (
            <div key={groupName}>
              <div className={`relative rounded-md ${colors[index % groupCount].bg} p-3`}>
                <p className="mb-2 text-sm font-semibold">{groupName}</p>
                <div
                  className={`absolute top-1/2 -left-2 h-full w-1 -translate-y-1/2 rounded-full ${colors[index % groupCount].line}`}
                />
                <div className="flex flex-col gap-2">
                  {inconsistency.facts[groupName].map((fact: InconsistencyFact) => (
                    <InconsistenciesFact
                      key={fact.id}
                      highlightedFactId={highlightedFactId}
                      fact={fact.fact}
                      openDocViewer={openDocViewer}
                      compressedVersion={compressedVersion}
                    />
                  ))}
                </div>
              </div>
              {index < groupNames.length - 1 && (
                <div className="my-2 flex w-full items-center justify-center">
                  <div className="flex items-center justify-center rounded-full border bg-gray-100 p-1">
                    <ArrowUpDown className="h-5 w-5 text-gray-600" />
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      }
      className="relative p-0"
    />
  );
};

export default InconsistencyRow;
