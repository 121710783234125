import { useEffect, useState } from 'react';

import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PaginationState } from '@tanstack/react-table';
import { useLocalStorage } from '@uidotdev/usehooks';
import { CircleHelp } from 'lucide-react';
import { useSearchParams } from 'react-router-dom';

import DocComparer from './components/DocComparer';
import InconsistencyRow from './components/InconsistencyRow';
import InconsistencyRunPopup from './components/InconsistencyRunPopup';
import InconsistenciesEditorToolbar from './InconsistenciesEditorToolbar';
import Modal from '../../../../../components/molecules/Modals/CustomModal';
import DocViewer from '../../components/DocViewer/FactDocViewer';
import { useDocumentNavigation } from '../../hooks/useOpenDocViewer';
import IncludeCheckbox from '../components/IncludeCheckbox';
import { trackCustomEvent, trackPageView } from '@/analytics/Mixpanel';
import { useGetCase } from '@/api/queries/useGetCase';
import { useGetDocIds } from '@/api/queries/useGetDocIds';
import { useGetInconsistencies } from '@/api/queries/useGetInconsistencies';
import EmptyState from '@/components/molecules/EmptyState';
import EmptyStateCreation from '@/components/molecules/EmptyStateCreation';
import { Inconsistency, InconsistencyFact, InconsistencyFilters } from '@/types';

const InconsistenciesEditor = () => {
  const [searchParams] = useSearchParams();
  const caseId = searchParams.get('caseId');
  const pageNumber = searchParams.get('page') ?? '0';

  const [searchQueryInput, setSearchQueryInput] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [pagination, setPagination] = useState<PaginationState>({ pageIndex: parseInt(pageNumber), pageSize: 50 });
  const [isDocViewerOpen, setIsDocViewerOpen] = useState(false);
  const [filters, setFilters] = useState<InconsistencyFilters>({});

  const [isDocComparerOpen, setIsDocComparerOpen] = useState(false);
  const [docGroups, setDocGroups] = useState<{ [key: string]: InconsistencyFact[] } | null>(null);

  const [hasInconsistencyRun, setHasInconsistencyRun] = useState(false);
  const [isInconsistencyRunModalOpen, setIsInconsistencyRunModalOpen] = useState(false);

  const { closeDocViewer } = useDocumentNavigation();

  const { data, isLoading } = useGetInconsistencies({
    caseId,
    page: pagination.pageIndex + 1,
    pageSize: pagination.pageSize,
    filters,
    searchQuery,
  });
  const { data: responseDocs } = useGetDocIds(caseId);
  const { data: responseCase } = useGetCase(caseId ?? undefined);

  const [selectedInconsistencyIds, setSelectedInconsistencyIds] = useLocalStorage<string[]>(
    `case-${caseId}-selected-inconsistency-ids`,
    [],
  );

  function updateInconsistencyToggle(inconsistencyId: string) {
    if (selectedInconsistencyIds.find((id) => id === inconsistencyId)) {
      setSelectedInconsistencyIds(selectedInconsistencyIds.filter((id) => id !== inconsistencyId));
    } else {
      setSelectedInconsistencyIds([...selectedInconsistencyIds, inconsistencyId]);
    }
  }

  const handleToggleAll = (toggle: boolean) => {
    if (data) {
      if (toggle) {
        setSelectedInconsistencyIds(data?.allInconsistencyIds);
      } else {
        setSelectedInconsistencyIds([]);
      }
    }
  };

  // Pagination
  const goToPage = (val: number) => {
    const newPaginationState = { pageIndex: val, pageSize: pagination.pageSize };
    setPagination(newPaginationState);
  };
  const prevPage = () => goToPage(pagination.pageIndex - 1);
  const nextPage = () => goToPage(pagination.pageIndex + 1);

  const docId = searchParams.get('docId');
  const factId = searchParams.get('factId');

  const onSearchCall = async () => {
    trackCustomEvent('Search in Inconsistencies Editor');
    // Reset page to 0
    const newPaginationState = { pageIndex: 0, pageSize: pagination.pageSize };
    setPagination(newPaginationState);
    closeDocViewer();
    setSearchQuery(searchQueryInput); // Set the actual filter used for fetching
  };

  const clearSearch = async () => {
    setSearchQueryInput('');
    setSearchQuery('');
    await new Promise((resolve) => setTimeout(resolve, 0));
  };

  const openDocComparer = (docGroups: { [key: string]: InconsistencyFact[] }) => {
    setDocGroups(docGroups);
    setIsDocComparerOpen(true);
  };

  const closeDocComparer = () => {
    setIsDocComparerOpen(false);
    setDocGroups(null);
  };

  useEffect(() => {
    if (docId) {
      setIsDocViewerOpen(true);
    } else {
      setIsDocViewerOpen(false);
    }
  }, [docId]);

  useEffect(() => {
    if (responseCase) {
      setHasInconsistencyRun(responseCase.hasInconsistencyRun ?? false);
    }
  }, [responseCase]);

  useEffect(() => {
    trackPageView('Inconsistencies Page');
  }, []);

  return (
    <div className="flex h-full w-full">
      <div className="flex h-full w-full flex-col transition-all duration-300 ease-in-out">
        {data && caseId && (
          <InconsistenciesEditorToolbar
            caseId={caseId}
            allInconsistencyIds={data.allInconsistencyIds}
            selectedInconsistencyIds={selectedInconsistencyIds}
            globalFilter={searchQueryInput}
            setGlobalFilter={setSearchQueryInput}
            onSearchCall={onSearchCall}
            clearSearch={clearSearch}
            totalPages={data?.pageCount ?? 1}
            goToPage={goToPage}
            currentPage={pagination.pageIndex}
            prevPage={prevPage}
            nextPage={nextPage}
            allDocuments={responseDocs ?? []}
            activeFilters={filters}
            setFilters={setFilters}
            handleToggleAll={handleToggleAll}
          />
        )}
        <div className="flex h-full overflow-hidden pb-2">
          {hasInconsistencyRun ? (
            <>
              {isLoading ? (
                <div className="flex h-full min-h-[500px] w-full items-center justify-center">
                  <FontAwesomeIcon icon={faCircleNotch} className="animate-spin" />
                </div>
              ) : (
                <>
                  {caseId && data && data.inconsistencies && data.inconsistencies.length > 0 ? (
                    <>
                      <div
                        className={`h-full overflow-auto transition-all duration-300 ${isDocViewerOpen ? 'w-7/12' : 'w-full'}`}
                      >
                        <div className="flex w-full flex-col gap-3 py-1">
                          {data.inconsistencies.map((inconsistency: Inconsistency) => {
                            return (
                              <div key={inconsistency.id} className="relative flex gap-3">
                                <IncludeCheckbox
                                  included={selectedInconsistencyIds.includes(inconsistency.id)}
                                  onClick={() => updateInconsistencyToggle(inconsistency.id)}
                                  eventId={inconsistency.id}
                                />
                                <div className="flex w-full rounded-lg border pl-2">
                                  <InconsistencyRow
                                    inconsistency={inconsistency}
                                    caseId={caseId}
                                    openDocComparer={openDocComparer}
                                  />
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div
                        className={`transition-all duration-300 ease-in-out ${
                          isDocViewerOpen ? 'ml-2 w-5/12 opacity-100' : 'w-0 opacity-0'
                        } overflow-hidden rounded border-2 bg-white`}
                      >
                        <div
                          className={`h-full w-full transition-transform duration-300 ease-in-out ${
                            isDocViewerOpen ? 'translate-x-0' : 'translate-x-full'
                          }`}
                        >
                          {docId && factId && caseId && <DocViewer docId={docId} caseId={caseId} factId={factId} />}
                        </div>
                      </div>
                    </>
                  ) : (
                    <EmptyState
                      title="No inconsistencies found"
                      subtitle={
                        (filters && Object.keys(filters).length > 0) || pagination.pageIndex > 0
                          ? "Sorry, we couldn't find any inconsistencies matching these criteria"
                          : "Sorry, we couldn't find any inconsistencies for this case"
                      }
                      icon={<CircleHelp className="h-12 w-12 text-yellow-500" />}
                    />
                  )}
                </>
              )}
            </>
          ) : (
            <EmptyStateCreation
              title="Inconsistencies not yet generated"
              subtitle="Click the button below if you want to generate inconsistencies for this case"
              onClick={() => {
                setIsInconsistencyRunModalOpen(true);
              }}
            />
          )}
        </div>
      </div>
      {caseId && docGroups && (
        <Modal
          isOpen={isDocComparerOpen}
          handleClose={closeDocComparer}
          content={<DocComparer caseId={caseId} docGroups={docGroups} />}
          maxWidth="84rem"
        />
      )}
      <Modal
        isOpen={isInconsistencyRunModalOpen}
        handleClose={() => {
          setIsInconsistencyRunModalOpen(false);
        }}
        content={
          <InconsistencyRunPopup caseId={caseId ?? ''} closeModal={() => setIsInconsistencyRunModalOpen(false)} />
        }
      />
    </div>
  );
};

export default InconsistenciesEditor;
