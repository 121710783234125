import Checklist from './Checklist';
import ConfettiIcon from '@/assets/guide/homepage-guides/confetti.svg?react';
import { OrgUserWithMetadata } from '@/types';

const GetStarted = ({ user }: { user: OrgUserWithMetadata }) => {
  return (
    <div className="mb-8">
      <h2 className="mb-2 flex flex-row items-center gap-2 border-2 border-x-0 border-t-0 px-4 pb-1 text-lg font-semibold">
        Welcome to Wexler! <ConfettiIcon className="h-4 w-4" />
      </h2>
      <div className="flex w-full flex-col gap-4 py-2 text-sm text-gray-800">
        <div className="mb-2 flex flex-col gap-1 px-4">
          <div className="flex flex-col gap-2">
            <p>
              To get started you can use your <span className="font-semibold text-purple-500">Personal Matter</span> to
              upload your first documents.
            </p>
            <p className="text-sm text-gray-500">Complete the checklist below to get familiar with Wexler.</p>
          </div>
        </div>

        <Checklist user={user} />
      </div>
    </div>
  );
};

export default GetStarted;
