import { ReactNode, useEffect, useState } from 'react';

import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react';
import { X } from 'lucide-react';

export interface ModalProps {
  content: ReactNode;
  isOpen: boolean;
  handleClose: () => void;
  maxWidth?: string;
}

export default function Modal({ content, isOpen, handleClose, maxWidth = '32rem' }: ModalProps) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const closeModal = () => {
    handleClose();
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={closeModal} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[enter]:ease-out data-[leave]:duration-200 data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            style={{ maxWidth: maxWidth }}
            className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[enter]:ease-out data-[leave]:duration-200 data-[leave]:ease-in sm:my-8 sm:w-full data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div className="absolute top-2 right-2">
              <button
                onClick={closeModal}
                data-testid="modal-close-button"
                className="rounded-full border bg-gray-100 p-0.5 text-gray-600 hover:bg-gray-200 hover:text-gray-800"
              >
                <X />
              </button>
            </div>
            {content}
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
