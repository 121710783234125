import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence, motion } from 'framer-motion';

interface ToastDrawerProps {
  isOpen: boolean;
  toggleDrawer: () => void;
  children: any;
  position: 'left' | 'right';
}

const ToastDrawer = ({ isOpen, toggleDrawer, children, position }: ToastDrawerProps) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ y: '100%' }}
          animate={{ y: 0 }}
          exit={{ x: position === 'left' ? '0%' : '100%' }}
          transition={{ duration: 0.3, ease: 'easeInOut' }}
          className={`fixed bottom-4 ${position === 'left' ? 'left-2' : 'right-2'} z-50 w-[525px] max-w-full rounded-lg border bg-white p-2 shadow-xl`}
        >
          <div className="flex flex-col">
            <button
              className="absolute right-2 top-1 z-10 text-xl text-gray-600 hover:text-gray-800"
              onClick={toggleDrawer}
            >
              <FontAwesomeIcon className="pr-1 pt-1" icon={faTimes} />
            </button>

            <AnimatePresence mode="wait">
              <motion.div
                key={children.key}
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -20, opacity: 0 }}
                transition={{ duration: 0.2 }}
              >
                {children}
              </motion.div>
            </AnimatePresence>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default ToastDrawer;
