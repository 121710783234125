import { faInfoCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StageSpinner } from 'react-spinners-kit';
import { Tooltip } from 'react-tooltip';

import { File } from '../../../types';
import { getFileNameWithExtension } from '@/helpers/filenames';

export const FileChip = ({
  file,
  fileSizeExceeded,
  removing,
  index,
  handleRemoveFile,
}: {
  file: File;
  fileSizeExceeded: boolean;
  removing: boolean;
  index: number;
  handleRemoveFile: (id: string) => void;
}) => {
  return (
    <div
      className={`flex h-8 w-72 items-center rounded border bg-white p-2 text-xs ${
        fileSizeExceeded ? 'border-red-500 text-red-700' : 'border-blue-500 text-blue-700'
      }`}
      key={file.id}
    >
      <div className="flex w-full items-center justify-between">
        {/* {File name} */}
        <div className="w-1/3 shrink-0 truncate">{getFileNameWithExtension(file?.name)}</div>
        {/* Extension */}
        <div className="flex w-1/3 shrink-0 items-center justify-center">
          <div className={`flex items-center justify-center rounded bg-blue-50 p-1 uppercase`}>
            {file.extension ? file.extension.toUpperCase() : '---'}
          </div>
        </div>
        {/* Size */}
        <div className="flex flex-row items-center justify-center">
          {fileSizeExceeded && (
            <>
              <FontAwesomeIcon
                data-tooltip-id={`tooltip-exceeded-file-size-${index}`}
                icon={faInfoCircle}
                data-tooltip-content="File size too large for processing"
                className="ml-2 mr-3 cursor-pointer text-sm text-red-700"
              />
              <Tooltip id={`tooltip-exceeded-file-size-${index}`} className="z-10" />
            </>
          )}
        </div>

        <div
          className={`ml-auto flex w-8 shrink-0 cursor-pointer items-center justify-center rounded-lg px-2 py-1 font-bold not-italic`}
          onClick={() => handleRemoveFile(file.id)}
        >
          {removing ? (
            <StageSpinner className="m-auto" size={25} color={'#4161FF'} />
          ) : (
            <FontAwesomeIcon icon={faTrash} />
          )}
        </div>
      </div>
    </div>
  );
};
