import React from 'react';

import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import 'react-toggle/style.css';

interface IncludeCheckboxProps {
  included: boolean;
  eventId: string;
  onClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const IncludeCheckbox = ({ included: currentIncluded, eventId, onClick }: IncludeCheckboxProps) => {
  return (
    <div className="flex w-auto flex-row items-center justify-center">
      <label className="flex cursor-pointer items-center">
        <input
          onChange={onClick}
          id={`default-checkbox-${eventId}`}
          type="checkbox"
          checked={currentIncluded}
          className="peer hidden"
        />
        <div className="bg-buttonSecondary peer-checked:bg-brandSecondary flex h-4 w-4 items-center justify-center rounded-full border border-gray-300">
          <FontAwesomeIcon icon={faCheck} className="h-2 w-2 text-white peer-checked:inline-block" />
        </div>
      </label>
    </div>
  );
};

export default IncludeCheckbox;
