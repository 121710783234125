import { useMutation } from 'react-query';

import { APIBaseChronos } from '@/api/hosts';
import useGetFetchConfig, { FetchMethod } from '@/api/useGetFetchConfig';

interface CompleteGuidePayload {
  guideName: string;
}

export function useCompleteGuide() {
  const { getFetchConfig } = useGetFetchConfig();

  return useMutation<void, Error, CompleteGuidePayload>(async ({ guideName }: { guideName: string }) => {
    const url = new URL(`${APIBaseChronos}/api/user/guide`);

    await fetch(url.toString(), {
      ...getFetchConfig({ method: FetchMethod.POST }),
      body: JSON.stringify({ completedGuideName: guideName }),
    });
  });
}
