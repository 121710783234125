import { useQuery } from 'react-query';

import { APIBaseChronos } from '@/api/hosts';
import { QueryEntity } from '@/api/types';
import useGetFetchConfig from '@/api/useGetFetchConfig';
import { ThreadMessage } from '@/types';

const useGetMessages = (caseId: string, threadId: string) => {
  const { fetchConfigGET } = useGetFetchConfig();
  const getMessages = async (): Promise<ThreadMessage[]> => {
    const response = await fetch(`${APIBaseChronos}/api/case/${caseId}/thread/${threadId}/messages`, fetchConfigGET);
    if (!response.ok) {
      throw new Error('Fetching messages failed');
    }
    return response.json();
  };

  const {
    isFetching: isLoadingMessages,
    data: responseMessages,
    refetch: refetchMessages,
    error,
  } = useQuery([QueryEntity.Messages, threadId], getMessages, {
    enabled: false,
  });

  return {
    isLoadingMessages,
    responseMessages,
    refetchMessages,
    error,
  };
};

export default useGetMessages;
