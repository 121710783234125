import React, { useEffect, useState } from 'react';

import { faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import { useSearchParams } from 'react-router-dom';

import AnswerContent from './components/AnswerContent';
import AnswerHeader from './components/AnswerHeader';
import AnswerSidebar from './components/AnswerSidebar';
import ConfidenceTooltip from './components/ConfidenceTooltip';
import SupportingEvidence from './components/SupportingEvidence';
import { getConfidenceLevel, getTitleFromFlowType } from './helpers';
import ToastDrawer from '../../../../../components/molecules/ToastDrawer';
import ChunkDocViewer from '../../components/DocViewer/ChunkDocViewer';
import DocViewerFullFact from '../../components/DocViewer/components/DocViewerFullFact';
import FactDocViewer from '../../components/DocViewer/FactDocViewer';
import { useDocumentNavigation } from '../../hooks/useOpenDocViewer';
import FollowUpQuestions from '../Question/components/FollowUpQuestions';
import { trackCustomEvent } from '@/analytics/Mixpanel';
import Drawer from '@/components/molecules/Drawer';
import InfoModal from '@/components/molecules/Modals/CustomModal';
import SkeletonOrComponent from '@/components/molecules/SkeletonOrComponent';
import { ShadowPulseColors } from '@/constants/styles';
import { Artifact, Chunk, ExecuteKimSearch, Fact, KimMode, MessageType, ThreadMessage } from '@/types';

interface AnswerProps {
  threadId: string;
  caseId: string;
  messages: ThreadMessage[] | undefined;
  loading?: boolean;
  executeSearch: ExecuteKimSearch;
  createNewThread: () => void;
  goToThread: (threadId: string) => void;
  handleBack: () => void;
}

const Answer: React.FC<AnswerProps> = ({
  threadId,
  caseId,
  loading,
  messages,
  executeSearch,
  createNewThread,
  goToThread,
  handleBack,
}) => {
  const { openDocViewer, closeDocViewer } = useDocumentNavigation();

  const [facts, setFacts] = useState<Fact[]>([]);
  const [updatedFactsData, setUpdatedFactsData] = useState<{ [key: string]: Fact }>({});
  const [artifact, setArtifact] = useState<Artifact | undefined>();
  const [flowType, setFlowType] = useState<KimMode | undefined>();
  const [showAllChunks, setShowAllChunks] = useState(false);
  const [animate, setAnimate] = useState(false);
  const [showConfidenceTooltip, setShowConfidenceTooltip] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedChunk, setSelectedChunk] = useState<Chunk | null>(null);
  const [isDocViewerOpen, setIsDocViewerOpen] = useState(false);
  const [isChunkViewerOpen, setIsChunkViewerOpen] = useState(false);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedFact, setSelectedFact] = useState<Fact | null>(null);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
    setSelectedFact(null);
  };

  const selectFact = (fact: Fact) => {
    setSelectedFact(fact);
    setIsDrawerOpen(true);
  };

  const updateFactData = (factId: string, data: Partial<Fact>) => {
    setUpdatedFactsData((prev) => ({
      ...prev,
      [factId]: { ...prev[factId], ...data },
    }));
  };
  const docId = searchParams.get('docId');
  const factId = searchParams.get('factId');
  const chunkId = searchParams.get('chunkId');

  useEffect(() => {
    if (docId && factId) {
      trackCustomEvent('Open Kim Doc Viewer', { caseId, docId });
      setIsDocViewerOpen(true);
    } else {
      setIsDocViewerOpen(false);
    }
  }, [docId, factId, caseId]);

  useEffect(() => {
    if (docId && chunkId) {
      trackCustomEvent('Open Kim Chunk Viewer', { chunkId, docId });
      setIsChunkViewerOpen(true);
    } else {
      setIsChunkViewerOpen(false);
    }
  }, [docId, chunkId]);

  // Effects
  useEffect(() => {
    if (!loading && messages) {
      // Slight delay to make sure content is rendered
      setTimeout(() => setAnimate(true), 50);
    } else {
      setAnimate(false);
    }
  }, [loading, messages]);

  // If there are no facts but there are chunks, show the chunks
  useEffect(() => {
    if (!messages) return;
    const answerMessage = messages?.find((message) => message.type === MessageType.Assistant);
    if (!answerMessage) return;
    const { facts, chunks } = answerMessage;
    if (!facts || !chunks) return;
    if (facts.length === 0 && chunks.length > 0) {
      setShowAllChunks(true);
    }
  }, [messages]);

  useEffect(() => {
    if (!messages) return;
    const answerMessage = messages?.find((message) => message.type === MessageType.Assistant);
    if (!answerMessage) return;
    const { facts } = answerMessage;
    const flowType = answerMessage.config?.flowType;
    const artifacts = answerMessage.config?.artifacts;

    // Set artifact and flow_type if they exist
    setArtifact(undefined);
    setFlowType(flowType);

    if (artifacts && artifacts.length > 0) {
      setArtifact(artifacts[0]);
    }

    if (!facts) return;
    const modifiedFacts = facts.map((fact: Fact) => ({
      ...fact,
      ...updatedFactsData[fact.id],
    }));
    setFacts(modifiedFacts);
  }, [messages, updatedFactsData]);

  const scrollToRow = (id: string, type: string) => {
    trackCustomEvent('Kim Reference Clicked');
    if (type === 'chunk') {
      setShowAllChunks(true);
      // Add delay to allow UI to update
      setTimeout(() => {
        scrollToElement(id);
      }, 100);
    } else {
      // Add delay to allow UI to update
      setTimeout(() => {
        scrollToElement(id);
      }, 100);
    }
  };

  const scrollToElement = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.style.setProperty('--pulse-color', ShadowPulseColors.brandSecondary);
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      element.classList.add('relative', 'z-30', 'animate-shadow-pulse');
      setTimeout(() => {
        element.classList.remove('animate-shadow-pulse', 'z-30', 'relative');
      }, 2000);
    }
  };

  const userMessage = messages?.find((message) => message.type === 'user');
  const answerMessage = messages?.find((message) => message.type === 'assistant');
  const confidenceLevel = getConfidenceLevel(answerMessage?.config?.trafficLight);

  return (
    <div className="flex h-full">
      <AnswerSidebar
        userMessage={userMessage}
        createNewThread={createNewThread}
        goToThread={goToThread}
        handleBack={handleBack}
      />

      <div className="flex h-full w-full flex-1 flex-col overflow-y-auto px-4 py-4">
        <div className="mb-4 flex items-center justify-between">
          <div className="flex items-center gap-2">
            <FontAwesomeIcon icon={faLightbulb} className="text-brandSecondary pb-1" />
            <h2 className="text-base font-semibold text-gray-800">
              {getTitleFromFlowType(flowType)}
              {flowType === 'draft_flow' && (
                <span className="text-sm text-gray-600"> (please review draft before use)</span>
              )}
            </h2>
          </div>

          <AnswerHeader
            confidenceLevel={confidenceLevel}
            answerMessage={answerMessage}
            setShowConfidenceTooltip={setShowConfidenceTooltip}
            caseId={caseId}
            threadId={threadId}
            artifact={artifact}
            flowType={flowType}
          />
        </div>
        <SkeletonOrComponent
          isLoading={loading || !messages}
          component={
            <AnswerContent
              facts={facts}
              scrollToRow={scrollToRow}
              answerMessage={answerMessage}
              flowType={flowType}
              setSelectedChunk={(chunk) => {
                setSelectedChunk(chunk);
                setSearchParams((prev) => {
                  prev.set('chunkId', chunk.id);
                  prev.set('docId', chunk.docId);
                  return prev;
                });
              }}
              setSelectedFact={selectFact}
            />
          }
        />
        {flowType === KimMode.ExtractionFlow || !flowType ? (
          <></>
        ) : (
          <>
            <SupportingEvidence
              loading={loading}
              animate={animate}
              answerMessage={answerMessage}
              facts={facts}
              showAllChunks={showAllChunks}
              setShowAllChunks={setShowAllChunks}
              confidenceLevel={confidenceLevel}
              updateFactData={updateFactData}
              setSelectedChunk={setSelectedChunk}
            />
            <FollowUpQuestions
              followUpQuestions={answerMessage?.config?.followUpQuestions}
              animate={animate}
              loading={loading}
              executeSearch={executeSearch}
            />
          </>
        )}

        <Drawer
          children={docId && factId && <FactDocViewer caseId={caseId} docId={docId} factId={factId} />}
          hideCloseButton={true}
          isOpen={isDocViewerOpen}
          onClose={closeDocViewer}
        />
      </div>

      <Drawer
        children={
          selectedChunk && (
            <ChunkDocViewer
              caseId={caseId}
              docId={selectedChunk.docId}
              chunkId={selectedChunk.id}
              boundingBoxes={selectedChunk.boundingBoxes}
            />
          )
        }
        hideCloseButton={true}
        isOpen={isChunkViewerOpen}
        onClose={() => {
          setSearchParams((prev) => {
            prev.delete('docId');
            prev.delete('chunkId');
            return prev;
          });
        }}
      />

      <ToastDrawer isOpen={isDrawerOpen} toggleDrawer={toggleDrawer} position="left">
        {selectedFact && (
          <motion.div key={selectedFact.id}>
            <DocViewerFullFact
              fact={selectedFact}
              showDocumentDetails={true}
              showComments={false}
              gotoDocReference={openDocViewer}
            />
          </motion.div>
        )}
      </ToastDrawer>

      <InfoModal
        content={<ConfidenceTooltip confidenceLevel={confidenceLevel} />}
        isOpen={showConfidenceTooltip}
        handleClose={() => setShowConfidenceTooltip(false)}
      />
    </div>
  );
};

export default React.memo(Answer);
