import React from 'react';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/atoms/Tooltip';
import { cn } from '@/helpers/shadCnUtils';

interface TooltipIconProps {
  tooltipContent: string;
  className?: string;
}

const TooltipIcon: React.FC<TooltipIconProps> = React.memo(({ tooltipContent, className }) => (
  <TooltipProvider>
    <Tooltip delayDuration={0}>
      <TooltipTrigger asChild>
        <FontAwesomeIcon icon={faInfoCircle as IconProp} className={'h-4 w-4 cursor-pointer text-gray-400'} />
      </TooltipTrigger>

      <TooltipContent className={cn('max-w-56 bg-gray-50', className)}>
        <p className="text-xs text-gray-800">{tooltipContent}</p>
      </TooltipContent>
    </Tooltip>
  </TooltipProvider>
));

export default TooltipIcon;
