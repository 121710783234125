import { useCallback } from 'react';

import ReadMoreArea from '@foxeian/react-read-more';

import { referencingLinkRegex } from '../../../../../../../constants/referencing';
import { Chunk, Fact, Reference, ReferenceType } from '../../../../../../../types';

interface TextWithReferencesProps {
  facts: Fact[];
  chunks: Chunk[];
  text: string;
  references?: Reference;
  setSelectedChunk: (chunk: Chunk) => void;
  setSelectedFact: (fact: Fact) => void;
  uniqueId: string;
}

const TextWithReferences = ({
  chunks,
  facts,
  text,
  references,
  setSelectedChunk,
  setSelectedFact,
  uniqueId,
}: TextWithReferencesProps) => {
  const handleClick = useCallback((ref: { type: ReferenceType; id: string }) => {
    if (ref.type === 'chunk') {
      const chunk = chunks.find((chunk) => chunk.id === ref.id);
      if (chunk) {
        setSelectedChunk(chunk);
      }
    } else {
      const fact = facts.find((fact) => fact.id === ref.id);
      if (fact) {
        setSelectedFact(fact);
      }
    }
  }, []);

  const createReadMore = useCallback((text: string) => {
    return text.length > 200 ? (
      <ReadMoreArea key={uniqueId} lettersLimit={200}>
        {text}
      </ReadMoreArea>
    ) : (
      text
    );
  }, []);

  const renderTextWithReferences = (text: string) => {
    if (references === undefined) {
      return createReadMore(text);
    }

    const parts = [];
    let lastIndex = 0;
    let match;
    let referenceIndex = 0;

    while ((match = referencingLinkRegex.exec(text)) !== null) {
      const [fullMatch, num, uuid] = match;
      const startIndex = match.index;

      if (startIndex > lastIndex) {
        parts.push(text.substring(lastIndex, startIndex));
      }
      const ref = references[num];

      if (ref === undefined) {
        parts.push(`[${num}]`);
        lastIndex = startIndex + fullMatch.length;
        continue;
      }

      // Push the reference
      parts.push(
        <span
          className={`cursor-pointer ${ref.type === 'chunk' ? 'text-orange-500' : 'text-blue-500'}`}
          key={`${num}-${uuid}-${referenceIndex}-${uniqueId}`}
          onClick={() => handleClick(ref)}
        >
          [{num}]
        </span>,
      );

      referenceIndex++;
      lastIndex = startIndex + fullMatch.length;
    }

    // Push the remaining text after the last reference
    if (lastIndex < text.length) {
      parts.push(createReadMore(text.substring(lastIndex)));
    }

    return parts;
  };

  return <div>{renderTextWithReferences(text)}</div>;
};

export default TextWithReferences;
