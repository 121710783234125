import React, { Fragment, useEffect, useState } from 'react';

import { faFilePdf, faFolderPlus, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { faFileZip } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProgressBar from '@ramonak/react-progress-bar';
import { Accept, ErrorCode, FileWithPath, useDropzone } from 'react-dropzone';
import { toast as sonnerToast } from 'sonner';

import { useGetCaseIsPendingUpload } from '../../../api/queries/useGetCaseIsPendingUpload';
import { ZipUploadModalContent } from '../../../screens/Chronos/CaseEditor/components/Modals/ZipUploadModal';
import { useGetApideckAllConnections } from '@/api/queries/useGetApideckAllConnections';
import { useGetApideckSession } from '@/api/queries/useGetApideckSession';
import Box from '@/assets/apideck/Box.jpg';
import Dropbox from '@/assets/apideck/Dropbox.png';
import OneDrive from '@/assets/apideck/OneDrive.png';
import SharePoint from '@/assets/apideck/SharePoint.png';
import Button from '@/components/atoms/Button';
import ImagesButton from '@/components/atoms/ImagesButton';
import CustomFilePicker from '@/components/molecules/ApideckFilePicker';
import CustomModal from '@/components/molecules/Modals/CustomModal';
import Modal from '@/components/molecules/Modals/CustomModal';
import isPlural from '@/helpers/isPlural';
import { ApideckConnection } from '@/types';

interface FileUploaderProps {
  isUploading: boolean;
  onDrop: (acceptedFiles: File[]) => void;
  successfulUploads?: number;
  totalProgress?: number;
  acceptedTypes?: Accept;
  maxFiles?: number;
  maxSize?: number;
  showPreview?: boolean;
  type?: string;
  caseId?: string;
  onApideckSuccess?: () => void;
  isApideckEnabled?: boolean;
}

interface FileUploadInputContentProps {
  isUploading: FileUploaderProps['isUploading'];
  maxFiles: FileUploaderProps['maxFiles'];
  showPreview: FileUploaderProps['showPreview'];
  successfulUploads: FileUploaderProps['successfulUploads'];
  totalProgress?: number;
  acceptedFiles: readonly FileWithPath[];
  files: FileWithPreview[];
  onDeleteClick: (index: number) => void;
  isDragActive: boolean;
  type?: string;
}

const getErrorMessage = ({
  errorCode,
  maxFiles,
  maxSize,
  acceptedFileTypes,
}: {
  errorCode: ErrorCode;
  acceptedFileTypes?: Accept;
  maxFiles?: number;
  maxSize?: number;
}) => {
  const errorMessages: Record<ErrorCode, string> = {
    'file-invalid-type': acceptedFileTypes
      ? `File needs to be one of ${Object.values(acceptedFileTypes).join(', ')}`
      : 'Invalid file type',
    'file-too-large': maxSize ? `Maximum file size is ${maxSize / 10000} MB` : 'File too large',
    'file-too-small': 'File too small',
    'too-many-files': maxFiles
      ? `Only ${maxFiles} ${isPlural({ one: 'file', more: 'files', number: maxFiles })}`
      : 'Too many files',
  };

  return errorMessages[errorCode];
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 50,
  height: 50,
  padding: 4,
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
};

interface FileWithPreview extends File {
  preview: string;
}

const FileUploadInputContent = ({
  isUploading,
  isDragActive,
  maxFiles,
  showPreview,
  files,
  onDeleteClick,
  acceptedFiles,
  totalProgress,
  successfulUploads = 0,
  type = 'files',
}: FileUploadInputContentProps) => {
  const thumbs = files.map((file, index) => {
    return file.type.includes('pdf') ? (
      <Fragment key={file.name}>
        <div className="relative mr-4 h-full w-12">
          <FontAwesomeIcon
            className="absolute -top-2 right-2 hover:text-gray-500"
            icon={faTimesCircle}
            onClick={() => onDeleteClick(index)}
          />
          <FontAwesomeIcon icon={faFilePdf} className="mr-4 h-1/3 text-gray-500 transition-transform duration-300" />
          <p className="truncate text-xs">{file.name}</p>
        </div>
      </Fragment>
    ) : (
      <div style={thumb} key={file.name}>
        <div style={thumbInner}>
          <img
            src={file.preview}
            alt={file.name}
            style={img}
            onLoad={() => {
              URL.revokeObjectURL(file.preview);
            }}
          />
        </div>
      </div>
    );
  });

  const uploadButtonText =
    type === 'zip' ? 'Upload Zip' : isPlural({ one: 'Upload File', more: 'Upload Files', number: maxFiles ?? 0 });

  if (isDragActive) {
    if (maxFiles === files.length) {
      return <p className="text-red-400">Maximum amount of files reached, remove a file first</p>;
    }
    return <p className="text-blue-500">Drop the files here ...</p>;
  }

  if (isUploading) {
    return (
      <div className="flex flex-col">
        <div className="mb-4 text-center text-base leading-6 font-bold text-gray-900 not-italic">
          Uploaded {successfulUploads} of {acceptedFiles.length} files
        </div>
        <ProgressBar
          completed={totalProgress ?? 0}
          customLabel={''}
          bgColor={'#4161FF'}
          height={'6px'}
          className="animate-pulse"
          isLabelVisible={false}
        />
      </div>
    );
  }

  if (files.length && showPreview) {
    return <aside className="z-10 flex flex-1 justify-center">{thumbs}</aside>;
  }

  return (
    <div>
      <Button
        type="brand"
        testid="upload-files"
        rounded="md"
        text={uploadButtonText}
        onClick={() => null}
        icon={<FontAwesomeIcon icon={faFolderPlus} className="mr-2 text-white" />}
      />
      <p className="mt-3 text-center text-sm leading-4 text-gray-700 not-italic">Or drag here</p>
    </div>
  );
};

const FileUploadInput: React.FC<FileUploaderProps> = ({
  isUploading,
  onDrop,
  acceptedTypes,
  maxFiles,
  maxSize,
  totalProgress,
  showPreview,
  type = 'files',
  caseId,
  onApideckSuccess,
  successfulUploads = 1,
  isApideckEnabled,
}) => {
  const [localError, setLocalError] = useState<ErrorCode | null>(null);
  const [files, setFiles] = useState<FileWithPreview[]>([]);

  const [isZipUploadModalOpen, setIsZipUploadModalOpen] = useState(false);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    acceptedFiles,
    fileRejections = [],
  } = useDropzone({
    multiple: maxFiles !== 1,
    noClick: maxFiles === files.length,
    onDropAccepted: (acceptedFiles) => {
      setLocalError(null);

      if (maxFiles === files.length) {
        return setLocalError(ErrorCode.TooManyFiles);
      }

      const newFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      );

      setFiles([...files, ...newFiles]);
      onDrop(acceptedFiles);
    },
    accept: acceptedTypes,
    maxSize,
    maxFiles,
  });
  const onDeleteClick = (index: number) => {
    const newFiles = files.filter((file, i) => i !== index);

    setFiles(newFiles);
  };

  // APIDECK INTEGRATION
  const { data: apideckSessionData, refetch: refetchApideckSession } = useGetApideckSession({
    enabled: false,
    refetchOnWindowFocus: false,
  });
  const { refetch: refetchConnections } = useGetApideckAllConnections({ enabled: false, refetchOnWindowFocus: false });

  const [apiDeckSessionToken, setApiDeckSessionToken] = useState<string | null>(null);
  const [apiDeckConnections, setApiDeckConnections] = useState<Array<ApideckConnection>>([]);
  const apideckConnectionsImages = [
    { name: 'Box', icon: Box },
    { name: 'Dropbox', icon: Dropbox },
    { name: 'OneDrive', icon: OneDrive },
    { name: 'SharePoint', icon: SharePoint },
  ];

  const refetchApideckSessionFunc = async () => {
    const { data } = await refetchApideckSession();
    if (data) setApiDeckSessionToken(data.sessionToken);
  };

  useEffect(() => {
    if (!isApideckEnabled) return;

    if (apideckSessionData?.sessionToken) {
      setApiDeckSessionToken(apideckSessionData.sessionToken);
    } else {
      refetchApideckSessionFunc();
    }
  }, [apideckSessionData, isApideckEnabled]);

  const resetConnections = async () => {
    const { data } = await refetchConnections();
    if (data) setApiDeckConnections(data);
  };

  useEffect(() => {
    if (apiDeckSessionToken) {
      resetConnections();
    }
  }, [apiDeckSessionToken]);

  // Only one concurrent upload allowed.
  // This function checks whether there is an ongoing upload from the user.
  const { refetch: isPendingUploadRefetch } = useGetCaseIsPendingUpload(caseId);
  const isPendingUpload = async (): Promise<boolean> => {
    const { data } = await isPendingUploadRefetch();
    return data === true;
  };

  // MODAL
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState<React.ReactNode | null>(null);
  const handleOpenModal = async (connection: string) => {
    if (!apiDeckSessionToken || !caseId) return;
    if (await isPendingUpload()) {
      sonnerToast.info('An upload is still in progress. Only one concurrent upload is allowed.', {
        duration: 3000,
        position: 'top-right',
        richColors: true,
      });
    } else {
      setModalContent(
        <CustomFilePicker
          token={apiDeckSessionToken}
          caseId={caseId}
          closeModalFunction={setModalOpen}
          initialConnection={connection}
          onApideckSuccess={onApideckSuccess}
        />,
      );
      setModalOpen(true);
    }
  };

  return (
    <div>
      {caseId && (
        <div className="mb-6 flex justify-center space-x-4 border-b border-gray-100 pb-6">
          {isApideckEnabled && (
            <>
              {apiDeckConnections.length
                ? apiDeckConnections.map((connection) => (
                    <ImagesButton
                      key={connection.id}
                      title={connection.name}
                      imageSource={[connection.icon]}
                      hoverText={connection.name}
                      onClick={async () => await handleOpenModal(connection.service_id)}
                    />
                  ))
                : apideckConnectionsImages.map(({ name, icon }, index) => (
                    <ImagesButton
                      key={index}
                      title={name}
                      imageSource={[icon]}
                      hoverText={name}
                      onClick={() => null}
                      blankCount={1}
                    />
                  ))}
            </>
          )}

          <ImagesButton
            key="zip"
            fontAwesomeIcon={faFileZip}
            title="Zip"
            hoverText="Zip"
            onClick={() => setIsZipUploadModalOpen(true)}
            blankCount={1}
          />
        </div>
      )}
      <div
        data-testid="file-uploader"
        className={`border-brandSecondary relative mt-2 flex h-32 items-center justify-center rounded-2xl border border-dashed bg-slate-100 ${
          maxFiles === files.length ? 'cursor-default' : 'cursor-pointer'
        }`}
        {...getRootProps()}
      >
        <input {...getInputProps()} data-testid="upload-files-input" />
        <FileUploadInputContent
          isUploading={isUploading}
          isDragActive={isDragActive}
          maxFiles={maxFiles}
          showPreview={showPreview}
          files={files}
          acceptedFiles={acceptedFiles}
          totalProgress={totalProgress}
          successfulUploads={successfulUploads}
          onDeleteClick={onDeleteClick}
        />
        <div className="absolute bottom-2 left-3">
          <p
            className={`h-4 text-xs text-red-400 transition-opacity ${
              fileRejections[0] || localError ? 'opacity-100' : 'opacity-0'
            }`}
          >
            {getErrorMessage({
              errorCode: localError || (fileRejections[0]?.errors[0].code as ErrorCode),
              acceptedFileTypes: acceptedTypes,
              maxFiles,
              maxSize,
            })}
          </p>
        </div>
      </div>

      <CustomModal isOpen={modalOpen} handleClose={() => setModalOpen(false)} content={modalContent} maxWidth="52rem" />

      {caseId && (
        <Modal
          content={<ZipUploadModalContent caseId={caseId} />}
          isOpen={isZipUploadModalOpen}
          handleClose={() => setIsZipUploadModalOpen(false)}
        />
      )}
    </div>
  );
};
export default FileUploadInput;
