import { ReactNode, useEffect, useState } from 'react';

import {
  fa1,
  fa2,
  faArrowRight,
  faBook,
  faCircleCheck,
  faCircleInfo,
  faCircleNotch,
  faFile,
  faRefresh,
  faUpload,
  faWarning,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSearchParams } from 'react-router-dom';
import { StageSpinner } from 'react-spinners-kit';
import { toast as sonnerToast } from 'sonner';

import { useGetCaseIsPendingUpload } from '../../../../../../api/queries/useGetCaseIsPendingUpload';
import useGetFiles from '../../../../../../api/queries/useGetFiles';
import { trackCustomEvent } from '@/analytics/Mixpanel';
import useUpdateCaseMetadata from '@/api/mutations/useUpdateCaseMetadata';
import { useGetCase } from '@/api/queries/useGetCase';
import EditableField from '@/components/molecules/EditableField';
import Modal from '@/components/molecules/Modals/SettingsModal';
import PermittedFilesChip from '@/components/molecules/PermittedFilesChip';
import FileUploaderComponent from '@/components/organisms/DocumentAdder';
import useUpdateCase from '@/screens/Chronos/CaseEditor/hooks/useUpdateCaseObject';

const CaseDetails = ({
  caseId,
  caseName,
  isFetching,
  caseIssues,
  handleIssuesChange,
  caseParties,
  handlePartiesChange,
  caseContext,
  handleContextChange,
  caseTimePeriod,
  handleTimePeriodChange,
  setActiveTab,
}: {
  caseId: string;
  caseName: string;
  isFetching: boolean;
  caseIssues: string;
  handleIssuesChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  caseParties: string;
  handlePartiesChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  caseContext: string;
  handleContextChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  caseTimePeriod: string;
  handleTimePeriodChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  setActiveTab: (tab: string) => void;
}) => {
  const { mutate: updateCaseMetadata, isLoading } = useUpdateCaseMetadata();
  const onEditCaseName = (updatedName: string) => {
    updateCaseMetadata({ caseId, data: { name: updatedName } });
  };

  return (
    <div className="flex h-full flex-col gap-4 overflow-scroll">
      <div className="flex items-center justify-between">
        <div className="w-1/2 text-xl font-semibold">
          <EditableField value={caseName} isLoading={isLoading} onSave={onEditCaseName} />
        </div>
        <button
          onClick={() => setActiveTab('Documents')}
          className="bg-buttonPrimary hover:bg-buttonPrimary-hover flex items-center justify-center gap-2 rounded px-2 py-1 text-white shadow"
        >
          Add Documents
          <FontAwesomeIcon icon={faArrowRight} />
        </button>
      </div>
      <div>
        {isFetching ? (
          <div className="flex h-72 items-center justify-center">
            <FontAwesomeIcon icon={faCircleNotch} className="fa-spin" />
          </div>
        ) : (
          <div>
            <div className="px-1 text-sm font-semibold">Case Issues</div>
            <textarea
              className="h-40 w-full resize-none rounded border px-4 py-2 text-sm font-normal not-italic transition-all duration-300 hover:bg-gray-50 focus:border-blue-300 focus:outline-none"
              value={caseIssues}
              onChange={handleIssuesChange}
            />
            <div className="mt-2 px-1 text-sm font-semibold">Case Parties</div>
            <textarea
              className="h-10 w-full resize-none rounded border px-4 py-2 text-sm font-normal not-italic transition-all duration-300 hover:bg-gray-50 focus:border-blue-300 focus:outline-none"
              value={caseParties}
              onChange={handlePartiesChange}
            />

            <div className="my-4 border-b border-gray-300"></div>

            <div className="px-1 text-sm font-semibold text-gray-600">Additional Context (optional)</div>
            <textarea
              className="h-24 w-full resize-none rounded border px-4 py-2 text-sm font-normal not-italic transition-all duration-300 hover:bg-gray-50 focus:border-blue-300 focus:outline-none"
              value={caseContext}
              onChange={handleContextChange}
            />
            <div className="mt-2 px-1 text-sm font-semibold text-gray-600">Estimated Time Period (optional)</div>
            <textarea
              className="h-10 w-full resize-none rounded border px-4 py-2 text-sm font-normal not-italic transition-all duration-300 hover:bg-gray-50 focus:border-blue-300 focus:outline-none"
              value={caseTimePeriod}
              onChange={handleTimePeriodChange}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const Confirmation = ({
  detailsChanged,
  totalUnprocessedDocs,
  caseId,
  caseIssues,
  caseParties,
  caseContext,
  caseTimePeriod,
  closeModal,
}: {
  detailsChanged: boolean;
  totalUnprocessedDocs: number;
  caseId: string;
  caseIssues: string;
  caseParties: string;
  caseContext: string;
  caseTimePeriod: string;
  closeModal: () => void;
}) => {
  const { mutateAsync } = useUpdateCase();
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState('');

  const handleUpdateCase = async () => {
    setIsLoading(true);
    await mutateAsync(
      {
        caseId: caseId,
        updateDetailsActive: detailsChanged,
        caseIssues: caseIssues,
        caseParties: caseParties,
        caseContext: caseContext,
        caseTimePeriod: caseTimePeriod,
      },
      {
        onSuccess: () => {
          setResult('success');
          setIsLoading(false);
          setTimeout(() => {
            closeModal();
          }, 2000);
        },
        onError: () => {
          setResult('failed');
          setIsLoading(false);
          setTimeout(() => {
            closeModal();
          }, 2000);
        },
      },
    );
    trackCustomEvent('Update case', { caseId });
  };

  // This function checks whether there is an ongoing upload from the user.
  const { data: isPendingUploadData, refetch: isPendingUploadRefetch } = useGetCaseIsPendingUpload(caseId);
  const isPendingUpload = async (): Promise<boolean> => {
    const { data } = await isPendingUploadRefetch();
    return data === true;
  };

  // Processes cases only where an ongoing upload from the user is not present.
  const processCaseWrapper = async () => {
    const isUploadOngoing = await isPendingUpload();
    if (isUploadOngoing) {
      sonnerToast.info('An upload is still in progress. Please wait and try again later.', {
        duration: 3000,
        position: 'top-right',
        richColors: true,
      });
    } else {
      sonnerToast.success('Case processing.', {
        duration: 3000,
        position: 'top-right',
        richColors: true,
      });
      handleUpdateCase();
    }
  };

  // Messy, but a quick fix until Redis is implemented.
  const { refetch: refetchFiles } = useGetFiles(caseId);
  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;
    if (isPendingUploadData === true) {
      interval = setInterval(() => {
        refetchFiles();
        isPendingUploadRefetch();
      }, 10_000);
    } else if (isPendingUploadData !== true) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isPendingUploadData, refetchFiles, isPendingUploadRefetch]);

  return (
    <div className="px-8 py-4 text-sm text-gray-800">
      {isLoading ? (
        <div className="flex h-52 flex-col items-center justify-center">
          <StageSpinner size={30} color={'#4161FF'} />
        </div>
      ) : result === 'failed' ? (
        <div className="flex h-52 flex-col items-center gap-4 pt-10">
          <p className="flex items-center gap-2 text-xl font-semibold">
            <FontAwesomeIcon icon={faWarning} className="h-5 w-5 text-red-700" />
            Update Failed
          </p>
          <p className="text-sm text-gray-500">
            Please{' '}
            <a href="mailto:support@wexler.ai" className="text-blue-600">
              contact
            </a>{' '}
            the Wexler team.
          </p>
        </div>
      ) : result === 'success' ? (
        <div className="flex h-52 flex-col items-center gap-4 pt-10">
          <p className="flex items-center gap-2 text-xl font-semibold">
            <FontAwesomeIcon icon={faCircleCheck} className="h-5 w-5 text-green-700" />
            Update In Progress
          </p>
          <p className="text-sm text-gray-500">Please check back later, you can go back to your case now.</p>
        </div>
      ) : (
        <>
          <p className="text-xs text-gray-500">
            Make sure you have made all of the necessary changes to your case details. Updating a case can be time
            consuming.
          </p>
          <p className="mt-2 text-xs text-gray-500">
            If there are unwanted documents in this update please remove them from the Documents tab.
          </p>

          <div className="mt-4 border border-x-0 border-b-0 pt-4">
            <p className="">
              This update will include: {detailsChanged && <b className="underline">case details</b>}
              {detailsChanged && totalUnprocessedDocs > 0 && ', '}
              {totalUnprocessedDocs > 0 && (
                <b className="underline">
                  {totalUnprocessedDocs} {totalUnprocessedDocs === 1 ? 'document' : 'documents'}
                </b>
              )}
            </p>

            <div className="mt-6 mb-2 flex gap-10">
              <button
                onClick={async () => await processCaseWrapper()}
                className="bg-brandSecondary hover:bg-brandSecondary-hover rounded border px-4 py-2 text-base text-white"
              >
                Update
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const Documents = ({
  openModal,
  setTotalUnprocessedDocs,
  detailsChanged,
}: {
  openModal: (open: boolean) => void;
  setTotalUnprocessedDocs: (docNumber: number) => void;
  detailsChanged: boolean;
}) => {
  const [uploadDisabled, setUploadDisabled] = useState(false);
  const [searchParams] = useSearchParams();
  const caseId = searchParams.get('caseId');
  return (
    <div className="flex h-full flex-col justify-between overflow-auto">
      <div className="pr-4">
        <div className="flex items-center justify-between">
          <p className="mb-2 text-xl font-semibold">Documents</p>
          <PermittedFilesChip />
        </div>
        <p className="mb-1 text-sm text-gray-700">Upload additional documents.</p>
        <FileUploaderComponent
          setUploadDisabled={setUploadDisabled}
          setTotalUnprocessedDocs={setTotalUnprocessedDocs}
          caseId={caseId || ''}
        />
      </div>
      <div className="flex justify-end py-3 pr-4">
        <button
          disabled={uploadDisabled && !detailsChanged}
          onClick={() => openModal(true)}
          className="bg-buttonPrimary hover:bg-buttonPrimary-hover flex items-center justify-center gap-2 rounded px-2 py-1 text-white shadow disabled:cursor-not-allowed disabled:bg-gray-300"
        >
          <FontAwesomeIcon icon={faRefresh} />
          Update Case
        </button>
      </div>
    </div>
  );
};

const CaseInformation = ({ setActiveTab }: { setActiveTab: (tab: string) => void }) => {
  return (
    <div className="flex h-full flex-col justify-between">
      <div>
        <p className="mb-4 text-xl font-semibold">Case Information</p>
        <p>Update your case details, add more documents or both.</p>

        <div className="mt-6 flex flex-col gap-6 text-sm">
          <div
            onClick={() => setActiveTab('Case Details')}
            className="rounded-md border border-blue-200 px-3 py-2 transition-all duration-300 hover:cursor-pointer hover:border-blue-300 hover:bg-gray-50"
          >
            <p className="mb-1 font-semibold">
              1. Case Details
              <FontAwesomeIcon icon={faBook} className="ml-2 text-gray-700" />
            </p>
            <div className="flex flex-col gap-2 text-gray-700">
              <p>
                Updating your case details will amend the 'significance' of each fact, which explains how a fact relates
                to the case's core legal issues.
              </p>
              <p>It will also generate a new summary of the case, and may alter the key themes and key facts.</p>
            </div>
          </div>

          <div
            onClick={() => setActiveTab('Documents')}
            className="rounded-md border border-blue-200 px-3 py-2 transition-all duration-300 hover:cursor-pointer hover:border-blue-300 hover:bg-gray-50"
          >
            <p className="mb-1 font-semibold">
              2. Add Documents
              <FontAwesomeIcon icon={faFile} className="ml-2 text-gray-700" />
            </p>
            <div className="flex flex-col gap-2 text-gray-700">
              <p>Adding new documents to your case will extract new facts from these documents.</p>
              <p>It will also generate a new summary of the case, and may alter the key themes and key facts.</p>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-between">
        <button
          onClick={() => setActiveTab('Case Details')}
          className="bg-buttonPrimary hover:bg-buttonPrimary-hover flex items-center justify-center gap-2 rounded px-2 py-1 text-white shadow"
        >
          Update Case Details
          <FontAwesomeIcon icon={faArrowRight} />
        </button>
      </div>
    </div>
  );
};

const NavItem = ({
  title,
  icon,
  onClick,
  active = false,
  className,
}: {
  title: string;
  icon: ReactNode;
  onClick: () => void;
  active?: boolean;
  className?: string;
}) => {
  return (
    <p
      className={
        `flex w-full items-center gap-2 py-1 transition-all duration-300 hover:cursor-pointer hover:bg-gray-100 ${
          active ? 'bg-gray-100' : ''
        } ` + className
      }
      onClick={onClick}
    >
      {icon}
      {title}
    </p>
  );
};
const SettingsModal = ({ caseId, handleClose }: { caseId: string; handleClose: () => void }) => {
  const TABS = {
    CASE_INFORMATION: 'Case Information',
    CASE_DETAILS: 'Case Details',
    DOCUMENTS: 'Documents',
    SETTINGS: 'Settings',
  };
  const [activeTab, setActiveTab] = useState(TABS.CASE_INFORMATION);
  const [confimationModalOpen, setConfirmationModalOpen] = useState(false);
  const [totalUnprocessedDocs, setTotalUnprocessedDocs] = useState(0);

  // -------- Synopsis state at this level so that it will persist between tabs
  const [caseName, setCaseName] = useState('');

  const [originalIssues, setOriginalIssues] = useState('');
  const [caseIssues, setCaseIssues] = useState('');

  const [originalParties, setOriginalParties] = useState('');
  const [caseParties, setCaseParties] = useState('');

  const [originalContext, setOriginalContext] = useState('');
  const [caseContext, setCaseContext] = useState('');

  const [originalTimePeriod, setOriginalTimePeriod] = useState('');
  const [caseTimePeriod, setCaseTimePeriod] = useState('');

  const [detailsChanged, setDetailsChanged] = useState(false);

  const { isFetching, data: caseObjectResp } = useGetCase(caseId);

  useEffect(() => {
    if (caseObjectResp) {
      setCaseName(caseObjectResp?.name);
      setCaseIssues(caseObjectResp?.legalIssues || '');
      setOriginalIssues(caseObjectResp?.legalIssues || '');

      setCaseParties(caseObjectResp?.parties || '');
      setOriginalParties(caseObjectResp?.parties || '');

      setCaseContext(caseObjectResp?.keyContext || '');
      setOriginalContext(caseObjectResp?.keyContext || '');

      setCaseTimePeriod(caseObjectResp?.keyTimePeriod || '');
      setOriginalTimePeriod(caseObjectResp?.keyTimePeriod || '');
    }
  }, [caseObjectResp]);

  const handleIssuesChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCaseIssues(event.target.value);
  };
  const handlePartiesChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCaseParties(event.target.value);
  };
  const handleContextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCaseContext(event.target.value);
  };
  const handleTimePeriodChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCaseTimePeriod(event.target.value);
  };

  useEffect(() => {
    // TODO: maybe ignore this check and just set details changed if any of the handlers are called
    const isIssuesChanged = caseIssues.trim() !== originalIssues.trim();
    const isPartiesChanged = caseParties.trim() !== originalParties.trim();
    const isContextChanged = caseContext.trim() !== originalContext.trim();
    const isTimePeriodChanged = caseTimePeriod.trim() !== originalTimePeriod.trim();
    setDetailsChanged(isIssuesChanged || isPartiesChanged || isContextChanged || isTimePeriodChanged);
  }, [
    caseIssues,
    originalIssues,
    caseParties,
    originalParties,
    caseContext,
    originalContext,
    caseTimePeriod,
    originalTimePeriod,
  ]);
  // --------------------------------------------------------------------------

  const renderContent = (
    tab: string,
    setConfirmationModalOpen: (open: boolean) => void,
    setTotalUnprocessedDocs: (docNumber: number) => void,
  ) => {
    switch (tab) {
      case 'Case Information':
        return <CaseInformation setActiveTab={setActiveTab} />;
      case 'Case Details':
        return (
          <CaseDetails
            caseName={caseName}
            isFetching={isFetching}
            caseIssues={caseIssues}
            caseParties={caseParties}
            caseContext={caseContext}
            caseTimePeriod={caseTimePeriod}
            handleIssuesChange={handleIssuesChange}
            handlePartiesChange={handlePartiesChange}
            handleContextChange={handleContextChange}
            handleTimePeriodChange={handleTimePeriodChange}
            setActiveTab={setActiveTab}
            caseId={caseId}
          />
        );
      case 'Documents':
        return (
          <Documents
            openModal={setConfirmationModalOpen}
            setTotalUnprocessedDocs={setTotalUnprocessedDocs}
            detailsChanged={detailsChanged}
          />
        );
    }
  };

  return (
    <div className="flex h-[650px]">
      <div className="flex w-56 flex-col justify-between border border-y-0 border-l-0 pt-6 pb-10">
        <div className="flex flex-col">
          <NavItem
            title="Case Information"
            icon={<FontAwesomeIcon icon={faCircleInfo} className="text-brandSecondary h-4 w-4" />}
            active={activeTab === 'Case Information'}
            onClick={() => setActiveTab('Case Information')}
            className="pl-6 text-sm font-medium"
          />
          <div className="">
            <NavItem
              title="Details"
              icon={
                <div className="bg-brandSecondary relative flex rounded-full px-1">
                  <FontAwesomeIcon icon={fa1} className="bg-brandSecondary h-1 w-1 text-white" />
                </div>
              }
              active={activeTab === 'Case Details'}
              onClick={() => setActiveTab('Case Details')}
              className="pl-9 text-sm font-light"
            />
            <NavItem
              title="Documents"
              icon={
                <div className="bg-brandSecondary relative flex rounded-full px-1">
                  <FontAwesomeIcon icon={fa2} className="bg-brandSecondary h-1 w-1 text-white" />
                </div>
              }
              active={activeTab === 'Documents'}
              onClick={() => setActiveTab('Documents')}
              className="pl-9 text-sm font-light"
            />
          </div>
        </div>
      </div>
      <div className="w-full px-6 pt-6 pb-3">
        {renderContent(activeTab, setConfirmationModalOpen, setTotalUnprocessedDocs)}
      </div>
      <Modal
        content={
          <Confirmation
            detailsChanged={detailsChanged}
            totalUnprocessedDocs={totalUnprocessedDocs}
            caseId={caseId}
            caseIssues={caseIssues}
            caseParties={caseParties}
            caseContext={caseContext}
            caseTimePeriod={caseTimePeriod}
            closeModal={() => {
              setConfirmationModalOpen(false);
              handleClose();
            }}
          />
        }
        title={
          <div className="mt-1 flex h-full items-center gap-2 text-base font-semibold">
            <FontAwesomeIcon icon={faUpload} className="text-gray-700" />
            Update Case
          </div>
        }
        isOpen={confimationModalOpen}
        handleClose={() => setConfirmationModalOpen(false)}
        size="small"
      />
    </div>
  );
};

export default SettingsModal;
