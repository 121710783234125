import DropdownInput from './DropdownInput';
import FactDocumentDetails from './FactDocumentDetails';
import { useDocumentNavigation } from '../../../screens/Chronos/CaseEditor/hooks/useOpenDocViewer';
import TooltipIcon from '../../atoms/TooltipIcon';
import CommentPopup from '../../molecules/Comments';
import EditableField from '../../molecules/EditableField';
import { APIBaseChronos } from '@/api/hosts';
import useUpdateFact from '@/api/mutations/useUpdateFact';
import { RelevanceOptions } from '@/constants/filterOptions';
import { renderHighlightedTextWithLinks } from '@/helpers/textRendering';
import { Fact, Relevance } from '@/types';

interface FactDetailProps {
  fact: Fact;
  significanceText: string;
  relevanceText: string;
  compressedVersion?: boolean;
  updateRelevance: (v: Relevance) => void;
  onViewDocument?: (docId: string, factId: string, pageNumber: number) => void;
}

const FactDetail = ({
  fact,
  significanceText,
  relevanceText,
  updateRelevance,
  onViewDocument,
  compressedVersion = false,
}: FactDetailProps) => {
  const { openDocViewer } = useDocumentNavigation();
  const { mutate: updateFact, isLoading } = useUpdateFact({ analyticsLocation: 'Fact' });

  const handleUpdateFact = (newFact: string) => {
    updateFact({ field: 'significance', value: newFact, factId: fact.id, caseId: fact.caseId });
  };

  return (
    <div className={`${compressedVersion ? 'text-xs' : 'text-sm'}`}>
      <div className="mt-2 border border-x-0 border-t-0 border-dashed border-blue-200" />

      <div className="flex gap-4 py-4 pr-4 pl-2">
        <div className="w-4/5">
          <div className="mb-1 flex items-center gap-1 px-2 font-semibold text-gray-800">
            Significance
            <TooltipIcon tooltipContent="A short analysis of the significance of the fact as it pertains to the key case issues and the context of the document it is extracted from." />
          </div>
          <div className="px-2 text-gray-800">
            <EditableField value={significanceText} onSave={handleUpdateFact} isLoading={isLoading} />
          </div>
        </div>

        <div className="border border-y-0 border-r-0 border-dashed border-blue-200 pl-6">
          <div className="w-36">
            <div className="mb-1 flex items-center gap-1 font-semibold text-gray-800">
              Relevant
              <TooltipIcon tooltipContent="Is this fact relevant to the key case issues." />
            </div>
            <div
              className={`${
                {
                  [Relevance.YES]: 'text-green-700',
                  [Relevance.NO]: 'text-red-500',
                  [Relevance.MAYBE]: 'text-orange-400',
                }[relevanceText] ?? ''
              }`}
            >
              <DropdownInput
                entryFieldKey={'relevance'}
                entryFieldValue={relevanceText}
                endpointToUpdate={`${APIBaseChronos}/api/case/${fact.caseId}/fact/${fact.id}`}
                options={RelevanceOptions}
                updateRelevance={updateRelevance}
              />
            </div>

            <div className="mt-2 mb-1 flex items-center gap-1 font-semibold text-gray-800">
              Classification
              <TooltipIcon tooltipContent="Fact classification. One of Primary, Secondary, Future or Litigation." />
            </div>
            <div>
              {fact.classification ? fact.classification.charAt(0).toUpperCase() + fact.classification.slice(1) : ''}
            </div>
          </div>
        </div>
      </div>

      <div className="border border-x-0 border-t-0 border-dashed border-blue-200"></div>

      <div className="py-4 pr-4 pl-2">
        <FactDocumentDetails
          fact={fact}
          gotoDocReference={
            onViewDocument
              ? onViewDocument
              : (docId: string, factId: string, pageNum: number) => openDocViewer(docId, factId, pageNum)
          }
        />
      </div>

      <div className="border-2 border-x-0 border-t-0 border-blue-200"></div>

      <div className="flex justify-between gap-10 rounded-b-lg bg-slate-100 px-4">
        <div>
          <div className="pt-2 pb-1 font-semibold">Comments</div>
          <div>
            {fact.comments && fact.comments.length > 0 ? (
              renderHighlightedTextWithLinks(fact.comments[0].content)
            ) : (
              <span className="font-light text-gray-500">No comments yet</span>
            )}
          </div>
        </div>
        <div className="flex items-center gap-2 py-3">
          <CommentPopup
            caseId={fact.caseId}
            factId={fact.id}
            commentsCount={fact.comments.length}
            comments={fact.comments}
          />
        </div>
      </div>
    </div>
  );
};

export default FactDetail;
