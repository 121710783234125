import { useEffect, useState } from 'react';

import { FileText, ExternalLink, Video, Lock, Download, Mail, Copy } from 'lucide-react';
import { toast } from 'sonner';

import { videos, downloadableDocs } from './constants';
import EmailContent from './EmailPopup';
import { getStaticAssetPresignedUrl } from './helpers';
import { trackCustomEvent, trackPageView } from '../../../analytics/Mixpanel';
import useGetFetchConfig from '@/api/useGetFetchConfig';
import { Button } from '@/components/atoms/new-button';
import Modal from '@/components/molecules/Modals/CustomModal';
import PageWrapper from '@/components/molecules/PageWrappers/PageWrapper';

const ClientMaterials = () => {
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const { fetchConfigGET } = useGetFetchConfig();

  const handleDownloadStaticAsset = async (key: string) => {
    try {
      trackCustomEvent(`Downloaded Material ${key}`);
      const url = await getStaticAssetPresignedUrl(key, fetchConfigGET);

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', key);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      toast.success(`Downloading ${key}`);
      return new Promise((resolve) => setTimeout(resolve, 1000));
    } catch (error) {
      console.error('Download error:', error);
      toast.error('Failed to download file');
    }
  };

  const handleDownloadAllDocs = async () => {
    for (const material of downloadableDocs) {
      await handleDownloadStaticAsset(material.url);
    }
  };

  const handleCopyVideoUrl = (url: string) => {
    navigator.clipboard.writeText(url);
    toast.success('Copied to clipboard', {
      description: url,
      duration: 1000,
    });
  };

  const openEmailModal = () => {
    setIsEmailModalOpen(true);
  };

  const closeEmailModal = () => {
    setIsEmailModalOpen(false);
  };

  useEffect(() => {
    trackPageView('Viewed Client Materials');
  }, []);

  return (
    <PageWrapper className="bg-gray-50">
      <div className="flex w-full flex-col items-start justify-start px-4 md:px-6 lg:px-8">
        <div className="mb-8 flex w-full flex-col gap-6">
          <div className="flex w-full justify-between rounded-xl bg-white p-6 shadow-sm">
            <div className="flex flex-col">
              <h1 className="text-xl font-semibold text-gray-800">Client Materials</h1>
              <p className="text-sm text-gray-600">Share resources and materials with your clients.</p>
            </div>
            <div className="flex items-center gap-4">
              <Button variant="primary" onClick={openEmailModal}>
                <Mail className="h-4 w-4" />
                <span>Email Template</span>
              </Button>
            </div>
          </div>

          <div className="w-full rounded-xl bg-white p-6 shadow-sm">
            <div className="flex flex-col gap-6">
              {/* Trust Site Section */}
              <div className="rounded-xl border border-gray-200 bg-gray-50 p-6 shadow-sm">
                <div className="mb-4 flex items-center gap-2">
                  <div className="rounded-full bg-blue-50 p-2">
                    <Lock className="text-brandSecondary h-5 w-5" />
                  </div>
                  <h3 className="text-lg font-medium text-gray-700">Trust & Security</h3>
                </div>
                <div className="rounded-lg border border-gray-200 bg-white p-4">
                  <p className="mb-3 text-gray-600">
                    Our trust site provides detailed information about our security practices, data handling policies,
                    and compliance certifications.
                  </p>
                  <a
                    href="https://trust.wexler.ai/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-flex items-center gap-2 font-medium text-blue-500 hover:text-blue-600"
                  >
                    Visit our Trust Center
                    <ExternalLink className="h-4 w-4" />
                  </a>
                </div>
              </div>

              {/* Downloadable Materials Section */}
              <div className="rounded-xl border border-gray-200 bg-gray-50 p-6 shadow-sm">
                <div className="mb-4 flex items-center justify-between gap-2">
                  <div className="flex items-center gap-2">
                    <div className="rounded-full bg-blue-50 p-2">
                      <FileText className="text-brandSecondary h-5 w-5" />
                    </div>
                    <h3 className="text-lg font-medium text-gray-700">Overview Documents</h3>
                  </div>
                  <Button variant="primary" onClick={handleDownloadAllDocs}>
                    <Download className="h-4 w-4" />
                    <span>Download All</span>
                  </Button>
                </div>
                <p className="mb-4 text-gray-600">
                  These documents provide detailed information about our services and address common questions from
                  clients.
                </p>
                <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                  {downloadableDocs.map((material, index) => (
                    <div
                      key={index}
                      className="hover:border-brandSecondary flex cursor-pointer flex-col rounded-lg border border-gray-200 bg-white p-4 transition-all duration-200 hover:shadow-md"
                      onClick={() => handleDownloadStaticAsset(material.url)}
                    >
                      <div className="flex items-start justify-between gap-3">
                        <div className="flex items-start gap-3">
                          <FileText className="text-brandSecondary h-5 w-5" />
                          <div>
                            <h4 className="font-medium text-gray-800">{material.title}</h4>
                          </div>
                        </div>
                        <Download className="h-5 w-5 text-gray-600" />
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {/* Videos Section */}
              <div className="rounded-xl border border-gray-200 bg-gray-50 p-6 shadow-sm">
                <div className="mb-4 flex items-center gap-2">
                  <div className="rounded-full bg-blue-50 p-2">
                    <Video className="text-brandSecondary h-5 w-5" />
                  </div>
                  <h3 className="text-lg font-medium text-gray-700">Feature Videos</h3>
                </div>
                <p className="mb-4 text-gray-600">Videos explaining Wexler's main features.</p>
                <div className="grid grid-cols-1 gap-4 md:grid-cols-4">
                  {videos.map((video, index) => (
                    <div
                      key={index}
                      className="flex h-full flex-col justify-between rounded-lg border border-gray-200 bg-white p-4"
                    >
                      <div>
                        <div className="mb-2 flex items-center gap-2">
                          {video.icon}
                          <h4 className="font-medium text-gray-800">{video.title}</h4>
                        </div>
                        <p className="text-sm text-gray-600">{video.description}</p>
                      </div>
                      <div className="mt-4 flex gap-2">
                        <a
                          href={video.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="flex flex-1 items-center justify-center rounded-md border border-gray-200 bg-white px-2 py-1.5 text-xs font-medium text-blue-500 hover:bg-blue-50 hover:text-blue-600"
                        >
                          <ExternalLink className="mr-1 h-3.5 w-3.5" />
                          Watch Video
                        </a>
                        <button
                          onClick={() => handleCopyVideoUrl(video.url)}
                          className="flex flex-1 items-center justify-center rounded-md border border-gray-200 bg-white px-2 py-1.5 text-xs font-medium text-gray-600 hover:bg-gray-50"
                        >
                          <Copy className="mr-1 h-3.5 w-3.5" />
                          Copy Link
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={isEmailModalOpen} handleClose={closeEmailModal} content={<EmailContent />} maxWidth="40rem" />
    </PageWrapper>
  );
};

export default ClientMaterials;
