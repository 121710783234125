import { ConfidenceLevelObject } from '@/types';

const ConfidenceTooltip = ({ confidenceLevel }: { confidenceLevel: ConfidenceLevelObject }) => {
  return (
    <div>
      <div className={`flex h-24 items-center justify-center ${confidenceLevel.tooltipColor}/80`}></div>
      <div className="px-8 pb-10">
        <p className="pt-4 pb-2 text-lg font-semibold">{confidenceLevel.text} Confidence</p>
        <div className="flex flex-col gap-4 text-gray-700">
          <p>{confidenceLevel.tooltip}</p>
          <p className="border border-x-0 border-b-0 pt-4 text-sm text-gray-500">
            All Kim answers have a confidence level, ranging from High to Low.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ConfidenceTooltip;
