import { memo, useCallback, useState } from 'react';

import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Clock } from 'lucide-react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useDeleteInconsistencyReport } from '../../../../../api/mutations/useDeleteInconsistencyReport';
import { useUpdateInconsistencyReport } from '../../../../../api/mutations/useUpdateInconsistencyReport';
import { useUserList } from '../../../../../api/queries/useUsersList';
import { InconsistencyReport } from '../../../../../api/types/backend-dto-types';
import Button from '@/components/atoms/Button';
import EditableField from '@/components/molecules/EditableField';
import ConfirmationModal from '@/components/molecules/Modals/Confirmation';
import { useUserContext } from '@/Contexts/User';
import formatDate from '@/helpers/formatDate';

export const InconsistencyReportListItem = memo(({ report }: { report: InconsistencyReport }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [editMode, setEditMode] = useState(false);
  const [currentName, setCurrentName] = useState(report.title ?? '');
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const { user } = useUserContext();
  const userList = useUserList();

  const { mutate: updateReport, isLoading: isLoadingReportUpdate } = useUpdateInconsistencyReport({
    reportId: report.id,
    caseId: report.caseId,
  });
  const { mutate: deleteReport, isLoading: isDeletingReport } = useDeleteInconsistencyReport({
    reportId: report.id,
    caseId: report.caseId,
  });

  const onReportClick = useCallback(() => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('page', '1');
    searchParams.set('reportId', report.id);
    searchParams.set('title', currentName);
    navigate('/app/chronos/case-editor/data/inconsistency-report/view?' + searchParams.toString());
  }, [report.id, currentName]);

  const onEditSave = (updatedValue: string) => {
    setCurrentName(updatedValue);
    if (updatedValue !== report.title) {
      setEditMode(false);
      updateReport(updatedValue);
    }
  };

  const handleDeleteReport = async () => {
    deleteReport();
    setDeleteModalOpen(false);
  };

  const lastUpdatedDate = report.editedDate ?? report.createdDate;
  const lastEditedByEmail = userList?.find((u) => u.id === report.editedById)?.email;

  return (
    <div
      className="group relative mb-3 flex w-full flex-col rounded-lg border bg-white p-4 shadow-sm transition-all hover:border-gray-300 hover:shadow-md"
      key={report.id}
    >
      <div className="flex w-full flex-col gap-3">
        <div className="flex items-center justify-between gap-4">
          <div className="min-w-0 flex-1">
            <div className="flex items-center gap-2">
              <FontAwesomeIcon icon={faFileAlt} />
              <div className="truncate text-sm font-semibold text-gray-900">
                <EditableField
                  value={report.title}
                  onFieldClick={onReportClick}
                  isLoading={isLoadingReportUpdate}
                  onSave={onEditSave}
                />
              </div>
            </div>
          </div>
          <div className="flex-shrink-0">
            <span className="inline-flex items-center rounded-full bg-blue-50 px-2.5 py-1 text-xs font-medium text-blue-700 ring-1 ring-blue-100 ring-inset">
              Created on {formatDate(report.createdDate)}
            </span>
          </div>
        </div>

        <div className="flex items-center justify-between gap-2 border-t border-dashed pt-4">
          <div className="flex items-center gap-2">
            <Button text="View" type="primary" size="xs" rounded="md" onClick={onReportClick} disabled={editMode} />
            {user?.id === report.createdById && (
              <Button text="Delete" type="delete" size="xs" rounded="md" onClick={() => setDeleteModalOpen(true)} />
            )}
          </div>
          {lastEditedByEmail && (
            <div className="flex items-center gap-2 rounded-full bg-gray-50 px-3 py-1 text-xs text-gray-600 ring-1 ring-gray-100 ring-inset">
              <Clock className="h-3.5 w-3.5 text-gray-400" />
              <span>
                Last updated by {lastEditedByEmail} on {formatDate(lastUpdatedDate)}
              </span>
            </div>
          )}
        </div>
      </div>

      <ConfirmationModal
        isOpen={deleteModalOpen}
        title="Delete report"
        description="Are you sure you want to delete this report?"
        onConfirm={handleDeleteReport}
        isLoading={isDeletingReport}
        handleClose={() => setDeleteModalOpen(false)}
      />
    </div>
  );
});
