import { memo, useCallback, useState } from 'react';

import { faListTimeline } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChartGantt, Clock } from 'lucide-react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useDeleteChronology } from '@/api/mutations/useDeleteChronology';
import { useUpdateChronology } from '@/api/mutations/useUpdateChronology';
import Button from '@/components/atoms/Button';
import EditableField from '@/components/molecules/EditableField';
import ConfirmationModal from '@/components/molecules/Modals/Confirmation';
import { useUserContext } from '@/Contexts/User';
import formatDate from '@/helpers/formatDate';
import { Chronology } from '@/types';

const ChronologyListItem = memo(({ chronology }: { chronology: Chronology }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [editMode, setEditMode] = useState(false);
  const [currentName, setCurrentName] = useState(chronology.title || '');
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const { user } = useUserContext();

  const { mutate: updateChronologyName, isLoading: isLoadingUpdateChronologyName } = useUpdateChronology({
    chronologyId: chronology.id,
    caseId: chronology.caseId,
  });
  const { mutate: deleteChronology, isLoading: isDeleting } = useDeleteChronology({
    chronologyId: chronology.id,
    caseId: chronology.caseId,
  });

  const onChronologyClick = useCallback(() => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('page', '1');
    searchParams.set('chronologyId', chronology.id);
    searchParams.set('title', currentName);
    navigate('/app/chronos/case-editor/data/chronology/view?' + searchParams.toString());
  }, [chronology.id, currentName]);

  const onEditSave = (updatedValue: string) => {
    setCurrentName(updatedValue);
    if (updatedValue !== chronology.title) {
      setEditMode(false);
      updateChronologyName(updatedValue);
    }
  };

  const handleDeleteChronology = async () => {
    await deleteChronology();
    setDeleteModalOpen(false);
  };

  const lastUpdatedDate = chronology.editedDate || chronology.createdDate;

  return (
    <div
      className="group relative mb-3 flex w-full flex-col rounded-lg border bg-white p-4 shadow-sm transition-all hover:border-gray-300 hover:shadow-md"
      key={chronology.id}
    >
      <div className="flex w-full flex-col gap-3">
        <div className="flex items-center justify-between gap-4">
          <div className="min-w-0 flex-1">
            <div className="flex items-center gap-2">
              <FontAwesomeIcon icon={faListTimeline} />
              <div className="truncate text-sm font-semibold text-gray-900">
                <EditableField
                  value={chronology.title}
                  onFieldClick={onChronologyClick}
                  isLoading={isLoadingUpdateChronologyName}
                  onSave={onEditSave}
                />
              </div>
            </div>
          </div>
          <div className="flex-shrink-0">
            <span className="inline-flex items-center rounded-full bg-blue-50 px-2.5 py-1 text-xs font-medium text-blue-700 ring-1 ring-blue-100 ring-inset">
              Created by {chronology.createdBy.email} on {formatDate(chronology.createdDate)}
            </span>
          </div>
        </div>

        <div className="flex items-center justify-between gap-2 border-t border-dashed pt-4">
          <div className="flex items-center gap-2">
            <Button text="View" type="primary" size="xs" rounded="md" onClick={onChronologyClick} disabled={editMode} />
            {user?.id === chronology.createdById && (
              <Button text="Delete" type="delete" size="xs" rounded="md" onClick={() => setDeleteModalOpen(true)} />
            )}
          </div>
          {chronology.editedBy && (
            <div className="flex items-center gap-2 rounded-full bg-gray-50 px-3 py-1 text-xs text-gray-600 ring-1 ring-gray-100 ring-inset">
              <Clock className="h-3.5 w-3.5 text-gray-400" />
              <span>
                Last updated by {chronology.editedBy.email} on {formatDate(lastUpdatedDate)}
              </span>
            </div>
          )}
        </div>
      </div>

      <ConfirmationModal
        isOpen={deleteModalOpen}
        title="Delete chronology"
        description="Are you sure you want to delete this chronology?"
        onConfirm={handleDeleteChronology}
        isLoading={isDeleting}
        handleClose={() => setDeleteModalOpen(false)}
      />
    </div>
  );
});

export default ChronologyListItem;
