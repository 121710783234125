import { useMutation, useQueryClient } from 'react-query';

import { APIBaseChronos } from '@/api/hosts';
import useGetFetchConfig, { FetchMethod } from '@/api/useGetFetchConfig';

interface PreSaveCaseData {
  name: string;
  legalIssues: string;
  type: string;
  parties: string;
  keyContext?: string;
  keyTimePeriod?: string;
  disputeStatus?: string;
}

export const useSaveCase = (caseId: string) => {
  const { getFetchConfig } = useGetFetchConfig();
  const queryClient = useQueryClient();

  const saveCase = async (data: PreSaveCaseData) => {
    const fetchConfig = getFetchConfig({
      method: FetchMethod.PATCH,
      data,
    });
    await fetch(`${APIBaseChronos}/api/case/${caseId}/metadata`, fetchConfig);
  };

  return useMutation({
    mutationFn: saveCase,
    onSuccess: () => {
      queryClient.invalidateQueries(['caseMetadata', caseId]);
    },
  });
};
