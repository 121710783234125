import { useMemo } from 'react';

import { useGetDocPresignedURL } from '../../../../../../api/queries/useGetDocPresignedURL';
import { useGetFact } from '../../../../../../api/queries/useGetFact';
import { useDocumentNavigation } from '../../../hooks/useOpenDocViewer';
import { processFactHighlights } from '../components/utils';
import PDFViewer from '@/components/organisms/PDFViewer';

const FactDocViewer = ({
  caseId,
  docId,
  factId,
  onClose,
  hideCloseButton,
}: {
  caseId: string;
  docId: string;
  factId: string;
  onClose?: () => void;
  hideCloseButton?: boolean;
}) => {
  const { closeDocViewer } = useDocumentNavigation();

  // Data fetching
  const { data: docUrl } = useGetDocPresignedURL(caseId, docId);
  const { data: fact } = useGetFact(caseId, factId);

  const { coordinates } = useMemo(() => {
    return processFactHighlights(fact ? [fact] : [], docId);
  }, [fact, docId]);

  return (
    <div className="flex h-full flex-col">
      <div className="flex-grow overflow-hidden">
        <div className="h-full">
          {docUrl && (
            <PDFViewer
              fileUrl={docUrl}
              highlights={coordinates}
              closeViewer={!hideCloseButton ? (onClose ?? closeDocViewer) : undefined}
              highlightedFactId={factId}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default FactDocViewer;
