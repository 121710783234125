import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { APIBaseChronos } from '../hosts';
import { QueryEntity } from '../types';
import useGetFetchConfig, { FetchMethod } from '../useGetFetchConfig';
import { trackCustomEvent } from '@/analytics/Mixpanel';

interface UseUpdateChronologyFact {
  field: string;
  value: string;
  factId: string;
  chronologyId: string;
}

const useUpdateChronologyFact = ({ analyticsLocation, caseId }: { analyticsLocation: string; caseId: string }) => {
  const { getFetchConfig } = useGetFetchConfig();
  const queryClient = useQueryClient();

  return useMutation(async ({ field, value, factId, chronologyId }: UseUpdateChronologyFact) => {
    const fetchConfig = getFetchConfig({ method: FetchMethod.PATCH, data: { [field]: value } });
    return fetch(`${APIBaseChronos}/api/case/${caseId}/chronology/${chronologyId}/fact/${factId}`, fetchConfig)
      .then((res) => {
        if (!res.ok) {
          toast.error('Failed to update field');
          return;
        }
        trackCustomEvent(`Field updated ${field} - ${analyticsLocation}`, { chronologyId });
        queryClient.invalidateQueries({ queryKey: [QueryEntity.Chronology, { chronologyId }] });
      })
      .catch((err) => {
        toast.error('Failed to update field');
      });
  });
};

export default useUpdateChronologyFact;
