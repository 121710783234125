import * as React from 'react';

import { ArrowRight, Check, ChevronsUpDown, AlertCircle } from 'lucide-react';
import { z } from 'zod';

import { Option } from '../../types';
import { Input } from '../atoms/input';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/atoms/Command';
import { Button } from '@/components/atoms/new-button';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/atoms/Popover';
import { cn } from '@/helpers/shadCnUtils';

// Email validation schema
const emailSchema = z.string().email('Please enter a valid email address');

export const AddUserInput = ({
  options,
  onCreate,
  onAdd,
}: {
  options: (Option & { id: string })[];
  onCreate: (email: string) => void;
  onAdd: (user: Option) => void;
}) => {
  const [open, setOpen] = React.useState(false);
  const [showEmailInput, setShowEmailInput] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [emailError, setEmailError] = React.useState<string | null>(null);
  const [attemptedSubmit, setAttemptedSubmit] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState<string | null>(null);

  const handleInviteClick = () => {
    setShowEmailInput(true);
    setEmailError(null);
    setSuccessMessage(null);
    setAttemptedSubmit(false);
  };

  const validateEmail = (email: string): boolean => {
    try {
      emailSchema.parse(email);
      setEmailError(null);
      return true;
    } catch (error) {
      if (error instanceof z.ZodError) {
        setEmailError(error.errors[0].message);
      }
      return false;
    }
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEmail(value);

    // Only validate in real-time if they've already tried to submit once
    if (attemptedSubmit && value.trim()) {
      validateEmail(value);
    } else if (attemptedSubmit && !value.trim()) {
      setEmailError('Email is required');
    }
  };

  const handleEmailSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setAttemptedSubmit(true);

    if (!email.trim()) {
      setEmailError('Email is required');
      return;
    }

    if (validateEmail(email.trim()) && onCreate) {
      onCreate(email.trim());
      setEmail('');
      setEmailError(null);
      setAttemptedSubmit(false);
      setSuccessMessage(`Invitation sent to ${email.trim()}`);

      // Hide success message after 1.5 seconds
      setTimeout(() => {
        setSuccessMessage(null);
        setShowEmailInput(false);
        setOpen(false);
      }, 1500);
    }
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button variant="outline" role="combobox" aria-expanded={open} className="justify-between">
          Select team members...
          <ChevronsUpDown className="opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent side="top" align="center">
        <Command>
          <CommandInput onFocus={() => setShowEmailInput(false)} placeholder="Search team members..." />
          <CommandList>
            <CommandEmpty>
              <div className="flex flex-col items-center gap-3 py-2 text-sm">
                {!showEmailInput ? (
                  <div className="text-center">
                    <p className="font-medium text-gray-700">Can't find who you're looking for?</p>
                    <p className="mt-1 text-gray-500">
                      <div
                        className="inline-flex cursor-pointer items-center font-medium text-blue-600 hover:text-blue-800"
                        onClick={handleInviteClick}
                      >
                        Invite them to Wexler
                        <ArrowRight className="ml-1 h-4 w-4" />
                      </div>
                    </p>
                  </div>
                ) : (
                  <form onSubmit={handleEmailSubmit} className="w-full space-y-3 rounded border bg-gray-50 p-4">
                    <div className="text-left">
                      <p className="font-medium text-gray-700">Enter email address to invite</p>
                    </div>

                    {successMessage ? (
                      <div className="flex items-center space-x-2 rounded-md bg-green-50 p-3 text-sm text-green-700">
                        <Check className="h-4 w-4 text-green-500" />
                        <p>{successMessage}</p>
                      </div>
                    ) : (
                      <div className="space-y-2">
                        <div className="relative">
                          <Input
                            value={email}
                            onChange={handleEmailChange}
                            placeholder="name@example.com"
                            className={cn(
                              'bg-white text-xs transition-colors',
                              attemptedSubmit && emailError
                                ? 'border-red-300 focus-visible:ring-red-400'
                                : 'focus-visible:ring-blue-400',
                            )}
                          />
                          {attemptedSubmit && emailError && (
                            <AlertCircle className="absolute top-1/2 right-3 h-4 w-4 -translate-y-1/2 text-red-500" />
                          )}
                        </div>

                        {attemptedSubmit && emailError && (
                          <p className="text-xs font-medium text-red-500">{emailError}</p>
                        )}
                      </div>
                    )}

                    <div className="flex items-center justify-between pt-1">
                      {!successMessage && (
                        <Button type="submit" variant="primary" size="sm" disabled={!email.trim()}>
                          Invite
                          <ArrowRight className="h-2 w-2" />
                        </Button>
                      )}
                    </div>
                  </form>
                )}
              </div>
            </CommandEmpty>
            <CommandGroup>
              {options.map((option) => (
                <CommandItem
                  className="cursor-pointer hover:bg-gray-100"
                  key={option.value}
                  value={option.value}
                  onSelect={() => {
                    setOpen(false);
                    onAdd({ label: option.label, value: option.id });
                  }}
                >
                  {option.label}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
