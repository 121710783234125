import { useCallback, useEffect, useState } from 'react';

import { faStar as farStar } from '@fortawesome/free-regular-svg-icons';
import {
  faArrowsRotate,
  faFilter,
  faPlusCircle,
  faStar,
  faTimeline,
  faWarning,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQuery } from 'react-query';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Tour from 'reactour';

import IncludeAllDropdown from '../components/IncludeAllDropdown';
import AddToChronologyModalContent from './components/AddToChronologyModal';
import FactAdder from './components/FactAdder';
import FactsFilterPopup from './components/FilterPopup';
import { OrgPermission } from '../../../../../../../../backend/src/organisation/organisation-permission';
import { ShownSelectedCountsHeader } from '../../components/ShownSelectedCountsHeader';
import { trackCustomEvent } from '@/analytics/Mixpanel';
import { APIBaseChronos } from '@/api/hosts';
import { useGetChronologies } from '@/api/queries/useGetChronologies';
import useGetFetchConfig, { FetchMethod } from '@/api/useGetFetchConfig';
import Button from '@/components/atoms/Button';
import Dropdown from '@/components/molecules/Dropdown';
import ExpandableButton from '@/components/molecules/ExpandableButton';
import CustomModal from '@/components/molecules/Modals/SettingsModal';
import SearchBox from '@/components/molecules/SearchBox';
import Pagination from '@/components/organisms/Pagination';
import { ShadowPulseColors } from '@/constants/styles';
import { SEARCH_STEPS } from '@/constants/tours';
import { useOrgPermissions } from '@/hooks/orgPermissions';
import DataViewToolbar from '@/screens/Chronos/CaseEditor/DataView/components/TableToolbar';
import { Doc, DocIds, FactFilters } from '@/types';

interface FactsEditorToolbarProps {
  caseId: string | null;
  docId?: string;
  globalFilter: string;
  resultsLength: number;
  resultsIncludedLength: number;
  isDateDirty: boolean;
  refetchCaseFacts: () => void;
  clearSearch: () => void;
  setGlobalFilter: (value: string) => void;
  canGetPrevPage: boolean;
  canGetNextPage: boolean;
  prevPage: () => void;
  nextPage: () => void;
  currentPage: number;
  noOfPages: number;
  onSearchCall: () => void;
  goToPage: (value: number) => void;
  keyFactFilterActive: boolean;
  hasKeyFacts: boolean;
  loadingKeyFacts: boolean;
  handleKeyFactFilterToggle: () => void;
  disputedFactFilterActive: boolean;
  handleDisputedFactFilterToggle: () => void;
  handleToggleAll: (v: boolean) => void;
  allDocuments: DocIds[];
  activeFilters: FactFilters;
  setFilters: (v: FactFilters) => void;
}

const FactsEditorToolbar = ({
  docId,
  caseId,
  globalFilter,
  refetchCaseFacts,
  clearSearch,
  setGlobalFilter,
  resultsLength,
  resultsIncludedLength,
  canGetPrevPage,
  canGetNextPage,
  prevPage,
  nextPage,
  currentPage,
  noOfPages,
  isDateDirty,
  onSearchCall,
  goToPage,
  keyFactFilterActive,
  hasKeyFacts,
  loadingKeyFacts,
  handleKeyFactFilterToggle,
  disputedFactFilterActive,
  handleDisputedFactFilterToggle,
  handleToggleAll,
  allDocuments,
  activeFilters,
  setFilters,
}: FactsEditorToolbarProps) => {
  // State
  const [factAdderModalIsOpen, setFactAdderModalIsOpen] = useState(false);
  const [filtersModalOpen, setFiltersModalOpen] = useState(false);
  const [selectChronologyModalIsOpen, setSelectChronologyModalIsOpen] = useState(false);
  const [isLoadingChronology, setIsLoadingChronology] = useState(false);
  const [docs, setDocs] = useState<Doc[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [exploreTourOpen, setExploreTourOpen] = useState(false);
  const [activeFilterCount, setActiveFilterCount] = useState(0);

  const location = useLocation();
  const navigate = useNavigate();

  // Data fetching
  const { fetchConfigGET, getFetchConfig } = useGetFetchConfig();

  const fetchCaseDocs = () => {
    return fetch(`${APIBaseChronos}/api/case/${caseId}/doc-ids`, fetchConfigGET).then((res) => res.json());
  };

  const { data: responseDocs, refetch: refetchCaseDocs } = useQuery(['userDocs', docId], fetchCaseDocs, {
    enabled: false,
  });

  const { isEnabled } = useOrgPermissions();

  // Effects
  useEffect(() => {
    if (responseDocs && responseDocs.length > 0) {
      setDocs(responseDocs);
    }
  }, [responseDocs]);

  useEffect(() => {
    refetchCaseDocs();
  }, [docId, refetchCaseDocs]);

  const handleNewFactCreated = useCallback(() => {
    trackCustomEvent('Add Fact from Facts Editor', { caseId });

    refetchCaseFacts();
    setFactAdderModalIsOpen(false);
  }, [refetchCaseFacts]);

  useEffect(() => {
    // Check if explore = true is in seach params
    if (searchParams.get('explore') === 'true') {
      setExploreTourOpen(true);

      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.delete('explore');
      // Update the URL without the 'explore' parameter
      setSearchParams(newSearchParams, { replace: true });
    }
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    let count = 0;
    Object.keys(activeFilters).forEach((key) => {
      const filterValues = activeFilters[key as keyof FactFilters];
      if (filterValues && Array.isArray(filterValues) && filterValues.length > 0) {
        count += 1;
      }
    });
    setActiveFilterCount(count);
  }, [activeFilters]);

  // Handlers
  const { data: chronologiesResponse, isLoading: isLoadingChronologies } = useGetChronologies({
    caseId: caseId ?? '',
    paginated: false,
  });

  async function startChronology(chronologyId: string | null) {
    setIsLoadingChronology(true);

    const fetchConfig = getFetchConfig({
      method: FetchMethod.POST,
      data: {
        caseId,
        addToExisting: chronologyId !== null,
        existingChronologyId: chronologyId,
      },
    });

    try {
      const res = await fetch(`${APIBaseChronos}/api/case/${caseId}/chronology`, fetchConfig);
      const responseJson = await res.json();
      const searchParams = new URLSearchParams(location.search);

      searchParams.set('chronologyId', responseJson.chronologyId);
      searchParams.set('title', responseJson.chronologyTitle);
      searchParams.set('page', '1');

      navigate('/app/chronos/case-editor/data/chronology/view?' + searchParams.toString(), { replace: true });
    } catch (err) {
      toast.error(`${err}`);
    } finally {
      setIsLoadingChronology(false);
    }
  }

  const onDropdownClick = (option: string) => {
    if (option === 'new') {
      handleCreateChronology();
    } else {
      handleAddToExistingChronology();
    }
  };

  const handleCreateChronology = () => {
    startChronology(null);
    trackCustomEvent('Create chronology from Facts Editor', { caseId });
  };

  const handleAddToExistingChronology = () => {
    setSelectChronologyModalIsOpen(true);
  };

  const handleSelectChronology = (chronologyId: string) => {
    closeSelectChronologyModal();
    startChronology(chronologyId);
    trackCustomEvent('Add to existing chronology from Facts Editor', { caseId, chronologyId });
  };

  const closeSelectChronologyModal = useCallback(() => {
    setSelectChronologyModalIsOpen(false);
  }, []);

  const dropdownOptions = [
    { label: 'Create New', key: 'new' },
    { label: 'Add to Existing', key: 'existing' },
  ];

  return (
    <>
      <DataViewToolbar
        titleSection={
          <ShownSelectedCountsHeader
            header="Facts"
            resultsLength={resultsLength}
            resultsIncludedLength={resultsIncludedLength}
            handleToggleAll={handleToggleAll}
          />
        }
        actionButtons={
          <>
            <Dropdown
              id="chronology"
              placeholder="Chronology"
              buttonIcon={faTimeline}
              onOptionClick={onDropdownClick}
              options={dropdownOptions}
              isDisabled={resultsIncludedLength === 0}
              isLoading={isLoadingChronology}
            />
            <Button
              icon={<FontAwesomeIcon icon={faPlusCircle} className="pr-0 xl:pr-2" />}
              text="Add Fact"
              onClick={() => setFactAdderModalIsOpen(true)}
              type="secondary"
              size="xs"
              rounded="base"
              hideTextSmallScreen
              className="px-2 py-2 shadow"
            />
          </>
        }
        searchBar={
          <div id="facts-search-bar">
            <SearchBox
              value={globalFilter}
              placeholder={`Search ${resultsLength ?? 0} facts`}
              onChange={setGlobalFilter}
              onSearchCall={onSearchCall}
              clearSearch={clearSearch}
            />
          </div>
        }
        filterButtons={
          <>
            {isDateDirty && (
              <div className="animate-popIn flex scale-0 transform items-center justify-center transition-transform">
                <ExpandableButton icon={faArrowsRotate} onClick={refetchCaseFacts}>
                  <p className="text-sm">Refresh Table</p>
                </ExpandableButton>
              </div>
            )}

            {isEnabled(OrgPermission.Inconsistencies) && (
              <ExpandableButton
                icon={faWarning}
                pulseColor={ShadowPulseColors.red}
                isActive={disputedFactFilterActive}
                onClick={handleDisputedFactFilterToggle}
                className={` ${disputedFactFilterActive && 'border-red-300 bg-red-50 text-red-500'}`}
              >
                <p className="text-sm">Disputed Facts</p>
              </ExpandableButton>
            )}

            <ExpandableButton
              hidden={!hasKeyFacts}
              isLoading={loadingKeyFacts}
              isActive={keyFactFilterActive}
              icon={keyFactFilterActive ? faStar : farStar}
              onClick={handleKeyFactFilterToggle}
              className={`${keyFactFilterActive && 'border-yellow-300 bg-yellow-50 text-yellow-500'}`}
            >
              <p className="text-sm">Key Facts</p>
            </ExpandableButton>
            <div className="relative">
              <ExpandableButton icon={faFilter} onClick={() => setFiltersModalOpen(true)}>
                <p className="text-sm">Filters</p>
              </ExpandableButton>
              {activeFilterCount > 0 ? (
                <div className="bg-brandTertiary/20 absolute top-[-8px] right-[-8px] rounded-full border px-[4px] text-xs">
                  {activeFilterCount}
                </div>
              ) : null}
            </div>
          </>
        }
        pagination={
          <Pagination
            canGetPrevPage={canGetPrevPage}
            canGetNextPage={canGetNextPage}
            prevPage={prevPage}
            nextPage={nextPage}
            currentPage={currentPage}
            noOfPages={noOfPages}
            goToPage={goToPage}
          />
        }
      />

      <Tour
        startAt={0}
        steps={SEARCH_STEPS}
        isOpen={exploreTourOpen}
        onRequestClose={() => setExploreTourOpen(false)}
      />
      <CustomModal
        title={
          <div className="flex items-center gap-2">
            <FontAwesomeIcon icon={faFilter} className="text-gray-600" />
            Filters
          </div>
        }
        content={
          <FactsFilterPopup
            activeFilters={activeFilters}
            allDocuments={allDocuments}
            setFilters={setFilters}
            handleClose={() => setFiltersModalOpen(false)}
          />
        }
        isOpen={filtersModalOpen}
        size="small"
        handleClose={() => setFiltersModalOpen(false)}
      />
      <CustomModal
        title={
          <div className="flex items-center gap-2 font-semibold text-blue-500">
            <FontAwesomeIcon icon={faTimeline} className="" />
            Select Existing Chronology
          </div>
        }
        content={
          <AddToChronologyModalContent
            isLoadingChronologies={isLoadingChronologies}
            chronologies={chronologiesResponse?.chronologies ?? []}
            handleSelectChronology={handleSelectChronology}
          />
        }
        isOpen={selectChronologyModalIsOpen}
        size="small"
        handleClose={closeSelectChronologyModal}
      />
      <CustomModal
        title={
          <div className="flex items-center gap-2 font-semibold text-blue-500">
            <FontAwesomeIcon icon={faPlusCircle} className="" />
            Add Fact
          </div>
        }
        content={
          <FactAdder caseId={caseId || ''} docs={docs} handleNewFactCreated={handleNewFactCreated} docId={docId} />
        }
        isOpen={factAdderModalIsOpen}
        size="small"
        handleClose={() => setFactAdderModalIsOpen(false)}
      />
    </>
  );
};

export default FactsEditorToolbar;
