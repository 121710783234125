import { ReactNode, useCallback, useEffect, useState } from 'react';

import {
  faCircleCheck,
  faCircleNotch,
  faClipboardList,
  faTrash,
  faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSearchParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { twMerge } from 'tailwind-merge';

import { ThreadStatus } from '../../../../../types';
import useDeleteThread from '../hooks/useDeleteThread';
import { APIBaseChronos } from '@/api/hosts';
import useGetFetchConfig from '@/api/useGetFetchConfig';
import { TooltipStyles } from '@/constants/styles';
import formatDate from '@/helpers/formatDate';

interface ThreadItemProps {
  threadText: string;
  threadId: string;
  goToThread: (threadId: string) => void;
  threadStatus: ThreadStatus;
  isActive: boolean;
  createdAt: string;
}

const ACTIVE_STATUSES: ThreadStatus[] = [ThreadStatus.PlanExecuting, ThreadStatus.PlanLoading];

const Icon = ({ status, icon }: { status: ThreadStatus; icon: ReactNode }) => {
  return ACTIVE_STATUSES.includes(status) ? (
    <FontAwesomeIcon icon={faCircleNotch} className="fa-spin mr-2 h-3 w-3" />
  ) : status === ThreadStatus.PlanLoaded ? (
    <FontAwesomeIcon icon={faClipboardList} className="mr-2 h-3 w-3 text-gray-600" />
  ) : status === ThreadStatus.Failed ? (
    <FontAwesomeIcon icon={faTriangleExclamation} className="mr-2 h-3 w-3 text-red-600" />
  ) : status === ThreadStatus.PlanComplete && icon ? (
    <div className="animate-popInHalf flex items-center">{icon}</div>
  ) : null;
};

const ThreadsBarItem = ({ threadText, threadId, goToThread, threadStatus, createdAt, isActive }: ThreadItemProps) => {
  const [searchParams] = useSearchParams();
  const [status, setStatus] = useState<ThreadStatus>(threadStatus);
  const [icon, setIcon] = useState<ReactNode>();
  const caseId = searchParams.get('caseId');
  const { fetchConfigGET } = useGetFetchConfig();

  const { mutate: deleteThread, isLoading } = useDeleteThread(caseId || '');

  const onDeleteThreadClick = () => deleteThread(threadId);

  const fetchThread = useCallback(
    async (threadId: string) => {
      const response = await fetch(`${APIBaseChronos}/api/case/${caseId}/thread/${threadId}`, fetchConfigGET);
      if (!response.ok) {
        console.error('Fetching thread failed');
        return null;
      }
      return response.json();
    },
    [caseId, fetchConfigGET],
  );

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    const pollThread = async (threadId: string) => {
      const response = await fetchThread(threadId);
      if (!response || !response.thread || !response.thread.length) return;
      const thread = response.thread[0];
      if (!thread) return;
      const currentStatus: ThreadStatus = thread.status;

      if (currentStatus === ThreadStatus.PlanLoaded) {
        setIcon(<FontAwesomeIcon icon={faClipboardList} className="mr-2 h-3 w-3 text-gray-600" />);
        setStatus(currentStatus);
      } else if (currentStatus === ThreadStatus.PlanComplete) {
        setStatus(currentStatus);
        setIcon(<FontAwesomeIcon icon={faCircleCheck} className="mr-2 h-3 w-3 text-green-600" />);
        setTimeout(() => {
          setIcon(undefined);
        }, 2000);
      }
    };

    if (status === ThreadStatus.PlanExecuting || status === ThreadStatus.PlanLoading) {
      intervalId = setInterval(() => {
        pollThread(threadId);
      }, 4000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [status, threadId, fetchThread]);

  const canDelete = !ACTIVE_STATUSES.includes(status) && !isActive;
  const tooltipId = `${threadId}-full-details`;

  return (
    <div
      data-tooltip-id={tooltipId}
      className={twMerge(
        `group flex cursor-pointer items-center justify-between rounded border transition-colors duration-150 hover:bg-gray-200`,
        status === ThreadStatus.PlanComplete && icon ? 'animate-border-pulse-green' : 'border-gray-50/50',
        isActive && 'bg-gray-300',
      )}
    >
      <div
        className={`w-5/6 cursor-pointer overflow-hidden px-2 py-2 text-xs text-ellipsis`}
        onClick={() => threadId && goToThread(threadId)}
      >
        <div className="overflow-hidden text-ellipsis whitespace-nowrap">{threadText}</div>
      </div>

      <Icon status={status} icon={icon} />
      {canDelete && (
        <FontAwesomeIcon
          icon={isLoading ? faCircleNotch : faTrash}
          onClick={onDeleteThreadClick}
          className={twMerge(
            'mr-2 h-3 w-3 opacity-0 transition-opacity duration-500 group-hover:opacity-100 hover:text-gray-500',
            isLoading && 'fa-spin opacity-100',
          )}
        />
      )}
      <Tooltip
        opacity={1}
        id={tooltipId}
        style={{ ...TooltipStyles, width: 'auto', maxWidth: '600px', zIndex: 100 }}
        place="right"
      >
        <div className="flex flex-col">
          <p className="mb-2">{formatDate(createdAt)}</p>
          <p>{threadText}</p>
        </div>
      </Tooltip>
    </div>
  );
};

export default ThreadsBarItem;
