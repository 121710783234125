import { useCallback, useMemo } from 'react';

import { useAuthInfo } from '@propelauth/react';

export enum FetchMethod {
  GET = 'GET',
  PATCH = 'PATCH',
  POST = 'POST',
  DELETE = 'DELETE',
}

interface UseGetFetchConfig {
  method: FetchMethod;
  data?: Record<string, any>;
  isFormData?: boolean;
}

export interface FetchConfig {
  method: FetchMethod;
  mode: RequestMode;
  cache: RequestCache;
  credentials: RequestCredentials;
  headers: Headers;
}

const useGetFetchConfig = () => {
  const authInfo = useAuthInfo();
  const accessToken = authInfo.accessToken;

  const fetchConfigGET = useMemo(
    () => ({
      method: FetchMethod.GET,
      mode: 'cors' as RequestMode,
      cache: 'no-cache' as RequestCache,
      credentials: 'same-origin' as RequestCredentials,
      headers: new Headers({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${accessToken}`,
      }),
    }),
    [accessToken],
  );

  const getFetchConfig = useCallback(
    ({ method, data, isFormData }: UseGetFetchConfig) => {
      const body = isFormData ? (data as FormData) : JSON.stringify(data);

      const headers: HeadersInit = isFormData
        ? {
            Authorization: `Bearer ${accessToken}`,
          }
        : {
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          };

      return {
        method,
        mode: 'cors' as RequestMode,
        cache: 'no-cache' as RequestCache,
        credentials: 'same-origin' as RequestCredentials,
        headers: new Headers(headers),
        body: data ? body : undefined,
      };
    },
    [accessToken],
  );

  return {
    fetchConfigGET,
    getFetchConfig,
  };
};

export default useGetFetchConfig;
