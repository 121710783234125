import React from 'react';

import StatisticItem from './StatisticItem';
import { FetchOrgUsageDataRes } from '@/api/types/queries';

interface StatisticsGridProps {
  isLoading: boolean;
  data?: FetchOrgUsageDataRes;
}

const StatisticsGrid: React.FC<StatisticsGridProps> = ({ isLoading, data }) => {
  return (
    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-6">
      <StatisticItem title="Pages processed" value={data?.totals?.pages ?? null} isFetching={isLoading} />
      <StatisticItem title="Runs submitted" value={data?.totals?.runs ?? null} isFetching={isLoading} />
      <StatisticItem title="Chronologies created" value={data?.totals?.chronologies ?? null} isFetching={isLoading} />
      <StatisticItem title="Facts extracted" value={data?.totals?.facts ?? null} isFetching={isLoading} />
      <StatisticItem title="Kim queries" value={data?.totals?.threads ?? null} isFetching={isLoading} />
      <StatisticItem
        title="Tokens used"
        value={data?.totals?.tokens ?? null}
        isFetching={isLoading}
        tooltipText="Tokens are calculated as: 1 page of A4 = 1 token, 1 Kim query = 10 tokens"
      />
    </div>
  );
};

export default StatisticsGrid;
