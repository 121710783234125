import { SetURLSearchParams } from 'react-router-dom';

import { Label } from '@/components/atoms/label';
import { Switch } from '@/components/atoms/switch';
import { cn } from '@/helpers/shadCnUtils';
import { UploadMode } from '@/types';

interface UploadTypeSwitchProps {
  mode: UploadMode;
  setSearchParams: SetURLSearchParams;
}

const UploadTypeSwitch = ({ mode, setSearchParams }: UploadTypeSwitchProps) => {
  return (
    <div className="flex items-center space-x-2 rounded-md border bg-white px-2 py-1">
      <div className="flex items-center space-x-2">
        <Label
          className={cn(
            'text-sm text-gray-700 transition-colors duration-150',
            mode === UploadMode.Light && 'text-brandSecondary',
          )}
        >
          Light Upload
        </Label>
        <Switch
          className="bg-gray-100"
          checked={mode === UploadMode.Full}
          onCheckedChange={() => {
            setSearchParams((params) => ({
              ...Object.fromEntries(params),
              mode: mode === UploadMode.Full ? UploadMode.Light : UploadMode.Full,
            }));
          }}
        />
        <Label
          className={cn(
            'text-sm text-gray-700 transition-colors duration-150',
            mode === UploadMode.Full && 'text-brandSecondary',
          )}
        >
          Full Upload
        </Label>
      </div>
    </div>
  );
};

export default UploadTypeSwitch;
