import { useEffect, useState } from 'react';

import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PencilRuler, Plus } from 'lucide-react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'sonner';

import CaseCard from './components/CaseCard';
import DraftsModalContent from './components/DraftsModal';
import Share, { ShareOptions } from '../CaseEditor/components/Share';
import { useCreateCase } from '@/api/mutations/useCreateCase';
import { useDeleteCase } from '@/api/mutations/useDeleteCase';
import { useCasesQuery } from '@/api/queries/useGetCases';
import useGetMatter from '@/api/queries/useGetMatter';
import { Button } from '@/components/atoms/new-button';
import EmptyState from '@/components/molecules/EmptyState';
import HeaderBar from '@/components/molecules/HeaderBar';
import ConfirmationModal from '@/components/molecules/Modals/Confirmation';
import CustomModal from '@/components/molecules/Modals/CustomModal';
import { MainPageWrapper } from '@/components/molecules/PageWrappers/MainPageWrapper';
import SearchBox from '@/components/molecules/SearchBox';
import Pagination from '@/components/organisms/Pagination';
import { useUserContext } from '@/Contexts/User';
import { Case, MatterType } from '@/types';

const CasesExplorer = () => {
  const { user } = useUserContext();
  const isSuperAdmin = user?.metadata?.metadata?.orgSuperAdmin === 'true';

  // State
  const [searchText, setSearchText] = useState('');
  const [totalPages, setTotalPages] = useState<number | null>(null);
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
  const [caseIdToDelete, setCaseIdToDelete] = useState('');
  const [isDraftsModalOpen, setIsDraftsModalOpen] = useState(false);

  const [cases, setCases] = useState<Case[]>([]);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const pageQuery = parseInt(searchParams.get('page') || '0') || 0;
  const matterId = searchParams.get('matterId');

  const { isLoading: isLoadingCases, data: casesData } = useCasesQuery({ matterId, searchText, pageQuery });
  const { isLoadingMatter, responseMatterData } = useGetMatter(matterId ?? '');

  const { mutate: createCase, isLoading: isCreating } = useCreateCase();

  const onDeleteError = () => {
    toast.error('Error deleting case. Try again later');
    setConfirmDeleteModalOpen(false);
    setCaseIdToDelete('');
  };

  const onDeleteCancel = () => {
    setCaseIdToDelete('');
    setConfirmDeleteModalOpen(false);
  };

  const onDelete = (caseId: string) => {
    setCaseIdToDelete(caseId);
    setConfirmDeleteModalOpen(true);
  };

  const { mutate: deleteCase, isLoading: isDeleting } = useDeleteCase({
    onError: onDeleteError,
  });

  useEffect(() => {
    if ((totalPages === null || casesData?.pageCount !== totalPages) && casesData?.pageCount != undefined) {
      setTotalPages(casesData?.pageCount || 1);
    }
    setCases(casesData?.cases ?? []);
  }, [casesData]);

  const onDeleteConfirm = () => {
    setConfirmDeleteModalOpen(false);
    // Remove the case from the cases array
    const updatedCases = cases.filter((c) => c.id !== caseIdToDelete);
    setCases(updatedCases);
    deleteCase({ caseId: caseIdToDelete, isSuperAdmin });
    setCaseIdToDelete('');
    setConfirmDeleteModalOpen(false);
    toast.success('Case deleted', { richColors: true });
  };

  const handleViewDraft = (id: string) => {
    navigate(
      `/app/chronos/case-creator?caseId=${id}&mode=${responseMatterData?.type === MatterType.Personal ? 'light' : 'full'}`,
    );
    setIsDraftsModalOpen(false);
  };

  const handleViewCase = (id: string) => {
    navigate(`/app/chronos/case-editor/summary?caseId=${id}`);
  };

  // Pagination
  const handleGoToPreviousPage = () => {
    navigate(
      `/app/chronos/explore?matterId=${matterId}&page=${pageQuery - 1}${searchText ? `&searchText=${searchText}` : ''}`,
    );
  };
  const handleGoToNextPage = () => {
    navigate(
      `/app/chronos/explore?matterId=${matterId}&page=${pageQuery + 1}${searchText ? `&searchText=${searchText}` : ''}`,
    );
  };
  const goToPage = (value: number) => {
    navigate(`/app/chronos/explore?matterId=${matterId}&page=${value}${searchText ? `&searchText=${searchText}` : ''}`);
  };

  const headerContent = (
    <HeaderBar
      breadcrumbPages={[
        {
          label: <FontAwesomeIcon icon={faHome} className="h-4 w-4 text-gray-700" />,
          href: '/app/chronos/matters',
          testid: 'home-link',
        },
        {
          label: <div>{isLoadingMatter ? '...' : responseMatterData?.name}</div>,
          href: `/app/chronos/explore?matterId=${matterId}`,
          testid: 'matter-name',
        },
      ]}
      rightContent={
        matterId && responseMatterData && responseMatterData.type !== MatterType.Personal ? (
          <Share matterId={matterId} allowedOptions={[ShareOptions.AddUsers]} />
        ) : null
      }
    />
  );

  return (
    <MainPageWrapper headerContent={headerContent}>
      <div className="mt-2 w-full px-6 pb-4">
        <div className="relative flex h-[calc(100vh-5rem)] w-full rounded-lg border-2 border-gray-200/40 bg-white">
          <div className="flex w-full flex-col">
            <h2 className="mx-4 my-4 border-b-2 text-lg font-semibold">Cases</h2>
            <p className="mx-4 mb-4 text-sm text-gray-800">
              Cases organise documents for analysis. Create a case to extract and analyse facts from your documents
              using Wexler's AI.
            </p>
            <div className="mb-4 flex items-center justify-between gap-4 px-4">
              <div className="flex items-center gap-4">
                <Button
                  data-testid="new-case-btn"
                  variant="brand"
                  size="sm"
                  onClick={() => createCase(matterId)}
                  disabled={isCreating}
                >
                  New Case
                  <Plus className="h-4 w-4" />
                </Button>
                <Button
                  data-testid="open-drafts-btn"
                  variant="outline"
                  size="sm"
                  onClick={() => setIsDraftsModalOpen(true)}
                >
                  Drafts
                  <PencilRuler className="h-4 w-4" />
                </Button>
              </div>
              <div className="flex gap-8">
                <div className="flex flex-row items-center gap-6">
                  <SearchBox value={searchText} onSearchCall={setSearchText} placeholder="Search cases..." />
                </div>

                {totalPages ? (
                  <Pagination
                    testid="cases"
                    canGetNextPage={pageQuery < totalPages - 1}
                    canGetPrevPage={pageQuery > 0}
                    prevPage={handleGoToPreviousPage}
                    nextPage={handleGoToNextPage}
                    currentPage={pageQuery}
                    noOfPages={totalPages}
                    goToPage={goToPage}
                  />
                ) : null}
              </div>
            </div>

            <div className="flex-1 overflow-y-auto py-2">
              {isLoadingCases ? (
                <></>
              ) : !responseMatterData || !cases || cases.length === 0 ? (
                <EmptyState title="No cases found" subtitle="Create your first case!" />
              ) : (
                <div className="full grid grid-cols-1 gap-4 px-4 md:grid-cols-2 lg:grid-cols-3">
                  {cases.map((caseItem, index) => (
                    <div data-testid={`active-case-${index}`} data-case-id={caseItem.id} key={caseItem.id}>
                      <CaseCard
                        caseObject={caseItem}
                        onClick={handleViewCase}
                        onDelete={onDelete}
                        isDeleting={caseIdToDelete === caseItem.id}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          <ConfirmationModal
            title="Delete Case"
            description="Deleting cases is irreversible. Are you sure you want to proceed?"
            isLoading={isDeleting}
            isOpen={confirmDeleteModalOpen}
            handleClose={onDeleteCancel}
            onConfirm={onDeleteConfirm}
          />

          <CustomModal
            isOpen={isDraftsModalOpen}
            handleClose={() => setIsDraftsModalOpen(false)}
            content={
              <DraftsModalContent
                matterId={matterId ?? ''}
                onSelectDraft={handleViewDraft}
                onDeleteDraft={(id: string) => deleteCase({ caseId: id, isSuperAdmin })}
              />
            }
          />
        </div>
      </div>
    </MainPageWrapper>
  );
};

export default CasesExplorer;
