import { useState } from 'react';

import { ExampleQuestions } from './components/ExampleQuestions';
import ModeSelector from './components/ModeSelector';
import QuestionInput from './components/QuestionInput';
import QuestionSidebar from './components/QuestionSidebar';
import { useGetCase } from '../../../../../api/queries/useGetCase';
import FileUploadInput from '@/components/organisms/FileUploadInput';
import { acceptedDocTypes, FIFTY_KB } from '@/components/organisms/FileUploadInput/constants';
import { DocIds, DraftType, ExecuteKimSearch, KimMode } from '@/types';

interface QuestionProps {
  caseId: string;
  executeSearch: ExecuteKimSearch;
  setQuestionValue: (v: string) => void;
  isCreatingThread: boolean;
  questionValue: string;
  docs: DocIds[];
  taggedDocuments: { doc_id: string; file_name: string }[];
  setTaggedDocuments: (v: { doc_id: string; file_name: string }[]) => void;
  mode: KimMode;
  setMode: (v: KimMode) => void;
  setTaskFiles: (files: File[]) => void;
  createNewThread: () => void;
  goToThread: (thread_id: string) => void;
  draftType: DraftType | null;
  setDraftType: (draftType: DraftType | null) => void;
}

const getTitleFromMode = (mode: KimMode) => {
  switch (mode) {
    case KimMode.QuestionFlow:
      return 'Ask a question...';
    case KimMode.ExtractionFlow:
      return 'Extract information...';
    case KimMode.DraftFlow:
      return 'Draft me a…';
  }
};

const Question = ({
  caseId,
  executeSearch,
  isCreatingThread,
  setQuestionValue,
  questionValue,
  docs,
  taggedDocuments,
  setTaggedDocuments,
  mode,
  setMode,
  createNewThread,
  goToThread,
  setTaskFiles,
  draftType,
  setDraftType,
}: QuestionProps) => {
  const [placeholder, setPlaceholder] = useState('Tell me about X...');
  const DEV_SHOW_FILE_INPUT = false;

  const { data: caseData } = useGetCase(caseId ?? '');

  const flow = {
    [KimMode.QuestionFlow]: 'question',
    [KimMode.ExtractionFlow]: 'extract',
    [KimMode.DraftFlow]: 'draft',
  }[mode] as 'question' | 'extract' | 'draft';

  const exampleQuestionsForFlow = caseData?.exampleKimQuestions?.[flow] ?? [];

  return (
    <div className="relative flex h-full w-full">
      <div className="flex h-full w-full items-center">
        <QuestionSidebar taggedDocuments={taggedDocuments} createNewThread={createNewThread} goToThread={goToThread} />

        <div className="flex w-full flex-col items-center justify-center">
          <div className="flex w-4/5 flex-col items-center xl:w-3/4">
            <div className="mb-8 text-2xl font-bold text-gray-600">{getTitleFromMode(mode)}</div>

            <QuestionInput
              onExecuteCall={executeSearch}
              onChange={(v: string) => setQuestionValue(v)}
              value={questionValue}
              docs={docs}
              placeholder={placeholder}
              setTaggedDocuments={setTaggedDocuments}
              draftType={draftType}
              setDraftType={setDraftType}
              mode={mode}
              isCreatingThread={isCreatingThread}
            />
            {DEV_SHOW_FILE_INPUT && (
              <div className="w-full">
                <FileUploadInput
                  isUploading={false}
                  onDrop={setTaskFiles}
                  acceptedTypes={acceptedDocTypes}
                  maxFiles={1}
                  maxSize={FIFTY_KB}
                  showPreview
                />
              </div>
            )}
            <ModeSelector mode={mode} setMode={setMode} setPlaceholder={setPlaceholder} />

            {exampleQuestionsForFlow.length > 0 && (
              <ExampleQuestions questions={exampleQuestionsForFlow} setQuestionValue={setQuestionValue} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Question;
