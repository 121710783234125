import { useState, ReactNode } from 'react';

import { faPenToSquare, faUpload, faEdit, faClock, faToggleOn } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { OnboardingModalBase, StepItem } from './OnboardingModalBase';

export const DocumentUploadOnboardingModal = () => {
  const [checkListModalOpen, setCheckListModalOpen] = useState(true);

  const steps: StepItem[] = [
    {
      icon: faEdit,
      title: 'Fill out case details',
      description:
        'Add a case name and information about the key issues you want to explore (our AI uses this to tag which facts are relevant).',
    },
    {
      icon: faUpload,
      title: 'Upload your documents',
      description: 'Add the documents you want to process. Either from your file system, or using our integrations.',
    },
    {
      icon: faClock,
      title: 'Start processing',
      description: 'Click the Start Processing button to begin. Your progress will be saved if you leave the page.',
    },
  ];

  const introduction: ReactNode = (
    <>
      <p className="mb-2 text-sm text-gray-700">
        To upload documents, you need to create a <b>Case</b>. Cases are our way of organising collections of documents.
      </p>
      <p className="mb-4 text-xs text-gray-700">
        To start with we'll just create this case inside your <b>Personal Matter</b>, this is a space where you can do
        any uploads that you don't want to share with your team.
      </p>
    </>
  );

  // Custom content for the upload type toggle explanation
  const uploadTypeToggleInfo = (
    <div className="mb-4 rounded-lg border border-blue-100 bg-blue-50 p-4 text-left">
      <div className="flex items-start">
        <FontAwesomeIcon icon={faToggleOn} className="mt-1 mr-3 h-5 w-5 text-blue-500" />
        <div>
          <h4 className="text-sm font-medium text-blue-800">Choose your upload type:</h4>
          <p className="mt-1 text-sm text-blue-700">
            <span className="text-brand-secondary font-semibold">Light Upload:</span> Requires fewer initial details -
            great for getting started quickly.
          </p>
          <p className="mt-1 text-sm text-blue-700">
            <span className="text-brand-secondary font-semibold">Full Upload:</span> Specify more details to help our AI
            better understand which facts are important.
          </p>
        </div>
      </div>
    </div>
  );

  return (
    <OnboardingModalBase
      testId="document-upload-onboarding-modal"
      isOpen={checkListModalOpen}
      setIsOpen={setCheckListModalOpen}
      title="Uploading Documents!"
      icon={faPenToSquare}
      maxWidth="56rem"
      introduction={introduction}
      steps={steps}
    >
      <p className="mb-4 text-xs text-gray-700">
        Don't worry if you forget to add some documents or want to change the case details, you can edit these later
        after the case has processed.
      </p>
      {uploadTypeToggleInfo}
    </OnboardingModalBase>
  );
};

export const DocumentUploadCompleteOnboardingModal = () => {
  const [isOpen, setIsOpen] = useState(true);

  const mainContent: ReactNode = (
    <>
      <div className="mb-6 rounded-lg border border-green-100 bg-green-50 p-4 text-left">
        <p className="text-sm text-green-800">
          You can monitor the progress of your upload here. Wexler's AI analyses your documents in depth, which can take
          from a few minutes to a few hours.
        </p>
      </div>

      <div className="mb-4">
        <p className="text-sm text-gray-600">
          Once your upload is out of our queue, you'll see a time estimate on the top right of this page.
        </p>
      </div>

      <p className="mb-6 text-xs text-gray-500">
        Don't worry, you can close this page and we will send you an email when your upload is complete!
      </p>

      <hr className="my-4 border-gray-200" />
    </>
  );

  return (
    <OnboardingModalBase
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title="You've uploaded your first documents!"
      icon={faPenToSquare}
    >
      {mainContent}
    </OnboardingModalBase>
  );
};
