import { HTMLAttributes } from 'react';

import { cn } from '../../helpers/shadCnUtils';

const Chip = ({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLDivElement> & {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div
      className={cn(`flex items-center gap-1 rounded bg-red-100 px-2 py-0.5 text-xs text-gray-600`, className)}
      {...props}
    >
      {children}
    </div>
  );
};

export default Chip;
