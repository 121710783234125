import type { UsageDataObject, TableUsageData, UsageData } from '../types/types.js';

export const formatTableData = (data: TableUsageData | undefined) => {
  if (!data) return [];
  return Object.entries(data).map(([key, value]) => {
    return {
      id: key,
      ...value,
    };
  });
};

export const formatChartData = (
  data: UsageData[],
  category: keyof UsageDataObject,
  startDate: Date | null,
  endDate: Date | null,
) => {
  if (data.length === 0) return [];

  const groupedByWeek: Record<string, number> = {};

  // Ensure all weeks between start and end date are included
  if (startDate && endDate) {
    const start = new Date(startDate);
    // Adjust to start of week (Sunday)
    start.setDate(start.getDate() - start.getDay());

    const end = new Date(endDate);
    // Adjust to start of week (Sunday)
    end.setDate(end.getDate() - end.getDay());

    // Create a temporary date to iterate through weeks
    const currentDate = new Date(start);

    // Loop through each week from start to end setting to 0
    while (currentDate <= end) {
      const weekKey = currentDate.toISOString().split('T')[0];
      groupedByWeek[weekKey] = 0;
      // Move to next week
      currentDate.setDate(currentDate.getDate() + 7);
    }
  }

  // For each item in the data, add the value to the correct week
  data.map((item) => {
    // Convert timestamp to date object
    const date = new Date(item['date']);

    // Get the start of the week (Sunday)
    const startOfWeek = new Date(date);
    startOfWeek.setDate(date.getDate() - date.getDay());

    // Format as YYYY-MM-DD for the week key
    const weekKey = startOfWeek.toISOString().split('T')[0];

    groupedByWeek[weekKey] += item.data[category] || 0;
  }, {});

  // Convert to array format needed by AreaChart and sort by week
  return Object.entries(groupedByWeek)
    .map(([week, value]) => ({
      week,
      [category]: value,
    }))
    .sort((a, b) => a.week.localeCompare(b.week));
};
