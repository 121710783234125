import React, { useEffect, useState } from 'react';

import { useSearchParams } from 'react-router-dom';

import ChronologyDropdown from './components/ChronologyDropdown';
import DocViewer from '../../components/DocViewer/FactDocViewer';
import { ChronologyFact } from '@/types';

interface ChronologyTimelineProps {
  chronology: ChronologyFact[];
  handleOpenDeleteModal: (lineId: string) => void;
}

export const ChronologyTimeline: React.FC<ChronologyTimelineProps> = ({ chronology, handleOpenDeleteModal }) => {
  // State
  const [searchParams] = useSearchParams();
  const [isDocViewerOpen, setIsDocViewerOpen] = useState(false);

  const caseId = searchParams.get('caseId');
  const docId = searchParams.get('docId');
  const factId = searchParams.get('factId');

  useEffect(() => {
    if (docId) {
      setIsDocViewerOpen(true);
    } else {
      setIsDocViewerOpen(false);
    }
  }, [docId]);

  return (
    <div className="flex h-full w-full">
      <div className={`relative flex h-full ${isDocViewerOpen ? 'w-7/12' : 'w-full'}`}>
        {/* Vertical line - moved outside scrollable area */}
        <div className="absolute top-0 left-4 h-full border-l-2 border-gray-300"></div>

        <div className="relative flex h-full w-full flex-col gap-4 overflow-y-scroll pr-1 transition-all duration-300">
          {chronology.map((fact: ChronologyFact, index: number) => (
            <div key={index} className="">
              <div className="flex items-center">
                {/* Marker */}
                <div className="border-brandSecondary absolute left-[9px] h-4 w-4 rounded-full border-2 bg-white"></div>
                {/* Fact content */}
                <div key={fact.id} id={fact.id} className="relative ml-10 w-full rounded border-2 border-gray-200/40">
                  <ChronologyDropdown caseId={caseId ?? ''} fact={fact} handleOpenDeleteModal={handleOpenDeleteModal} />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div
        className={`transition-all duration-300 ease-in-out ${
          isDocViewerOpen ? 'ml-2 w-5/12 opacity-100' : 'w-0 opacity-0'
        } overflow-hidden rounded border-2 bg-white`}
      >
        <div
          className={`h-full w-full transition-transform duration-300 ease-in-out ${
            isDocViewerOpen ? 'translate-x-0' : 'translate-x-full'
          }`}
        >
          {docId && caseId && factId && <DocViewer docId={docId} caseId={caseId} factId={factId} />}
        </div>
      </div>
    </div>
  );
};
