import { useMutation } from 'react-query';

import { APIBaseChronos } from '@/api/hosts';
import useGetFetchConfig, { FetchMethod } from '@/api/useGetFetchConfig';

interface UseChronologyDownloadProps {
  chronologyId: string;
  caseId: string | null;
}

// This should maybe use useMutation?
const useChronologyDownload = ({ chronologyId, caseId }: UseChronologyDownloadProps) => {
  const { getFetchConfig } = useGetFetchConfig();

  const downloadChronology = async ({
    format,
    title,
    columns,
  }: {
    format: string;
    title: string;
    columns: string[];
  }) => {
    const fetchConfig = getFetchConfig({
      method: FetchMethod.POST,
      data: {
        format,
        caseId,
        title,
        columns,
      },
    });

    const downloadResult = await fetch(
      `${APIBaseChronos}/api/case/${caseId}/chronology/${chronologyId}/download`,
      fetchConfig,
    );

    const blob = await downloadResult.blob();
    const link = document.createElement('a');
    const file = new Blob([blob], { type: 'text/plain' });
    link.href = URL.createObjectURL(file);

    link.download = `${title}.${format}`;
    link.click();

    URL.revokeObjectURL(link.href);
  };

  const { mutate: fetchDownload, isLoading: isLoadingDownload } = useMutation(downloadChronology);

  return { fetchDownload, isLoadingDownload };
};

export default useChronologyDownload;
