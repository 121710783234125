import mixpanel from 'mixpanel-browser';

import { OrgUserWithMetadata } from '@/types';

let isInitialized = false;

if (import.meta.env.VITE_WEXLER_ENV === 'production') {
  try {
    mixpanel.init('895d76aa353309f28cdbbee9432886e8', {
      debug: true,
      track_pageview: true,
      persistence: 'localStorage',
    });
    isInitialized = true;
  } catch (error) {
    console.error('Failed to initialize Mixpanel:', error);
  }
}

export const trackUser = (user: OrgUserWithMetadata | null) => {
  if (import.meta.env.VITE_WEXLER_ENV === 'production' && isInitialized) {
    try {
      mixpanel.identify(user?.id);
      mixpanel.people.set({
        $email: user?.email,
      });
    } catch (error) {
      console.error('Failed to track user:', error);
    }
  }
};

export const trackPageView = (pageName: string, properties = {}) => {
  if (import.meta.env.VITE_WEXLER_ENV === 'production' && isInitialized) {
    try {
      mixpanel.track_pageview({ page: pageName }, properties);
    } catch (error) {
      console.error('Failed to track page view:', error);
    }
  }
};

export const trackCustomEvent = (eventName: string, properties = {}) => {
  if (import.meta.env.VITE_WEXLER_ENV === 'production' && isInitialized) {
    try {
      mixpanel.track(eventName, properties);
    } catch (error) {
      console.error('Failed to track custom event:', error);
    }
  }
};

export default mixpanel;
