import { useState } from 'react';

import ChronologyDetails from './ChronologyDetails';
import ChronologyTitle from './ChronologyTitle';
import Accordion from '@/components/molecules/Accordion';
import { ChronologyFact } from '@/types';

const ChronologyDropdown = ({
  fact,
  caseId,
  handleOpenDeleteModal,
}: {
  fact: ChronologyFact;
  caseId: string;
  handleOpenDeleteModal: (lineId: string) => void;
}) => {
  const [open, setOpen] = useState(false);
  // Setup fact state here so edits are reflected immediately on FE
  const [dateText, setDateText] = useState(fact.factDateText);

  return (
    <Accordion
      title={
        <ChronologyTitle
          caseId={caseId}
          open={open}
          fact={fact}
          dateText={dateText}
          updateDate={setDateText}
          factText={fact.description}
        />
      }
      content={
        <ChronologyDetails
          caseId={caseId}
          fact={fact}
          significanceText={fact.significance ?? ''}
          handleOpenDeleteModal={handleOpenDeleteModal}
        />
      }
      className="relative p-0"
      outerOpen={setOpen}
    />
  );
};

export default ChronologyDropdown;
