import { useState } from 'react';

import { toast } from 'react-toastify';

import useGetFetchConfig, { FetchMethod } from '@/api/useGetFetchConfig';
import { KEY_VALUE_TYPE, MyOptionType, Relevance } from '@/types';

interface SelectInputCellProps {
  entryFieldKey: string;
  entryFieldValue: string;
  endpointToUpdate: string;
  options: KEY_VALUE_TYPE;
  updateRelevance: (v: Relevance) => void;
  callback?: () => void;
}
const DropdownInput = ({
  entryFieldKey,
  entryFieldValue,
  endpointToUpdate,
  options,
  updateRelevance,
  callback,
}: SelectInputCellProps) => {
  const [currentFieldValue, setCurrentFieldValue] = useState<MyOptionType>(options[entryFieldValue]);
  const { getFetchConfig } = useGetFetchConfig();

  const handleChangeFieldValue = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const val = event.target.value as Relevance;
    setCurrentFieldValue(options[val]);
    updateRelevance(val);

    const fetchConfig = getFetchConfig({ method: FetchMethod.PATCH, data: { [entryFieldKey]: val } });
    return fetch(endpointToUpdate, fetchConfig)
      .then((res) => {
        callback?.();

        if (res.headers.get('content-type') === 'application/json') {
          return res.json();
        }
      })
      .catch((err) => {
        toast.error('Failed to update field');
      });
  };

  return (
    <select
      className="rounded border hover:cursor-pointer"
      onChange={handleChangeFieldValue}
      value={currentFieldValue?.value}
    >
      {Object.values(options).map((option) => (
        <option key={option.label} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export default DropdownInput;
