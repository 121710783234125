import { useQuery } from 'react-query';

import { QueryEntity } from '../../types';
import { APIBaseChronos } from '@/api/hosts';
import useGetFetchConfig from '@/api/useGetFetchConfig';
import { OrgUserWithMetadata } from '@/types';

const useFetchOrgUsers = (orgIds: string[] = []) => {
  const { fetchConfigGET } = useGetFetchConfig();

  return useQuery<OrgUserWithMetadata[]>(
    [QueryEntity.Users, orgIds],
    () => {
      const queryParams = new URLSearchParams({ orgIds: orgIds.join(',') }).toString();
      return fetch(`${APIBaseChronos}/api/org/users?${queryParams}`, fetchConfigGET)
        .then((res) => res.json())
        .catch((err) => {
          throw err;
        });
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

export default useFetchOrgUsers;
