import { useEffect, useState } from 'react';

import { faArrowLeft, faFile } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { toast as sonnerToast } from 'sonner';

import CaseDetails from './components/CaseDetails';
import UploadTypeSwitch from './components/UploadTypeSwitch';
import { FullCaseForm, fullCaseSchema, lightCaseSchema } from './schemas';
import { trackCustomEvent } from '@/analytics/Mixpanel';
import { useProcessCase } from '@/api/mutations/useProcessCase';
import { useSaveCase } from '@/api/mutations/useSaveCase';
import { useGetCase } from '@/api/queries/useGetCase';
import { CaseCreationRequestDetails } from '@/api/types/mutations';
import Button from '@/components/atoms/Button';
import { Form } from '@/components/molecules/form';
import { CaseCreationOnboardingModal } from '@/components/molecules/Modals/Onboarding/CaseCreationOnboardingModal';
import { DocumentUploadOnboardingModal } from '@/components/molecules/Modals/Onboarding/DocumentUploadOnboardingModal';
import PermittedFilesChip from '@/components/molecules/PermittedFilesChip';
import FileUploaderComponent from '@/components/organisms/DocumentAdder';
import { parseURLParams } from '@/helpers/parseURLParams';
import { UploadMode } from '@/types';

const CaseCreator = () => {
  const [uploadDisabled, setUploadDisabled] = useState(true);
  const [totalUnprocessedDocs, setTotalUnprocessedDocs] = useState(0);

  const [searchParams, setSearchParams] = useSearchParams();
  const caseId = parseURLParams<string>(searchParams, 'caseId', '');
  const mode = parseURLParams<UploadMode>(searchParams, 'mode', UploadMode.Full);

  const navigate = useNavigate();
  const { state } = useLocation();
  const fromChecklistDocumentUpload = state?.fromChecklistDocumentUpload;
  const fromChecklistCaseCreation = state?.fromChecklistCaseCreation;

  // Queries
  const { data: responseCase, isLoading: isLoadingCase } = useGetCase(caseId);
  const { mutate: saveCase } = useSaveCase(caseId || '');
  const { mutate: processCase } = useProcessCase();

  const handleClickBack = () => {
    saveCase(
      {
        ...form.getValues(),
      },
      {
        onSuccess: () => {
          sonnerToast.success('Case saved.', {
            richColors: true,
            position: 'top-right',
            duration: 1500,
          });
          navigate(`/app/chronos/explore?matterId=${responseCase?.matterId}`);
        },
        onError: () => {
          sonnerToast.error('Failed to save case', {
            richColors: true,
            duration: 1500,
            position: 'top-right',
          });
          navigate(`/app/chronos/explore?matterId=${responseCase?.matterId}`);
        },
      },
    );
  };

  const form = useForm<FullCaseForm>({
    resolver: zodResolver(mode === UploadMode.Light ? lightCaseSchema : fullCaseSchema),
    defaultValues: {
      name: '',
      legalIssues: '',
      type: '',
      parties: '',
      keyContext: '',
      keyTimePeriod: '',
      disputeStatus: '',
    },
  });

  const onSubmit = (data: Omit<CaseCreationRequestDetails, 'caseId'>) => {
    trackCustomEvent(mode === UploadMode.Light ? 'Light case uploaded' : 'Full case uploaded', {
      caseId,
    });
    processCase(
      {
        caseId,
        ...data,
      },
      {
        onSuccess: () => {
          sonnerToast.success('Case processing.', {
            duration: 3000,
            position: 'top-right',
            richColors: true,
          });
          navigate(`/app/chronos/case-editor/summary?caseId=${caseId}`, {
            state: { fromChecklistCaseCreation, fromChecklistDocumentUpload },
          });
        },
      },
    );
  };

  // Load in any existing case metadata
  useEffect(() => {
    if (responseCase) {
      form.reset({
        name: responseCase?.name ?? '',
        legalIssues: responseCase?.legalIssues ?? '',
        parties: responseCase?.parties ?? '',
        keyTimePeriod: responseCase?.keyTimePeriod ?? '',
        keyContext: responseCase?.keyContext ?? '',
        disputeStatus: responseCase?.disputeStatus ?? '',
        type: responseCase?.type ?? '',
      });
    }
  }, [responseCase, form]);

  return (
    <div className="relative flex min-h-screen w-full flex-col items-start justify-start overflow-auto bg-gray-50 px-8 py-6">
      {!isLoadingCase && (
        <>
          <div className="mb-4 flex w-full items-center justify-between">
            <div className="flex items-center">
              <Button
                className="rounded-full px-2 py-1 hover:text-gray-600"
                onClick={handleClickBack}
                icon={<FontAwesomeIcon icon={faArrowLeft} className="h-4 w-4" />}
                text=""
              />
              <h1 className="ml-2 text-xl font-bold text-gray-900">Create New Case</h1>
            </div>

            <UploadTypeSwitch mode={mode} setSearchParams={setSearchParams} />
          </div>

          <div className="flex h-full w-full flex-row gap-8">
            <div className="flex w-1/2 flex-col rounded-xl border border-gray-200 bg-white p-6 shadow-sm">
              <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                  <CaseDetails
                    mode={mode}
                    form={form}
                    onSubmit={onSubmit}
                    uploadDisabled={uploadDisabled}
                    activeUploadJobIds={responseCase?.activeUploadJobIds ?? []}
                  />
                </form>
              </Form>
            </div>

            <div className="flex w-1/2 flex-col rounded-xl border border-gray-200 bg-white p-6 shadow-sm">
              <div className="mb-6 flex items-center justify-between border-b border-gray-100 pb-4">
                <div className="flex items-center">
                  <FontAwesomeIcon icon={faFile} className="mr-3 h-4 w-4 text-gray-600" />
                  <h2 className="text-lg font-semibold text-gray-900">
                    Files{' '}
                    {totalUnprocessedDocs ? <span className="ml-1 text-sm">{`(${totalUnprocessedDocs})`}</span> : ''}
                  </h2>
                </div>
                <PermittedFilesChip />
              </div>

              <FileUploaderComponent
                setUploadDisabled={setUploadDisabled}
                setTotalUnprocessedDocs={setTotalUnprocessedDocs}
                caseId={caseId ?? ''}
                isCaseCreator={true}
              />
            </div>
          </div>
        </>
      )}
      {fromChecklistDocumentUpload && <DocumentUploadOnboardingModal />}
      {fromChecklistCaseCreation && <CaseCreationOnboardingModal />}
    </div>
  );
};

export default CaseCreator;
