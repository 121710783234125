import React, { useState } from 'react';

import { faArrowUp, faCloud, faEraser, faFolderPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { trackCustomEvent } from '../../analytics/Mixpanel';
import { File } from '../organisms/ApideckFilePickerCustom/types/File';
import { useApideckUploadFiles } from '@/api/mutations/useApideckUploadFiles';
import Button from '@/components/atoms/Button';
import FilePicker from '@/components/organisms/ApideckFilePickerCustom';
import { ApideckFileData } from '@/types';

interface CustomFilePickerProps {
  token: string;
  caseId: string;
  closeModalFunction: React.Dispatch<React.SetStateAction<boolean>>;
  initialConnection: string;
  onApideckSuccess?: () => void;
}

const CustomFilePicker = ({
  token,
  caseId,
  closeModalFunction,
  initialConnection,
  onApideckSuccess,
}: CustomFilePickerProps) => {
  const [selectedSource, setSelectedSource] = useState<string>('');
  const [selectedFiles, setSelectedFiles] = useState<Record<string, ApideckFileData>>({});

  // FILE / FOLDER SELECTION HANDLING
  const addOrUpdateFile = (id: string, file: File, connectedSource: string) => {
    setSelectedFiles((prevState) => ({
      ...prevState,
      [id]: {
        id: file.id,
        name: file.name,
        source: selectedSource || connectedSource,
        size: file.size ?? 0,
        type: file.type !== 'folder' ? 'file' : 'folder',
      },
    }));
  };

  const removeFile = (id: string) => {
    setSelectedFiles((prevState) => {
      const newState = { ...prevState };
      delete newState[id];
      return newState;
    });
  };

  const handleSelect = (file: File, selectedSource: string) => {
    if (selectedFiles[file.id]) {
      removeFile(file.id);
    } else {
      addOrUpdateFile(file.id, file, selectedSource);
    }
  };

  const resetSelection = () => {
    setSelectedFiles({});
  };

  // MODAL CLOSE HANDLING
  const closeModal = () => {
    resetSelection();
    setSelectedSource('');
    closeModalFunction(false);
  };

  // UPLOAD HANDLING
  const [isUploading, setIsUploading] = useState(false);
  const [isError, setIsError] = useState(false);
  const onErrorUpload = () => {
    trackCustomEvent('Integration Upload Unsuccessful');
    setIsError(true);
  };
  const onSuccessUpload = async () => {
    if (onApideckSuccess) {
      // Allow some time to prevent a race condition and for the animation.
      await new Promise((resolve) => setTimeout(resolve, 1000));
      onApideckSuccess();
    }
    trackCustomEvent('Integration Upload Successful');
    closeModal();
  };
  const { mutateAsync } = useApideckUploadFiles({ onError: onErrorUpload, onSuccess: onSuccessUpload });
  const handleUpload = async () => {
    setIsUploading(true);
    trackCustomEvent('Integration Upload Attempted', { items: Object.keys(selectedFiles).length });
    await mutateAsync({ caseId, fileArray: Object.values(selectedFiles) });
  };

  return isUploading ? (
    isError ? (
      <div className="flex h-[44rem] flex-col items-center justify-center rounded-lg bg-gray-100 p-12 shadow-lg">
        <div className="relative mb-4 h-24 w-24">
          <FontAwesomeIcon
            icon={faTimes}
            className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform text-6xl text-red-500"
          />
        </div>
        <p className="text-center text-lg font-semibold text-gray-700">Something went wrong.</p>
        <p className="text-center text-lg font-semibold text-gray-700">Please try again.</p>
      </div>
    ) : (
      <div className="flex h-[44rem] flex-col items-center justify-center rounded-lg bg-gray-100 p-12 shadow-lg">
        <div className="relative mb-4 h-24 w-24">
          <FontAwesomeIcon
            icon={faCloud}
            className="absolute top-0 left-1/2 -translate-x-1/2 -translate-y-2 transform text-6xl text-blue-500"
          />
          <FontAwesomeIcon
            icon={faArrowUp}
            className="absolute bottom-0 left-1/3 -translate-x-1/16 transform animate-bounce text-4xl text-blue-500"
          />
        </div>
        <p className="text-center text-lg font-semibold text-gray-700">The upload will be done in the background.</p>
      </div>
    )
  ) : (
    <div className="h-[44rem] p-14">
      <FilePicker
        onSelect={handleSelect}
        token={token}
        selectedObject={selectedFiles}
        resetSelection={resetSelection}
        onConnectionSelect={(connection) => {
          setSelectedSource(connection.service_id);
          trackCustomEvent(`Integration Selected - ${connection.service_id}`, { integration: connection.service_id });
          resetSelection();
        }}
        initialConnection={initialConnection}
      />
      <hr className="my-6 border-gray-200" />
      <div className="flex items-center justify-end space-x-4 px-4 py-5 sm:px-6">
        <Button
          type="brand"
          rounded="md"
          text="Clear"
          onClick={resetSelection}
          data-tooltip-id="agreed-tooltip"
          data-tooltip-target="tooltip-hover"
          data-tooltip-trigger="hover"
          data-tooltip-content="Clear selection"
          icon={<FontAwesomeIcon icon={faEraser} className="mr-2 text-white" />}
        />
        <Button
          type="brand"
          rounded="md"
          text="Upload"
          onClick={async () => await handleUpload()}
          data-tooltip-id="agreed-tooltip"
          data-tooltip-target="tooltip-hover"
          data-tooltip-trigger="hover"
          data-tooltip-content="Upload to Wexler"
          icon={<FontAwesomeIcon icon={faFolderPlus} className="mr-2 text-white" />}
        />
      </div>
    </div>
  );
};

export default CustomFilePicker;
