import { useQuery } from 'react-query';

import { APIBaseChronos } from '@/api/hosts';
import { QueryEntity } from '@/api/types';
import { GetInconsistenciesResponse } from '@/api/types/queries';
import useGetFetchConfig from '@/api/useGetFetchConfig';
import { createFilterParams } from '@/helpers/createFilterParams';
import { InconsistencyFilters } from '@/types';

export const useGetInconsistencies = ({
  caseId,
  page,
  pageSize,
  filters,
  searchQuery,
}: {
  caseId: string | null;
  page: number;
  pageSize: number;
  filters: InconsistencyFilters;
  searchQuery?: string;
}) => {
  const { fetchConfigGET } = useGetFetchConfig();

  return useQuery<GetInconsistenciesResponse>(
    [QueryEntity.CaseInconsistencies, { caseId }, { filters, page, pageSize, searchQuery }],
    async () => {
      const filterParams = createFilterParams(filters);
      const res = await fetch(
        `${APIBaseChronos}/api/case/${caseId}/inconsistency?page=${page}&pageSize=${pageSize}&${filterParams}&searchQuery=${searchQuery ? searchQuery : ''}`,
        fetchConfigGET,
      );
      return res.json();
    },
    { refetchOnWindowFocus: false },
  );
};
