import React, { useRef, useState, useEffect, useMemo } from 'react';

import { IconDefinition, faChevronDown, faChevronUp, faCircleNotch, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { twMerge } from 'tailwind-merge';

export interface DropdownOption<T> {
  label: string;
  key: T;
}

export interface DropdownProps<T = string> {
  id: string;
  value?: string | number | null;
  placeholder: string;
  buttonIcon: IconDefinition;
  options: DropdownOption<T>[];
  onOptionClick: (key: T) => void;
  onClearClick?: VoidFunction;
  className?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
}

const Dropdown = <T,>({
  id,
  value,
  placeholder,
  buttonIcon,
  options,
  onOptionClick,
  onClearClick,
  className,
  isDisabled = false,
  isLoading = false,
}: DropdownProps<T>) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const dropdownContentRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        // Need to do this so that the create chronology tour works when clicking next on tour
        let element = event.target as HTMLElement;
        let hasDataAttr = false;
        for (let i = 0; i < 4 && element; i++) {
          // Check up to 3 parents (0 is the target itself)
          if (element.hasAttribute('data-tour-elem')) {
            hasDataAttr = true;
            break;
          }
          element = element.parentElement as HTMLElement;
        }
        if (!hasDataAttr) {
          setDropdownOpen(false);
        }
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const valueLabel = options.find((option) => option.key === value)?.label || placeholder;
  const hasValue = value !== null && value !== undefined;

  const maxHeight = useMemo(() => {
    return dropdownOpen ? `max-h-64` : 'max-h-0';
     
  }, [dropdownOpen, hasValue]);

  return (
    <div data-open={dropdownOpen} className="relative" ref={dropdownRef}>
      <button
        id={`${id}-dropdown`}
        className={twMerge(
          'flex w-full cursor-pointer items-center justify-between rounded border bg-buttonSecondary px-2 py-2 text-xs not-italic shadow transition-transform duration-200 hover:bg-buttonSecondary-hover disabled:cursor-not-allowed disabled:bg-gray-300 disabled:opacity-40',
          className,
          isLoading && 'justify-center',
        )}
        disabled={isDisabled || isLoading}
        onClick={() => !isDisabled && toggleDropdown()}
      >
        {isLoading ? (
          <FontAwesomeIcon icon={faCircleNotch} className="fa-spin w-28 py-0.5" />
        ) : (
          <>
            <div className="flex items-center overflow-hidden">
              <FontAwesomeIcon icon={buttonIcon} className="pr-2" />
              <p className={`mr-2 truncate text-xs ${hasValue ? '' : 'text-gray-80'}`}>{valueLabel}</p>
            </div>
            <FontAwesomeIcon icon={dropdownOpen ? faChevronUp : faChevronDown} className="pl-1" />
          </>
        )}
      </button>

      <div
        id={`${id}-dropdown-options`}
        ref={dropdownContentRef}
        className={`absolute z-20 w-full overflow-hidden rounded-b-md bg-white text-xs shadow-lg transition-all duration-500 ${maxHeight} `}
      >
        <div className="max-h-40 overflow-scroll">
          {options.map((option, index) => (
            <button
              key={index}
              className="flex w-full cursor-pointer items-center justify-center px-2 py-3 not-italic hover:bg-gray-100 focus:bg-gray-100"
              onClick={() => {
                onOptionClick(option.key);
                setDropdownOpen(false);
              }}
            >
              {option.label}
            </button>
          ))}
        </div>
        {hasValue && onClearClick && (
          <button
            className="flex w-full cursor-pointer items-center justify-center bg-red-50 px-2 py-3 not-italic hover:bg-red-100"
            onClick={onClearClick}
          >
            <p className="flex items-center gap-2">
              Clear <FontAwesomeIcon icon={faXmark} />
            </p>
          </button>
        )}
      </div>
    </div>
  );
};

export default Dropdown;
