import {
  faCircleCheck,
  faCircleQuestion,
  faCircleXmark,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'react-tooltip';

import { APIBaseChronos } from '@/api/hosts';
import useUpdateFact from '@/api/mutations/useUpdateFact';
import ReactInfoYellow from '@/assets/icons/info-yellow.svg?react';
import Chip from '@/components/atoms/chip';
import CalendarEditPopup from '@/components/molecules/CalendarPopup';
import EditableField from '@/components/molecules/EditableField';
import { TooltipStyles } from '@/constants/styles';
import { useDocumentNavigation } from '@/screens/Chronos/CaseEditor/hooks/useOpenDocViewer';
import { useOpenInconsistencyViewer } from '@/screens/Chronos/CaseEditor/hooks/useOpenInconsistencyViewer';
import { Fact, Relevance } from '@/types';

interface FactTitleProps {
  open: boolean;
  fact: Fact;
  factText: string;
  dateText: string;
  updateDate: (v: string) => void;
  compressedVersion?: boolean;
  reference?: number;
  hideRelevanceIndicator?: boolean;
  setIsDateDirty?: (v: boolean) => void;
  onViewDocument?: (docId: string, factId: string, pageNumber: number) => void;
}

const FactTitle = ({
  open,
  fact,
  factText,
  dateText,
  updateDate,
  reference,
  hideRelevanceIndicator,
  setIsDateDirty,
  onViewDocument,
  compressedVersion = false,
}: FactTitleProps) => {
  const { openDocViewer } = useDocumentNavigation();
  const { openInconsistencyViewer } = useOpenInconsistencyViewer();
  const { mutate: updateFact, isLoading } = useUpdateFact({ analyticsLocation: 'Fact' });

  const handleUpdateFact = (newFact: string) => {
    updateFact({ field: 'description', value: newFact, factId: fact.id, caseId: fact.caseId });
  };

  const referenceDigits = reference ? Math.abs(reference).toString().length : 2;
  const referencePosition = referenceDigits === 1 ? 'right-5' : referenceDigits === 2 ? 'right-4' : 'right-3';

  const RelevanceIndicator = ({ relevance }: { relevance: Relevance }) => {
    const getRelevanceInfo = () => {
      switch (relevance) {
        case Relevance.YES:
          return { text: 'Relevant', color: 'text-green-800', icon: faCircleCheck };
        case Relevance.MAYBE:
          return { text: 'Maybe Relevant', color: 'text-orange-400', icon: faCircleQuestion };
        case Relevance.NO:
          return { text: 'Not Relevant', color: 'text-red-800', icon: faCircleXmark };
        default:
          return { text: 'Not Relevant', color: 'text-red-800', icon: faCircleXmark };
      }
    };

    const { text, color, icon } = getRelevanceInfo();

    return (
      <p className={`flex w-32 items-center justify-end gap-2 text-xs ${color}`}>
        <FontAwesomeIcon icon={icon} />
        <span>{text}</span>
      </p>
    );
  };

  return (
    <div className="flex w-full items-center justify-between font-normal">
      <div className={`w-full px-2 pl-2 ${compressedVersion ? 'p-[6px]' : 'p-2'}`}>
        <div className="flex w-full justify-between">
          <div className={`flex items-center gap-2 font-semibold ${compressedVersion ? 'text-xs' : 'text-sm'}`}>
            <CalendarEditPopup
              dateText={dateText}
              entryFieldKey="factDateText"
              updateDate={updateDate}
              compressedVersion={compressedVersion}
              endpointToUpdate={`${APIBaseChronos}/api/case/${fact.caseId}/fact/${fact.id}`}
              setIsDateDirty={setIsDateDirty}
              location="Fact"
            />

            {dateText ? dateText : '(Date Unknown)'}

            {fact.dateIsAmbiguous && (
              <>
                <ReactInfoYellow
                  data-tooltip-id={`fact-date-text-tooltip-${fact.id}-ambiguous`}
                  data-tooltip-content={fact.dateIsAmbiguousText || 'Date format is ambiguous, please check'}
                  className="cursor-pointer text-sm text-gray-700"
                  style={{ color: '#E3B336' }}
                />
                <Tooltip
                  opacity={1}
                  id={`fact-date-text-tooltip-${fact.id}-ambiguous`}
                  style={TooltipStyles}
                  place={'top-start'}
                />
              </>
            )}
          </div>

          <div className="flex items-center gap-6">
            {fact.inconsistencies && fact.inconsistencies.length ? (
              <Chip
                className="bg-red-100 hover:bg-red-200"
                onClick={() =>
                  fact.inconsistencies &&
                  openInconsistencyViewer(fact.inconsistencies.map((inconsistency) => inconsistency.inconsistencyId))
                }
              >
                <FontAwesomeIcon icon={faExclamationTriangle} className="text-red-500" />
                <span className="text-gray-700">Disputed</span>
              </Chip>
            ) : (
              <></>
            )}

            <div className="flex gap-0.5 text-xs">
              {fact.locations.map((location, index) => {
                if (location.doc === undefined || location.doc === null) return <></>;
                if (compressedVersion && index === 2) return <div key={`${index}-ellipsis`}>...</div>;
                if (compressedVersion && index > 2) return <div key={`${index}-ellipsis`}></div>;

                const doc = location.doc;
                return (
                  <span key={`${doc.id}-${index}`} className="flex items-center justify-between">
                    <span
                      className="text-blue-600"
                      data-tooltip-id={`fact-document-tooltip-${fact.id}-${location.docId}`}
                      data-tooltip-content={doc.file.name}
                      onClick={(e) => {
                        e.stopPropagation();

                        (onViewDocument ?? openDocViewer)(
                          location.docId,
                          fact.id,
                          location.boundingBoxes?.[0]?.pageNumber ?? 0,
                        );
                      }}
                    >
                      {doc.file.name ? doc.file.name.slice(0, 20).trim() + '...' : ''}
                    </span>
                    {doc.type && <Chip className="bg-brandTertiary/20 mx-1">{doc.type}</Chip>}
                    {fact.locations.length > 1 && index < fact.locations.length - 1 ? <>, </> : <></>}
                    <Tooltip
                      opacity={1}
                      id={`fact-document-tooltip-${fact.id}-${location.docId}`}
                      style={TooltipStyles}
                    />
                  </span>
                );
              })}
            </div>
          </div>
        </div>

        <div className="flex justify-between gap-1">
          <div className={`w-5/6 pt-1 pl-2 text-gray-900 ${compressedVersion ? 'text-xs' : 'text-sm'}`}>
            {open ? (
              <EditableField value={factText} onSave={handleUpdateFact} isLoading={isLoading} />
            ) : factText && factText.length > 160 ? (
              factText.slice(0, 160) + '...'
            ) : (
              factText
            )}
          </div>
          {compressedVersion && !hideRelevanceIndicator ? <RelevanceIndicator relevance={fact.relevance} /> : <></>}
          <p
            className={`absolute top-2 text-blue-600 ${
              compressedVersion ? 'text-xs font-normal' : 'text-sm font-bold'
            } ${referencePosition}`}
          >
            {reference}
          </p>
        </div>
      </div>
    </div>
  );
};

export default FactTitle;
