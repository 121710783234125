import { Filters } from '@/types';

export const createFilterParams = (filters: Filters) => {
  return Object.keys(filters)
    .map((key) => {
      const keyName = key as keyof Filters;
      const values = filters[keyName];
      if (Array.isArray(values)) {
        return `${values.map((val) => `${keyName}[]=${val?.value ?? val}`).join('&')}`;
      }
      return `${keyName}=${values}`;
    })
    .filter((param) => param !== '')
    .join('&');
};
