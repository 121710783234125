import { toast } from 'sonner';

import { formatEmailDownloadContent } from './constants';
import { trackCustomEvent } from '@/analytics/Mixpanel';
import { APIBaseChronos } from '@/api/hosts';
import { FetchConfig } from '@/api/useGetFetchConfig';

const getStaticAssetPresignedUrl = async (key: string, fetchConfig: FetchConfig) => {
  const url = new URL(`${APIBaseChronos}/api/storage/presigned-get-static-asset?key=${key}`);
  const data = await fetch(url.toString(), fetchConfig).then((res) => res.json());
  return data.url;
};

// Helper function to fetch and convert to Base64
const fetchAttachmentAsBase64 = async (url: string): Promise<string> => {
  const response = await fetch(url);
  const blob = await response.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const dataUrl = reader.result as string;
      const base64 = dataUrl.split(',')[1];
      resolve(base64);
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

const handleDownloadClientMaterialsEml = async (userEmail: string, fetchConfig: FetchConfig) => {
  trackCustomEvent('Downloaded Email Template');
  try {
    // Get presigned URLs for the attachments
    const overviewUrl = await getStaticAssetPresignedUrl('overview.docx', fetchConfig);
    const faqsUrl = await getStaticAssetPresignedUrl('faqs.docx', fetchConfig);

    // Fetch the attachments and convert to Base64
    const [overviewBase64, faqsBase64] = await Promise.all([
      fetchAttachmentAsBase64(overviewUrl),
      fetchAttachmentAsBase64(faqsUrl),
    ]);

    // Create EML content with attachments
    const emlContent = formatEmailDownloadContent(userEmail, overviewBase64, faqsBase64).replace(/\n/g, '\r\n');

    // Create and download the file
    const blob = new Blob([emlContent], { type: 'message/rfc822' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'wexler_introduction.eml';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);

    toast.success('Email downloaded');
  } catch (error) {
    console.error('Download error:', error);
    toast.error('Failed to download email');
  }
};

export { getStaticAssetPresignedUrl, handleDownloadClientMaterialsEml };
