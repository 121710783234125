import { ReactElement } from 'react';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FileArchive } from 'lucide-react';

import { cn } from '@/helpers/shadCnUtils';

interface ImagesButtonProps {
  title: string;
  imageSource?: Array<string>;
  fontAwesomeIcon?: IconDefinition;
  hoverText: string;
  onClick: () => void;
  blankCount?: number;
}

const ImagesButton = ({
  title,
  imageSource,
  fontAwesomeIcon,
  hoverText,
  onClick,
  blankCount = 4,
}: ImagesButtonProps) => {
  return (
    <button
      onClick={onClick}
      title={hoverText}
      className={cn(
        'border-brandSecondary flex h-[54px] w-fit cursor-pointer items-center justify-center rounded-2xl border border-dashed bg-gray-200 bg-slate-100 text-base font-bold text-white',
      )}
    >
      <div className="flex items-center justify-between p-2.5">
        <div className="flex gap-1.5">
          {fontAwesomeIcon ? (
            <FontAwesomeIcon className="w-8 text-2xl text-gray-700" icon={fontAwesomeIcon as IconProp} />
          ) : imageSource && imageSource.length ? (
            imageSource.map((source, index) => <img key={index} src={source} alt={title} className="h-8 w-8 rounded" />)
          ) : (
            Array.from({ length: blankCount }).map((_, index) => (
              <div key={index} className="h-8 w-8 rounded bg-gray-500" />
            ))
          )}
        </div>
      </div>
    </button>
  );
};

export default ImagesButton;
