import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ArrowRight, Check, Search } from 'lucide-react';

import { Input } from '@/components/atoms/input';
import { Button } from '@/components/atoms/new-button';
import { Matter, OrgUser, OrgUserWithMetadata } from '@/types';

interface NewMattersProps {
  selectedUser: OrgUserWithMetadata | OrgUser | null;
  filteredMatters: Matter[];
  isLoadingMatters: boolean;
  selectedMatters: Set<string>;
  onToggleMatterSelection: (matterId: string) => void;
  onAddMattersToUser: () => void;
  onCancel: () => void;
  matterSearchQuery: string;
  onSearchQueryChange: (query: string) => void;
}

const NewMatters = ({
  selectedUser,
  filteredMatters,
  isLoadingMatters,
  selectedMatters,
  onToggleMatterSelection,
  onAddMattersToUser,
  onCancel,
  matterSearchQuery,
  onSearchQueryChange,
}: NewMattersProps) => {
  if (!selectedUser) return null;

  return (
    <div className="p-6">
      <h3 className="mb-4 border-b pb-2 text-lg font-medium text-gray-900">
        Add <span className="text-blue-600">{selectedUser.email}</span> to Matters
      </h3>

      <div className="relative mb-4">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <Search className="h-4 w-4 text-gray-400" />
        </div>
        <Input
          type="text"
          placeholder="Search matters..."
          className="pl-10"
          value={matterSearchQuery}
          onChange={(e) => onSearchQueryChange(e.target.value)}
        />
      </div>

      <div className="mb-4 max-h-72 overflow-y-auto rounded-lg border">
        {isLoadingMatters ? (
          <div className="flex items-center justify-center p-8">
            <FontAwesomeIcon icon={faCircleNotch} className="h-6 w-6 animate-spin" />
          </div>
        ) : filteredMatters.length > 0 ? (
          <div className="divide-y">
            {filteredMatters.map((matter: Matter) => {
              const isSelected = selectedMatters.has(matter.id);
              return (
                <div
                  key={matter.id}
                  className={`flex h-18 cursor-pointer items-center px-4 py-3 transition-colors ${
                    isSelected ? 'border-blue-200 bg-blue-50' : 'hover:bg-gray-50'
                  }`}
                  onClick={() => onToggleMatterSelection(matter.id)}
                >
                  <div className="flex w-full items-center gap-3">
                    <div
                      className={`flex h-5 w-5 flex-shrink-0 items-center justify-center rounded border ${
                        isSelected ? 'border-blue-600 bg-blue-600' : 'border-gray-300'
                      }`}
                    >
                      {isSelected && <Check className="h-3 w-3 text-white" />}
                    </div>
                    <div className="min-w-0 flex-1">
                      <h4 className="truncate text-sm font-medium text-gray-900">{matter.name}</h4>
                      <div className="mt-1 flex flex-wrap items-center gap-2">
                        {matter.code && (
                          <span className="inline-flex items-center rounded-md bg-blue-50 px-2 py-0.5 text-xs font-medium text-blue-700 ring-1 ring-blue-700/10 ring-inset">
                            {matter.code}
                          </span>
                        )}
                        {matter.description && (
                          <p className="line-clamp-1 text-xs text-gray-600">{matter.description}</p>
                        )}
                      </div>
                    </div>
                    <div className="ml-2 text-gray-400">
                      <Check
                        className={`h-4 w-4 transition-opacity ${isSelected ? 'text-blue-500 opacity-100' : 'opacity-0'}`}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center p-8 text-center text-gray-500">
            {matterSearchQuery ? (
              <p>No matching matters found</p>
            ) : (
              <p>User already has access to all available matters</p>
            )}
          </div>
        )}
      </div>

      <div className="flex justify-between border-t pt-4">
        <div className="text-sm text-gray-500">
          {selectedMatters.size} {selectedMatters.size === 1 ? 'matter' : 'matters'} selected
        </div>
        <div className="space-x-3">
          <Button variant="secondary" onClick={onCancel}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onAddMattersToUser} disabled={selectedMatters.size === 0}>
            Add Access
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NewMatters;
