import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PipelineMessage = ({ timingMessage, isFailed }: { timingMessage: string; isFailed: boolean }) => {
  let message = '';
  let subMessage = '';
  if (isFailed) {
    message = 'There was an error processing your case.';
    subMessage =
      'A member of the Wexler technical staff has been informed and will reach out shortly when the issue has been resolved.';
  } else {
    message = 'Your documents are currently being processed, you should receive an email when they have finished.';
    subMessage = 'You may close this page if you wish.';
  }

  return (
    <div className="flex flex-col text-gray-600">
      <div className="flex items-center justify-between gap-10">
        {isFailed ? (
          <div className="flex items-center gap-2 text-lg font-medium">
            <FontAwesomeIcon icon={faExclamationTriangle} className="h-6 w-6 text-red-500" />
            <p>{message}</p>
          </div>
        ) : (
          <p>{message}</p>
        )}

        {!isFailed && <div className="border-brandTertiary rounded bg-gray-200 px-2 py-1 text-sm">{timingMessage}</div>}
      </div>
      <p className="text-sm text-gray-400">{subMessage}</p>
    </div>
  );
};

export default PipelineMessage;
