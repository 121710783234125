import { useRef } from 'react';

import { faFilePen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ILottie } from '@lottielab/lottie-player/react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

import useGetRecentCases from '../hooks/useGetRecentCases';
import { trackCustomEvent } from '@/analytics/Mixpanel';
import Clock from '@/assets/animations/Clock.json';
import LottieAnimation from '@/components/atoms/Lottie';
import formatDate from '@/helpers/formatDate';
import { cn } from '@/helpers/shadCnUtils';
import { RecentCase } from '@/types';

const RecentCaseItem = ({ recentCase }: { recentCase: RecentCase }) => {
  const ref = useRef<ILottie>(null);
  const navigate = useNavigate();

  const isDraft = !recentCase.case.runs || recentCase.case.runs.length === 0;

  return (
    <div
      onClick={() => {
        trackCustomEvent('Recent Case Clicked', {
          caseId: recentCase.caseId,
          isDraft: isDraft,
        });

        if (isDraft) {
          navigate(`/app/chronos/case-creator?caseId=${recentCase.caseId}`);
        } else {
          navigate(`/app/chronos/case-editor/summary?caseId=${recentCase.caseId}`);
        }
      }}
      onMouseEnter={() => {
        ref.current?.interactivity?.trigger('start');
      }}
      onMouseLeave={() => {}}
      className={cn(
        'group cursor-pointer rounded-md border px-4 py-2 shadow-sm transition-all duration-200 hover:-translate-y-[2px] hover:shadow-md',
        isDraft
          ? 'border-dashed border-gray-300 bg-gray-100 hover:border-gray-400'
          : 'border-gray-200 bg-gray-50 hover:border-gray-300',
      )}
    >
      <div className="flex items-start justify-between">
        <div className="flex-1 gap-2">
          <div className="flex items-center justify-between">
            <div className="mb-1 flex items-center gap-2 lg:mb-0">
              <LottieAnimation lottieRef={ref} className="h-4 w-4" animationData={Clock} loop={false} />
              <h3 className="font-medium text-blue-600">
                {recentCase?.case?.name && recentCase.case.name.length > 50
                  ? `${recentCase.case.name.slice(0, 50)}...`
                  : recentCase.case.name}
              </h3>
            </div>
            <div>
              {isDraft && (
                <div className="flex items-center gap-1 text-gray-600">
                  <FontAwesomeIcon icon={faFilePen} className="h-3 w-3" />
                  <span className="text-xs">Draft</span>
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col items-start justify-between text-gray-700 lg:flex-row lg:items-center lg:gap-2">
            <span className="text-xs font-medium">
              {recentCase?.case?.matter?.name && recentCase.case.matter.name.length > 40
                ? `${recentCase.case.matter.name.slice(0, 40)}...`
                : recentCase.case.matter.name}
            </span>
            <span className="text-xs text-gray-500">Accessed: {formatDate(recentCase.accessedAt)}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const RecentCases = () => {
  const { data: recentCases, isLoading } = useGetRecentCases();

  return (
    <div className="mb-6">
      <h2 className="mb-4 border-2 border-x-0 border-t-0 px-4 pb-1 text-lg font-semibold">Where you left off...</h2>
      <div className="flex w-full flex-col gap-2 px-4 text-sm text-gray-800">
        {recentCases && recentCases.length > 0
          ? recentCases?.map((recentCase, i) => (
              <motion.div
                key={i}
                initial={{ opacity: 0, y: 40 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3, delay: i * 0.1 }}
              >
                <RecentCaseItem key={recentCase.caseId} recentCase={recentCase} />
              </motion.div>
            ))
          : !isLoading && (
              <div className="flex flex-col gap-2">
                <p>
                  Explore your team's matters and cases or upload to your{' '}
                  <span className="font-semibold text-purple-500">Personal Matter</span>.
                </p>
                <p className="text-sm text-gray-500">Check out our guides below for more information.</p>
              </div>
            )}
      </div>
    </div>
  );
};

export default RecentCases;
