import { useQuery } from 'react-query';

import { APIBaseChronos } from '../hosts';
import { QueryEntity } from '../types';
import useGetFetchConfig from '../useGetFetchConfig';

export const useGetPersonalMatter = () => {
  const { fetchConfigGET } = useGetFetchConfig();

  const getPersonalMatter = async () => {
    const response = await fetch(`${APIBaseChronos}/api/matters/personal`, fetchConfigGET);
    return await response.json();
  };

  return useQuery({
    queryKey: [QueryEntity.PersonalMatter],
    queryFn: getPersonalMatter,
    refetchOnWindowFocus: false,
  });
};
