import { z } from 'zod';

export const lightCaseSchema = z.object({
  name: z.string().min(1, { message: 'Name must be provided.' }),
  legalIssues: z.string().min(1, { message: 'Case issues must be provided.' }),
});

export const fullCaseSchema = lightCaseSchema.extend({
  type: z.string().min(1, { message: 'Type must be provided.' }),
  parties: z.string().min(1, { message: 'Parties must be provided.' }),
  keyContext: z.string().optional(),
  keyTimePeriod: z.string().optional(),
  disputeStatus: z.string().optional(),
});

export type LightCaseForm = z.infer<typeof lightCaseSchema>;
export type FullCaseForm = z.infer<typeof fullCaseSchema>;
