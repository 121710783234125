import { RefObject, useCallback, useEffect, useState } from 'react';

import { BUFFER_PAGES, DEFAULT_PAGE_HEIGHT } from './constants';

export const useVisiblePages = (
  containerRef: RefObject<HTMLDivElement>,
  numPages: number,
  pageDimensions: { height: number; width: number } ,
) => {
  const [visiblePages, setVisiblePages] = useState<number[]>([1]);

  const updateVisiblePages = useCallback(() => {
    if (!containerRef.current || numPages === 0) return;

    const scrollTop = containerRef.current.scrollTop;
    const clientHeight = containerRef.current.clientHeight;
    const scrollBottom = scrollTop + clientHeight;

    let accHeight = 0;
    const visible = [];

    for (let i = 0; i < numPages; i++) {
      const pageNumber = i + 1;
      const pageHeight = pageDimensions.height || DEFAULT_PAGE_HEIGHT;
      const pageTop = accHeight;
      const pageBottom = accHeight + pageHeight;

      if (pageBottom + BUFFER_PAGES * pageHeight > scrollTop && pageTop - BUFFER_PAGES * pageHeight < scrollBottom) {
        visible.push(pageNumber);
      }

      accHeight += pageHeight;
    }

    setVisiblePages(visible);
  }, [numPages, pageDimensions, containerRef]);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    let ticking = false;
    const handleScroll = () => {
      if (!ticking) {
        requestAnimationFrame(() => {
          updateVisiblePages();
          ticking = false;
        });
        ticking = true;
      }
    };

    container.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);

    return () => {
      container.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    };
  }, [updateVisiblePages, containerRef]);

  useEffect(() => {
    return () => {
      setVisiblePages([]);
    };
  }, []);

  return visiblePages;
};
