import { faArrowLeft, faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button from '@/components/atoms/Button';
import Pagination, { PaginationProps } from '@/components/organisms/Pagination';
import DataViewToolbar from '@/screens/Chronos/CaseEditor/DataView/components/TableToolbar';

interface Props {
  reportTitle: string;
  handleBack: () => void;
  openDownloadModal: () => void;
  goToPage: (value: number) => void;
}

export const InconsistencyReportToolbar = ({
  reportTitle,
  handleBack,
  openDownloadModal,
  canGetPrevPage,
  canGetNextPage,
  prevPage,
  nextPage,
  currentPage,
  noOfPages,
  goToPage,
}: Props & PaginationProps) => {
  return (
    <DataViewToolbar
      titleSection={
        <div className="flex items-center pr-4">
          <FontAwesomeIcon
            icon={faArrowLeft}
            className="mr-3 ml-2 h-3 w-3 cursor-pointer rounded-full border bg-gray-50 p-1 text-gray-700"
            onClick={handleBack}
          />
          <div className="font-semibold text-blue-500">{reportTitle}</div>
        </div>
      }
      longTitleSection={true}
      actionButtons={
        <>
          <Button
            icon={<FontAwesomeIcon icon={faDownload} className="pr-2" />}
            className="bg-buttonSecondary hover:bg-buttonSecondary-hover rounded border px-2 py-2 text-xs shadow"
            onClick={openDownloadModal}
            text="Download"
          />
        </>
      }
      pagination={
        <Pagination
          canGetPrevPage={canGetPrevPage}
          canGetNextPage={canGetNextPage}
          prevPage={prevPage}
          nextPage={nextPage}
          currentPage={currentPage}
          noOfPages={noOfPages}
          goToPage={goToPage}
        />
      }
    />
  );
};
