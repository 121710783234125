import { CountsChip } from './CountsChip';
import IncludeAllDropdown from '../FactsEditor/components/IncludeAllDropdown';

export const ShownSelectedCountsHeader = ({
  header,
  resultsLength,
  resultsIncludedLength,
  handleToggleAll,
}: {
  header: string;
  resultsLength: number;
  resultsIncludedLength: number;
  handleToggleAll: (checked: boolean) => void;
}) => {
  return (
    <>
      <div className="flex items-center space-x-1">
        <h1 className="text-lg font-semibold">{header}</h1>
        <span className="text-sm text-gray-600">({resultsLength})</span>
      </div>
      <div className="flex items-center space-x-2">
        <IncludeAllDropdown
          allRowsChecked={resultsLength === resultsIncludedLength}
          anyRowsChecked={resultsIncludedLength > 0}
          handleSelectAll={() => handleToggleAll(true)}
          handleClearAll={() => handleToggleAll(false)}
        />
        <CountsChip count={resultsIncludedLength} label="selected" />
      </div>
    </>
  );
};
