import React, { useCallback, useMemo, useState } from 'react';

import { faCircleNotch, faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';

import { useCreateChronologyFact } from '@/api/mutations/useCreateChronologyFact';
import { useGetDocIds } from '@/api/queries/useGetDocIds';
import Button from '@/components/atoms/Button';
import { ClassificationOptions, RelevanceOptions } from '@/constants/filterOptions';
import { reactSelectStylesFormFact } from '@/constants/styles';
import { Classification, MyOptionType, Relevance } from '@/types';
import 'react-calendar/dist/Calendar.css';

interface FactAdderProps {
  caseId: string;
  handleNewFactCreated: () => void;
  chronologyId: string;
}
const FactAdder = ({ caseId, chronologyId, handleNewFactCreated }: FactAdderProps) => {
  const [selectedDoc, setSelectedDoc] = useState<MyOptionType>(null);
  const [selectedRelevance, setSelectedRelevance] = useState<MyOptionType>(null);
  const [selectedClassification, setSelectedClassification] = useState<MyOptionType>(null);
  const [significance, setSignificance] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [factDateText, setFactDateText] = useState<string>('');

  const { data: docIds } = useGetDocIds(caseId);
  const { mutateAsync: createChronologyFact, isLoading: isLoadingAddNewFact } = useCreateChronologyFact(
    chronologyId,
    selectedDoc?.value ?? '',
    caseId,
  );

  const handleChangeDescription = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(e.target.value);
  }, []);

  const handleChangeSelectedDoc = useCallback((val: MyOptionType) => {
    setSelectedDoc(val);
  }, []);

  const handleChangeDateOfSubjectMatterText = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setFactDateText(e.target.value);
  }, []);

  const handleChangeSignificance = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSignificance(e.target.value);
  }, []);

  const docsOptions = useMemo(() => docIds?.map((doc) => ({ label: doc.file.name, value: doc.id })) ?? [], [docIds]);

  const handleAddFact = async () => {
    await createChronologyFact({
      description,
      factDateText,
      significance,
      classification: selectedClassification?.value as Classification,
      relevance: selectedRelevance?.value as Relevance,
    });
    handleNewFactCreated();
  };

  const disabled = !selectedDoc || !selectedClassification || !selectedRelevance;
  return (
    <>
      {isLoadingAddNewFact ? (
        <div className="flex h-72 flex-col items-center justify-center gap-8">
          <div className="text-xl">Adding Fact...</div>
          <FontAwesomeIcon icon={faCircleNotch} className="fa-spin text-brandSecondary text-4xl" />
        </div>
      ) : (
        <div className="px-6 py-4">
          <input
            onChange={handleChangeDescription}
            className="mt-2 h-12 w-full rounded-lg bg-white pl-3 text-base font-normal text-black not-italic placeholder:text-gray-400 focus:outline-none"
            value={description}
            placeholder="Description"
            style={{ border: '1px solid #ECEFFF' }}
          />
          <Select
            options={docsOptions}
            className="mt-3 w-full rounded-md bg-white outline-none"
            styles={reactSelectStylesFormFact}
            onChange={handleChangeSelectedDoc}
            value={selectedDoc}
            placeholder={'Select a document'}
          />
          <input
            onChange={handleChangeDateOfSubjectMatterText}
            className="mt-3 h-12 w-full rounded-lg bg-white pl-3 text-base font-normal text-black not-italic placeholder:text-gray-400 focus:outline-none"
            value={factDateText}
            placeholder="Date"
            style={{ border: '1px solid #ECEFFF' }}
          />
          <input
            onChange={handleChangeSignificance}
            className="mt-3 h-12 w-full rounded-lg bg-white pl-3 text-base font-normal text-black not-italic placeholder:text-gray-400 focus:outline-none"
            value={significance}
            placeholder="Significance"
            style={{ border: '1px solid #ECEFFF' }}
          />
          <div className="mb-4 flex gap-2">
            <Select
              options={Object.values(RelevanceOptions)}
              className="mt-3 w-full rounded-md bg-white outline-none"
              styles={reactSelectStylesFormFact}
              onChange={setSelectedRelevance}
              value={selectedRelevance}
              placeholder={'Relevant'}
            />
            <Select
              options={Object.values(ClassificationOptions)}
              className="mt-3 w-full rounded-md bg-white outline-none"
              styles={reactSelectStylesFormFact}
              onChange={setSelectedClassification}
              value={selectedClassification}
              placeholder={'Classification'}
            />
          </div>

          <Button
            onClick={handleAddFact}
            disabled={disabled}
            text="Add"
            type="primary"
            rounded="md"
            icon={<FontAwesomeIcon icon={faCirclePlus} className="mr-2" />}
          />
        </div>
      )}
    </>
  );
};

export default FactAdder;
