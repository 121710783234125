import React, { useCallback, useEffect, useState } from 'react';

import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { processQuestion } from './helpers';
import useCreateMessage from './hooks/useCreateMessage';
import useCreateThread from './hooks/useCreateThread';
import Question from './Question';
import ThreadView from './ThreadView';
import { trackCustomEvent, trackPageView } from '@/analytics/Mixpanel';
import { useGetDocIds } from '@/api/queries/useGetDocIds';
import { DraftType, ExecuteKimSearch, KimMode } from '@/types';

const CaseAssistant = React.memo(() => {
  // Question answering state
  const [questionValue, setQuestionValue] = useState('');
  const [draftType, setDraftType] = useState<DraftType | null>(null);
  const [taskFiles, setTaskFiles] = useState<File[]>([]);
  const [mode, setMode] = useState<KimMode>(KimMode.QuestionFlow);
  const [taggedDocuments, setTaggedDocuments] = useState<{ doc_id: string; file_name: string }[]>([]);

  const [displayQuestion, setDisplayQuestion] = useState('');
  const [isCreatingThread, setIsCreatingThread] = useState(false);
  const [isExecutingSearch, setIsExecutingSearch] = useState(false);

  const resetState = () => {
    setQuestionValue('');
    setTaggedDocuments([]);
    setMode(KimMode.QuestionFlow);
    setTaskFiles([]);
    setDraftType(null);
  };

  const resetStateOnRequestComplete = () => {
    setIsExecutingSearch(false);
    setIsCreatingThread(false);
    setDisplayQuestion('');
  };

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  const caseId = searchParams.get('caseId');
  const threadId = searchParams.get('threadId');

  const { data: responseDocIds } = useGetDocIds(caseId || '');

  // Mutations
  const { mutate: createThread } = useCreateThread(caseId || '');
  const { mutate: createMessage } = useCreateMessage();

  // Thread navigation handlers
  const handleNewThread = useCallback(() => {
    trackCustomEvent('Kim New Thread');

    searchParams.delete('threadId');
    navigate(`${location.pathname}?${searchParams.toString()}`);
  }, [navigate, location, searchParams]);

  useEffect(() => {
    setDraftType(null);
  }, [mode]);

  const goToThread = useCallback(
    (threadId: string) => {
      // If a document is selected in params, delete it
      searchParams.delete('selectedDocId');

      searchParams.set('threadId', threadId);
      navigate(`${location.pathname}?${searchParams.toString()}`);
    },
    [navigate, location, searchParams],
  );

  // Error handling
  const errorToast = useCallback(() => {
    toast.error('Error processing your question. Please try again.');
  }, []);

  const executeSearch: ExecuteKimSearch = ({ question: value, draftType }) => {
    if (value.trim() === '') {
      return;
    }
    setIsCreatingThread(true);
    setIsExecutingSearch(true);
    const question = processQuestion(value);

    // First, create the thread and add the message to the thread
    createThread(question, {
      onSuccess: (data) => {
        // Set the thread id in search params
        searchParams.set('threadId', data.id);
        navigate(`${location.pathname}?${searchParams.toString()}`);

        const messagePayload = {
          caseId: caseId || '',
          question: question,
          docIds: taggedDocuments.map((doc) => doc.doc_id),
          draftType,
          files: taskFiles,
          mode: mode,
          threadId: data.id,
        };
        setDisplayQuestion(question);
        resetState();
        trackCustomEvent('Kim Question Asked', { caseId, mode });

        // Then create the message
        createMessage(messagePayload, {
          onError: (error) => {
            console.error('Error creating message');
            errorToast();
          },
          onSettled: () => {
            resetStateOnRequestComplete();
          },
        });
      },
      onError: (error) => {
        console.error('Error creating thread');
        errorToast();
        resetStateOnRequestComplete();
      },
    });
  };

  // Analytics
  trackPageView('Kim');

  return (
    <div className="flex h-[92%] w-full gap-2">
      <>
        <div className="flex h-full w-full flex-col rounded border-2 border-gray-200/80 bg-white">
          {threadId ? (
            <ThreadView
              question={displayQuestion}
              caseId={caseId || ''}
              threadId={threadId || ''}
              isExecutingSearch={isExecutingSearch}
              errorToast={errorToast}
              executeSearch={executeSearch}
              // Previous thread management
              goToThread={goToThread}
              handleNewThread={handleNewThread}
            />
          ) : (
            <Question
              caseId={caseId ?? ''}
              // Question Execution
              executeSearch={executeSearch}
              setQuestionValue={setQuestionValue}
              questionValue={questionValue}
              setTaskFiles={setTaskFiles}
              isCreatingThread={isCreatingThread}
              draftType={draftType}
              setDraftType={setDraftType}
              // Document @-ing
              docs={responseDocIds ?? []}
              taggedDocuments={taggedDocuments}
              setTaggedDocuments={setTaggedDocuments}
              // Flow
              mode={mode}
              setMode={setMode}
              // Threads
              createNewThread={handleNewThread}
              goToThread={goToThread}
            />
          )}
        </div>
      </>
    </div>
  );
});

export default CaseAssistant;
