import * as React from "react";
const SvgQuestionIcon = (props) => /* @__PURE__ */ React.createElement("svg", { height: "800px", width: "800px", id: "Layer_1", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", viewBox: "0 0 512 512", xmlSpace: "preserve", ...props }, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFD782"
}, d: "M512,256.001C512,114.615,397.385,0,255.999,0S0,114.615,0,256.001 C0,397.385,114.615,512,255.999,512S512,397.385,512,256.001z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#515262"
}, d: "M285.464,284.948v30.209c0,13.012-9.759,22.773-22.773,22.773c-12.084,0-22.773-9.759-22.773-22.773 v-48.333c0-0.465,0.465-0.465,0.465-0.93c-0.465-0.465-0.465-0.465-0.465-0.93c0-12.084,10.69-22.773,22.773-22.773 c29.279,0,52.981-23.238,52.981-52.051c0-29.279-23.702-52.051-52.981-52.051c-19.055,0-35.786,10.224-44.615,25.561 c-6.506,11.154-20.449,14.872-31.603,8.366c-10.69-6.506-14.407-19.984-7.901-31.138c16.73-28.814,48.333-48.333,84.119-48.333 c53.91,0,98.061,43.686,98.061,97.597C360.752,236.149,328.685,274.723,285.464,284.948z M285.464,386.727v8.366 c0,13.012-9.759,22.773-22.773,22.773c-12.084,0-22.773-9.759-22.773-22.773v-8.366c0-12.549,10.69-22.773,22.773-22.773 C275.704,363.954,285.464,374.179,285.464,386.727z" }), /* @__PURE__ */ React.createElement("path", { style: {
  opacity: 0.1,
  enableBackground: "new"
}, d: "M68.872,256.001c0-129.706,96.466-236.866,221.564-253.688 C279.172,0.798,267.68,0,256.001,0C114.615,0,0,114.615,0,256.001S114.615,512,256.001,512c11.68,0,23.171-0.798,34.436-2.313 C165.338,492.865,68.872,385.705,68.872,256.001z" }));
export default SvgQuestionIcon;
