import { useState } from 'react';

import FactDetail from './FactDetail';
import FactTitle from './FactTitle';
import Accordion from '@/components/molecules/Accordion';
import { Fact, Relevance } from '@/types';

// Interfaces
interface FactDropdownProps {
  fact: Fact;
  compressedVersion?: boolean;
  reference?: number;
  hideRelevanceIndicator?: boolean;
  setIsDateDirty?: (v: boolean) => void;
  updateFactData?: (eventId: string, data: Partial<Fact>) => void;
  onViewDocument?: (docId: string, factId: string, pageNumber: number) => void;
}

const FactDropdown = ({
  fact,
  reference,
  hideRelevanceIndicator,
  compressedVersion = false,
  setIsDateDirty,
  updateFactData,
  onViewDocument,
}: FactDropdownProps) => {
  const [open, setOpen] = useState(false);

  const handleUpdateDate = (newDate: string) => {
    if (updateFactData) {
      updateFactData(fact.id, { factDateText: newDate });
    }
    setIsDateDirty && setIsDateDirty(true);
  };

  const handleUpdateRelevance = (newRelevance: Relevance) => {
    if (updateFactData) {
      updateFactData(fact.id, { relevance: newRelevance });
    }
  };

  return (
    <Accordion
      title={
        <FactTitle
          fact={fact}
          factText={fact.description}
          dateText={fact.factDateText}
          updateDate={handleUpdateDate}
          open={open}
          reference={reference}
          compressedVersion={compressedVersion}
          setIsDateDirty={setIsDateDirty}
          hideRelevanceIndicator={hideRelevanceIndicator}
          onViewDocument={onViewDocument}
        />
      }
      content={
        <FactDetail
          fact={fact}
          significanceText={fact.significance}
          relevanceText={fact.relevance}
          updateRelevance={handleUpdateRelevance}
          compressedVersion={compressedVersion}
          onViewDocument={onViewDocument}
        />
      }
      className="relative p-0"
      outerOpen={setOpen}
    />
  );
};

export default FactDropdown;
