import { useEffect, useState } from 'react';

import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { InconsistencyReportListItem } from './InconsistencyReportListItem';
import { useGetInconsistencyReports } from '../../../../../api/queries/useGetInconsistencyReports';
import { InconsistencyReport } from '../../../../../api/types/backend-dto-types';
import { CountsChip } from '../components/CountsChip';
import { trackPageView } from '@/analytics/Mixpanel';
import EmptyStateCreation from '@/components/molecules/EmptyStateCreation';
import SearchBox from '@/components/molecules/SearchBox';
import Pagination from '@/components/organisms/Pagination';
import DataViewToolbar from '@/screens/Chronos/CaseEditor/DataView/components/TableToolbar';
import { useGeneralPagination } from '@/screens/Chronos/CaseEditor/hooks/useGeneralPagination';

interface Props {
  caseId: string | null;
}

export const InconsistencyReportsList = ({ caseId }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const initialPageSize = 20;
  const [searchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState('');
  const { pagination, setPagination } = useGeneralPagination({ pageSize: initialPageSize });

  const {
    data: responseReports,
    isFetching: isLoadingReports,
    refetch: refetchReports,
  } = useGetInconsistencyReports({
    caseId: caseId ?? '',
    searchQuery,
    page: pagination.pageIndex + 1,
    pageSize: pagination.pageSize,
  });
  const noOfPages = responseReports?.pageCount ?? 0;

  const onSearchCall = () => {
    refetchReports();
  };

  const goToPage = (val: number) => {
    const newPaginationState = { pageIndex: val, pageSize: initialPageSize };
    setPagination(newPaginationState);
    searchParams.set('page', String(newPaginationState.pageIndex + 1));
    navigate(location.pathname + '?' + searchParams.toString());
  };

  const prevPage = () => goToPage(pagination.pageIndex - 1);
  const nextPage = () => goToPage(pagination.pageIndex + 1);
  const canGetPrevPage = pagination.pageIndex > 0;
  const canGetNextPage = pagination.pageIndex < (responseReports?.totalCount ?? 0) / initialPageSize - 1;

  useEffect(() => {
    trackPageView('Inconsistency Report List Page');
  }, []);

  return (
    <div className="flex h-full flex-col">
      <DataViewToolbar
        titleSection={
          <>
            <h1 className="text-lg font-semibold"> Reports</h1>
            <CountsChip count={responseReports?.totalCount ?? 0} label="reports" />
          </>
        }
        searchBar={
          <SearchBox
            value={searchQuery}
            placeholder="Search reports"
            onChange={setSearchQuery}
            onSearchCall={onSearchCall}
          />
        }
        pagination={
          <Pagination
            canGetPrevPage={canGetPrevPage}
            canGetNextPage={canGetNextPage}
            prevPage={prevPage}
            nextPage={nextPage}
            currentPage={pagination.pageIndex}
            noOfPages={noOfPages}
            isLoadingFacts={isLoadingReports}
            goToPage={goToPage}
          />
        }
      />
      <div className="h-full flex-grow overflow-hidden">
        <div className="h-full overflow-y-auto">
          {isLoadingReports ? (
            <div className="flex h-full w-full items-center justify-center">
              <FontAwesomeIcon icon={faCircleNotch} className="fa-spin" />
            </div>
          ) : responseReports?.data && responseReports.data.length ? (
            <div className="max-w-full pr-2">
              {responseReports.data.map((report: InconsistencyReport) => {
                return <InconsistencyReportListItem report={report} key={report.id} />;
              })}
            </div>
          ) : (
            <EmptyStateCreation
              title="No inconsistency reports found"
              subtitle="To make your first report, select the inconsistencies you want to include on the inconsistencies table"
              onClick={() => {
                navigate(`/app/chronos/case-editor/data/inconsistencies?caseId=${caseId}`);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};
