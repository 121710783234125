import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { APIBaseChronos } from '@/api/hosts';
import useGetFetchConfig, { FetchMethod } from '@/api/useGetFetchConfig';

export const useUpdateChronology = ({ chronologyId, caseId }: { chronologyId: string; caseId: string }) => {
  const { getFetchConfig } = useGetFetchConfig();

  return useMutation(
    async (title: string) => {
      const fetchConfig = getFetchConfig({
        method: FetchMethod.PATCH,
        data: { title },
      });

      const response = await fetch(`${APIBaseChronos}/api/case/${caseId}/chronology/${chronologyId}`, fetchConfig);
      if (!response.ok) {
        toast.error('Failed to update chronology');
        return;
      }
    },
    {
      onError: (error) => {
        console.error('Mutation Error: ', error);
        toast.error('Failed to update chronology');
      },
    },
  );
};
