import { useEffect, useState } from 'react';

import { toast as sonnerToast } from 'sonner';

import UsersWithAccess from './UsersWithAccess';
import useUpdateMatter from '../../../../../api/mutations/useUpdateMatter';
import useOrgUsers from '../../../../../api/queries/useGetOrgUsers';
import UserOptionList from '../../../../../components/molecules/UserOptionList';
import { useUserContext } from '../../../../../Contexts/User';
import useGetUsersWithAccessToMatter from '@/api/queries/useGetUsersWithAccessToMatter';
import { MyOptionType, OrgUser } from '@/types';

interface AddUsersToMatterProps {
  matterId: string;
}

const AddUsers = ({ matterId }: AddUsersToMatterProps) => {
  const { user } = useUserContext();

  const { data: orgUsers } = useOrgUsers();
  const { data: usersWithAccess, isFetching: usersLoading } = useGetUsersWithAccessToMatter(matterId);
  const { mutateAsync: updateMatter } = useUpdateMatter(matterId);

  const [selectedUsers, setSelectedUsers] = useState<OrgUser[]>([]);

  useEffect(() => {
    if (usersWithAccess) {
      setSelectedUsers(usersWithAccess);
    }
  }, [usersWithAccess]);

  const orgUsersOptions = (orgUsers ?? [])
    .filter((u) => !usersWithAccess?.some((x) => x.id === u.id) && u.id !== user?.id)
    .map((u) => ({
      value: u.id,
      label: u.email,
    }));

  const handleAddUser = async (userToAdd: MyOptionType) => {
    const newUser = orgUsers?.find((u) => u.id === userToAdd?.value);

    if (newUser === undefined) {
      return;
    }

    const newSelectedUsers = [...selectedUsers, newUser];

    await updateMatter({
      userIds: newSelectedUsers.map((u) => u.id),
    });

    setSelectedUsers(newSelectedUsers);
    sonnerToast(`${userToAdd?.label} added`, {
      duration: 1000,
    });
  };

  return (
    <div className="px-6 pb-4">
      {selectedUsers && !usersLoading ? (
        <>
          <UsersWithAccess users={selectedUsers} />

          <div className="mt-4 text-sm text-gray-500">
            Make sure you are authorised to share this matter with these users.
          </div>
        </>
      ) : (
        <div className="flex h-10 justify-center"></div>
      )}

      <UserOptionList orgUsersOptions={orgUsersOptions} handleAddUser={handleAddUser} />
    </div>
  );
};

export default AddUsers;
