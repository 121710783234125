import { useQuery } from 'react-query';

import { APIBaseChronos } from '@/api/hosts';
import { QueryEntity } from '@/api/types';
import useGetFetchConfig from '@/api/useGetFetchConfig';
import { ApideckSession } from '@/types';

export const useGetApideckSession = (options = {}) => {
  const { fetchConfigGET } = useGetFetchConfig();

  const getApideckSession = async (): Promise<ApideckSession> => {
    const response = await fetch(`${APIBaseChronos}/api/apideck/launch-session/`, fetchConfigGET);
    return await response.json();
  };

  return useQuery<ApideckSession>([QueryEntity.ApideckSession], getApideckSession, options);
};
