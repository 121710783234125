import React from 'react';

import { motion, AnimatePresence } from 'framer-motion';

interface DrawerProps {
  isOpen: boolean;
  onClose?: () => void;
  children: React.ReactNode;
  hideCloseButton?: boolean;
}

const Drawer: React.FC<DrawerProps> = ({ isOpen, onClose, children, hideCloseButton = false }) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.5 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="fixed inset-0 z-40 bg-gray-500"
            onClick={onClose}
          />
          <motion.div
            initial={{ x: '100%' }}
            animate={{ x: 0 }}
            exit={{ x: '100%' }}
            transition={{ type: 'tween', duration: 0.3 }}
            className="fixed right-0 top-0 z-50 h-full w-[600px] bg-white shadow-lg"
          >
            {!hideCloseButton && (
              <button
                onClick={onClose}
                className="absolute right-4 top-4 cursor-pointer border-none bg-transparent text-2xl"
              >
                &times;
              </button>
            )}
            <div className="h-full">{children}</div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
};

export default Drawer;
