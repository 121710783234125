export const getFrontendHost = () => {
  const environment = import.meta.env.VITE_WEXLER_ENV;
  switch (environment) {
    case 'development':
      return 'https://dev.app.wexler.ai';
    case 'production':
      return 'https://app.wexler.ai';
    case 'test':
      return 'https://test.wexler.ai';
    case 'local':
      return 'http://localhost:3000';
    default:
      return 'http://localhost:3000';
  }
};

export const frontendHost = getFrontendHost();
