import { useCallback } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

export const useDocumentNavigation = () => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const openDocViewer = useCallback(
    (newDocId: string, newFactId: string, pageNumber: number) => {
      const searchParams = new URLSearchParams(search);
      searchParams.set('docId', newDocId);
      searchParams.set('factId', newFactId);
      searchParams.set('docPageNumber', pageNumber.toString());
      navigate(`${pathname}?${searchParams}`, { replace: true });
    },
    [navigate, pathname, search],
  );

  const closeDocViewer = () => {
    const searchParams = new URLSearchParams(search);
    searchParams.delete('docId');
    searchParams.delete('factId');
    searchParams.delete('docPageNumber');
    searchParams.delete('factTotal');
    navigate(`${pathname}?${searchParams}`, { replace: true });
  };

  return { openDocViewer, closeDocViewer };
};
