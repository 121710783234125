import { useMutation } from 'react-query';

import { APIBaseChronos } from '@/api/hosts';
import useGetFetchConfig, { FetchMethod } from '@/api/useGetFetchConfig';

const useToggleIncluded = () => {
  const { getFetchConfig } = useGetFetchConfig();

  const putIncludedToggle = useMutation(
    async ({ caseId, toggleValue, eventId }: { caseId: string; toggleValue: boolean; eventId: string }) => {
      const fetchConfig = getFetchConfig({
        method: FetchMethod.PATCH,
        data: { included: toggleValue },
      });
      const response = await fetch(
        `${APIBaseChronos}/api/case/${caseId}/fact/${eventId}/toggle-included/`,
        fetchConfig,
      );
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
    },
  );

  return putIncludedToggle;
};

export default useToggleIncluded;
