import { useQuery } from 'react-query';

import { APIBaseChronos } from '@/api/hosts';
import { QueryEntity } from '@/api/types';
import useGetFetchConfig from '@/api/useGetFetchConfig';
import { Fact } from '@/types';

const useFetchDocumentFacts = ({
  docId,
  caseId,
}: {
  docId: string | null | undefined;
  caseId: string | null | undefined;
}) => {
  const { fetchConfigGET } = useGetFetchConfig();

  return useQuery<Fact[], Error>(
    [QueryEntity.CaseFacts, { docId }],
    async () => {
      const response = await fetch(
        `${APIBaseChronos}/api/case/${caseId}/facts?docIds[]=${docId}&paginated=false`,
        fetchConfigGET,
      );
      if (!response.ok) throw new Error('Network response was not ok');
      return response.json();
    },
    { refetchOnWindowFocus: false },
  );
};

export default useFetchDocumentFacts;
