import * as React from "react";
const SvgConfetti = (props) => /* @__PURE__ */ React.createElement("svg", { height: "800px", width: "800px", id: "Layer_1", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", viewBox: "0 0 512 512", xmlSpace: "preserve", ...props }, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#E65C64"
}, d: "M367.178,314.667h-58.358c-9.223,0-16.697-7.474-16.697-16.697c0-9.223,7.474-16.697,16.697-16.697 h58.358c9.223,0,16.697,7.474,16.697,16.697C383.875,307.194,376.401,314.667,367.178,314.667z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#F26D76"
}, d: "M214.366,220.219c-9.223,0-16.697-7.474-16.697-16.697v-58.358c0-9.223,7.474-16.697,16.697-16.697 c9.223,0,16.697,7.474,16.697,16.697v58.358C231.063,212.745,223.589,220.219,214.366,220.219z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFC033"
}, d: "M241.006,14.693c-19.48-19.591-51.314-19.591-70.794,0c-19.48,19.48-19.48,51.315,0,70.795 c9.461,9.462,22.04,14.693,35.396,14.693s25.937-5.232,35.397-14.693C260.599,66.009,260.599,34.173,241.006,14.693z  M217.459,61.809c-6.78,6.78-17.183,6.508-23.632,0.07c-6.392-6.392-6.392-17.186,0-23.578c6.564-6.59,17.102-6.49,23.502-0.066 C224.127,45.001,223.762,55.546,217.459,61.809z" }), /* @__PURE__ */ React.createElement("circle", { style: {
  fill: "#43BFBF"
}, cx: 426.886, cy: 203.525, r: 16.697 }), /* @__PURE__ */ React.createElement("circle", { style: {
  fill: "#36D9D9"
}, cx: 308.816, cy: 85.455, r: 16.697 }), /* @__PURE__ */ React.createElement("circle", { style: {
  fill: "#43BFBF"
}, cx: 426.886, cy: 392.424, r: 16.697 }), /* @__PURE__ */ React.createElement("circle", { style: {
  fill: "#36D9D9"
}, cx: 119.918, cy: 85.455, r: 16.697 }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFC033"
}, d: "M443.585,52.059c-9.223,0-16.697-7.474-16.697-16.697V18.665c0-9.223,7.474-16.697,16.697-16.697 c9.223,0,16.697,7.474,16.697,16.697v16.697C460.282,44.585,452.809,52.059,443.585,52.059z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#F9A926"
}, d: "M443.585,135.544c-9.223,0-16.697-7.474-16.697-16.697V102.15c0-9.223,7.474-16.697,16.697-16.697 c9.223,0,16.697,7.474,16.697,16.697v16.697C460.282,128.07,452.809,135.544,443.585,135.544z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFC033"
}, d: "M410.191,85.453h-16.697c-9.223,0-16.697-7.474-16.697-16.697s7.474-16.697,16.697-16.697h16.697 c9.223,0,16.697,7.474,16.697,16.697S419.415,85.453,410.191,85.453z" }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#F9A926"
}, d: "M493.676,85.453h-16.697c-9.223,0-16.697-7.474-16.697-16.697s7.474-16.697,16.697-16.697h16.697 c9.218,0,16.697,7.474,16.697,16.697S502.894,85.453,493.676,85.453z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#F9A926"
}, d: "M497.027,259.583c-19.48-19.591-51.314-19.591-70.794,0c-19.48,19.48-19.48,51.315,0,70.795 c9.461,9.462,22.04,14.693,35.397,14.693s25.937-5.232,35.397-14.693C516.619,310.898,516.619,279.062,497.027,259.583z  M473.48,306.693c-6.752,6.763-17.152,6.545-23.632,0.076c-6.392-6.392-6.392-17.186,0-23.578c6.564-6.59,17.102-6.49,23.502-0.066 C480.12,289.871,479.812,300.401,473.48,306.693z" })), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFC033"
}, d: "M268.512,260.527c-4.272,0-8.544-1.631-11.805-4.892c-6.522-6.516-6.522-17.093,0-23.611 l104.232-104.232c6.522-6.522,17.089-6.522,23.611,0c6.522,6.516,6.522,17.093,0,23.611L280.318,255.634 C277.056,258.896,272.784,260.527,268.512,260.527z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#F9A926"
}, d: "M256.706,255.634c3.261,3.261,7.534,4.892,11.805,4.892c4.271,0,8.544-1.631,11.805-4.892 l104.232-104.232c6.522-6.516,6.522-17.093,0-23.611L256.706,255.634L256.706,255.634z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#F26D76"
}, d: "M129.919,426.074v47.141L22.557,511.017c-6.601,2.326-13.068,0.323-17.32-3.907 c-4.286-4.263-6.311-10.764-3.974-17.398L39.11,382.472h47.252l21.739,21.762L129.919,426.074z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#E65C64"
}, d: "M129.919,426.074v47.141L22.557,511.017c-6.601,2.326-13.068,0.323-17.32-3.907l102.865-102.876 L129.919,426.074z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#F26D76"
}, d: "M82.274,319.365l110.819,110.861h58.73l62.54-22.04c8.706-3.125,13.243-12.584,10.24-21.372 L125.465,187.786c-8.66-3.109-18.251,1.434-21.261,10.13l-21.93,62.132v59.318H82.274z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#E65C64"
}, d: "M193.093,430.226h58.73l62.54-22.04c8.706-3.125,13.243-12.584,10.24-21.372l-99.565-99.511 l-87.423,87.423L193.093,430.226z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFC033"
}, d: "M82.162,260.25L39.086,382.473l90.831,90.72l0.334-0.111l121.776-42.967L82.162,260.25z" }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F9A926"
}, points: "129.917,473.193 130.251,473.082 252.026,430.115 167.127,345.215 84.504,427.836 " }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#36D9D9"
}, d: "M332.436,432.736c-4.272,0-8.544-1.631-11.805-4.892L84.496,191.712 c-6.522-6.516-6.522-17.093,0-23.611c6.522-6.522,17.089-6.522,23.611,0l236.133,236.133c6.522,6.516,6.522,17.093,0,23.611 C340.979,431.105,336.708,432.736,332.436,432.736z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#43BFBF"
}, d: "M320.63,427.844c3.261,3.261,7.534,4.892,11.805,4.892s8.544-1.631,11.805-4.892 c6.522-6.516,6.522-17.093,0-23.611L226.174,286.168l-23.611,23.611L320.63,427.844z" }));
export default SvgConfetti;
