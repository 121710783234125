import { useEffect, useState } from 'react';

import {
  faArrowLeft,
  faArrowRight,
  faPenToSquare,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Download, SquareArrowOutUpRight } from 'lucide-react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { toast as sonnerToast } from 'sonner';
import { useDebouncedCallback } from 'use-debounce';

import UserList from './components/UserList';
import { handleDownloadClientMaterialsEml } from '../ClientMaterials/helpers';
import useCreateMatter from '@/api/mutations/useCreateMatter';
import useUpdateMatter from '@/api/mutations/useUpdateMatter';
import useGetMatter from '@/api/queries/useGetMatter';
import useOrgUsers from '@/api/queries/useGetOrgUsers';
import useGetFetchConfig from '@/api/useGetFetchConfig';
import Button from '@/components/atoms/Button';
import { Input } from '@/components/atoms/input';
import { Label } from '@/components/atoms/label';
import { Textarea } from '@/components/atoms/textarea';
import Modal from '@/components/molecules/Modals/CustomModal';
import { MatterCreationOnboardingModal } from '@/components/molecules/Modals/Onboarding/MatterCreationOnboardingModal';
import { useUserContext } from '@/Contexts/User';
import { OrgUserOption, Option } from '@/types';

const MatterCreator = ({ isEditMode = false }: { isEditMode?: boolean }) => {
  const { fetchConfigGET } = useGetFetchConfig();

  const navigate = useNavigate();
  const location = useLocation();
  const fromChecklistMatterCreation = location.state?.fromChecklistMatterCreation;

  const { matterId } = useParams<{ matterId: string }>();

  const [matterName, setMatterName] = useState<string>('');
  const [matterDescription, setMatterDescription] = useState<string>('');
  const [matterCode, setMatterCode] = useState<string>('');
  const [selectedUsers, setSelectedUsers] = useState<OrgUserOption[]>([]);
  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const [isAddUsersModalOpen, setIsAddUsersModalOpen] = useState<boolean>(false);
  const { user } = useUserContext();

  const onSuccess = () => {
    if (isEditMode) {
      toast.success('Matter updated successfully');
    } else {
      toast.success('Matter created successfully');
    }
    handleClickBack();
  };

  const { data: orgUsers } = useOrgUsers();

  const { mutateAsync: updateMatter, isLoading: isLoadingUpdate } = useUpdateMatter(matterId ?? '');
  const { mutate: createMatter, isLoading: isLoadingCreateMatter } = useCreateMatter(onSuccess);
  const { isLoadingMatter, responseMatterData, refetchMatterData } = useGetMatter(matterId ?? '');

  useEffect(() => {
    if (matterId) {
      refetchMatterData();
    }
  }, [matterId, refetchMatterData]);

  // Pre-fill form when data is fetched
  useEffect(() => {
    if (responseMatterData && isEditMode) {
      setMatterName(responseMatterData.name);
      setMatterDescription(responseMatterData.description);
      setMatterCode(responseMatterData.code);
      setSelectedUsers(
        responseMatterData.users.map((x) => {
          return {
            id: x.id,
            email: x.email,
          };
        }),
      );
    }
  }, [responseMatterData, isEditMode]);

  const handleSubmit = () => {
    // Check if no additional users have been added
    if (selectedUsers.length === 0) {
      setConfirmModalOpen(true);
      return;
    }

    // Proceed with creating the matter
    createMatter({
      name: matterName,
      description: matterDescription,
      code: matterCode,
      users: selectedUsers,
    });
  };

  const handleConfirmCreateMatter = () => {
    createMatter({
      name: matterName,
      description: matterDescription,
      code: matterCode,
      users: selectedUsers,
    });
    setConfirmModalOpen(false);
  };

  const handleClickBack = () => {
    navigate(`/app/chronos/matters?page=0`, { state: { fromChecklistMatterCreation } });
  };

  const handleRemoveUser = async (userIds: string[]) => {
    setSelectedUsers(selectedUsers.filter((user) => !userIds.includes(user.id)));
    if (isEditMode) {
      await debouncedMatterUpdate();
    }
  };

  const handleAddUser = async (userToAdd: Option) => {
    const newSelectedUsers = [
      ...selectedUsers,
      {
        id: userToAdd?.value ?? '',
        email: userToAdd?.label ?? '',
      },
    ];

    if (isEditMode) {
      await updateMatter({
        userIds: newSelectedUsers.map((u) => u.id),
      });
    }

    setSelectedUsers(newSelectedUsers);
    sonnerToast(`${userToAdd?.label} added`, {
      duration: 1000,
    });
  };

  const debouncedMatterUpdate = useDebouncedCallback(async () => {
    if (isEditMode) {
      await updateMatter({
        name: matterName,
        description: matterDescription,
        code: matterCode,
        userIds: selectedUsers.map((u) => u.id),
      });

      sonnerToast('Matter updated', {
        duration: 1000,
      });
    }
  }, 500);

  const canCreateMatter = matterName && selectedUsers;

  return (
    <div className="relative flex min-h-screen w-full flex-row gap-6 overflow-auto bg-gray-50">
      <div className="flex w-full flex-col items-start justify-start px-8 py-6">
        {!isLoadingMatter && (
          <>
            <div className="mb-4 flex w-full items-center justify-between">
              <div className="flex items-center">
                <Button
                  className="rounded-full px-2 py-1 hover:text-gray-600"
                  onClick={handleClickBack}
                  icon={<FontAwesomeIcon icon={faArrowLeft} className="h-4 w-4" />}
                  text=""
                  data-testid="matter-creator-back-button"
                />

                <h1 className="ml-2 text-xl font-bold text-gray-900">
                  {isEditMode ? 'Update Matter' : 'Create Matter'}
                </h1>
              </div>
            </div>

            <div className="flex h-full w-full flex-row gap-8">
              <div className="flex w-full flex-row gap-8">
                <div className="flex w-1/2 flex-col justify-between gap-2 rounded-xl border border-gray-200 bg-white p-6 shadow-sm">
                  <div>
                    <div className="mb-2 flex items-center justify-between border-b border-gray-100 pb-4">
                      <div className="flex items-center">
                        <FontAwesomeIcon icon={faPenToSquare} className="mr-3 h-4 w-4 text-gray-600" />
                        <h2 className="text-lg font-semibold text-gray-900">Details</h2>
                      </div>
                    </div>

                    <div className="flex flex-col gap-4">
                      <div>
                        <Label className="text-sm font-medium text-gray-700">Matter Name</Label>
                        <Input
                          className="rounded-lg border-gray-300"
                          value={matterName}
                          onChange={(e) => {
                            setMatterName(e.target.value);
                            debouncedMatterUpdate();
                          }}
                          placeholder="Enter a name for your matter"
                          data-testid="matter-creator-name-field"
                        />
                      </div>
                      <div>
                        <Label className="text-sm font-medium text-gray-700">Matter Description (Optional)</Label>
                        <Textarea
                          data-testid="matter-creator-description-field"
                          className="h-12 max-h-12 rounded-lg border-gray-300"
                          value={matterDescription}
                          onChange={(e) => {
                            setMatterDescription(e.target.value);
                            debouncedMatterUpdate();
                          }}
                          placeholder="Enter a description for your matter"
                        />
                      </div>

                      <div>
                        <Label className="text-sm font-medium text-gray-700">Matter Code (Optional)</Label>
                        <Input
                          data-testid="matter-creator-code-field"
                          className="rounded-lg border-gray-300"
                          value={matterCode}
                          onChange={(e) => {
                            setMatterCode(e.target.value);
                            debouncedMatterUpdate();
                          }}
                          placeholder="Enter a code for your matter"
                        />
                      </div>
                    </div>

                    <div className="mt-4 text-xs text-gray-600 lg:text-sm">
                      <ul className="list-disc space-y-1 pl-4">
                        <li>Matters are spaces used to group cases together.</li>
                        <li>Users with permission to view a matter can view all cases within that matter.</li>
                        <li>You can update these details at any time, including adding or removing team members.</li>
                      </ul>
                      <div className="mt-10 flex cursor-pointer items-center gap-2 text-blue-500 hover:text-blue-600 hover:underline">
                        <p onClick={() => handleDownloadClientMaterialsEml(user?.email ?? '', fetchConfigGET)}>
                          Download client email template.
                        </p>

                        <Download className="h-4 w-4" />
                      </div>
                      <div className="mt-2 flex cursor-pointer items-center gap-2 text-blue-500 hover:text-blue-600 hover:underline">
                        <NavLink to="/app/chronos/client-materials" target="_blank" rel="noopener noreferrer">
                          View client information pack.
                        </NavLink>

                        <SquareArrowOutUpRight className="h-4 w-4" />
                      </div>
                    </div>
                  </div>

                  {!isEditMode && (
                    <Button
                      type="primary"
                      rounded="md"
                      onClick={handleSubmit}
                      data-testid="matter-creator-button"
                      text={'Create Matter'}
                      loading={isLoadingUpdate || isLoadingMatter || isLoadingCreateMatter}
                      disabled={!canCreateMatter || isLoadingUpdate || isLoadingMatter || isLoadingCreateMatter}
                      icon={<FontAwesomeIcon className="ml-2" icon={faArrowRight} />}
                      iconRight
                    />
                  )}
                </div>

                <div className="w-1/2 rounded-xl border border-gray-200 bg-white p-6 shadow-sm">
                  <div className="mb-2 flex items-center justify-between border-b border-gray-100 pb-4">
                    <div className="flex items-center">
                      <FontAwesomeIcon icon={faUsers} className="mr-3 h-4 w-4 text-gray-600" />
                      <h2 className="text-lg font-semibold text-gray-900">
                        Users ({selectedUsers.length + (isEditMode ? 0 : 1)})
                      </h2>
                    </div>
                  </div>

                  <UserList
                    users={orgUsers ?? []}
                    selectedUsers={[
                      ...selectedUsers,
                      ...(!isEditMode ? [{ id: user?.id ?? '', email: user?.email ?? '' }] : []),
                    ]}
                    userId={user?.id ?? ''}
                    onRemove={handleRemoveUser}
                    onAdd={handleAddUser}
                    forceModalOpen={isAddUsersModalOpen}
                    setForceModalOpen={setIsAddUsersModalOpen}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {fromChecklistMatterCreation && <MatterCreationOnboardingModal />}

      <Modal
        isOpen={confirmModalOpen}
        handleClose={() => setConfirmModalOpen(false)}
        content={
          <div data-testid="matter-creator-confirm-modal" className="flex flex-col p-6">
            <div className="mb-6 border-b border-gray-200 pb-4">
              <div className="mb-2 flex items-center">
                <h2 className="text-lg font-medium text-gray-800">Do you want to add team members?</h2>
              </div>
              <p className="mt-2 text-sm text-gray-600">
                You haven't added any additional team members to this matter. Would you like to proceed with just
                yourself?
              </p>
            </div>
            <div className="flex items-center">
              <Button
                type="secondary"
                rounded="md"
                text="Add Team Members"
                onClick={() => {
                  setConfirmModalOpen(false);
                  setIsAddUsersModalOpen(true);
                }}
                icon={<FontAwesomeIcon icon={faUsers} className="ml-2" />}
                iconRight
              />
              <div className="flex-grow"></div>
              <Button
                testid="matter-creator-modal-confirm-button"
                type="primary"
                rounded="md"
                text="Create Matter"
                onClick={handleConfirmCreateMatter}
                icon={<FontAwesomeIcon className="ml-2" icon={faArrowRight} />}
                iconRight
              />
            </div>
          </div>
        }
      />
    </div>
  );
};

export default MatterCreator;
