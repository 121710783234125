import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { APIBaseChronos } from '@/api/hosts';
import { QueryEntity } from '@/api/types';
import useGetFetchConfig, { FetchMethod } from '@/api/useGetFetchConfig';

export const useDeleteChronology = ({ chronologyId, caseId }: { chronologyId: string; caseId: string }) => {
  const { getFetchConfig } = useGetFetchConfig();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      const fetchConfig = getFetchConfig({
        method: FetchMethod.DELETE,
      });
      const response = await fetch(`${APIBaseChronos}/api/case/${caseId}/chronology/${chronologyId}`, fetchConfig);
      if (response.status === 403) {
        toast.error('You are not authorised to delete this chronology');
        return;
      }
      if (!response.ok) {
        toast.error('Failed to delete chronology');
        return;
      }
      toast.success('Chronology deleted successfully');
    },
    onError: (error) => {
      console.error('Mutation Error: ', error);
      toast.error('Failed to delete chronology');
    },
    onSuccess: () => {
      queryClient.invalidateQueries([QueryEntity.Chronologies, caseId]);
    },
  });
};
