import { useQuery } from 'react-query';

import { APIBaseChronos } from '@/api/hosts'; // Ensure this path is correct
import useGetFetchConfig from '@/api/useGetFetchConfig';
import { Matter } from '@/types';

const useFetchOrgMatters = (orgIds: string[] = []) => {
  const { fetchConfigGET } = useGetFetchConfig();

  return useQuery<Matter[]>(
    'fetchOrgMatters',
    () => {
      const queryParams = new URLSearchParams({ orgIds: orgIds.join(',') }).toString();
      return fetch(`${APIBaseChronos}/api/org/matters?${queryParams}`, fetchConfigGET)
        .then((res) => res.json())
        .catch((err) => {
          console.error(err);
        });
    },
    {
      cacheTime: 0,
      enabled: true,
    },
  );
};

export default useFetchOrgMatters;
