import { useState } from 'react';

import { ArrowLeftRight, Link, UserPlus } from 'lucide-react';
import { toast } from 'sonner';

import AddUsers from './AddUsers';
import TransferCase from './TransferCase';
import { Button as NewButton } from '@/components/atoms/new-button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/molecules/DropdownMenu';
import Modal from '@/components/molecules/Modals/SettingsModal';

export enum ShareOptions {
  MoveToDifferentMatter = 'MoveToDifferentMatter',
  AddUsers = 'AddUsers',
}

interface ShareProps {
  matterId?: string;
  caseId?: string;
  allowedOptions: ShareOptions[];
}

const Share = ({ matterId, caseId, allowedOptions }: ShareProps) => {
  const [matterShareModalOpen, setMatterShareModalOpen] = useState(false);
  const [addUsersModalOpen, setAddUsersModalOpen] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(window.location.href);
    toast.success('Link to case copied', { duration: 1500, position: 'top-center' });
  };

  return (
    <>
      <div className="flex">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <NewButton size={'sm'} variant="brand" className="rounded-l-full rounded-r-none border font-semibold">
              <UserPlus size={20} strokeWidth={2.5} />
              Share
            </NewButton>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="mx-4 w-56 bg-white">
            <DropdownMenuLabel>Options</DropdownMenuLabel>
            <DropdownMenuSeparator className="bg-gray-200" />
            <DropdownMenuGroup>
              {allowedOptions.includes(ShareOptions.MoveToDifferentMatter) && (
                <DropdownMenuItem
                  onClick={() => setMatterShareModalOpen(true)}
                  className="rounded hover:cursor-pointer hover:bg-gray-100"
                >
                  <span>Move to a different matter</span>
                  <ArrowLeftRight size={16} />
                </DropdownMenuItem>
              )}

              {allowedOptions.includes(ShareOptions.AddUsers) && (
                <DropdownMenuItem
                  onClick={() => setAddUsersModalOpen(true)}
                  className="rounded hover:cursor-pointer hover:bg-gray-100"
                >
                  <span>Add users</span>
                </DropdownMenuItem>
              )}
            </DropdownMenuGroup>
          </DropdownMenuContent>
        </DropdownMenu>

        <NewButton
          size={'sm'}
          variant="brand"
          className="rounded-l-none rounded-r-full border border-l-0 font-semibold"
          onClick={copyToClipboard}
        >
          <Link size={20} strokeWidth={2.5} />
        </NewButton>
      </div>

      {caseId && (
        <Modal
          title={<div className="text-lg font-semibold">Transfer Case</div>}
          isOpen={matterShareModalOpen}
          handleClose={() => setMatterShareModalOpen(false)}
          size="small"
          content={<TransferCase caseId={caseId} setMatterShareModalOpen={setMatterShareModalOpen} />}
        />
      )}

      {matterId && (
        <Modal
          title={<div className="text-lg font-semibold">Add Users</div>}
          isOpen={addUsersModalOpen}
          handleClose={() => setAddUsersModalOpen(false)}
          size="small"
          content={<AddUsers matterId={matterId} />}
        />
      )}
    </>
  );
};

export default Share;
