import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import PageWrapper from '@/components/molecules/PageWrappers/PageWrapper';

const KimQA = () => {
  return (
    <PageWrapper className="bg-gray-50">
      <div className="flex w-full flex-col items-start justify-start px-4 md:px-6 lg:px-8">
        <div className="mb-8 flex w-full flex-col gap-6">
          <div className="w-full rounded-xl bg-white p-6 shadow-sm">
            <div className="flex flex-col">
              <h1 className="text-xl font-semibold text-gray-800">Kim Q&A</h1>
              <p className="text-sm text-gray-600">Learn how to use Kim effectively.</p>
            </div>
          </div>

          <div className="w-full rounded-xl bg-white p-6 shadow-sm">
            <div className="flex flex-col gap-6">
              {/* Question 1 */}
              <div className="rounded-xl border border-gray-200 bg-gray-50 p-6 shadow-sm">
                <h3 className="mb-4 text-lg font-semibold text-gray-800">What can Kim do?</h3>
                <p className="text-gray-700">
                  Kim can answer questions about facts extracted from a case, where each and every piece of information
                  is backed up with a source in the documents themselves. This means that users can, and should, verify
                  each answer with reference to the source provided. It will also showcase the full range of sources
                  used to provide each answer.
                </p>
                <p className="mt-2 text-sm text-gray-500">
                  Kim only looks at the facts and documents in your case, not the wider internet or other sources.
                </p>
              </div>

              {/* Question 2 */}
              <div className="rounded-xl border border-gray-200 bg-gray-50 p-6 shadow-sm">
                <h3 className="mb-4 text-lg font-semibold text-gray-800">
                  What do Kim's <i>confidence</i> levels mean?
                </h3>
                <p className="text-gray-700">
                  Wexler values transparency above all else. We have built a system which rates the confidence of
                  answers for completeness and accuracy.
                </p>

                <ul className="mt-4 space-y-2 text-sm text-gray-700">
                  <li className="flex items-center gap-2">
                    <FontAwesomeIcon icon={faCircle} className="text-green-500" />
                    <span>Green means Kim has high confidence in and has found sources to back up its answer.</span>
                  </li>
                  <li className="flex items-center gap-2">
                    <FontAwesomeIcon icon={faCircle} className="text-yellow-500" />
                    <span>
                      Amber means that Kim has found some sources to back up its answer, but it is not fully confident
                      in its accuracy, users should carefully check the source documents.
                    </span>
                  </li>
                  <li className="flex items-center gap-2">
                    <FontAwesomeIcon icon={faCircle} className="text-red-500" />
                    <span>
                      Red means that either there is no suitable source in the documents to answer the question or Kim
                      is unable to answer, but has found some potentially relevant sources, which it will display.
                    </span>
                  </li>
                </ul>
              </div>

              {/* Question 3 */}
              <div className="rounded-xl border border-gray-200 bg-gray-50 p-6 shadow-sm">
                <h3 className="mb-4 text-lg font-semibold text-gray-800">
                  What if the answer isn't quite what I am looking for?
                </h3>
                <p className="text-gray-700">
                  If your answer isn't quite what you are looking for, or you want to ask a slightly different question,
                  you will need to create a new thread to ask another question.
                </p>
                <p className="mt-2 text-sm text-gray-500">
                  We are currently building a more 'iterative' solution where you can discuss back and forth to get to
                  your final output.
                </p>
              </div>

              {/* Question 4 */}
              <div className="rounded-xl border border-gray-200 bg-gray-50 p-6 shadow-sm">
                <h3 className="mb-4 text-lg font-semibold text-gray-800">
                  What if the question I ask isn't answerable by the documents I am looking at?
                </h3>
                <p className="text-gray-700">
                  Kim will only look within the documents you provide it. If you ask it a question such as
                  <span className="italic">
                    "How does the case of Donoghue v Stevenson [1932] AC 562 apply to the issue of duty of care in the
                    documents?"
                  </span>
                  , Kim will not return an answer, since Wexler is deliberately not connected to a legal database, nor
                  does it search its training data, nor does it search the internet. This is by design, to limit the
                  chance of inaccuracies or erroneous or inexplicable information, Kim is built only to extract
                  information from the documents it is pointed at.
                </p>
              </div>

              {/* Question 5 */}
              <div className="rounded-xl border border-gray-200 bg-gray-50 p-6 shadow-sm">
                <h3 className="mb-4 text-lg font-semibold text-gray-800">
                  Will Kim answer any question from the documents?
                </h3>
                <p className="text-gray-700">
                  Kim's 'answer engine' is based on the facts that have been extracted from the documents. Therefore,
                  questions should be around factual information related to the dispute, rather than questions on points
                  of law.
                </p>

                <p className="mt-4 text-gray-700">
                  For example the below questions are example questions from the Enron case which would be good for Kim
                  to answer:
                </p>
                <ul className="mt-2 ml-5 list-disc text-sm text-gray-700">
                  <li>
                    What was the role of the Special Purpose Entities (SPEs) in Enron's financial statements, as
                    revealed in the documents?
                  </li>
                  <li>
                    How did Enron's use of mark-to-market accounting contribute to the company's reported financial
                    performance?
                  </li>
                  <li>What were the key findings of the Powers Report regarding Enron's accounting practices?</li>
                  <li>
                    How did Enron's corporate culture, as depicted in internal documents, contribute to its eventual
                    collapse?
                  </li>
                  <li>
                    What were the main allegations against Enron's executives as outlined in the internal communications
                    and memos?
                  </li>
                </ul>
              </div>

              {/* Question 6 */}
              <div className="rounded-xl border border-gray-200 bg-gray-50 p-6 shadow-sm">
                <h3 className="mb-4 text-lg font-semibold text-gray-800">Can Kim get it wrong?</h3>
                <p className="text-gray-700">
                  Kim has undergone extensive testing to increase the accuracy of the answers. However, as with all
                  generative AI systems, in complex scenarios it could return an inaccurate answer. Crucially, the human
                  should check the source fact and document in the citation to verify the answer's completeness or
                  accuracy. We have designed the tool so that answers and sources can be easily checked and verified.
                </p>
              </div>

              {/* Question 7 */}
              <div className="rounded-xl border border-gray-200 bg-gray-50 p-6 shadow-sm">
                <h3 className="mb-4 text-lg font-semibold text-gray-800">How will Kim develop?</h3>
                <p className="text-gray-700">
                  We are building more ways of manipulating the data for specific use cases within Kim. This includes:
                </p>
                <ul className="mt-2 ml-5 list-disc text-sm text-gray-700">
                  <li>
                    Building 'sub-chronologies' with different columns based on the data, so that you can make focused
                    chronologies and tables based on the facts.
                  </li>
                  <li>
                    Kim prioritizing 'verified' facts and chronology entries at the top of responses and search results.
                  </li>
                  <li>
                    Comparing the statements of case: Using Kim to create tables and summaries of agreed and disputed
                    facts, with their relevant dates and other information.
                  </li>
                  <li>
                    Witness statement analysis: Comparing witness testimony with facts from the pleadings or the wider
                    chronologies.
                  </li>
                </ul>
              </div>

              {/* Contact */}
              <div className="mt-4 rounded-lg border bg-gray-100 p-4">
                <p className="text-sm text-gray-700">
                  If you have any further questions or issues, please email{' '}
                  <a className="font-bold text-blue-500 hover:text-blue-600" href="mailto:support@wexler.ai">
                    support@wexler.ai
                  </a>{' '}
                  directly.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

export default KimQA;
