import { useMutation } from 'react-query';

import { APIBaseChronos } from '@/api/hosts';
import useGetFetchConfig, { FetchMethod } from '@/api/useGetFetchConfig';
import { KimMode, PlanStage } from '@/types';

interface ProcessPlanProps {
  threadId: string;
  caseId: string;
  question: string;
  mode: KimMode | null;
  plan: PlanStage[];
}

const useProcessPlan = () => {
  const { getFetchConfig } = useGetFetchConfig();

  const createMessage = async ({ threadId, question, caseId, mode, plan }: ProcessPlanProps) => {
    const fetchConfig = getFetchConfig({
      method: FetchMethod.POST,
      data: {
        question,
        mode,
        parameters: plan,
        threadId,
      },
    });

    const response = await fetch(`${APIBaseChronos}/api/case/${caseId}/thread/plan`, fetchConfig);
    if (!response.ok) {
      throw new Error('Error creating message');
    }
    return response.json();
  };

  const mutation = useMutation(async ({ threadId, question, caseId, mode, plan }: ProcessPlanProps) => {
    return createMessage({ threadId, question, caseId, mode, plan });
  });

  return mutation;
};

export default useProcessPlan;
