import { format } from 'date-fns';

const formatDate = (date: string | Date, shortMonth?: boolean) => {
  try {
    const dateString = typeof date === 'string' ? new Date(date) : date;
    const monthFormat = shortMonth ? 'MMM' : 'MMMM';
    return format(dateString, `dd ${monthFormat} yyyy`);
  } catch (error) {
    return '';
  }
};

export default formatDate;
