import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DateRangeIndicator = ({ startDate, endDate }: { startDate: Date | null; endDate: Date | null }) => {
  return (
    <div className="flex items-center rounded-md border border-gray-100 bg-gray-50 p-2 text-xs">
      <div className="mr-2 flex items-center text-gray-500">
        <FontAwesomeIcon icon={faCalendarAlt} className="mr-2" />
        <span className="font-medium">Between:</span>
      </div>
      <div className="flex items-center">
        <span className="font-medium text-gray-700">
          {startDate
            ? startDate.toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })
            : 'N/A'}
        </span>
        <span className="mx-2 text-gray-400">and</span>
        <span className="font-medium text-gray-700">
          {endDate ? endDate.toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }) : 'N/A'}
        </span>
      </div>
    </div>
  );
};

export default DateRangeIndicator;
