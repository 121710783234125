import React, { useCallback, useEffect, useRef, useState } from 'react';

import { faFile } from '@fortawesome/free-regular-svg-icons';
import { faArrowRight, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Mention, MentionsInput } from 'react-mentions';
import { useSearchParams } from 'react-router-dom';

import { trackCustomEvent } from '@/analytics/Mixpanel';
import Dropdown, { DropdownOption } from '@/components/molecules/Dropdown';
import { MentionsInputStyleKim } from '@/styles';
import { DocIds, DraftType, ExecuteKimSearch, KimMode } from '@/types';

interface MentionInterface {
  id: string;
  display: string;
  childIndex: number;
  index: number;
  plainTextIndex: number;
}

interface QuestionInputProps {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  onExecuteCall?: ExecuteKimSearch;
  docs: DocIds[];
  setTaggedDocuments: (v: { doc_id: string; file_name: string }[]) => void;
  mode: KimMode;
  isCreatingThread: boolean;
  draftType: DraftType | null;
  setDraftType: (draftType: DraftType | null) => void;
}

const draftTypeOptions: DropdownOption<DraftType>[] = [
  { label: 'Letter of claim outline', key: 'claim' },
  { label: 'Letter of rebuttal outline', key: 'rebuttal' },
];

const QuestionInput: React.FC<QuestionInputProps> = ({
  value,
  onChange,
  placeholder,
  onExecuteCall,
  docs,
  mode,
  setTaggedDocuments,
  isCreatingThread,
  draftType,
  setDraftType,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const mentionsInputRef = useRef<any>(null);

  // Check search params
  const [searchParams] = useSearchParams();
  const selectedDoc = searchParams.get('selectedDocId');
  const processedDocRef = useRef<string | null>(null);

  useEffect(() => {
    if (selectedDoc && selectedDoc !== processedDocRef.current) {
      const docName = docs.find((doc) => doc.id === selectedDoc)?.file.name || selectedDoc;
      setTaggedDocuments([{ doc_id: selectedDoc, file_name: docName }]);
      onChange(`@[${docName}](${selectedDoc}) `);

      if (mentionsInputRef.current) {
        const textarea = mentionsInputRef.current;
        textarea.setSelectionRange(textarea.value.length, textarea.value.length);
        textarea.focus();
      }

      processedDocRef.current = selectedDoc;
    }
  }, [selectedDoc, docs, setTaggedDocuments, onChange]);

  // Handlers
  const executeSearch = () => onExecuteCall && onExecuteCall({ question: value, draftType });
  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e.shiftKey && e.key === 'Enter') {
      executeSearch();
    }
  };

  const renderTruncatedMention = (id: string, display: string) => {
    return display.length > 20 ? `${display.substring(0, 20)}...` : display;
  };

  const handleChange = ({ newValue, mentions }: { newValue: string; mentions: MentionInterface[] }) => {
    const uniqueTaggedDocs = mentions.reduce(
      (acc, mention) => {
        if (!acc.some((doc) => doc.doc_id === mention.id)) {
          acc.push({ doc_id: mention.id, file_name: mention.display });
        }
        return acc;
      },
      [] as { doc_id: string; file_name: string }[],
    );

    setTaggedDocuments(uniqueTaggedDocs);
    onChange(newValue);
  };

  const triggerMentions = useCallback(() => {
    if (mentionsInputRef.current) {
      const textarea = mentionsInputRef.current;
      const currentValue = value;
      const cursorPosition = textarea.selectionStart;

      // Insert '@' at the cursor position
      const newValue = currentValue + '@';
      onChange(newValue);

      // Use requestAnimationFrame to ensure the DOM has updated
      requestAnimationFrame(() => {
        // Set cursor position after the inserted '@'
        textarea.setSelectionRange(cursorPosition + 1, cursorPosition + 1);
        textarea.focus();

        // Simulate typing '@' by dispatching events
        const inputEvent = new InputEvent('input', { bubbles: true });
        textarea.dispatchEvent(inputEvent);

        const keydownEvent = new KeyboardEvent('keydown', { key: '@', bubbles: true });
        textarea.dispatchEvent(keydownEvent);
      });
    }
  }, [onChange, value]);

  const sanitizeFileName = (fileName: string) => {
    return fileName.replace(/[[]()]/g, '');
  };

  return (
    <div className="relative flex w-full flex-col items-center gap-4">
      <div
        className={`z-10 flex w-full flex-col gap-1 rounded-lg border-2 px-2 pt-4 pb-2 transition-all duration-300 ${
          isFocused ? 'border-gray-300' : 'border-gray-200'
        }`}
      >
        <div className="relative">
          <MentionsInput
            value={value}
            onChange={(e, newValue, newPlainTextValue, mentions) => handleChange({ newValue, mentions })}
            placeholder={placeholder || 'Ask Kim..'}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            a11ySuggestionsListLabel={'Suggested mentions'}
            onKeyDown={onKeyDown}
            inputRef={mentionsInputRef}
            style={MentionsInputStyleKim}
            disabled={isCreatingThread}
          >
            <Mention
              trigger="@"
              data={docs.map((doc) => ({ id: doc.id, display: sanitizeFileName(doc.file.name) }))}
              appendSpaceOnAdd={true}
              displayTransform={renderTruncatedMention}
              style={{
                backgroundColor: '#EEEEEE',
                borderRadius: '2px',
              }}
              onAdd={() => trackCustomEvent('Document @ in Kim')}
            />
          </MentionsInput>
          {mode === 'draft_flow' && (
            <div className="w-fit">
              <Dropdown<DraftType>
                id="draftType"
                placeholder="Draft type (optional)"
                buttonIcon={faFile}
                options={draftTypeOptions}
                onOptionClick={setDraftType}
                onClearClick={() => setDraftType(null)}
                value={draftType}
                className="mt-2"
              />
            </div>
          )}
        </div>

        <div className="z-10 flex items-end justify-between gap-2 pr-1">
          <button
            className="flex cursor-pointer items-center text-xs text-gray-500 italic hover:text-gray-700"
            onClick={triggerMentions}
          >
            @ Documents
          </button>
          <button
            className="bg-brandSecondary hover:bg-brandSecondary-hover flex h-7 w-7 cursor-pointer items-center justify-center rounded-full px-[9px] py-2 text-sm font-semibold text-white disabled:bg-gray-500"
            onClick={executeSearch}
          >
            {isCreatingThread ? (
              <FontAwesomeIcon icon={faCircleNotch} className="fa-spin text-white" />
            ) : (
              <FontAwesomeIcon icon={faArrowRight} className="text-white" />
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default React.memo(QuestionInput);
