import React from 'react';

import { Transition } from '@headlessui/react';
import { useSortBy, useTable } from 'react-table';

import { Connection } from '..';
import { LoadingRow } from './LoadingTable';
import { formatBytes } from '../utils/bytesToSize';

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
interface IProps {
  data: any[];
  isLoading?: boolean;
  isLoadingMore?: boolean;
  handleSelect: any;
  searchMode: boolean;
  uploadingMode: boolean;
  selectedObject: Record<string, any>;
  onSelect: (file: any, selectedSource: string) => void;
  serviceId: string;
}

const FilesTable = ({
  data = [],
  isLoadingMore,
  handleSelect,
  searchMode,
  uploadingMode,
  selectedObject,
  onSelect,
  serviceId,
}: IProps) => {
  const columns: any[] = React.useMemo(() => {
    return [
      {
        Header: 'X',
        accessor: 'selected',
        width: 46,
        centerItems: true,
        sortable: false,
        Cell: ({ row }: any) => {
          return (
            <div className="flex items-center justify-center">
              <input
                type="checkbox"
                checked={!!selectedObject[row.original.id]}
                onChange={() => {
                  onSelect(row.original, serviceId);
                }}
              />
            </div>
          );
        },
      },
      {
        Header: 'Name',
        accessor: 'name',
        width: searchMode ? 400 - 137 : 400,
        centerItems: false,
        sortable: true,
        Cell: (props: any) => {
          const type = props.row.original.type;
          if (type === 'folder') {
            return (
              <div className="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="mr-1 inline-block h-5 w-5 text-yellow-300 flex-shrink-0"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z"
                  />
                </svg>
                <span className="truncate">{props.value}</span>
              </div>
            );
          }
          return (
            <div className="flex items-center truncate">
              <svg viewBox="0 0 24 24" className="mr-1 inline-block h-5 w-5 text-gray-500 flex-shrink-0">
                <path
                  d="M17 6v12c0 .52-.2 1-1 1H4c-.7 0-1-.33-1-1V2c0-.55.42-1 1-1h8l5 5zM14 8h-3.13c-.51 0-.87-.34-.87-.87V4"
                  stroke="currentColor"
                  fill="none"
                  fillRule="evenodd"
                  strokeLinejoin="round"
                />
              </svg>
              <span className="truncate">{props.value}</span>
            </div>
          );
        },
      },
      {
        Header: 'Size',
        accessor: 'size',
        width: 137,
        centerItems: true,
        sortable: true,
        Cell: ({ value }: { value: number }) => <div>{value ? formatBytes(value) : null}</div>,
      },
      {
        Header: 'Updated',
        accessor: 'updated_at',
        width: 137,
        centerItems: true,
        sortable: true,
        Cell: ({ value }: { value: string }) => {
          if (!value) return <span></span>;
          const date = new Date(value);
          return (
            <span>{`${months[date.getMonth()]} ${date.getDate()}, ${new Date(value).toLocaleTimeString([], {
              hour: '2-digit',
              minute: '2-digit',
            })}`}</span>
          );
        },
      },
      {
        Header: 'Service',
        accessor: 'connection',
        width: 137,
        centerItems: true,
        sortable: true,
        Cell: ({ value }: { value: Connection }) => {
          if (!value) return <span className="hidden text-gray-900">-</span>;
          return (
            <div className="flex justify-center pr-2">
              <img
                className="inline-block h-5 w-5 rounded-full text-right"
                src={value?.icon ? value.icon : '/img/logo.png'}
                alt={value.service_id}
              />
            </div>
          );
        },
      },
    ];

     
  }, [selectedObject, isLoadingMore, searchMode]);

  const { getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useSortBy,
  );

  return (
    <div className="overflow-auto">
      <table className="w-full divide-y divide-gray-200">
        <thead className="">
          {headerGroups.map((headerGroup: any, i: number) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={`headerGroup${i}`}>
              {headerGroup.headers.map((column: any, i: number) => {
                if (i === headerGroup.headers.length - 1 && !searchMode) {
                  return null;
                }
                return (
                  <th
                    className={`space-x-6 py-3 text-xs font-medium uppercase tracking-wide text-gray-500 !text-center`}
                    {...(column.sortable ? column.getHeaderProps(column.getSortByToggleProps()) : column.getHeaderProps())}
                    width={column.width}
                    key={`column-${i}`}
                  >
                    {column.render('Header')}
                    {!column.sortable ? ('') :
                    (<span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            className="ml-2 inline-block h-3 w-3 text-gray-500"
                          >
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            className="ml-2 inline-block h-3 w-3 text-gray-500"
                          >
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
                          </svg>
                        )
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          className="ml-2 inline-block h-3 w-3 text-gray-500"
                        >
                        </svg>
                      )}
                    </span>)
                  }
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()} className="divide-y divide-gray-200 bg-white">
          {rows.map((row: any, i: number) => {
            prepareRow(row);

            return (
              <Transition
                show={true}
                enter="transition ease-out duration-300"
                enterFrom="transform opacity-40"
                enterTo="transform opacity-100"
                leave="transition ease-in duration-300"
                leaveFrom="transform opacity-100"
                leaveTo="transform opacity-0"
                as="tr"
                {...row.getRowProps()}
                key={`row-${i}`}
                className={`${
                  uploadingMode && row.original.type === 'file'
                    ? 'text-gray-300'
                    : 'cursor-pointer text-gray-900 hover:bg-gray-50'
                }`}
                data-testid={`row-${i}`}
              >
                {row.cells.map((cell: any, i: number) => {
                  return (
                    <td
                      className={`max-w-2xs space-x-6 truncate whitespace-nowrap py-3 text-xs ${
                        cell.column.centerItems ? '!text-center' : '!text-left'
                      }`}
                      style={{ maxWidth: '16rem' }}
                      {...cell.getCellProps()}
                      key={`cell-${i}`}
                      onClick={() => {
                        if (i === 0) {
                          onSelect(row.original, serviceId);
                        } else if (!uploadingMode || row.original.type === 'folder') {
                          handleSelect(row.original);
                        }
                      }}
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </Transition>
            );
          })}
        </tbody>
      </table>
      {isLoadingMore ? (
        <table className="w-full divide-y divide-gray-200">
          <tbody className="divide-y divide-gray-200 bg-white">
            {Array.from(Array(12).keys()).map((key) => (
              <LoadingRow key={key} columns={columns} />
            ))}
          </tbody>
        </table>
      ) : (
        ''
      )}
    </div>
  );
};

export default FilesTable;
