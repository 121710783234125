import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { APIBaseChronos } from '@/api/hosts';
import useGetFetchConfig, { FetchMethod } from '@/api/useGetFetchConfig';

export const useUpdateInconsistencyReport = ({ reportId, caseId }: { reportId: string; caseId: string }) => {
  const { getFetchConfig } = useGetFetchConfig();

  return useMutation(
    async (title: string) => {
      const fetchConfig = getFetchConfig({
        method: FetchMethod.PATCH,
        data: { title },
      });

      const response = await fetch(
        `${APIBaseChronos}/api/case/${caseId}/inconsistency/report/${reportId}`,
        fetchConfig,
      );
      if (!response.ok) {
        toast.error('Failed to update report');
        return;
      }
    },
    {
      onError: () => {
        toast.error('Failed to update report');
      },
    },
  );
};
