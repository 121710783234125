import { useState } from 'react';

import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { ArrowUpDown, FileText } from 'lucide-react';

import { Input } from '@/components/atoms/input';
import { Button } from '@/components/atoms/new-button';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/molecules/table';
import { OrgUser, OrgUserWithMetadata } from '@/types';

interface UsersTableProps {
  users: OrgUserWithMetadata[] | OrgUser[];
  isLoading: boolean;
  onViewUserMatters: (user: OrgUserWithMetadata | OrgUser) => void;
}

const UsersTable = ({ users, isLoading, onViewUserMatters }: UsersTableProps) => {
  // Table states
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
  const [globalFilter, setGlobalFilter] = useState<string>('');

  // Define columns for the table
  const columns: ColumnDef<OrgUserWithMetadata | OrgUser>[] = [
    {
      accessorKey: 'email',
      header: ({ column }) => {
        return (
          <div className="text-left">
            <div
              className="flex items-center gap-1 hover:cursor-pointer"
              onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
            >
              Email
              <ArrowUpDown className="h-4 w-4 text-gray-700" />
            </div>
          </div>
        );
      },
      cell: ({ row }) => {
        const email = row.getValue('email') as string;
        const disabled = row.original.disabled;
        return (
          <div className="text-left">
            {email}
            {disabled ? ' (disabled)' : ''}
          </div>
        );
      },
    },
    {
      accessorKey: 'hasBeenActive',
      header: ({ column }) => {
        return (
          <div className="text-left">
            <div
              className="flex items-center gap-1 hover:cursor-pointer"
              onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
            >
              Active
              <ArrowUpDown className="h-4 w-4 text-gray-700" />
            </div>
          </div>
        );
      },
      cell: ({ row }) => {
        const hasBeenActive = row.getValue('hasBeenActive') as boolean;
        return (
          <span
            className={`inline-flex rounded-full px-2 py-1 text-xs font-medium ${
              hasBeenActive ? 'bg-blue-100 text-blue-800' : 'bg-gray-100 text-gray-800'
            }`}
          >
            {hasBeenActive ? 'Yes' : 'No'}
          </span>
        );
      },
    },
    {
      accessorKey: 'lastActiveAt',
      header: ({ column }) => {
        return (
          <div className="text-left">
            <div
              className="flex items-center gap-1 hover:cursor-pointer"
              onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
            >
              Last Active
              <ArrowUpDown className="h-4 w-4 text-gray-700" />
            </div>
          </div>
        );
      },
      cell: ({ row }) => {
        const user = row.original as OrgUserWithMetadata;
        const lastActiveAt = user.metadata?.lastActiveAt;

        return (
          <span className="inline-flex rounded-full px-2 py-1 text-xs font-medium">
            {lastActiveAt
              ? new Date(lastActiveAt * 1000).toLocaleString(undefined, {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                })
              : 'Never'}
          </span>
        );
      },
    },
    {
      accessorKey: 'matters',
      header: ({ column }) => {
        return (
          <div className="text-left">
            <div
              className="flex items-center gap-1 hover:cursor-pointer"
              onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
            >
              Matters
              <ArrowUpDown className="h-4 w-4 text-gray-700" />
            </div>
          </div>
        );
      },
      cell: ({ row }) => {
        const user = row.original as OrgUserWithMetadata;
        const matters = user.matters?.length ?? 0;

        return (
          <div className="flex items-center gap-2">
            {/* -1 to exclude personal Matters */}
            <span className="inline-flex rounded-full px-2 py-1 text-xs font-medium">{matters - 1}</span>

            {matters > 0 && (
              <Button
                onClick={() => onViewUserMatters(user)}
                className="flex h-auto items-center gap-1 rounded-md border border-gray-200 bg-white px-2 py-1 shadow-sm transition-all hover:border-blue-300 hover:bg-blue-50 hover:text-blue-600"
                variant="ghost"
                aria-label="View user matters"
              >
                <FileText className="h-3.5 w-3.5" />
                <span className="text-xs font-medium">View</span>
              </Button>
            )}
          </div>
        );
      },
    },
  ];

  // Create table instance
  const table = useReactTable({
    data: users || [],
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    initialState: {
      pagination: {
        pageSize: 10,
      },
    },
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      globalFilter,
    },
  });

  return (
    <div className="flex w-full flex-col justify-center gap-4 rounded-xl bg-white p-6 shadow-sm">
      <div className="flex w-full flex-row items-center justify-between gap-2">
        <div className="flex items-center gap-4">
          <h2 className="text-lg font-semibold text-gray-800">All Users</h2>
        </div>

        <Input
          placeholder="Search by email..."
          value={globalFilter}
          onChange={(e) => setGlobalFilter(e.target.value)}
          className="max-w-sm"
        />
      </div>

      <div className="w-full overflow-x-auto rounded-xl border border-gray-200 text-sm">
        {isLoading ? (
          <div className="flex h-60 items-center justify-center p-8">
            <FontAwesomeIcon icon={faCircleNotch} className="h-6 w-6 animate-spin" />
          </div>
        ) : (
          <div className="w-full overflow-x-auto">
            <Table>
              <TableHeader>
                {table.getHeaderGroups().map((headerGroup) => (
                  <TableRow key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <TableHead key={header.id}>
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                      </TableHead>
                    ))}
                  </TableRow>
                ))}
              </TableHeader>
              <TableBody>
                {table.getRowModel().rows.length > 0 ? (
                  table.getRowModel().rows.map((row, index) => {
                    const disabled = row.original.disabled;
                    return (
                      <TableRow
                        key={row.id}
                        className={`h-16 ${disabled ? 'bg-red-50 hover:bg-red-100' : index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}`}
                      >
                        {row.getVisibleCells().map((cell) => (
                          <TableCell key={cell.id}>
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </TableCell>
                        ))}
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={columns.length} className="h-24 text-center text-gray-500">
                      No users found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        )}
      </div>

      {/* Pagination Controls */}
      <div className="flex items-center justify-end space-x-2 py-4">
        <div className="flex-1 text-sm text-gray-500">Showing {table.getFilteredRowModel().rows.length} users</div>
        <div className="space-x-2">
          <Button
            variant="secondary"
            size="sm"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            Previous
          </Button>
          <Button variant="secondary" size="sm" onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UsersTable;
