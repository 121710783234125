import { useCallback, useEffect, useMemo } from 'react';

import { ACTIVE_HIGHLIGHT_COLOR, HIGHLIGHT_COLOR } from './constants';
import { BoundingBoxWithId } from '@/types';

interface HighlightCanvasProps {
  canvas: HTMLCanvasElement | null;
  highlightCanvas: HTMLCanvasElement | null;
  highlights: BoundingBoxWithId[];
  pageNumber: number;
  width: number;
  height: number;
  highlightedFactId?: string | null;
  onHighlightClick?: (id: string) => void;
}

export const HighlightCanvas = ({
  canvas,
  highlightCanvas,
  highlights,
  pageNumber,
  width,
  height,
  highlightedFactId,
  onHighlightClick,
}: HighlightCanvasProps) => {
  const handleCanvasClick = (event: React.MouseEvent<HTMLCanvasElement>, pageNumber: number) => {
    const canvas = event.currentTarget;
    const rect = canvas.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    const clickedHighlight = highlights.find(
      (h) =>
        h.pageNumber === pageNumber &&
        x >= h.left * width &&
        x <= (h.left + h.width) * width &&
        y >= h.top * height &&
        y <= (h.top + h.height) * height,
    );

    if (clickedHighlight && onHighlightClick) {
      onHighlightClick(clickedHighlight.id);
    }
  };

  const pageHighlights = useMemo(() => highlights.filter((h) => h.pageNumber === pageNumber), [highlights, pageNumber]);

  const updateCanvasCursor = useCallback(
    (event: React.MouseEvent<HTMLCanvasElement>) => {
      const rect = event.currentTarget.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;

      if (pageHighlights.length === 0) {
        event.currentTarget.style.cursor = 'default';
        return;
      }

      const isOverHighlight = pageHighlights.some(
        (h) =>
          x >= h.left * width &&
          x <= (h.left + h.width) * width &&
          y >= h.top * height &&
          y <= (h.top + h.height) * height,
      );

      event.currentTarget.style.cursor = isOverHighlight ? 'pointer' : 'default';
    },
    [pageHighlights, width, height],
  );

  useEffect(() => {
    if (!canvas || !highlightCanvas) return;

    // Match highlight canvas size to PDF canvas
    highlightCanvas.width = canvas.width;
    highlightCanvas.height = canvas.height;

    const context = highlightCanvas.getContext('2d');
    if (!context) return;
    context.clearRect(0, 0, highlightCanvas.width, highlightCanvas.height);

    // Draw highlights for this specific page
    const pageHighlights = highlights.filter((h) => h.pageNumber === pageNumber);
    pageHighlights.forEach((highlight) => {
      context.beginPath();
      context.rect(
        highlight.left * highlightCanvas!.width,
        highlight.top * highlightCanvas!.height,
        highlight.width * highlightCanvas!.width,
        highlight.height * highlightCanvas!.height,
      );
      context.fillStyle = highlight.id === highlightedFactId ? ACTIVE_HIGHLIGHT_COLOR : HIGHLIGHT_COLOR;
      context.fill();
    });
  }, [canvas, highlightCanvas, highlights, highlightedFactId, pageNumber]);

  return (
    <canvas
      ref={(ref) => (highlightCanvas = ref)}
      className="absolute inset-0 z-20 opacity-50"
      onMouseMove={updateCanvasCursor}
      onClick={(e) => handleCanvasClick(e, pageNumber)}
    />
  );
};
