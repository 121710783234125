import { useCallback, useEffect, useState } from 'react';

import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PaginationState } from '@tanstack/react-table';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { InconsistencyReportDownloadModal } from './InconsistencyReportDownloadModal';
import { InconsistencyReportToolbar } from './InconsistencyReportToolbar';
import { trackCustomEvent } from '../../../../../analytics/Mixpanel';
import { useDownloadInconsistencyReport } from '../../../../../api/mutations/useDownloadInconsistencyReport';
import { useGetInconsistencyReport } from '../../../../../api/queries/useGetInconsistencyReport';
import CustomModal from '../../../../../components/molecules/Modals/CustomModal';
import { DownloadCSVModal } from '../../../../../components/molecules/Modals/DownloadCSVModal';
import Modal from '../../../../../components/molecules/Modals/SettingsModal';
import { Inconsistency, InconsistencyFact } from '../../../../../types';
import DocViewer from '../../components/DocViewer/FactDocViewer';
import DocComparer from '../InconsistenciesEditor/components/DocComparer';
import InconsistencyRow from '../InconsistenciesEditor/components/InconsistencyRow';

export const InconsistencyReport = () => {
  const [searchParams] = useSearchParams();
  const caseId = searchParams.get('caseId') ?? '';
  const reportId = searchParams.get('reportId') ?? '';
  const pageNumber = searchParams.get('page') ?? '1';
  const reportTitle = searchParams.get('title') ?? '';

  const [pagination, setPagination] = useState<PaginationState>({ pageIndex: parseInt(pageNumber), pageSize: 50 });

  const [downloadModalIsOpen, setDownloadModalIsOpen] = useState(false);
  const [isDocViewerOpen, setIsDocViewerOpen] = useState(false);
  const [isDocComparerOpen, setIsDocComparerOpen] = useState(false);
  const [docGroups, setDocGroups] = useState<{ [key: string]: InconsistencyFact[] } | null>(null);

  const location = useLocation();
  const navigate = useNavigate();

  const { data: inconsistencies } = useGetInconsistencyReport({
    caseId,
    reportId,
    page: pagination.pageIndex,
    pageSize: pagination.pageSize,
  });

  const { fetchDownload, isLoadingDownload } = useDownloadInconsistencyReport({
    reportId,
    caseId,
  });

  // Pagination
  const goToPage = (val: number) => {
    const newPaginationState = { pageIndex: val, pageSize: pagination.pageSize };
    setPagination(newPaginationState);
  };
  const prevPage = () => goToPage(pagination.pageIndex - 1);
  const nextPage = () => goToPage(pagination.pageIndex + 1);

  const docId = searchParams.get('docId');
  const factId = searchParams.get('factId');

  const openDocComparer = (docGroups: { [key: string]: InconsistencyFact[] }) => {
    setDocGroups(docGroups);
    setIsDocComparerOpen(true);
  };

  const closeDocComparer = () => {
    setIsDocComparerOpen(false);
    setDocGroups(null);
  };

  useEffect(() => {
    if (docId) {
      setIsDocViewerOpen(true);
    } else {
      setIsDocViewerOpen(false);
    }
  }, [docId]);

  // Handlers
  const handleBack = useCallback(() => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('reportId');
    searchParams.delete('title');
    searchParams.delete('page');
    navigate('/app/chronos/case-editor/data/inconsistency-report?' + searchParams.toString(), { replace: true });
  }, [location.search, navigate]);

  const handleDownload = useCallback(
    ({ title, columns, format }: { title: string; columns: string[]; format: string }) => {
      trackCustomEvent(`Download Report ${format}`, { reportId, caseId });
      fetchDownload(
        { title, columns, format },
        {
          onSuccess: async () => {
            await new Promise((resolve) => setTimeout(resolve, 200));
            setDownloadModalIsOpen(false);
          },
        },
      );
    },
    [caseId],
  );

  return (
    <>
      <div className="flex h-full w-full">
        <div className="flex h-full w-full flex-col transition-all duration-300 ease-in-out">
          {inconsistencies && caseId && (
            <InconsistencyReportToolbar
              reportTitle={reportTitle}
              handleBack={handleBack}
              openDownloadModal={() => setDownloadModalIsOpen(true)}
              canGetPrevPage={pagination.pageIndex - 1 > 0}
              canGetNextPage={pagination.pageIndex < inconsistencies.pageCount - 1}
              prevPage={prevPage}
              nextPage={nextPage}
              currentPage={pagination.pageIndex - 1}
              noOfPages={inconsistencies.pageCount}
              goToPage={goToPage}
            />
          )}

          <div className="flex h-full overflow-y-scroll">
            {caseId && inconsistencies && inconsistencies.data && (
              <>
                <div className={`h-full transition-all duration-300 ${false ? 'w-7/12' : 'w-full'}`}>
                  <div className="flex w-full flex-col gap-3 py-1">
                    {inconsistencies.data.map((inconsistency: Inconsistency) => {
                      return (
                        <div key={inconsistency.id} className="w-full rounded-lg border">
                          <InconsistencyRow
                            inconsistency={inconsistency}
                            caseId={caseId}
                            openDocComparer={openDocComparer}
                            isReportInconsistency
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>

                <div
                  className={`transition-all duration-300 ease-in-out ${
                    isDocViewerOpen ? 'ml-2 w-5/12 opacity-100' : 'w-0 opacity-0'
                  } overflow-hidden rounded border-2 bg-white`}
                >
                  <div
                    className={`h-full w-full transition-transform duration-300 ease-in-out ${
                      isDocViewerOpen ? 'translate-x-0' : 'translate-x-full'
                    }`}
                  >
                    {docId && factId && caseId && <DocViewer docId={docId} caseId={caseId} factId={factId} />}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      {caseId && docGroups && (
        <CustomModal
          isOpen={isDocComparerOpen}
          handleClose={closeDocComparer}
          content={<DocComparer caseId={caseId} docGroups={docGroups} />}
          maxWidth="84rem"
        />
      )}

      <Modal
        title={
          <div className="flex items-center gap-2 font-semibold text-blue-500">
            <FontAwesomeIcon icon={faDownload} className="" />
            Download Report
          </div>
        }
        isOpen={downloadModalIsOpen}
        handleClose={() => setDownloadModalIsOpen(false)}
        content={
          <DownloadCSVModal
            columnOptions={inconsistencyReportDownloadColumnOptions}
            downloadTitle={reportTitle}
            loadingDownload={isLoadingDownload}
            handleDownload={handleDownload}
          />
        }
        size="small"
      />
    </>
  );
};

const inconsistencyReportDownloadColumnOptions = [
  { label: 'ID', value: 'ID' },
  { label: 'Inconsistency', value: 'Inconsistency' },
  { label: 'Inconsistency Type', value: 'Inconsistency Type' },
  { label: 'Source', value: 'Source' },
  { label: 'Severity', value: 'Severity' },
  { label: 'Fact Document Name', value: 'Fact Document Name' },
  { label: 'Fact Source Text', value: 'Fact Source Text' },
];
