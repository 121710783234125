import { useCallback, useEffect, useMemo, useState } from 'react';

import { faCircleNotch, faFileAlt, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuthInfo } from '@propelauth/react';
import AwsS3 from '@uppy/aws-s3';
import Uppy, { Meta, UppyFile } from '@uppy/core';
import useUppyState from '@uppy/react/lib/useUppyState';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { StageSpinner } from 'react-spinners-kit';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

import { MB_150_IN_BYTES } from './constants';
import { FileChip } from './FileChip';
import { OrgPermission } from '../../../../../backend/src/organisation/organisation-permission';
import { useGetCaseIsPendingUpload } from '../../../api/queries/useGetCaseIsPendingUpload';
import { useGetUploadPagesEstimate } from '../../../api/queries/useGetUploadPagesEstimate';
import useRemoveFile from '@/api/mutations/useRemoveFile';
import useGetFiles from '@/api/queries/useGetFiles';
import { usePresignedPost } from '@/api/queries/usePresignedPost';
import FileUploadInput from '@/components/organisms/FileUploadInput';
import { allowedFileTypes } from '@/constants/allowedFileTypes';
import { useUserContext } from '@/Contexts/User';
import { File as FileEntity } from '@/types';

const FileUploaderComponent = ({
  setUploadDisabled,
  setTotalUnprocessedDocs,
  caseId,
  isCaseCreator,
}: {
  setUploadDisabled: (disabled: boolean) => void;
  setTotalUnprocessedDocs: (n: number) => void;
  caseId: string;
  isCaseCreator?: boolean;
}) => {
  const authInfo = useAuthInfo();
  const { user } = useUserContext();
  const { data: presignedPostData } = usePresignedPost(caseId);

  const {
    data: filesResponse,
    refetch: refetchFiles,
    isLoading: isLoadingFiles,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useGetFiles(caseId);

  const { data: estimatedPages, refetch: refetchEstimate } = useGetUploadPagesEstimate(caseId);

  const { refetch: caseIsPendingUploadRefetch } = useGetCaseIsPendingUpload(caseId);
  const onApideckSuccess = useCallback(() => {
    caseIsPendingUploadRefetch();
    refetchFiles();
    refetchEstimate();
  }, [caseIsPendingUploadRefetch, refetchFiles, refetchEstimate]);

  const [uppy] = useState(() =>
    new Uppy({
      restrictions: {
        maxFileSize: MB_150_IN_BYTES,
        maxNumberOfFiles: 10000,
        allowedFileTypes,
      },

      allowMultipleUploadBatches: true,

      onBeforeFileAdded: (file) => {
        if (file.name === undefined) {
          return;
        }

        const id = uuidv4();
        const extension = file.name.split('.').length > 1 ? file.name.split('.').pop() : undefined;
        const name = `${id}${extension ? `.${extension}` : ''}`;

        fileIdNameMap.set(id, file.name);

        // Make a new File with the correct name as S3 only cares about the File contents' name
        const newFile = new File([file.data], name, { type: file.data.type });

        return { ...file, data: newFile, name, meta: { ...file.meta, name } };
      },
    }).use(AwsS3),
  );
  const [isUploading, setIsUploading] = useState(false);
  const [allFiles, setAllFiles] = useState<FileEntity[]>();

  const fileIdNameMap = useMemo(() => new Map<string, string>(), []);
  const completedUploads = useMemo(() => new Set<string>(), []);
  const [completedUploadsCount, setCompletedUploadsCount] = useState(0);

  const completedUploadsInBatch: UppyFile<{ id?: string }, {}>[] = useMemo(() => [], []);
  const recordUploadedFileToDB = useMemo(
    () => new Worker(new URL('../../../workers/record-doc-upload.ts', import.meta.url), { type: 'module' }),
    [],
  );

  async function getAccessToken() {
    const accessToken = await authInfo.tokens.getAccessToken();

    return accessToken;
  }

  const requestWorkerUpdateFileDatabase = useCallback(
    async (isFinal = false) => {
      if (window.Worker) {
        const token = await getAccessToken();

        recordUploadedFileToDB.postMessage({
          accessToken: token,
          caseId,
          files: completedUploadsInBatch.map((file) => {
            const id = file.name?.split('.')[0] ?? '';

            return {
              id,
              name: fileIdNameMap.get(id),
              size: file.size,
              extension: file.extension,
            };
          }),
          isFinal,
        });

        completedUploadsInBatch.splice(0, completedUploadsInBatch.length);
      }
    },
    [caseId, fileIdNameMap, completedUploadsInBatch, recordUploadedFileToDB],
  );

  const handleUppyUploadSuccess = useCallback(
    (file: UppyFile<{ id?: string }, {}> | undefined) => {
      if (file && !completedUploads.has(file.id)) {
        completedUploads.add(file.id);
        completedUploadsInBatch.push(file);

        setCompletedUploadsCount(completedUploads.size);

        // Send every 10 completed docs to the file creation endpoint on the backend
        // which uses a web worker to keep the main thread focused on uploading
        if (completedUploadsInBatch.length > 10) {
          requestWorkerUpdateFileDatabase();
        }
      }
    },
    [completedUploads, completedUploadsInBatch, requestWorkerUpdateFileDatabase],
  );

  const handleUppyUploadComplete = useCallback(async () => {
    requestWorkerUpdateFileDatabase(true);

    // Wait for worker to complete
    await new Promise((resolve) => (recordUploadedFileToDB.onmessage = resolve));

    uppy.resetProgress();
    uppy.removeFiles([...completedUploads].map((id) => id));

    setCompletedUploadsCount(0);
    completedUploads.clear();
    fileIdNameMap.clear();

    setCompletedUploadsCount(0);
    setIsUploading(false);

    refetchFiles();
    refetchEstimate();
  }, [uppy, fileIdNameMap, completedUploads, refetchFiles, requestWorkerUpdateFileDatabase, recordUploadedFileToDB]);

  const handleUppyRestrictionFailed = useCallback(
    (file: UppyFile<Meta, Record<string, never>> | undefined, error: Error) => {
      toast.error(error.message);
    },
    [],
  );

  useEffect(() => {
    if (presignedPostData) {
      uppy.getPlugin('AwsS3Multipart')?.setOptions({
        shouldUseMultipart: false,
        getUploadParameters: () => {
          return {
            method: 'POST',
            url: presignedPostData.url,
            fields: presignedPostData.fields,
          };
        },
      });

      uppy.off('upload-success', handleUppyUploadSuccess);
      uppy.off('complete', handleUppyUploadComplete);
      uppy.off('restriction-failed', handleUppyRestrictionFailed);

      uppy.on('upload-success', handleUppyUploadSuccess);
      uppy.on('complete', handleUppyUploadComplete);
      uppy.on('restriction-failed', handleUppyRestrictionFailed);
    }
  }, [
    presignedPostData,
    handleUppyUploadComplete,
    handleUppyUploadSuccess,
    requestWorkerUpdateFileDatabase,
    handleUppyRestrictionFailed,
    uppy,
  ]);

  const { handleRemoveFile, fileRemoving } = useRemoveFile({
    caseId,
    refetchFiles,
  });

  const onUploadUppy = useCallback(
    (files: File[]) => {
      uppy.addFiles(files.map((file) => ({ source: 'local', data: file, name: file.name })));
      uppy.upload();

      setIsUploading(true);
    },
    [uppy],
  );

  const totalProgress = useUppyState(uppy, (state) => state.totalProgress);

  const [sentryRef] = useInfiniteScroll({
    loading: false,
    hasNextPage: Boolean(hasNextPage),
    onLoadMore: fetchNextPage,
  });

  useEffect(() => {
    if (filesResponse) {
      setAllFiles(filesResponse.pages.flatMap((page) => page.files));
      setTotalUnprocessedDocs(filesResponse.pages.flat()[0].totalCount);
    }
  }, [filesResponse, setTotalUnprocessedDocs]);

  // Todo this won't even work if we use pagination
  const largeFiles = allFiles?.filter((file) => {
    return (file?.size ?? 0) > MB_150_IN_BYTES;
  });

  const getMessage = () => {
    if (largeFiles?.length && largeFiles?.length > 0) {
      return `${largeFiles?.length} files exceed 150MB file size limit`;
    }
    return '';
  };
  const message = getMessage();

  useEffect(() => {
    setUploadDisabled(isUploading || (allFiles?.length || 0) > 10000 || (allFiles?.length || 0) === 0);
  }, [allFiles, isUploading, setUploadDisabled]);

  // TODO: Remove this when complete.
  const superUserSet = new Set([
    '669c1da0-cc1d-42b3-98aa-92ae934d94c8',
    '8c712ce8-7be3-49e0-bd15-905d16efa220',
    '6baea74b-fd7a-418a-8f29-5e0c2f172c73',
    'f55c6964-8ae5-433a-a28d-06f49136a5d4',
    '9f5d2132-7e60-4497-bf66-0c6b6eba4531',
    '94d980eb-9637-4527-bf24-2f3ea3e9006b',
    '39ca814f-7202-4897-9c11-d4c64f9def5b',
  ]);

  return (
    <div>
      {message !== '' && <div className="font-semibold text-red-700 italic">{message}</div>}
      <FileUploadInput
        isUploading={isUploading}
        onDrop={onUploadUppy}
        totalProgress={totalProgress}
        successfulUploads={completedUploadsCount}
        caseId={caseId}
        onApideckSuccess={onApideckSuccess}
        isApideckEnabled={
          user?.organisation?.permissions.includes(OrgPermission.Apideck) || superUserSet.has(user?.id || '')
        }
      />
      <div className="flex-column flex w-full">
        {isLoadingFiles ? (
          <div className={`flex h-32 w-full items-center justify-center ${isCaseCreator ? 'h-96' : 'h-32'}`}>
            <StageSpinner className="m-auto" size={25} color={'#4161FF'} />
          </div>
        ) : (
          <>
            {allFiles?.length && allFiles?.length > 0 ? (
              <div className="mt-2 flex max-h-60 w-full flex-col justify-between overflow-y-scroll rounded border bg-gray-50 p-2">
                <div className="flex flex-wrap justify-between gap-2">
                  {allFiles?.map((file, idx) => {
                    const fileSizeExceeded = (file.size ?? 0) > MB_150_IN_BYTES;
                    return (
                      <FileChip
                        key={file.id}
                        file={file}
                        fileSizeExceeded={fileSizeExceeded}
                        removing={fileRemoving === file.id}
                        index={idx}
                        handleRemoveFile={handleRemoveFile}
                      />
                    );
                  })}
                </div>

                {hasNextPage && !isFetchingNextPage && (
                  <div ref={sentryRef} className="my-2 flex items-center justify-center">
                    <FontAwesomeIcon icon={faCircleNotch} className="fa-spin text-brandSecondary" />
                  </div>
                )}
              </div>
            ) : null}
          </>
        )}
      </div>

      {estimatedPages?.count !== undefined && estimatedPages.count > 0 && (
        <div className="flex w-full justify-end">
          <div className="mt-3 ml-auto inline-flex items-center justify-end rounded-md border border-gray-200 bg-gray-50 px-2 py-1 text-base">
            <FontAwesomeIcon icon={faFileAlt} className="mr-1.5 h-2.5 w-2.5 text-gray-500" />
            <span className="font-medium text-gray-600">Estimated Pages/Tokens:</span>
            <span className="text-brandSecondary ml-1.5 font-semibold">{estimatedPages?.count.toLocaleString()}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default FileUploaderComponent;
