import { useMutation, useQueryClient } from 'react-query';

import { APIBaseChronos } from '@/api/hosts';
import { QueryEntity } from '@/api/types';
import useGetFetchConfig, { FetchMethod } from '@/api/useGetFetchConfig';

const useDeleteThread = (caseId: string) => {
  const { getFetchConfig } = useGetFetchConfig();
  const queryClient = useQueryClient();

  const deleteThread = async (threadId: string) => {
    const fetchConfig = getFetchConfig({
      method: FetchMethod.DELETE,
    });

    const response = await fetch(`${APIBaseChronos}/api/case/${caseId}/thread/${threadId}`, fetchConfig);
    if (!response.ok) {
      throw new Error('Creating thread failed');
    }
  };

  return useMutation({
    mutationFn: (threadId: string) => deleteThread(threadId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryEntity.Threads, caseId] });
    },
  });
};

export default useDeleteThread;
