import { useMutation } from 'react-query';

import { APIBaseChronos } from '@/api/hosts';
import useGetFetchConfig, { FetchMethod } from '@/api/useGetFetchConfig';

export const useCreateInconsistencies = (caseId: string) => {
  const { getFetchConfig } = useGetFetchConfig();

  const createInconsistencies = async () => {
    const fetchConfig = getFetchConfig({
      method: FetchMethod.POST,
    });
    await fetch(`${APIBaseChronos}/api/case/${caseId}/inconsistency`, fetchConfig);
  };

  return useMutation({
    mutationFn: createInconsistencies,
  });
};
