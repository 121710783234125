import { faChartColumn, faShield } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ActivityPanel from './components/ActivityPanel';
import MattersPanel from './components/MattersPanel';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/atoms/Tooltip';
import { useUserContext } from '@/Contexts/User';

const MattersExplorer = () => {
  const { user } = useUserContext();
  const org = user?.organisation?.name;
  const isAnalyticsAdmin = user?.metadata?.metadata?.analyticsAdmin === 'true';
  const isSuperAdmin = user?.metadata?.metadata?.orgSuperAdmin === 'true';

  return (
    <div className="flex h-screen w-full flex-col overflow-y-scroll">
      <div className="min-h-0 w-full px-6 py-4">
        <div className="flex w-full flex-col gap-4 text-gray-800">
          <div className="mt-1 flex w-full items-center justify-between">
            <h1 className="text-2xl font-semibold text-gray-900 not-italic">Welcome</h1>
            <div className="flex items-center gap-2 rounded-full border border-gray-200 bg-white px-3 py-1.5 shadow-sm">
              <span className="text-sm font-semibold text-gray-800">{user?.email}</span>
              <span className="text-sm text-gray-400">|</span>
              <span className="text-sm text-gray-500">{org}</span>
              {isSuperAdmin && (
                <TooltipProvider>
                  <Tooltip delayDuration={0}>
                    <TooltipTrigger asChild>
                      <div className="flex cursor-pointer items-center gap-1 rounded-full bg-purple-100 px-2 py-0.5 text-purple-700 hover:bg-purple-200">
                        <FontAwesomeIcon icon={faShield} className="h-4 w-4" />
                        <span className="text-xs font-medium whitespace-nowrap">Admin</span>
                      </div>
                    </TooltipTrigger>
                    <TooltipContent className="max-w-56 bg-gray-50">
                      <p className="text-xs text-gray-800">Admins have full access to all matters.</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              )}
              {isAnalyticsAdmin && (
                <TooltipProvider>
                  <Tooltip delayDuration={0}>
                    <TooltipTrigger asChild>
                      <div className="flex cursor-pointer items-center gap-1 rounded-full bg-blue-100 px-2 py-0.5 text-blue-700 hover:bg-blue-200">
                        <FontAwesomeIcon icon={faChartColumn} className="h-3 w-3" />
                        <span className="text-xs font-medium whitespace-nowrap">Analytics</span>
                      </div>
                    </TooltipTrigger>
                    <TooltipContent className="max-w-56 bg-gray-50">
                      <p className="text-xs text-gray-800">
                        Analytics Admins can view your organisation's analytics dashboard (found on the sidebar).
                      </p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              )}
            </div>
          </div>

          <div className="relative mb-2 flex h-full min-h-[calc(100vh-6rem)] w-full rounded-lg border-2 border-gray-200/40 bg-white">
            <ActivityPanel />
            <MattersPanel />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MattersExplorer;
