import { getInconsistencySourceDisplayName } from '../../../backend/src/case/inconsistency/inconsistency-display';
import { InconsistencySource } from '../types';

const relevanceMapping = {
  relevant: 'Relevant',
  notRelevant: 'Not Relevant',
  maybeRelevant: 'Maybe Relevant',
};

export const capitalizeFirstLetter = (filter: string) => {
  return filter.charAt(0).toUpperCase() + filter.slice(1).toLowerCase();
};

export const transformFilterName = (filter: string, filterType?: string) => {
  if (filterType === 'inconsistencySource') {
    return getInconsistencySourceDisplayName(filter as InconsistencySource);
  }
  if (filterType === 'factRelevance') {
    return relevanceMapping[filter as keyof typeof relevanceMapping];
  }
  return capitalizeFirstLetter(filter);
};
