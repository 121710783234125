import { Download, Copy } from 'lucide-react';
import { toast } from 'sonner';

import { emailClipboardContent, videos } from './constants';
import { handleDownloadClientMaterialsEml } from './helpers';
import { trackCustomEvent } from '@/analytics/Mixpanel';
import useGetFetchConfig from '@/api/useGetFetchConfig';
import { Button } from '@/components/atoms/new-button';
import { useUserContext } from '@/Contexts/User';

const EmailContent = () => {
  const { user } = useUserContext();
  const { fetchConfigGET } = useGetFetchConfig();

  return (
    <div className="p-6">
      <h2 className="mb-4 text-xl font-semibold text-gray-800">Email Template</h2>
      <div className="rounded-lg border border-gray-200 bg-gray-50 p-6 shadow-sm">
        <div className="space-y-4 text-gray-700">
          <p>Dear [Client],</p>
          <p>
            We have onboarded a platform called{' '}
            <a href="https://wexler.ai" target="_blank" rel="noopener noreferrer" className="text-blue-500">
              wexler.ai
            </a>{' '}
            to assist with our litigation workflows. It reads through documents and extracts the key facts, creates
            chronologies and allows you to ask and answer questions. The security details are found at{' '}
            <a href="https://trust.wexler.ai" target="_blank" rel="noopener noreferrer" className="text-blue-500">
              trust.wexler.ai
            </a>
            , and I have attached two documents explaining the product in more detail.
          </p>
          <p>Videos of the platform:</p>
          <ul>
            {videos.map((video) => (
              <li key={video.title}>
                <a href={video.url} target="_blank" rel="noopener noreferrer" className="text-blue-500">
                  {video.title}
                </a>
              </li>
            ))}
          </ul>

          <p>Let me know if you have any further questions.</p>
          <p>Kind regards,</p>
        </div>
      </div>
      <div className="mt-6 flex justify-end gap-6">
        <Button
          onClick={() => handleDownloadClientMaterialsEml(user?.email ?? '', fetchConfigGET)}
          className="bg-button-primary hover:bg-button-primary-hover flex items-center gap-2 rounded-md px-4 py-2 text-white"
        >
          <Download className="h-4 w-4" />
          Download with Attachments
        </Button>
        <Button
          onClick={() => {
            navigator.clipboard.writeText(emailClipboardContent);
            toast.success('Email template copied to clipboard');
            trackCustomEvent('Copied Email Template To Clipboard');
          }}
          className="bg-button-primary hover:bg-button-primary-hover flex items-center gap-2 rounded-md px-4 py-2 text-white"
        >
          <Copy className="h-4 w-4" />
          Copy to Clipboard
        </Button>
      </div>
    </div>
  );
};

export default EmailContent;
