import OrganisationsTable from './OrganisationsTable';
import { useGetAllOrganisations } from '@/api/queries/useGetOrganisations';
import PageWrapper from '@/components/molecules/PageWrappers/PageWrapper';
import { useGlobalAdminCheck } from '@/hooks/useGlobalAdminCheck';

export const OrganisationList = () => {
  const { data: organisationsData, isLoading: isLoadingOrganisations } = useGetAllOrganisations();

  useGlobalAdminCheck();

  return (
    <PageWrapper className="bg-gray-50">
      <div className="flex w-full flex-col items-start justify-start px-4 md:px-6 lg:px-8">
        <div className="mb-8 flex w-full flex-col gap-6">
          <div className="w-full rounded-xl bg-white p-6 shadow-sm">
            <div className="flex flex-col gap-6">
              <div className="flex flex-col">
                <h1 className="text-xl font-semibold text-gray-800">Organisations</h1>
                <p className="text-sm text-gray-600">
                  Control organisation settings and permissions. For global admins only.
                </p>
              </div>
            </div>
          </div>

          <OrganisationsTable organisations={organisationsData || []} isLoading={isLoadingOrganisations} />
        </div>
      </div>
    </PageWrapper>
  );
};
