import React from 'react';

interface ChartCardProps {
  title: string;
  children: React.ReactNode;
  className?: string;
}

const ChartCard = ({ title, children, className = '' }: ChartCardProps) => {
  return (
    <div className={`rounded-xl bg-white p-8 ${className}`}>
      <div className="flex items-baseline justify-between">
        <div className="flex flex-row items-center gap-1 font-semibold">{title}</div>
      </div>
      {children}
    </div>
  );
};

export default ChartCard;
