import { useState } from 'react';

import { faCog, faList, faStar, faTimeline } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation, useNavigate } from 'react-router-dom';
import { StageSpinner } from 'react-spinners-kit';

import ActionButton from '../ActionButton';
import { trackCustomEvent } from '@/analytics/Mixpanel';
import {
  ApiError,
  CreateChronologyResponse,
  useCreateChronology,
} from '@/screens/Chronos/CaseEditor/hooks/useCreateChronology';

interface ChronologyModalProps {
  caseId: string | null;
  totalKeyFacts: number;
  totalRelevantFacts: number;
}

const ChronologyModal = ({ caseId, totalKeyFacts, totalRelevantFacts }: ChronologyModalProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const onSuccess = (data: CreateChronologyResponse) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('chronologyId', data.chronologyId);
    searchParams.set('title', data.chronologyTitle);
    searchParams.set('page', '1');
    navigate('/app/chronos/case-editor/data/chronology/view?' + searchParams.toString());
  };

  const onError = (error: ApiError) => {
    console.error('Mutation failed:', error);
    // Handle error scenario here
    setIsLoading(false);
  };

  const { createChronology } = useCreateChronology({ onSuccess, onError });

  const handleChronologyCreation = async (type: string) => {
    setIsLoading(true);
    createChronology.mutate({
      caseId,
      chronologyId: null,
      type: type,
    });
  };

  const handleCustomChronology = () => {
    const searchParams = new URLSearchParams(location.search);
    const caseId = searchParams.get('caseId');
    if (!caseId) return;
    searchParams.set('caseId', caseId);
    searchParams.set('chronologyTour', 'true');
    searchParams.set('page', '0');
    navigate('/app/chronos/case-editor/data/facts?' + searchParams.toString());
  };

  return (
    <div>
      <div className="w-full border-2 border-x-0 border-t-0 bg-gray-100">
        <h2 className="flex items-center gap-4 px-10 pb-4 pt-6 text-xl font-semibold text-blue-500">
          <FontAwesomeIcon icon={faTimeline} className="h-7 w-7" />
          Create Chronology
        </h2>
      </div>
      {isLoading ? (
        <div className="flex h-80 flex-col items-center justify-center gap-10">
          <p className="text-gray-700">Please wait while we construct your chronology...</p>
          <StageSpinner size={50} className="m-auto" color={'#4161FF'} />
        </div>
      ) : (
        <div className="px-10 pb-10 pt-6 text-gray-700">
          <div className="text-sm">
            <div className="mb-6 leading-relaxed text-gray-700">
              Create a chronology from our preset options, or discover how to create your own from the full body of
              facts.
            </div>
            <div className="flex w-5/6 flex-col gap-6">
              <div className="flex flex-col gap-4">
                <ActionButton
                  title="Key Facts"
                  description={
                    <span>
                      Create chronology from <b className="text-blue-500">{totalKeyFacts}</b> key facts
                    </span>
                  }
                  icon={
                    <FontAwesomeIcon
                      icon={faStar}
                      className="mr-2 h-4 w-4 cursor-pointer rounded-full border p-2 text-gray-800"
                    />
                  }
                  onClick={() => {
                    handleChronologyCreation('keyFacts');
                    trackCustomEvent('Create chronology from key facts from summary page', { caseId });
                  }}
                />
                <ActionButton
                  title="Relevant Facts"
                  description={
                    <span>
                      Create chronology from <b className="text-blue-500">{totalRelevantFacts}</b> relevant facts
                    </span>
                  }
                  icon={
                    <FontAwesomeIcon
                      icon={faList}
                      className="mr-2 h-4 w-4 cursor-pointer rounded-full border p-2 text-gray-800"
                    />
                  }
                  onClick={() => {
                    handleChronologyCreation('relevantFacts');
                    trackCustomEvent('Create chronology from relevant facts from summary page', { caseId });
                  }}
                />
              </div>
              <div className="border border-x-0 border-b-0 pt-6">
                <ActionButton
                  title="Custom"
                  description="See how you can build your own chronology"
                  icon={
                    <FontAwesomeIcon
                      icon={faCog}
                      className="mr-2 h-4 w-4 cursor-pointer rounded-full border p-2 text-gray-800"
                    />
                  }
                  onClick={() => {
                    handleCustomChronology();
                    trackCustomEvent('Create custom chronology from summary page', { caseId });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChronologyModal;
