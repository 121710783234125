import { useParams } from 'react-router-dom';
import { toast } from 'sonner';

import { OrgPermission } from '../../../../../backend/src/organisation/organisation-permission';
import { useUpdateOrganisation } from '@/api/mutations/useUpdateOrganisation';
import useGetOrganisation from '@/api/queries/useGetOrganisation';
import { Label } from '@/components/atoms/label';
import { Switch } from '@/components/atoms/switch';
import PageWrapper from '@/components/molecules/PageWrappers/PageWrapper';
import { useGlobalAdminCheck } from '@/hooks/useGlobalAdminCheck';

export const OrganisationEditor = () => {
  const { orgId } = useParams<{ orgId: string }>();

  const { data: organisation } = useGetOrganisation(orgId ?? '');
  const { mutate: updateOrganisation } = useUpdateOrganisation(orgId ?? '');

  useGlobalAdminCheck();

  function togglePermission(permission: OrgPermission) {
    if (!organisation) return;

    let updatedPermissions = organisation.permissions.includes(permission)
      ? organisation.permissions.filter((perm) => perm !== permission)
      : [...organisation.permissions, permission];

    // Remove Inconsistency permission if AnthropicModels is disabled
    if (!updatedPermissions.includes(OrgPermission.AnthropicModels)) {
      updatedPermissions = updatedPermissions.filter((perm) => perm !== OrgPermission.Inconsistencies);
    }

    updateOrganisation({ permissions: updatedPermissions });

    toast.success('Organisation permissions updated');
  }

  return (
    <PageWrapper className="bg-gray-50">
      <div className="flex w-full flex-col items-start justify-start px-4 md:px-6 lg:px-8">
        <div className="mb-8 flex w-full flex-col gap-6">
          <div className="w-full rounded-xl bg-white p-6 shadow-sm">
            <div className="flex flex-col gap-6">
              <div className="flex flex-col">
                <h1 className="text-xl font-semibold text-gray-800">Organisation Settings</h1>
                <p className="text-sm text-gray-600">Manage your organisation's permissions and settings.</p>
              </div>
            </div>
          </div>

          <div className="w-full rounded-xl bg-white p-6 shadow-sm">
            <div className="w-full overflow-x-auto">
              <div className="mb-6">
                <Label htmlFor="org-name" className="mb-2 block text-sm font-medium text-gray-700">
                  Organisation Name
                </Label>
                <div className="flex h-10 w-full items-center rounded-md border border-gray-300 bg-gray-100 px-3 text-gray-700">
                  {organisation?.name}
                </div>
                <p className="mt-1 text-xs text-gray-500">Organisation name cannot be edited</p>
              </div>

              <div className="mb-6">
                <div className="flex items-center justify-between">
                  <Label htmlFor="api-deck-toggle" className="text-sm font-medium text-gray-700">
                    Apideck Integration
                  </Label>
                  <div className="flex items-center gap-2">
                    <Switch
                      className="bg-gray-100"
                      id="api-deck-toggle"
                      checked={organisation?.permissions.includes(OrgPermission.Apideck)}
                      onCheckedChange={() => togglePermission(OrgPermission.Apideck)}
                    />
                    <span
                      className={`rounded px-2 py-0.5 text-xs font-medium ${organisation?.permissions.includes(OrgPermission.Apideck) ? 'bg-green-100 text-green-700' : 'bg-gray-100 text-gray-700'}`}
                    >
                      {organisation?.permissions.includes(OrgPermission.Apideck) ? 'ON' : 'OFF'}
                    </span>
                  </div>
                </div>
                <p className="mt-1 text-xs text-gray-500">
                  Whether the Apideck integration is enabled for this organisation.
                </p>
              </div>

              <div className="mb-6">
                <div className="flex items-center justify-between">
                  <Label htmlFor="anthropic-toggle" className="text-sm font-medium text-gray-700">
                    Anthropic Models
                  </Label>
                  <div className="flex items-center gap-2">
                    <Switch
                      className="bg-gray-100"
                      id="anthropic-toggle"
                      checked={organisation?.permissions.includes(OrgPermission.AnthropicModels)}
                      onCheckedChange={() => togglePermission(OrgPermission.AnthropicModels)}
                    />
                    <span
                      className={`rounded px-2 py-0.5 text-xs font-medium ${organisation?.permissions.includes(OrgPermission.AnthropicModels) ? 'bg-green-100 text-green-700' : 'bg-gray-100 text-gray-700'}`}
                    >
                      {organisation?.permissions.includes(OrgPermission.AnthropicModels) ? 'ON' : 'OFF'}
                    </span>
                  </div>
                </div>
                <p className="mt-1 text-xs text-gray-500">
                  Whether or not Anthropic models (Claude) through Frankfurt are enabled for this organisation.
                </p>
              </div>

              <div className="mb-6">
                <div className="flex items-center justify-between">
                  <Label htmlFor="inconsistency-toggle" className="text-sm font-medium text-gray-700">
                    Inconsistencies
                  </Label>
                  <div className="flex items-center gap-2">
                    <Switch
                      className="bg-gray-100"
                      id="inconsistency-toggle"
                      disabled={!organisation?.permissions.includes(OrgPermission.AnthropicModels)}
                      checked={organisation?.permissions.includes(OrgPermission.Inconsistencies)}
                      onCheckedChange={() => togglePermission(OrgPermission.Inconsistencies)}
                    />
                    <span
                      className={`rounded px-2 py-0.5 text-xs font-medium ${organisation?.permissions.includes(OrgPermission.Inconsistencies) ? 'bg-green-100 text-green-700' : 'bg-gray-100 text-gray-700'}`}
                    >
                      {organisation?.permissions.includes(OrgPermission.Inconsistencies) ? 'ON' : 'OFF'}
                    </span>
                  </div>
                </div>
                <p className="mt-1 text-xs text-gray-500">
                  Whether or not the Inconsistency feature is enabled in this organisation. This requires Anthropic
                  models to be enabled.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};
