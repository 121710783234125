import { useQuery } from 'react-query';

import { APIBaseChronos } from '@/api/hosts';
import { QueryEntity } from '@/api/types';
import useGetFetchConfig from '@/api/useGetFetchConfig';
import { ApideckConnection } from '@/types';

export const useGetApideckAllConnections = (options = {}) => {
  const { fetchConfigGET } = useGetFetchConfig();

  const getApideckAllConnections = async (): Promise<Array<ApideckConnection>> => {
    const response = await fetch(`${APIBaseChronos}/api/apideck/connections`, fetchConfigGET);
    return await response.json();
  };

  return useQuery<Array<ApideckConnection>>([QueryEntity.ApideckAllConnections], getApideckAllConnections, options);
};
