import React, { useState } from 'react';

import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SingleValue } from 'react-select';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/molecules/DropdownMenu';
import { FilterOption } from '@/types';

interface TableFilterPopupProps {
  options: FilterOption[];
  onChange: (newValue: string) => void;
}

const TableFilterPopup: React.FC<TableFilterPopupProps> = ({ onChange, options }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleApply = (newValue: SingleValue<FilterOption>) => {
    if (newValue?.value) {
      if (typeof newValue?.value === 'string') onChange(newValue?.value);
      setIsOpen(false);
    }
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <FontAwesomeIcon
          icon={faBars}
          className={`mr-2 flex cursor-pointer items-center justify-center rounded-md border p-1 shadow ${
            isOpen ? 'bg-gray-200' : ''
          }`}
          data-testid="filter-icon"
        />
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-24 bg-white">
        <DropdownMenuGroup>
          {options.map((option) => (
            <DropdownMenuItem
              key={option.value}
              onClick={() => handleApply(option)}
              className="rounded hover:cursor-pointer hover:bg-gray-100"
            >
              <span>{option.label}</span>
            </DropdownMenuItem>
          ))}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default TableFilterPopup;
