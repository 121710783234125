import { ReactNode, useState } from 'react';

import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Modal from '../CustomModal';
import ConfettiIcon from '@/assets/guide/homepage-guides/confetti.svg?react';
import { Button } from '@/components/atoms/new-button';

export interface StepItem {
  icon: IconDefinition;
  title: string;
  description: string;
}

export interface TipItem {
  text: string;
  color?: 'blue' | 'green';
}

export interface OnboardingModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  title: string;
  icon: IconDefinition;
  introduction?: ReactNode;
  steps?: StepItem[];
  tip?: TipItem;
  children?: ReactNode;
  maxWidth?: string;
  testId?: string;
}

export const OnboardingModalBase = ({
  isOpen,
  setIsOpen,
  title,
  icon,
  introduction,
  steps,
  tip,
  children,
  maxWidth = '42rem',
  testId,
}: OnboardingModalProps) => {
  const handleClose = () => setIsOpen(false);

  return (
    <Modal
      isOpen={isOpen}
      maxWidth={maxWidth}
      content={
        <div data-testid={testId} className="p-6 text-center">
          {/* Header with icon */}
          <div className="mb-4 flex justify-center">
            <div className="rounded-full bg-blue-100 p-3">
              <FontAwesomeIcon icon={icon} className="h-6 w-6 text-blue-600" />
            </div>
          </div>

          {/* Title with optional confetti */}
          <h3 className="mb-3 flex flex-row items-center justify-center gap-2 text-xl font-bold text-blue-700">
            {title} {<ConfettiIcon className="h-4 w-4" />}
          </h3>

          {/* Introduction text */}
          {introduction && introduction}

          {/* Step-by-step cards */}
          {steps && steps.length > 0 && (
            <div className="mb-4 space-y-3">
              {steps.map((step, index) => (
                <div
                  key={index}
                  className="flex items-start rounded-lg border border-gray-200 bg-white p-3 text-left shadow-sm"
                >
                  <div className="mr-3 flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full bg-blue-100">
                    <FontAwesomeIcon icon={step.icon} className="h-4 w-4 text-blue-600" />
                  </div>
                  <div>
                    <h4 className="text-sm font-medium text-gray-900">{`${index + 1}. ${step.title}`}</h4>
                    <p className="mt-1 text-sm text-gray-600">{step.description}</p>
                  </div>
                </div>
              ))}
            </div>
          )}

          {/* Tip section */}
          {tip && (
            <div className={`mt-2 rounded-lg bg-${tip.color || 'blue'}-50 p-3`}>
              <p className={`text-sm text-${tip.color || 'blue'}-600`}>
                <span className="font-semibold">Tip: </span>
                {tip.text}
              </p>
            </div>
          )}

          {/* Additional content */}
          {children}

          {/* Button section */}
          <div className="mt-4 flex justify-center">
            <Button variant="primary" onClick={handleClose} className="px-6 py-2">
              Got it!
            </Button>
          </div>
        </div>
      }
      handleClose={handleClose}
    />
  );
};
