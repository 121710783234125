import { useCallback, useEffect, useState } from 'react';

import { faCircleNotch, faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PaginationState } from '@tanstack/react-table';
import { CircleHelp } from 'lucide-react';
import { useSearchParams } from 'react-router-dom';

import DocumentRow from './components/DocumentRow';
import DocumentEditorToolbar from './DocumentEditorToolbar';
import { useDownloadDocumentsList } from '../../../../../api/mutations/useDownloadDocumentsList';
import { useGetCase } from '../../../../../api/queries/useGetCase';
import { DownloadCSVModal } from '../../../../../components/molecules/Modals/DownloadCSVModal';
import Modal from '../../../../../components/molecules/Modals/SettingsModal';
import DocViewer from '../../components/DocViewer/FullDocViewer';
import { trackCustomEvent, trackPageView } from '@/analytics/Mixpanel';
import { useGetDocs } from '@/api/queries/useGetDocs';
import { useGetDocTotals } from '@/api/queries/useGetDocTotals';
import EmptyState from '@/components/molecules/EmptyState';
import { Doc } from '@/types';

const DocumentEditor = ({ openSettingsModal }: { openSettingsModal: () => void }) => {
  const [searchParams] = useSearchParams();

  const caseId = searchParams.get('caseId');
  const pageNumber = searchParams.get('page') || '0';
  const docPageNumber = Number(searchParams.get('page'));
  const docId = searchParams.get('docId');

  const [globalFilter, setGlobalFilter] = useState('');
  const [appliedGlobalFilter, setAppliedGlobalFilter] = useState('');
  const [pagination, setPagination] = useState<PaginationState>({ pageIndex: parseInt(pageNumber), pageSize: 50 });
  const [title, setTitle] = useState('');
  const [isDocViewerOpen, setIsDocViewerOpen] = useState(false);
  const [isDocViewerFullyOpen, setIsDocViewerFullyOpen] = useState(false);

  const [downloadModalIsOpen, setDownloadModalIsOpen] = useState(false);

  useEffect(() => {
    if (docId) {
      setIsDocViewerOpen(true);
      // Add a slight delay before setting isDocViewerFullyOpen to true
      setTimeout(() => setIsDocViewerFullyOpen(true), 300);
    } else {
      setIsDocViewerFullyOpen(false);
      // Add a slight delay before closing the doc viewer
      setTimeout(() => setIsDocViewerOpen(false), 300);
    }
  }, [docId]);

  const {
    data: responseDocs,
    isFetching: isFetchingDocsTable,
    isLoading: isLoadingDocsTable,
    refetch: refetchDocs,
  } = useGetDocs(caseId, pagination.pageIndex + 1, pagination.pageSize, appliedGlobalFilter);
  const { data: docTotals } = useGetDocTotals(caseId);
  const { data: caseData } = useGetCase(caseId ?? '');

  const onSearchCall = () => {
    setPagination({ pageIndex: 0, pageSize: pagination.pageSize });
    setAppliedGlobalFilter(globalFilter);
  };

  const clearSearch = async () => {
    setGlobalFilter('');
    setAppliedGlobalFilter('');
  };

  useEffect(() => {
    refetchDocs();
  }, [pagination, appliedGlobalFilter, refetchDocs]);

  useEffect(() => {
    trackPageView('Documents Page');
  }, []);

  // Pagination
  const goToPage = (val: number) => {
    const newPaginationState = { pageIndex: val, pageSize: pagination.pageSize };
    setPagination(newPaginationState);
  };
  const prevPage = () => goToPage(pagination.pageIndex - 1);
  const nextPage = () => goToPage(pagination.pageIndex + 1);

  const { fetchDownload, isLoadingDownload } = useDownloadDocumentsList({
    caseId,
  });

  const handleDownload = useCallback(
    ({ title, columns, format }: { title: string; columns: string[]; format: string }) => {
      trackCustomEvent(`Download Document List ${format}`, { caseId });
      fetchDownload(
        { title, columns, format },
        {
          onSuccess: async () => {
            await new Promise((resolve) => setTimeout(resolve, 200));
            setDownloadModalIsOpen(false);
          },
        },
      );
    },
    [caseId],
  );

  return (
    <div className="flex h-full w-full">
      {!isDocViewerFullyOpen && (
        <div
          className="flex h-full flex-col transition-all duration-300 ease-in-out"
          style={{ width: isDocViewerOpen ? '0%' : '100%', opacity: isDocViewerOpen ? 0 : 1 }}
        >
          <DocumentEditorToolbar
            processedCount={docTotals?.processed ?? 0}
            totalDocs={docTotals?.total ?? 0}
            openSettingsModal={openSettingsModal}
            openDownloadModal={() => setDownloadModalIsOpen(true)}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            onSearchCall={onSearchCall}
            clearSearch={clearSearch}
            totalPages={responseDocs?.pageCount || 0}
            goToPage={goToPage}
            currentPage={pagination.pageIndex}
            prevPage={prevPage}
            nextPage={nextPage}
          />
          <div className="mb-6 overflow-y-scroll">
            {isLoadingDocsTable ? (
              <div className="flex h-full min-h-[500px] w-full items-center justify-center">
                <FontAwesomeIcon icon={faCircleNotch} className="animate-spin" />
              </div>
            ) : (
              <>
                <div className="flex w-full flex-col gap-3 py-1">
                  {responseDocs && responseDocs.docs && responseDocs.docs.length > 0 ? (
                    <>
                      {responseDocs.docs.map((doc: Doc) => {
                        return <DocumentRow key={doc.id} doc={doc} caseId={caseId || ''} setTitle={setTitle} />;
                      })}
                    </>
                  ) : (
                    <EmptyState
                      title="No documents found"
                      subtitle="Sorry, we couldn't find any documents matching these criteria"
                      icon={<CircleHelp className="h-12 w-12 text-yellow-500" />}
                    />
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      )}

      <div
        className={`transition-all duration-300 ease-in-out ${
          isDocViewerOpen ? 'w-full translate-x-0 opacity-100' : 'w-0 translate-x-full opacity-0'
        } overflow-hidden bg-white`}
      >
        {docId && (
          <DocViewer
            title={title}
            docId={docId}
            caseId={caseId || ''}
            pageNumber={docPageNumber}
            factTotal={responseDocs?.docs?.find((doc) => doc.id === docId)?.factsCount?.toString() ?? null}
          />
        )}
      </div>

      <Modal
        title={
          <div className="flex items-center gap-2 font-semibold text-blue-500">
            <FontAwesomeIcon icon={faDownload} className="" />
            Download Documents List
          </div>
        }
        isOpen={downloadModalIsOpen}
        handleClose={() => setDownloadModalIsOpen(false)}
        content={
          <DownloadCSVModal
            columnOptions={documentListDownloadOptions}
            downloadTitle={`${caseData?.name} Documents`}
            loadingDownload={isLoadingDownload}
            handleDownload={handleDownload}
          />
        }
        size="small"
      />
    </div>
  );
};

export default DocumentEditor;

const documentListDownloadOptions = [
  { label: 'ID', value: 'ID' },
  { label: 'Filename', value: 'Filename' },
  { label: 'File Type', value: 'File Type' },
  { label: 'Upload Date', value: 'Upload Date' },
  { label: 'Author', value: 'Author' },
  { label: 'Date', value: 'Date' },
  { label: 'Document Type', value: 'Document Type' },
  { label: 'Summary', value: 'Summary' },
  { label: 'Number Of Pages', value: 'Number Of Pages' },
  { label: 'Number Of Facts', value: 'Number Of Facts' },
];
