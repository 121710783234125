import { useState } from 'react';

import {
  faChevronCircleLeft,
  faFile,
  faFileShield,
  faMagnifyingGlass,
  faTimeline,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ShieldQuestion } from 'lucide-react';
import { Route, Routes, useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import ChronologyViewer from './ChronologyViewer';
import DocumentsEditor from './DocumentsEditor';
import FactsEditor from './FactsEditor';
import InconsistenciesEditor from './InconsistenciesEditor';
import { InconsistencyReportView } from './inconsistency-report/InconsistencyReportView';
import { OrgPermission } from '../../../../../../backend/src/organisation/organisation-permission';
import { useOrgPermissions } from '@/hooks/orgPermissions';

const DataView = ({ openSettingsModal }: { openSettingsModal: () => void }) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const caseId = searchParams.get('caseId');

  const [isCollapsed, setIsCollapsed] = useState(false); // Sidebar state

  const { isEnabled } = useOrgPermissions();

  const handleTabClick = (e: React.MouseEvent, path: string) => {
    navigate(path);
  };

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const NavItem = ({ title, route, icon }: { title: string; route: string; icon?: React.ReactNode }) => {
    const isActive = route ? location.pathname === route.split('?')[0] : false;

    return (
      <div
        className={`mx-1 flex items-center gap-2 rounded-sm px-2 py-1.5 text-xs transition-colors hover:cursor-pointer hover:bg-gray-100 ${
          isActive ? 'bg-gray-200 font-medium hover:bg-gray-200' : ''
        }`}
        onClick={(e) => handleTabClick(e, route)}
      >
        {icon && <span className="flex-shrink-0">{icon}</span>}
        <span>{title}</span>
      </div>
    );
  };

  const SectionHeader = ({ title }: { title: string }) => (
    <div className="mb-2 border-b px-3 pt-2 pb-1 text-sm font-medium tracking-wider text-gray-500">{title}</div>
  );

  const NavGroup = ({ title, children }: { title: string; children: React.ReactNode }) => (
    <div className="mb-2">
      <div className="px-3 py-1 text-xs font-medium text-gray-600">{title}</div>
      <div className="relative pl-3">
        <div className="absolute top-0 left-3 h-full w-px bg-gray-200"></div>
        <div className="flex flex-col gap-1">{children}</div>
      </div>
    </div>
  );

  return (
    <div className="flex h-[92%] w-full">
      {/* Sidebar container */}
      <div
        className={`transition-width relative flex h-full flex-col rounded border-2 bg-white py-1 duration-500 ease-in-out ${
          isCollapsed ? 'w-0 border-gray-50' : 'mr-2 w-36 border-gray-200/80'
        } `} // Dynamically set width and transition
      >
        <div className={`absolute top-[-6px] ${isCollapsed ? 'left-[-8px]' : 'right-[-8px]'}`}>
          <FontAwesomeIcon
            icon={faChevronCircleLeft} // Change icon based on state
            className={`text-brandSecondary h-5 w-5 cursor-pointer ${isCollapsed ? 'rotate-180' : ''}`}
            onClick={toggleSidebar}
          />
        </div>

        {!isCollapsed && (
          <div className="flex h-full flex-col overflow-y-auto">
            <SectionHeader title="Data" />

            <NavGroup title="Facts">
              <NavItem
                title="Facts"
                route={`/app/chronos/case-editor/data/facts?caseId=${caseId}`}
                icon={<FontAwesomeIcon icon={faMagnifyingGlass} className="h-3.5 w-3.5 shrink-0 text-gray-700" />}
              />
              <NavItem
                title="Chronologies"
                route={`/app/chronos/case-editor/data/chronology?caseId=${caseId}`}
                icon={<FontAwesomeIcon icon={faTimeline} className="h-3.5 w-3.5 shrink-0 text-gray-700" />}
              />
            </NavGroup>

            {isEnabled(OrgPermission.Inconsistencies) && (
              <NavGroup title="Inconsistencies">
                <NavItem
                  title="Inconsistencies"
                  route={`/app/chronos/case-editor/data/inconsistencies?caseId=${caseId}`}
                  icon={<ShieldQuestion className="h-3.5 w-3.5 shrink-0 text-gray-700" />}
                />
                <NavItem
                  title="Reports"
                  route={`/app/chronos/case-editor/data/inconsistency-report?caseId=${caseId}`}
                  icon={<FontAwesomeIcon icon={faFileShield} className="ml-0.5 h-3.5 w-3.5 shrink-0 text-gray-700" />}
                />
              </NavGroup>
            )}

            <NavItem
              title="Documents"
              route={`/app/chronos/case-editor/data/documents?caseId=${caseId}`}
              icon={<FontAwesomeIcon icon={faFile} className="h-3.5 w-3.5 shrink-0 text-gray-700" />}
            />
          </div>
        )}
      </div>

      {/* Main content area */}
      <div className="h-full w-full overflow-x-auto overflow-y-hidden rounded border-2 border-gray-200/80 bg-white px-4 transition-all duration-300">
        <Routes>
          <Route path="facts" element={<FactsEditor />} />
          <Route path="documents" element={<DocumentsEditor openSettingsModal={openSettingsModal} />} />
          <Route path="chronology/*" element={<ChronologyViewer />} />
          {isEnabled(OrgPermission.Inconsistencies) && (
            <>
              <Route path="inconsistencies" element={<InconsistenciesEditor />} />
              <Route path="inconsistency-report/*" element={<InconsistencyReportView />} />
            </>
          )}
        </Routes>
      </div>
    </div>
  );
};

export default DataView;
