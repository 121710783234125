import { useMutation } from 'react-query';

import { InconsistencyReportGetOneResponseDto } from '../../../../backend/src/case/inconsistency/dto/inconsistency-report-get-one.dto';
import { APIBaseChronos } from '@/api/hosts';
import useGetFetchConfig, { FetchMethod } from '@/api/useGetFetchConfig';

export const useCreateInconsistencyReport = (caseId: string) => {
  const { getFetchConfig } = useGetFetchConfig();

  const createInconsistencyReport = async (
    inconsistencyIds: string[],
  ): Promise<InconsistencyReportGetOneResponseDto> => {
    const fetchConfig = getFetchConfig({
      method: FetchMethod.POST,
      data: {
        inconsistencyIds,
      },
    });

    const response = await fetch(`${APIBaseChronos}/api/case/${caseId}/inconsistency/report`, fetchConfig);
    return await response.json();
  };

  return useMutation({
    mutationFn: createInconsistencyReport,
  });
};
