import React, { useCallback, useState } from 'react';

import { faSearch, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDebouncedCallback } from 'use-debounce';

interface SearchBoxProps {
  value: string;
  onChange?: (value: string) => void;
  placeholder?: string;
  onSearchCall?: (value: string) => void;
  loading?: boolean;
  clearSearch?: () => void;
}

const SearchBox = ({ value, onChange, placeholder, onSearchCall, clearSearch }: SearchBoxProps) => {
  const [text, setText] = useState(value);

  const debouncedUpdate = useDebouncedCallback((v) => {
    onSearchCall?.(text);
  }, 400);

  const onTextChange = useCallback(
    (text: string) => {
      setText(text);
      onChange?.(text);
      debouncedUpdate(text);
    },
    [debouncedUpdate, onChange],
  );

  return (
    <div
      className="relative flex flex-row items-center justify-center rounded-md border border-gray-300 bg-white pl-2 text-sm xl:w-72"
      data-testid="search-bar"
    >
      <FontAwesomeIcon icon={faSearch} className="text-gray-400" />
      <input
        className="h-8 w-full rounded-md bg-white pl-4 font-normal text-black not-italic placeholder:text-gray-400 focus:outline-none"
        onChange={(e) => onTextChange(e.target.value)}
        value={text}
        placeholder={placeholder ?? 'Search cases'}
      />

      {clearSearch && (
        <button
          className={`mr-2 cursor-pointer text-gray-600 transition-opacity duration-500 ${
            value ? 'opacity-100' : 'opacity-0'
          }`}
          onClick={() => {
            clearSearch();
            setText('');
          }}
        >
          <FontAwesomeIcon icon={faX} className="h-3 w-3" />
        </button>
      )}
    </div>
  );
};

export default React.memo(SearchBox);
